import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { OfferService } from "../services/offer.serice";

@Injectable({
    providedIn: 'root'
})
export class DashboardResolve implements Resolve<any> {
    constructor(private offerService: OfferService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let token = route.params.token;
        if (token) {
            window.localStorage.setItem('localuser', JSON.stringify({ token }));

        }
        let dd = new Date()
        let stopDate = new Date();
        let startDate = new Date(dd.setMonth(dd.getMonth() - 1));
        return this.offerService.loadDashboardData(route.data.type, false, startDate, stopDate);

    }
}


@Injectable({
    providedIn: 'root'
})
export class DashboardCanceledInternallCommandsResolve implements Resolve<any> {
    constructor(private offerService: OfferService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.offerService.loadCanceledInternallCommands();

    }
}

