import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable()
export class LoginService {
  serviceUrl = environment.serviceUrl;
  constructor(private http: HttpClient) {
  }

  login(username, password) {
    const url = `${this.serviceUrl}/admin/login?v=160`;
    let body = {
      client: username.split("@")[1],
      user: username.split("@")[0],
      password
    };
    return this.http.post(url, body).toPromise();
  }
}

