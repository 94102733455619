<h1 class="decision-header" mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <div class="message">{{ data.message | translate }}</div>
  <div *ngIf="data.items">
    <mat-list role="list">
      <mat-list-item *ngFor="let item of data.items" role="listitem">{{ item.name }}</mat-list-item>
    </mat-list>
  </div>
</div>
<div mat-dialog-actions>
  <div class="closeSvg" *ngIf="data.showClose" (click)="closeDialog(false)" [inlineSVG]="'/assets/images/close.svg'"></div>
  <div class="confirmSvg" (click)="closeDialog(true)" [inlineSVG]="'/assets/images/checked.svg'"></div>
</div>  