import { Component, OnInit, OnDestroy, Inject, HostListener, ViewChild, ElementRef, } from '@angular/core';
import { OfferService } from '../services/offer.serice';
import { CartDataModel } from '../../../helpers/datatable/data/cart-data.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifyService } from '../../../helpers/notify.service';
import { MatDialog } from '@angular/material/dialog';
import { DecisionModalComponent } from '../../../decision-modal/decision-modal.component';
import { OfferModel } from '../../models/offer.model';
import { environment } from '../../../../environments/environment';
import { DateAdapter } from '@angular/material/core';
import { UntypedFormControl } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { CarettaService } from '../../../helpers/caretta.helper';
import { openDB } from 'idb/with-async-ittr';


import {
    enable as enableDarkMode,
    disable as disableDarkMode,
} from 'darkreader';

@Component({
    selector: 'enode-offer-details',
    templateUrl: './offer-details.component.html',
    styleUrls: ['./offer-detail.component.scss'],
})
export class OfferDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('myCanvas', { static: false }) myCanvas;
    @ViewChild('dialDetail') dialDetail;
    @ViewChild('fenceDetail') fenceDetail;


    darkModeEnabled = false;
    showZeroQuantityArticles = false;
    firstLoad = true;
    scannedItems: CartDataModel[] = [];
    currentOffer: any = { articles: [], offerData: {} };
    colors;
    materials;
    thickness;
    selectedOfferType;
    selectedColor;
    selectedMaterial;
    selectedThickness;

    totalPrice = 0;
    totalMinimumPrice = 0;
    totalWithOutDiscount = 0;
    discountValue = 0;
    discountPercent = 0;
    adaosValue;
    adaosValuer;
    adaosPercent;
    showAdaos = false;
    includePrices = false;
    generateNewOffer = false;

    temporaryArticles = [];
    clientData;
    offerId;
    offersType = [
        // { name: 'ȚIGLĂ METALICĂ DAILY', key: 'DA' },
        // { name: 'ȚIGLĂ METALICĂ DAILY SMART', key: 'DS' },
        // { name: 'ȚIGLĂ METALICĂ NOBEL', key: 'NO' },
        // { name: 'ȚIGLĂ METALICĂ NOBEL SMART', key: 'NS' },
        // { name: 'TABLĂ CANTO', key: 'CA' },
        // { name: 'TABLĂ CANTO SMART', key: 'CS' },
        // { name: 'GARDURI ZEBRA', key: 'GAZ' },
        // { name: 'ELEMENTE OPTIONALE', key: 'OP' },
        // { name: 'VELUX', key: 'VLX' },
        // { name: 'DONAU', key: 'DO' },
        // { name: '----------\/\/\/DINAMIC\/\/\/----------', key: 'DO' }

    ];
    clientTypes = ['PF', 'PJ'];
    clientId;
    serviceUrl = environment.serviceUrl;

    invoiceTypes = OfferModel.InvoiceType;


    finishTypes = [
        { label: 'Factura', value: 'factura' },
        { label: 'Aviz', value: 'aviz' },
        { label: 'Casa de marcat', value: 'casaMarcat' }
    ]

    // paymentTypes = [
    //     { label: 'Cash varsamant', value: 'cash' },
    //     { label: 'OP', value: 'op' },
    //     { label: 'CARD', value: 'card' },
    //     { label: 'Cash bon fiscal', value: 'casaMarcat' },
    //     { label: 'Rate TBI', value: 'TBI' }
    // ];

    paymentTypes = [
        { label: 'Cash varsamant', value: 'cash' },
        { label: 'Cash bon fiscal', value: 'cashbon' },
        { label: 'OP', value: 'op' },
        { label: 'CARD', value: 'card' },
        { label: 'Rate TBI', value: 'TBI' }
    ];

    opType = [
        { label: 'Dealer', value: 'Dealer' },
        { label: 'Client', value: 'Client' }
    ];


    priceVariations = [{
        key: "agentMinimumPrice", name: "REF"
    }, {
        key: "P1", name: "P1"
    },
    {
        key: "P2", name: "P2"
    },
    {
        key: "P3", name: "P3"
    }
    ]



    minDate = new Date();

    selectedClient: any = {};
    selectedDealer: any = {};
    selectedDealerAgent: any = {};
    dealerAgents: any = [];
    //dealerInvoiceOnClient = false;
    deliverStreetAlternative;
    offerData;
    token;
    promokey;
    //select a client
    clients = [];
    dealers: any = [];

    selectedPhone;
    selectedCounty;
    onDestroy$ = new Subject<boolean>();
    pluvialType = 'SL';
    showPluvialTypeModal = false;
    debug = false;

    //temporary counties todo
    selectedValueCounty;
    counties;
    localities;
    myControl = new UntypedFormControl();
    filteredOptions;

    addNewClient = false;
    editClient = false;
    newClientType;
    showClientsModal = false;

    clientNameSearch;
    clientPhoneSearch;
    clientFiscalCodeSearch;

    stockDetail;

    newClientData = {
        clientType: '',
        name: '',
        fiscalCode: '',
        tradeRegister: '',
        phone: '',
        county: '',
        locality: null,
        street: '',
        scara: '',
        number: '',
        bloc: '',
        etaj: '',
        apartament: '',
    };
    editClientData = {
        isPF: false,
        clientId: '',
        agendaId: '',
        name: '',
        fiscalCode: '',
        tradeRegister: '',
        phone: '',
        county: '',
        locality: null,
        street: '',
        scara: '',
        number: '',
        bloc: '',
        etaj: '',
        apartament: ''
    };
    totalOfferWeight = 0;

    canChooseClientType = false;
    canCancelCommand = false;
    canPrintInternallCommandPdf = false;
    canDrawTechnicalDrawing = false;
    canSimulator = false;
    canPickedByClient = false;

    showAlternativeDelivery = false;
    showButtonAlternativeAddress = true;
    cancelCommandFlag = false;
    cancelObservations = '';
    username;

    showImage = false;
    image;

    validFiscalCode = false;
    validatedPartnerData;
    statusTaskOffer;
    isDealerTrusted;
    showSpecialCategory = true;
    inactiveCategory;
    creditValue;
    soldValue;
    isRestrictedArticlePromo;
    version = environment.version;
    promoRoofError = false;
    @ViewChild('fileUpload') fileUpload: any;

    industryCategories;

    percentRoofingPROMOSheets;
    accesoryProcent = 0;
    discountPromo = 0;

    temporaryCategories = [];

    yesterday;
    context;
    points = [];
    customLength;
    customTotal = 0;

    observation = "";

    drawingObject = {
        points: [],
        customLength: null,
        customWidth: null,
        customQuantity: null,
        observation: "",
        arrow: {
            p1: null,
            p2: null
        }
    }

    activeRAL = false;
    showRAL = false;
    showDraw = false;

    drawArticle = null;
    activeIndex1 = 0;
    classes;

    providers;

    confEnabled = false;


    numarPanouri = 0;
    distantaStalpi = 0;
    distantaSipci = 0;
    stalpi = 0;
    inaltimeStalpi = 0;
    sipci = 0;
    lungimeSipci = 0;
    profil = 0;
    lungimeProfil = 0;
    capace = 0;
    lungimeCapace = 0;
    capaceinchidere = 0;
    lungimeCapaceinchidere = 0;
    rigle = 0;
    lungimeRigle = 0;
    popnituri = 0;
    sipciObs = "";
    tipSipca;

    changeFenceModal = false;

    activeFenceIndex = 0;
    fences = [
        {
            name: "ZEBRA.ORIZONTAL X 121",
            imagePath: "./assets/caretta/X121.jpg",
            sectionPath: "./assets/caretta/sectiuneX121.png",
            heights: []
        },
        {
            name: "ZEBRA.ORIZONTAL X 121 2F",
            imagePath: "./assets/caretta/X121.jpg",
            sectionPath: "./assets/caretta/sectiuneX121F.png",
            heights: []
        }
        ,
        {
            name: "ZEBRA.ORIZONTAL X 135",
            imagePath: "./assets/caretta/X135.jpg",
            sectionPath: "./assets/caretta/sectiuneX135.png",
            heights: []
        },
        {
            name: "ZEBRA.ORIZONTAL X 135 2F",
            imagePath: "./assets/caretta/X135.jpg",
            sectionPath: "./assets/caretta/sectiuneX135.png",
            heights: []
        },
        {
            name: "ZEBRA.ORIZONTAL X 174",
            imagePath: "./assets/caretta/X174.jpg",
            sectionPath: "./assets/caretta/sectiuneX174.png",
            heights: []
        },
        {
            name: "ZEBRA.ORIZONTAL CASETA X 140",
            sectionPath: "./assets/caretta/sectiuneX140.png",
            imagePath: "./assets/caretta/X140.jpg"
        },

        {
            name: "ZEBRA.VERTICAL Y 118",
            imagePath: "./assets/caretta/Y118.jpg",
            sectionPath: "./assets/caretta/sectiuneY118.png"
        }, {
            name: "ZEBRA.VERTICAL Y 115",
            sectionPath: "./assets/caretta/sectiuney115.png",
            imagePath: "./assets/caretta/Y115.jpg"

        }
    ]

    selectedFenceCategory = false;
    selectedFenceType;


    //['CARETTA', 'BRAMAC', 'TONDACH', 'GERARD']
    constructor(
        private offerService: OfferService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private notify: NotifyService,
        private _adapter: DateAdapter<any>,
        private carettaService: CarettaService,
        @Inject('LOCALSTORAGE') private store: any,
        private location: LocationStrategy
    ) {
        window.onbeforeunload = function (event) {
            console.log(event);
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';
        };

        // preventing back button in browser 
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });

        this.darkModeEnabled = this.store.get('darkModeEnabled');
        this.darkMode();
    }

    async ngOnInit() {

        this.token = this.route.snapshot.paramMap.get('token');
        this.promokey = this.route.snapshot.paramMap.get('promokey');
        if (this.token) {
            this.store.set('localuser', { token: this.token });
        }

        this.offerId = this.route.snapshot.paramMap.get('offerId');

        this.classes = await this.offerService.getClasses();
        let prv = new Set();
        for (let classData of this.classes) {
            prv.add(classData.name.split(" ")[0])

            // offersType = [
            //     { name: 'ȚIGLĂ METALICĂ DAILY', key: 'DA' },
        }
        this.providers = prv;
        this.counties = await this.offerService.getCounties();
        await this.getDealers();
        //for caretta
        await this.getOfferData();
        this._adapter.setLocale('ro');
        if (this.offerData?.deliverDate) {
            this.offerData.deliverDate = this.offerData?.deliverDate ? new Date(this.offerData.deliverDate) : null;
        }
        this.offerData.isAdvancePayment = this.offerData.isAdvancePayment ? true : false;
        this.offerData.priceVariationKey = this.offerData.priceVariationKey ? this.offerData.priceVariationKey : "REF";
        this.loadAgentPrice(this.offerData.priceVariationKey);

        this.onChanges();
        let tokenData = this.decodeToken(this.store.get('localuser').token);
        this.username = tokenData.username;

        this.canChooseClientType = await this.checkAccess("CHOOSE_CLIENT_TYPE");
        this.canCancelCommand = await this.checkAccess("CANCEL_COMMAND");
        this.canPrintInternallCommandPdf = await this.checkAccess("PRODUCTION_PDF");
        this.canDrawTechnicalDrawing = await this.checkAccess("TECHNICAL_DRAWING");
        this.canSimulator = await this.checkAccess("FENCE_SIMULATOR");
        this.canPickedByClient = await this.checkAccess("PICKED_BY_CLIENT");

        this.yesterday = new Date();
        this.yesterday.setDate(this.yesterday.getDate() - 1);
    }

    ngAfterViewInit(): void {
        this.context = this.myCanvas.nativeElement.getContext('2d');

        // this.context.fillStyle = "white";
        // this.context.fillRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
        var data = '<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"> \
            <defs> \
                <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse"> \
                    <path d="M 8 0 L 0 0 0 8" fill="none" stroke="gray" stroke-width="0.5" /> \
                </pattern> \
                <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse"> \
                    <rect width="80" height="80" fill="url(#smallGrid)" /> \
                    <path d="M 80 0 L 0 0 0 80" fill="none" stroke="gray" stroke-width="1" /> \
                </pattern> \
            </defs> \
            <rect width="100%" height="100%" fill="url(#grid)" /> \
        </svg>';

        var DOMURL = window.URL;

        var img = new Image();
        var svg = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
        var url = DOMURL.createObjectURL(svg);
        let that = this;
        img.onload = function () {
            that.context.drawImage(img, 0, 0);
            DOMURL.revokeObjectURL(url);
        }
        img.src = url;
    }

    conf() {
        this.confEnabled = true;
        this.fences[0].heights = [];
        this.fences[1].heights = [];
        this.fences[2].heights = [];
        this.fences[3].heights = [];
        this.fences[4].heights = [];
        for (let category of this.offerData.categories) {

            for (let article of category.articles) {
                if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 35 GARD ORIZONTAL X121 368")) {
                    for (let detail of article.data) {
                        if (detail.D1 != 0.01)
                            this.fences[0].heights.push({ name: detail.D1, value: detail.D1 })
                    }
                }
                else if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 70 GARD ORIZONTAL X121 2F 388")) {
                    for (let detail of article.data) {
                        if (detail.D1 != 0.01)
                            this.fences[1].heights.push({ name: detail.D1, value: detail.D1 })
                    }
                } else if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 25 GARD ORIZONTAL X135 366")) {
                    for (let detail of article.data) {
                        if (detail.D1 != 0.01)
                            this.fences[2].heights.push({ name: detail.D1, value: detail.D1 })
                    }
                } else if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 50 GARD ORIZONTAL X135 2F 405")) {
                    for (let detail of article.data) {
                        if (detail.D1 != 0.01)
                            this.fences[3].heights.push({ name: detail.D1, value: detail.D1 })
                    }
                } else if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP X174 361")) {
                    for (let detail of article.data) {
                        if (detail.D1 != 0.01)
                            this.fences[4].heights.push({ name: detail.D1, value: detail.D1 })
                    }
                }
            }
        }
        if (this.changeFenceModal == true) {
            this.activeFenceIndex = 0;
        } else {
            this.activeFenceIndex = 1;
        }
        this.activateFenceTab(null);


    }

    setDrawArticle(article) {
        if (!this.drawArticle) {
            this.drawArticle = article;
            if (!this.drawArticle.data[0]["drawings"]) {
                this.drawArticle.data[0]["drawings"] = [];
            }
        }

    }

    setDraw(drawings) {
        this.drawArticle = {
            data: [{
                drawings
            }]
        }
        this.activeIndex1 = 0;
        this.handleChange({ index: 0 })
    }

    drawCanvas(event) {
        console.log("Clickked", event)
        const rect = this.myCanvas.nativeElement.getBoundingClientRect()
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (this.activeRAL || (this.drawingObject.arrow.p1 && this.drawingObject.arrow.p2)) {

            if (!this.drawingObject.arrow.p1) {
                this.drawingObject.arrow.p1 = { x, y };
            }
            else if (this.drawingObject.arrow.p1 && !this.drawingObject.arrow.p2) {
                this.drawingObject.arrow.p2 = { x, y };

                this.drawRAL(this.drawingObject.arrow.p1, this.drawingObject.arrow.p2)
            }
            return;

        }

        if (this.drawingObject.points.length == 0) {
            this.context.beginPath();
            this.context.moveTo(x, y);
            this.context.stroke();
            this.drawingObject.points = [...this.drawingObject.points, { x, y }];
            return;
        }

        let p1 = { x: 10, y: 10 };
        if (this.drawingObject.points.length > 0) {
            p1 = this.drawingObject.points[this.drawingObject.points.length - 1];
        }

        let p2 = { x, y, value: null };
        console.log("x: " + x + " y: " + y)
        this.drawingObject.points = [...this.drawingObject.points, p2];

        this.context.lineTo(x, y);
        this.context.strokeStyle = 'black';
        this.context.stroke();

        let alignment = "center";
        let pad = 1 / 2;

        var dx = p2.x - p1.x;
        var dy = p2.y - p1.y;
        var p;

        p = p1;


        //draw angle identification

        if (this.drawingObject.points.length > 2) {
            this.context.save();
            this.context.textAlign = alignment;
            this.context.translate(p1.x, p1.y);
            this.context.fillStyle = "blue";
            this.context.font = "30px CarettaOffice";
            this.context.fillText("U" + (this.drawingObject.points.length - 2), 0, 0);
            this.context.restore();
        }

        this.context.save();
        this.context.textAlign = alignment;
        console.log(dx * pad)
        this.context.translate(p.x + dx * pad, p.y + dy * pad);
        if (dx < 0) {
            this.context.rotate(Math.atan2(dy, dx) - Math.PI);  //to avoid label upside down
        }
        else {
            this.context.rotate(Math.atan2(dy, dx));
        }

        //draw edge identification
        this.context.font = "20px Arial";
        this.context.fillText("M" + (this.drawingObject.points.length - 1), 0, 0); //+ " / " + p2.value
        this.context.restore();
    }

    undoPoint() {
        this.drawingObject.points.pop();
        this.redrawCanvas(null);
    }

    checkDrawOk() {
        let sum = 0;
        //check if all values completed
        for (let [i, point] of this.drawingObject.points.entries()) {
            if (i > 0 && !point.value) {
                this.notify.errorMessage("Completati toate valorile pentru a genera graficul!");
                return false;
            }
            if (i > 0) {
                sum += point.value;
            }
        }
        this.drawingObject.customWidth = sum;

        if (sum > 125) {
            this.notify.errorMessage("Lățimea desfășurată nu poate fi mai mare de 125cm (1.25m) !");
            return false;
        }

        if (!this.drawingObject.customLength) {
            this.notify.errorMessage("Completati Lungimea element!");
            return false;
        }
        else if (this.drawingObject.customLength > 600) {
            this.notify.errorMessage("Lungimea maxima este de 600cm (6m) !");
            return false;
        }
        if (!this.drawingObject.customQuantity) {
            this.notify.errorMessage("Completati cantitatea!");
            return false;
        }

        return true;
    }

    activateRAL() {
        this.activeRAL = true;
    }

    deleteRAL() {
        this.drawingObject.arrow = { p1: null, p2: null };
        this.redrawCanvas(null)
    }

    saveCumulateQuantity() {
        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                if (article.articleName.includes('TABLA LISA COLORATA PRELUCRATA')) {
                    let surface = this.getTotalSurface();
                    article.data[0].quantity = this.round2Decimals(surface);
                }
            }
        }

        this.recalculate(true);
    }

    drawRAL(p1, p2) {

        this.context.beginPath();
        this.canvas_arrow(this.context, p1.x, p1.y, p2.x, p2.y);
        this.context.strokeStyle = 'green';
        this.context.stroke();
        let alignment = "center";
        let pad = 1 / 2;
        var dx = p2.x - p1.x;
        var dy = p2.y - p1.y;
        var p;
        p = p1;
        this.context.save();
        this.context.textAlign = alignment;
        console.log(dx * pad)
        this.context.translate(p.x + dx * pad, p.y + dy * pad);
        if (dx < 0) {
            this.context.rotate(Math.atan2(dy, dx) - Math.PI);  //to avoid label upside down
        }
        else {
            this.context.rotate(Math.atan2(dy, dx));

        }
        this.context.fillStyle = "green";
        this.context.font = "20px CarettaOffice";
        this.context.fillText("RAL", 0, 0); //+ " / " + p2.value
        this.context.restore();
    }

    canvas_arrow(context, fromx, fromy, tox, toy) {
        var headlen = 15; // length of head in pixels
        var dx = tox - fromx;
        var dy = toy - fromy;
        var angle = Math.atan2(dy, dx);
        context.moveTo(fromx, fromy);
        context.lineTo(tox, toy);
        context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
        context.moveTo(tox, toy);
        context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
    }

    handleChange(e) {
        if (e.index == this.drawArticle.data[0]["drawings"].length) {
            this.clearCanvas();
        } else {
            this.redrawCanvas(this.drawArticle.data[0]["drawings"][e.index]);
        }

    }

    checkFiles() {
        let canDraw = true;
        if (this.offerData.files) {
            for (let file of this.offerData.files) {
                if (file.user) {
                    canDraw = false;
                }
            }
        }
        return canDraw;
    }

    redrawCanvas(draw) {
        if (draw) {
            this.drawingObject = draw;
        }
        if (!this.checkDrawOk()) {
            return;
        }

        this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);

        //draw background
        var data = '<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"> \
            <defs> \
                <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse"> \
                    <path d="M 8 0 L 0 0 0 8" fill="none" stroke="gray" stroke-width="0.5" /> \
                </pattern> \
                <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse"> \
                    <rect width="80" height="80" fill="url(#smallGrid)" /> \
                    <path d="M 80 0 L 0 0 0 80" fill="none" stroke="gray" stroke-width="1" /> \
                </pattern> \
            </defs> \
            <rect width="100%" height="100%" fill="url(#grid)" /> \
        </svg>';

        var DOMURL = window.URL;

        var img = new Image();
        var svg = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
        var url = DOMURL.createObjectURL(svg);
        let that = this;
        img.onload = function () {
            that.context.drawImage(img, 0, 0);
            DOMURL.revokeObjectURL(url);
        }
        img.src = url;
        this.customTotal = 0;
        ///////////////
        for (let [i, point] of this.drawingObject.points.entries()) {
            if (i == 0) {
                this.context.beginPath();
                this.context.moveTo(point.x, point.y);
                this.context.stroke();
            } else {
                this.customTotal += point.value * this.drawingObject.customLength;

                let p1;
                p1 = this.drawingObject.points[i - 1];

                let p2 = point;

                this.context.lineTo(p2.x, p2.y);
                this.context.strokeStyle = 'black';
                this.context.stroke();

                let alignment = "center";
                let padding = 90;

                if (!padding) padding = 0;

                var dx = p2.x - p1.x;
                var dy = p2.y - p1.y;
                var p, pad;

                p = p1;
                pad = 1 / 2;

                //show angle info
                if (point.angle) {
                    this.context.save();
                    this.context.textAlign = alignment;
                    this.context.translate(p2.x, p2.y);
                    this.context.fillStyle = "blue";
                    this.context.font = "30px CarettaOffice";
                    this.context.fillText(point.angle + " °", 0, 0);
                    this.context.restore();
                }


                //draw line size
                this.context.save();
                this.context.textAlign = alignment;
                this.context.translate(p.x + dx * pad, p.y + dy * pad);
                if (dx < 0) {
                    this.context.rotate(Math.atan2(dy, dx) - Math.PI);  //to avoid label upside down
                }
                else {
                    this.context.rotate(Math.atan2(dy, dx));

                }
                this.context.fillStyle = "red";
                this.context.font = "25px CarettaOffice";
                this.context.fillText(point.value + " cm", 0, 0);
                this.context.restore();
            }
        }

        if (this.drawingObject?.arrow?.p1 && this.drawingObject?.arrow?.p2) {
            this.drawRAL(this.drawingObject.arrow.p1, this.drawingObject.arrow.p2)
        }
        this.customTotal = (this.customTotal * this.drawingObject.customQuantity) / 10000;
        if (!this.drawingObject.arrow.p1) {
            this.notify.infoMessage("Precizati partea vopsita utilizand butonul RAL!")
        }
        this.showRAL = true;


        this.context.font = "30px CarettaOffice";
        this.context.fillText(this.drawingObject.customQuantity + " Buc x " + this.round2Decimals(this.drawingObject.customLength / 100) + "m x " + this.round2Decimals(this.drawingObject.customWidth / 100) + "m = " + this.round2Decimals(this.customTotal) + "m2", 10, 590);


        let rrr = this.myCanvas.nativeElement.toDataURL();

        var myImageData = this.context.getImageData(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height).data;
        console.log(rrr)

    }

    clearCanvas() {
        this.drawingObject = {
            points: [],
            customWidth: null,
            customLength: null,
            customQuantity: null,
            observation: "",
            arrow: {
                p1: null,
                p2: null
            }
        };
        this.showRAL = false;
        this.activeRAL = false;
        this.customTotal = 0;


        this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);

        //draw background
        var data = '<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"> \
            <defs> \
                <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse"> \
                    <path d="M 8 0 L 0 0 0 8" fill="none" stroke="gray" stroke-width="0.5" /> \
                </pattern> \
                <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse"> \
                    <rect width="80" height="80" fill="url(#smallGrid)" /> \
                    <path d="M 80 0 L 0 0 0 80" fill="none" stroke="gray" stroke-width="1" /> \
                </pattern> \
            </defs> \
            <rect width="100%" height="100%" fill="url(#grid)" /> \
        </svg>';

        var DOMURL = window.URL;

        var img = new Image();
        var svg = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
        var url = DOMURL.createObjectURL(svg);
        let that = this;
        img.onload = function () {
            that.context.drawImage(img, 0, 0);
            DOMURL.revokeObjectURL(url);
        }
        img.src = url;
    }

    saveCanvas() {
        if (!this.checkDrawOk()) {
            return;
        }

        if (this.drawingObject.points.length < 2) {
            this.notify.errorMessage("Schita invalida!");
            return
        }
        this.drawArticle.data[0]["drawings"] = [...this.drawArticle.data[0]["drawings"], JSON.parse(JSON.stringify(this.drawingObject))];

        this.notify.successMessage("Schita adaugata!")
    }

    removeDraw(draw) {
        let aa = this.drawArticle.data[0]["drawings"].indexOf(draw);
        this.drawArticle.data[0]["drawings"].splice(aa, 1);
        this.activeIndex1 = 0;
        this.handleChange({ index: 0 })

        // handleChange(e) {
        //     if (e.index == this.drawArticle.data[0]["drawings"].length) {
        //         this.clearCanvas();
        //     } else {
        //         this.redrawCanvas(this.drawArticle.data[0]["drawings"][e.index]);
        //     }

        // }

    }

    showDialogMaximized(event) {
        this.dialDetail.maximized = true;
        this.dialDetail.toggleMaximize(event);
    }

    showFenceMaximized(event) {
        //this.fenceDetail.maximized = true;
        //this.fenceDetail.toggleMaximize(event);
    }

    getTotalSurface() {
        let sum = 0;
        if (this.drawArticle?.data[0]?.drawings) {
            for (let draw of this.drawArticle.data[0]["drawings"]) {
                let width = 0;
                for (let [index, point] of draw.points.entries()) {
                    if (index > 0) {
                        width += point.value;
                    }

                }
                sum += (width * draw.customQuantity * draw.customLength) / 10000;
            }
        }
        return sum;
    }

    decodeToken(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    async checkAccess(actionKey) {
        let res: any = await this.offerService.checkAccess(actionKey).toPromise()
        return res.hasAccess;
    }

    onChanges(): void {
        this.myControl.valueChanges.subscribe((val) => {
            if (this.localities && this.localities.rows)
                this.filteredOptions = this.localities.rows.filter((option) =>
                    option.localityName.toLowerCase().includes(val)
                );
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        $event.returnValue = true;
        //}
    }

    localityChange() {
        console.log('filter');
    }

    checkPluvialType() {
        if (this.pluvialType == 'SL') {
            this.notify.warningMessage('Selectati tip sistem pluvial!');
        } else {
            this.showPluvialTypeModal = false;
        }
    }

    //todo
    async loadLocalities(event) {
        console.log(event);
        this.newClientData.county = event.value;
        this.editClientData.county = event.value;
        this.newClientData.locality = '';
        this.spinner.show();
        this.localities = await this.offerService.getLocalities(
            event.value.countyId
        );
        this.spinner.hide();
        this.filteredOptions = this.localities.rows;
    }

    onSelectedLocality(locality) {
        console.log(locality);
    }

    async syncOffer() {
        //sync offer offline
        let r2: any = await this.offerService.syncOfferData().toPromise();

        const db = await openDB('Articles', 1, {
            upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('articles', {
                    // The 'id' property of the object will be the key.
                    keyPath: 'id',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    autoIncrement: true,
                });
                // Create an index on the 'date' property of the objects.
                store.createIndex('MATERIAL', 'MATERIAL');
                store.createIndex('MATERIALCULOARE', ['MATERIAL', 'CULOARE']);
                store.createIndex('MATERIALCULOAREGROSIME', ['MATERIAL', 'CULOARE', 'GROSIME']);
            },
        });

        for (let art of r2.rows) {
            try {
                await db.add('articles', art);
            }
            catch (err) {
                console.log(err, art);
            }
        }

        const tx = db.transaction('articles', 'readwrite');
        const index = tx.store.index('MATERIAL');
        console.time("find");
        for await (const cursor of index.iterate('100-1000')) {

            //console.log(cursor.value)
            //cursor.update(article);
        }
        console.timeEnd("find");
        await tx.done;
    }

    async getOfferData() {
        this.spinner.show();
        let offerData;
        if (this.offerData) {
            offerData = this.offerData.offerData;
        }




        let res: any = await this.offerService.getOffersCaretta(this.offerId, this.selectedOfferType ? this.selectedOfferType.key : null, this.selectedColor, this.selectedMaterial, this.selectedThickness, this.pluvialType, this.promokey).toPromise();

        this.offerData = res;
        this.offerData.categories = this.offerData && this.offerData.categories ? this.offerData.categories : [];
        //this.pluvialType = this.offerData.offerData.pluvialType;
        //preload agents
        if (this.offerData.dealer) {
            this.dealerAgents = await this.offerService.loadDealerAgents(this.offerData.dealer.partnerId);
            this.offerData.invoiceType = 'Dealer';
        } else {
            this.offerData.invoiceType = 'Client';
        }
        if (!this.selectedOfferType) {
            this.selectedOfferType = res.offerData.selectedOfferType;
            this.selectedColor = res.offerData.selectedColor;
            this.selectedMaterial = res.offerData.selectedMaterial;
            this.selectedThickness = res.offerData.selectedThickness;
            this.currentOffer.deliveryDate = res.deliveryDate;
            this.currentOffer.invoiceType = res.invoiceType;
            this.currentOffer.finishType = res.finishType;
            this.currentOffer.paymentType = res.paymentType;
            this.currentOffer.observations = res.observations;
            this.currentOffer.phone = res.phone;
            if (this.offerData.invoiceType == "Dealer") {
                //prospectare choose type, br ....
                // false = not show in PROSPECTARE
                this.checkDealerStatus(this.offerData.dealer.partnerId, false)
            }
        } else {
            if (this.offerData.invoiceType == "Dealer") {
                //you are already in offer here with just selectedOffer
                if (!this.offerData?.client?.name) {
                    //this.bringPrefillClientDealer(this.offerData.dealer.fiscalCode);
                    let event = {
                        value: {
                            fiscalCode: this.offerData.dealer.fiscalCode,
                            partnerId: this.offerData.dealer.partnerId
                        }
                    }
                    this.loadDealerAgents(event)
                }
            }
            this.prefillClientData(this.offerData);

            this.offerData.contact = this.offerData.contact ? JSON.parse(this.offerData.contact) : this.offerData.contact;
            this.offerData.offerData.selectedOfferType = this.selectedOfferType;
            this.offerData.offerData.selectedColor = this.selectedColor;
            this.offerData.offerData.selectedMaterial = this.selectedMaterial;
            this.offerData.offerData.selectedThickness = this.selectedThickness;
            this.offerData.offerData.pluvialType = this.pluvialType;
        }
        //check if saved state
        if (this.temporaryArticles) {
            for (let temporaryArticle of this.temporaryArticles) {
                for (let category of this.offerData.categories) {
                    for (let article of category.articles) {
                        for (let detail of article.data) {
                            if (
                                detail.DENUMIRE.includes(temporaryArticle.name) &&
                                detail.D1 == temporaryArticle.D1 &&
                                detail.D2 == temporaryArticle.D2 &&
                                detail.D3 == temporaryArticle.D3
                            ) {
                                detail.quantity = temporaryArticle.quantity;
                            }
                        }
                    }
                }
            }
        }
        if (this.offerData.deliverAddress) {
            this.showButtonAlternativeAddress = false
        }


        this.offerData.categories = [...this.offerData.categories, ...this.temporaryCategories];
        this.temporaryCategories = [];
        this.temporaryArticles = [];
        this.recalculate(true);

        if (offerData) {
            this.offerData.offerData = offerData;
        }

        this.statusTaskOffer = this.offerData.statusTaskOffer;

        if (this.offerData?.fences && this.offerData.fences.length > 0) {
            for (let fence of this.offerData.fences) {

                fence.distantaStalpi = this.round2Decimals(fence.distantaStalpi);
                fence.inaltimeStalpi = this.round2Decimals(fence.inaltimeStalpi);
                fence.numarPanouri = this.round2Decimals(fence.numarPanouri);
            }


        }

        if (this.offerData.fenceKey) {
            this.selectedFenceType = this.fences.find(item => {
                return item.name == this.offerData.fenceKey;
            });
            this.selectedFenceCategory = true;

            //activate first fence

            this.activeFenceIndex = 0;

            this.activateFenceTab(null);
        }

        this.spinner.hide();


    }

    async onPhoneInputChange() {
        if (this.clientPhoneSearch.length >= 3) {
            this.clientFiscalCodeSearch = '';
            this.clientNameSearch = '';
            const data: any = await this.offerService.getClientData(this.clientPhoneSearch, 'phone');
            this.clients = data.rows;
        }
    }

    async onNameInputChange() {
        if (this.clientNameSearch.length >= 3) {
            this.clientFiscalCodeSearch = '';
            this.clientPhoneSearch = '';
            const data: any = await this.offerService.getClientData(this.clientNameSearch, 'name');
            this.clients = data.rows;
        }
    }

    async onFiscalCodeChange() {
        if (this.clientFiscalCodeSearch.length >= 3) {
            this.clientPhoneSearch = '';
            this.clientNameSearch = '';
            const data: any = await this.offerService.getClientData(this.clientFiscalCodeSearch, 'cui');
            this.clients = data.rows;
            return;
        }
        return;
    }

    async onClientSelected(event) {
        this.selectedClient = event.option.value;
        this.offerData.client = {
            fiscalCode: event.option.value.CODFISCAL,
            name: event.option.value.DEN_PART,
            phone: event.option.value.TELEFON,
            internalId: event.option.value.CODPART,
        };


        let rezz: any = await this.offerService.getClientData(
            event.option.value.CODFISCAL,
            'cui'
        );

        this.offerData.client['address'] = {
            address: rezz.rows[0].ADRESA,
            city: rezz.rows[0].DEN_LOCALIT,
            conunty: rezz.rows[0].JUDET,
            headquarter: rezz.rows[0].headquarter,
        };
    }

    displayPhone(client) {
        return client ? client.phone : '';
    }

    displayLocalityName(locality) {
        return locality ? locality.localityName : '';
    }

    displayClientName(client) {
        return client ? client.name : '';
    }

    displayFiscalCode(client) {
        return client ? client.fiscalCode : '';
    }

    round2Decimals(value) {
        return Math.round(value * 100) / 100;
    }

    hasMultipleArts(item) {
        for (let article of item.articles) {
            if (article.data[0].D1) {
                for (let art of article.data) {
                    if (art.INACTIV == 1 && art.quantity || art.INACTIV == 0)
                        return true;
                }

            }
        }
        return false;
    }
    async cancelCommand() {
        if (this.cancelObservations.length === 0) {
            this.notify.warning('Va rugam sa completati motivul anularii');
            return;
        }
        this.spinner.show();
        if (typeof this.offerData.commandData !== 'object') {
            this.offerData.commandData = JSON.parse(this.offerData.commandData);
        }
        try {
            await this.offerService.cancelCommand(
                this.offerData.commandData.commandId,
                'Anulata ' + this.cancelObservations
            );
            this.cancelCommandFlag = false;

            this.changeStatusTask("CREATED")
            this.spinner.hide();
            window.location.reload();
        } catch (e) {
            console.log(e);
            this.cancelCommandFlag = false;
            this.spinner.hide();
        }
    }
    reset() {
        let dialogRef = this.dialog.open(DecisionModalComponent, {
            data: {
                title: 'Salvare cantitati oferta',
                message: 'Doriti salvarea cantitatilor de pe oferta actuala?',
            },
        });
        dialogRef.afterClosed().subscribe(async (reset) => {
            if (reset) {
                for (let category of this.offerData.categories) {
                    for (let article of category.articles) {
                        for (let detail of article.data) {
                            if (detail.quantity) {
                                let tempArticle = {
                                    DENUMIRE: detail.DENUMIRE,
                                    D1: detail.D1,
                                    D2: detail.D2,
                                    D3: detail.D3,
                                    quantity: detail.quantity,
                                    selectedColor: this.selectedColor,
                                    selectedThickness: this.selectedThickness,
                                };
                                this.temporaryArticles.push(tempArticle);
                            }
                        }
                    }
                }
                this.store.set('tempPrices', this.temporaryArticles);
            }
        });
    }

    loadPrices() {
        this.temporaryArticles = this.store.get('tempPrices');
        if (this.temporaryArticles) {
            for (let temporaryArticle of this.temporaryArticles) {
                for (let category of this.offerData.categories) {
                    for (let article of category.articles) {
                        for (let detail of article.data) {
                            let articleParsedName = this.getParentName(
                                detail,
                                this.selectedColor,
                                this.selectedThickness
                            );
                            let savedArticleParsedName = this.getParentName(
                                temporaryArticle,
                                temporaryArticle.selectedColor,
                                temporaryArticle.selectedThickness
                            );
                            if (
                                articleParsedName == savedArticleParsedName &&
                                detail.D1 == temporaryArticle.D1 &&
                                detail.D2 == temporaryArticle.D2 &&
                                detail.D3 == temporaryArticle.D3
                            ) {
                                detail.quantity = temporaryArticle.quantity;
                            }
                        }
                    }
                }
            }
        }
        this.temporaryArticles = [];
        this.recalculate(true);
        this.notify.successMessage('Cantitati precompletate!');
    }

    getParentName(article, selectedColor, selectedThickness) {
        //get selected data   {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
        let tempDen = article.DENUMIRE;
        tempDen = tempDen.split('PLUS').join('');
        tempDen = tempDen.split('SMART').join('');
        tempDen = tempDen.split('103').join('');
        tempDen = tempDen.split('104').join('');
        tempDen = tempDen.split('105').join('');
        tempDen = tempDen.split('106').join('');
        tempDen = tempDen.split('301').join('');
        tempDen = tempDen.split('304').join('');
        tempDen = tempDen.split(selectedColor).join('');
        tempDen = tempDen.split(selectedThickness).join('');
        tempDen = tempDen.split('BR').join('');
        tempDen = tempDen.split('MAT').join('');
        tempDen = tempDen.split('SL').join('');
        tempDen = tempDen.split('BRLIANT').join('');
        tempDen = tempDen.split('FRESCO').join('');
        tempDen = tempDen.split('LUSTRO').join('');
        return tempDen.trim();
    }

    async resetSelect() {
        await this.getIndustrySucategories();
        this.selectedOfferType = null;
        this.selectedColor = null;
        this.selectedMaterial = null;
        this.selectedThickness = null;
        this.colors = [];
        this.materials = [];
        this.thickness = [];
        this.temporaryCategories = this.offerData.categories;
        this.offerData.categories = [];
    }

    // async getCategoriesFromProvider(selectedProviderCode) {
    //     console.log(selectedProviderCode)
    //     this.offerData.offerData.providerName = selectedProviderCode.DEN_CLASEA;
    //     this.offerData.offerData.providerId = selectedProviderCode.CODCLASEA;

    //     this.offersType = result;
    //     this.showProviders = !this.showProviders;
    //   }

    async getIndustrySucategories() {
        console.log("industriale");
        this.offersType = [];
        this.industryCategories = await this.offerService.getIndustryCategories();

    }

    async getMaterial(offerType) {
        //this.materials = [];
        this.spinner.show();




        this.offerData.offerData.selectedOfferType = offerType;
        this.selectedOfferType = offerType;



        this.offerService
            .getMaterials(offerType.key)
            .subscribe(async (materials: any) => {
                this.materials = materials;
                this.spinner.hide();
                if (materials.length == 0) {
                    this.spinner.show();
                    //await this.getOfferData();
                    await this.getColors(null);
                    this.spinner.hide();
                };
            })
    }

    async getColors(material) {
        this.spinner.show();
        this.materials = [];
        this.selectedMaterial = material;
        this.pluvialType = this.selectedMaterial;
        this.offerData.offerData.selectedMaterial = material;
        this.offerData.offerData.pluvialType = material;
        // if (material != 'SL' && (this.offerData.offerData.selectedOfferType.key == 'AA' || this.offerData.offerData.selectedOfferType.key == 'AP' || this.offerData.offerData.selectedOfferType.key == 'MM' || this.offerData.offerData.selectedOfferType.key == 'MP' || this.offerData.offerData.selectedOfferType.key == 'CC' || this.offerData.offerData.selectedOfferType.key == 'CP')) {
        //     if (this.canChooseClientType = await this.checkAccess("CHANGE_PLUVIAL")) {
        //         this.showPluvialTypeModal = true;
        //     } else {
        //         this.pluvialType = 'BR';
        //     }
        // }

        if (material == "LUSTRO") {
            this.offerData.offerData.pluvialType = "LUSTRO DUO";
            this.pluvialType = "LUSTRO DUO";
        } else if (material == "FRESCO") {
            this.offerData.offerData.pluvialType = "FRESCO DUO";
            this.pluvialType = "FRESCO DUO";
        }
        this.offerService
            .getColors(this.selectedOfferType.key, material)
            .subscribe(async (colors: any) => {
                this.spinner.hide();
                this.colors = colors;
                if (colors.length == 0) {
                    this.spinner.show();
                    await this.getOfferData();

                    this.spinner.hide();
                }
            });
    }

    getThickness(color) {
        //this.materials = [];

        if ((color == "9002" || color == "7016" || color == "5010") && this.selectedMaterial == "LUSTRO") {
            this.offerData.offerData.pluvialType = "LUSTRO";
            this.pluvialType = "LUSTRO";
        }
        this.spinner.show();
        this.selectedColor = color;
        this.offerData.offerData.selectedColor = color;
        this.offerService
            .getThickness(
                this.selectedOfferType.key,
                this.selectedMaterial,
                this.selectedColor
            )
            .subscribe(async (thickness: any) => {
                this.thickness = thickness;
                this.spinner.hide();
                if (thickness.length == 0) {
                    this.spinner.show();
                    await this.getOfferData();

                    this.spinner.hide();
                }
            });
    }

    async selectThickness(thickness) {
        this.spinner.show();
        this.selectedThickness = thickness;
        this.offerData.offerData.selectedThickness = thickness;
        await this.getOfferData();

        this.spinner.hide();
    }

    getTotalQuantity(articleDetails) {
        let sum = 0;
        for (let articleDetail of articleDetails.data) {
            if (articleDetail.quantity && articleDetail.D2) {
                sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
            } else if (articleDetail.quantity) {
                sum += articleDetail.D1 * articleDetail.quantity;
            }
        }
        articleDetails.totalQuantity = sum;
        return sum;
    }

    getArticleTotalQuantity(articleDetail) {
        let sum = 0;
        if (articleDetail.quantity && articleDetail.D2) {
            sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
        } else if (articleDetail.quantity) {
            sum += articleDetail.D1 * articleDetail.quantity;
        }
        return sum;
    }

    getTotalPrice() {
        this.totalPrice = 0;
        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                for (let detail of article.data) {
                    if (detail.quantity) {
                        this.totalPrice +=
                            parseFloat(detail.quantity) *
                            parseFloat(detail.agentPrice) *
                            (article.d1 ? parseFloat(article.d1) : 1) *
                            (article.d2 ? parseFloat(article.d2) : 1) *
                            (article.d3 ? parseFloat(article.d3) : 1);
                    }
                }
            }
        }
        return this.round2Decimals(this.totalPrice);
    }

    getTotalListPrice() {
        this.totalPrice = 0;

        return this.round2Decimals(this.totalPrice);
    }
    //copy price to all details of an article
    propagateQuantity(articles) {
        for (let article of articles) {
            article.agentPrice = articles[0].agentPrice;
        }
    }

    async recalculate(event) {
        if (event && event.keyCode == 188) {
            this.notify.infoMessage('Folositi punctul ca separator pentru zecimale!');
            event.preventDefault();
            return;
        }
        this.totalPrice = 0;
        this.totalMinimumPrice = 0;
        this.totalWithOutDiscount = 0;
        this.discountValue = 0;
        this.discountPercent = 0;
        this.totalOfferWeight = 0;
        //price withoutDiscount
        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                article.invalidPrice = false;
                for (let detail of article.data) {

                    detail.D1 = this.round2Decimals(detail.D1);

                    //check if price lower than minimum posible, replace , with .
                    //detail.agentPrice = parseFloat(detail.agentPrice ? detail.agentPrice.toString().replace(",", "") : 0);
                    // if (detail.agentPrice == 0) {
                    //     //article.invalidPrice = true;
                    //     detail.agentPrice = this.round2Decimals(detail.agentMinimumPrice ? detail.agentMinimumPrice : 0)
                    // }

                    if (detail.quantity > 0 && (this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.agentMinimumPrice) || !detail.agentPrice)) {
                        article.invalidPrice = true;
                    }

                    if (detail.quantity) {
                        detail.quantity = detail.quantity.toString().replace('-', '');
                        detail.quantity = detail.quantity.toString().replace(',', '');
                        this.totalWithOutDiscount +=
                            parseFloat(detail.quantity) *
                            parseFloat(detail.LISTA) *
                            (detail.D1 ? parseFloat(detail.D1) : 1) *
                            (detail.D2 ? parseFloat(detail.D2) : 1) *
                            (detail.D3 ? parseFloat(detail.D3) : 1);
                        this.totalPrice +=
                            parseFloat(detail.quantity) *
                            parseFloat(article.data[0].agentPrice) *
                            (detail.D1 ? parseFloat(detail.D1) : 1) *
                            (detail.D2 ? parseFloat(detail.D2) : 1) *
                            (detail.D3 ? parseFloat(detail.D3) : 1);
                        this.totalMinimumPrice +=
                            parseFloat(detail.quantity) *
                            parseFloat(article.data[0].agentMinimumPrice) *
                            (detail.D1 ? parseFloat(detail.D1) : 1) *
                            (detail.D2 ? parseFloat(detail.D2) : 1) *
                            (detail.D3 ? parseFloat(detail.D3) : 1);
                        if (article.data[0].MASA) {
                            this.totalOfferWeight += parseFloat(detail.quantity) * parseFloat(article.data[0].MASA) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
                        }
                    }
                }
            }
        }
        this.totalWithOutDiscount = this.round2Decimals(this.totalWithOutDiscount);
        this.totalPrice = this.round2Decimals(this.totalPrice);
        this.offerData.totalPrice = this.totalPrice;

        this.discountValue = this.round2Decimals(
            this.totalWithOutDiscount - this.totalPrice
        );
        this.discountPercent = this.round2Decimals(((this.totalWithOutDiscount - this.totalPrice) / this.totalWithOutDiscount) * 100);


        this.checkCommandProcentual();

        console.log("TOAL PRICE", this.totalPrice)

        console.log("promo", this.discountPromo)
        console.log("min C", this.totalMinimumPrice)

        this.adaosValue = this.round2Decimals((this.round2Decimals(this.totalPrice - this.discountPromo) ? this.round2Decimals(this.totalPrice - this.discountPromo) / 1.19 : 0) - (this.totalMinimumPrice ? this.totalMinimumPrice / 1.19 : 0));

        let datt = [];

        this.adaosValuer = 0;
        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                for (let detail of article.data) {

                    if (detail.quantity) {
                        let articleCSum = parseFloat(detail.quantity) *
                            this.round2Decimals(article.data[0].agentMinimumPrice / 1.19) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
                        let articleAgentSum = parseFloat(detail.quantity) *
                            this.round2Decimals(detail.discount ? (this.round2Decimals(article.data[0].agentPrice / 1.19) - (this.round2Decimals(article.data[0].agentPrice / 1.19) * 0.15)) : this.round2Decimals(article.data[0].agentPrice / 1.19)) *
                            (detail.D1 ? parseFloat(detail.D1) : 1) *
                            (detail.D2 ? parseFloat(detail.D2) : 1) *
                            (detail.D3 ? parseFloat(detail.D3) : 1);

                        if (articleAgentSum > articleCSum) {
                            this.adaosValuer += articleAgentSum - articleCSum;
                        }
                        datt.push({ DENUMIRE: detail.DENUMIRE, quantity: detail.quantity, C: article.data[0].agentMinimumPrice, agentPrice: article.data[0].agentPrice, valoaretotala: detail.quantity * article.data[0].agentPrice, valoaretotalaPromofaratva: articleAgentSum, valoaretotalaCfaratva: articleCSum, ADAOS: articleAgentSum - articleCSum })
                    }

                }
            }
        }

        console.table(datt)

        this.adaosValue = this.round2Decimals(this.adaosValuer);
        console.log("Adaos: ", this.adaosValuer)


        this.offerData.adaosPrice = this.adaosValue;
        this.adaosPercent = this.round2Decimals(
            ((this.totalPrice - this.discountPromo - this.totalMinimumPrice) / (this.totalPrice - this.discountPromo)) * 100
        );



        if (this.offerData.custody && this.offerData.isAdvancePayment) {
            this.calculateAdvancedPayment(this.offerData)
        }
    }



    getSubtotal(price, quantity) {
        return this.round2Decimals(quantity ? parseFloat(price) * quantity : 0);
    }

    async saveOffer(status, message) {
        let restricted = this.checkCommandProcentual()
        if (restricted) {
            this.notify.error("Oferta nu a fost salvata!");
            this.notify.error('Procentul de accesorii nu depaseste 35% !')
            this.notify.error('Repartizati cantitatea pe articolul nepromotional!');
            return;
        }
        if (this.statusTaskOffer == 'FINANCIAL_VALIDATION') {
            return this.notify.warningMessage("Oferta inca nu a fost validata")
        }

        if (!this.offerData.invoiceType) {
            this.notify.warning(`Selecteaza tip comanda!`);
            return;
        }
        if (
            this.offerData.invoiceType == 'Dealer' &&
            Object.keys(this.offerData.dealer).length === 0 &&
            this.offerData.dealer.constructor === Object
        ) {
            this.notify.warning(`Selecteaza dealerul!`);
            return;
        }
        if (
            this.offerData.invoiceType == 'Dealer' &&
            Object.keys(this.offerData.dealerAgent).length === 0 &&
            this.offerData.dealerAgent.constructor === Object
        ) {
            this.notify.warning(`Selecteaza agentul dealerului!`);
            return;
        }

        if (
            this.offerData.invoiceType == 'Client' &&
            this.offerData.isAdvancePayment &&
            this.offerData.advancePaymentValue < 0
        ) {
            this.notify.warning(`Precizati suma avans!`);
            return;
        }


        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                if (article.invalidPrice) {
                    this.notify.warning(
                        `Oferta contine preturi sub limita stabilita articol ${article.articleName}!`
                    );
                    return;
                }

                let dim = [];
                for (let detail of article.data) {
                    if (detail.quantity && detail.quantity > 0 && detail.errorD1Real) {
                        this.notify.warning('Dimensiunea reala a articolului incorecta');
                        this.spinner.hide();
                        return;
                    }

                    if (detail.quantity && detail.quantity > 0 && detail.errorD1) {
                        this.notify.error('Dimensiune articol incorecta!!!');
                        this.spinner.hide();
                        return;
                    }
                    if (detail.quantity && detail.quantity > 0 && detail.INACTIV == 1) {
                        this.notify.warning('Oferta contine articole inactive!');
                        console.log("Inactiv", detail);
                        this.spinner.hide();
                        return;
                    }

                    if (detail.quantity && detail.quantity > 0 && (detail.DENUMIRE.includes("STALP 192") || detail.DENUMIRE.includes("ORIZONTAL 206")) &&
                        (this.round2Decimals(detail.D1) == 0.53 || this.round2Decimals(detail.D1) == 0.7 || this.round2Decimals(detail.D1) == 0.87 || this.round2Decimals(detail.D1) == 1.04 || this.round2Decimals(detail.D1) == 1.21 || this.round2Decimals(detail.D1) == 1.38 || this.round2Decimals(detail.D1) == 1.55 || this.round2Decimals(detail.D1) == 1.72 || this.round2Decimals(detail.D1) == 1.89 || this.round2Decimals(detail.D1) == 2.06 || this.round2Decimals(detail.D1) == 2.23 || this.round2Decimals(detail.D1) == 2.4)) {
                        this.notify.warning('Dimensiune articol inactivata!' + this.round2Decimals(detail.D1));
                        this.spinner.hide();
                        return;
                    }

                    let fnd = dim.find(d => {
                        return d == detail.D1
                    })

                    if (detail.quantity && detail.quantity > 0 && fnd) {
                        this.notify.warning('Cumulati cantitatea pe o singura dimensiune!');
                        console.log("Inactiv", detail);
                        this.spinner.hide();
                        return;
                    } else {
                        dim.push(detail.D1)
                    }
                }
            }
        }
        this.offerData.pickedByClient = this.offerData.pickedByClient ? this.offerData.pickedByClient : false;

        this.spinner.show();

        //save filter data in other object
        let fulldata = JSON.parse(JSON.stringify(this.offerData.categories));
        this.offerData.categories = this.offerData.categories.map((categ) => {
            categ.articles = categ.articles.filter((article) => {
                article.data = article.data.filter((art) => {
                    return parseFloat(art.quantity) > 0;
                });
                return article.data.length > 0;
            });
            return categ;
        });

        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                for (let detail of article.data) {
                    if (
                        this.offerData.offerData.selectedOfferType.key != 'GA' &&
                        this.offerData.offerData.selectedOfferType.key != 'GAZ' &&
                        (detail.DENUMIRE.includes(
                            'PROFIL METALIC DE FIXARE PE STALP 192'
                        ) ||
                            detail.DENUMIRE.includes('RIGLA METALICA DE RIGIDIZARE 193') ||
                            detail.DENUMIRE.includes('POP NITURI COLORATE 100 BUC 194') ||
                            detail.DENUMIRE.includes('LAMELA GARD ORIZONTAL 191') ||
                            detail.DENUMIRE.includes('SIPCA GARD 130'))
                    ) {
                        this.notify.warning(
                            `Oferta contine articole din categoria gard! Creati oferta selectand categoria gard.`
                        );
                        this.offerData.categories = fulldata;
                        this.spinner.hide();
                        return;
                    }

                }
            }
        }
        let tempStatus = this.offerData.status;
        try {

            this.offerData.status = status;
            this.offerData.actions = [
                {
                    action: message ? message : 'Oferta a fost schimbata!',
                    date: new Date(),
                },
            ];

            let response: any = await this.offerService.saveOfferV2(this.offerData);

            //set offer Number 
            if (response?.data) {
                this.offerData.data = response.data;
            }

            if (tempStatus != "COMANDA") {
                this.offerData.status = "OFERTA";
            }

            this.offerData.categories = fulldata;
            this.spinner.hide();
        } catch (e) {
            this.offerData.status = tempStatus;
            this.spinner.hide();
        }

    }
    async changeStatusTask(status) {
        await this.offerService.changeStatusTask(this.offerData.uuid, status)
        this.statusTaskOffer = 'FINANCIAL_VALIDATION';
    }
    async finishCommand() {
        if (!await this.checkAccess("CATEGORY_ATTICA") && (this.offerData.offerData.selectedOfferType.key == 'AA' || this.offerData.offerData.selectedOfferType.key == 'AP' || this.offerData.offerData.selectedOfferType.key == 'MM' || this.offerData.offerData.selectedOfferType.key == 'MP' || this.offerData.offerData.selectedOfferType.key == 'CC' || this.offerData.offerData.selectedOfferType.key == 'CP' || this.offerData.offerData.selectedOfferType.key == 'GA')) {
            this.notify.errorMessage("Comenzile nu pot fi plasata din oferte cu articole inactive!")
            return;
        }
        if ((this.offerData.offerData.selectedOfferType.key == 'AA' || this.offerData.offerData.selectedOfferType.key == 'AP' || this.offerData.offerData.selectedOfferType.key == 'MM' || this.offerData.offerData.selectedOfferType.key == 'MP' || this.offerData.offerData.selectedOfferType.key == 'CC' || this.offerData.offerData.selectedOfferType.key == 'CP') && this.offerData.offerData.selectedMaterial != this.offerData.offerData.pluvialType) {
            //check if pluvial article have quantity
            let pluvialCategory = this.offerData.categories.find(cat => {
                return cat.category == "SISTEM PLUVIAL";
            })
            if (pluvialCategory) {
                for (let art of pluvialCategory.articles) {
                    for (let data of art.data) {
                        if (data.quantity) {
                            this.notify.warning(`Materialul sistemului pluvial difera de cel al ofertei!`);
                            return;
                        }
                    }
                }
            }
        }
        //check required fields before anything
        if (!this.offerData.client.fiscalCode) {
            this.notify.warning(`Oferta nu contine detalii client!`);
            return;
        }

        if (!this.offerData.deliverDate) {
            this.notify.warningMessage('Selectati data de livrare!');
            return;
        }
        if (!this.offerData.finishType || this.offerData.finishType === "undefined") {
            this.notify.warningMessage('Selectati tip document!');
            return;
        }

        if (!this.offerData.paymentType || this.offerData.paymentType === "undefined") {
            this.notify.warningMessage('Selectati modalitatea de plata!');
            return;
        }

        if (
            this.offerData.invoiceType == 'Client' &&
            this.offerData.isAdvancePayment &&
            this.offerData.advancePaymentValue < 0
        ) {
            this.notify.warning(`Precizati suma avans!`);
            return;
        }

        //check promo 40-60
        let restricted = this.checkCommandProcentual()
        if (restricted) {
            this.notify.error("Oferta nu a fost salvata!");
            this.notify.error('Procentul de accesorii nu depaseste 35% !')
            this.notify.error('Repartizati cantitatea pe articolul nepromotional!');
            return;
        }
        //send more quiqly to have commision value
        this.offerData.adaosValue = this.adaosValue;

        //finacial validation
        if (this.statusTaskOffer == 'FINANCIAL_VALIDATION') {
            return this.notify.warningMessage("Oferta inca nu a fost validata")
        }
        if (this.statusTaskOffer != 'APPROVED') {


            //validare
            //|| (this.offerData.paymentType == "cash" && !this.offerData.client.company && this.totalPrice > 10000)
            //|| (this.offerData.paymentType == "cash" && this.offerData.client.company && this.totalPrice > 5000)
            if (this.isDealerTrusted == "error" || this.offerData.paymentType == "TBI") {
                let dialogRef = this.dialog.open(DecisionModalComponent, {
                    data: {
                        title: 'Validare Financiara',
                        message: 'Doriti trimiterea ofertei catre validare financiara?',
                    },
                });

                let boolResponse = await dialogRef.afterClosed().toPromise();
                if (!boolResponse) {
                    return;
                }
                else {
                    this.saveOffer('FINANCIAL_VALIDATION', null);
                    this.statusTaskOffer = "FINANCIAL_VALIDATION";
                    this.notify.successMessage("Oferta trimisa catre validare financiara!");
                    return;
                }
            }
        }
        //rate validare financiara




        //continue finishCommand
        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                if (article.invalidPrice) {
                    this.notify.error(`Oferta contine preturi sub limita stabilita articol ${article.articleName}!`);
                    return;
                }
                let dim = [];
                for (let detail of article.data) {



                    if (detail.quantity && detail.quantity > 0 && detail.INACTIV == 1) {
                        this.notify.warning('Oferta contine articole inactive!');
                        console.log("Inactiv", detail);
                        this.spinner.hide();
                        return;
                    }
                    // if (detail.quantity && detail.quantity > 0 && detail.PROMOTIE == 1) {
                    //     this.notify.warning('Oferta contine articole promo vechi!');
                    //     this.spinner.hide();
                    //     return;
                    // }

                    let fnd = dim.find(d => {
                        return d == detail.D1
                    })

                    if (detail.quantity && detail.quantity > 0 && fnd) {
                        this.notify.warning('Cumulati cantitatea pe o singura dimensiune!');
                        console.log("Inactiv", detail);
                        this.spinner.hide();
                        return;
                    } else {
                        dim.push(detail.D1)
                    }
                }
                if (article.data.length > 1) {
                    let index = 0;
                    for (let detail of article.data) {
                        let roundD1SubPrevious;
                        if (index > 0) {
                            roundD1SubPrevious = this.round2Decimals(article.data[index - 1].D1);
                        } else {
                            roundD1SubPrevious = 0;
                        }


                        let roundD1Sub = this.round2Decimals(detail.D1)
                        if (!detail.d1Real || detail.d1Real < roundD1Sub && detail.d1Real > roundD1SubPrevious) {
                            detail.errorD1Real = false;
                        } else {
                            this.notify.warning(`Dimensiunea reala a articolului incorecta`)
                            detail.errorD1Real = true;
                            return;
                        }
                        index++;
                    }

                }
            }
        }

        // let surface = 0;
        // for (let category of this.offerData.categories) {
        //     for (let article of category.articles) {
        //         if (article.articleName.startsWith('TIGLA METALICA') || article.articleName.startsWith('TABLA CLICK')) {
        //             surface = article.totalQuantity;
        //         }
        //         if (article.invalidPrice) {
        //             this.notify.warning(`Oferta contine preturi sub limita stabilita articol ${article.articleName}!`);
        //             return;
        //         }
        //         if (
        //             article.data[0].PROMOTIE == 1 && article.totalQuantity > Math.round(surface * 0.12)
        //         ) {
        //             this.notify.warning(`Cantitatea de burlan promotional depaseste limita stabilita! ${Math.round(surface * 0.12)} m!`);
        //             return;
        //         }
        //     }
        // }

        let dialogRef
        if (this.offerData.status == 'NU_ACUM') {
            dialogRef = this.dialog.open(DecisionModalComponent, {
                data: {
                    title: 'Salvare comanda',
                    message: 'Oferta are status NU ACUM. Doriti salvarea comenzii in mentor?',
                },
            });
        }
        else {
            dialogRef = this.dialog.open(DecisionModalComponent, {
                data: {
                    title: 'Salvare comanda',
                    message: 'Doriti salvarea comenzii in mentor?',
                },
            });
        }


        let boolResponse = await dialogRef.afterClosed().toPromise();
        if (!boolResponse) {
            return;
        }

        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                for (let detail of article.data) {
                    if (
                        this.offerData.offerData.selectedOfferType.key != 'GA' &&
                        this.offerData.offerData.selectedOfferType.key != 'GAZ' &&
                        detail.quantity > 0 &&
                        (detail.DENUMIRE.includes('PROFIL METALIC DE FIXARE PE STALP 192') ||
                            detail.DENUMIRE.includes('RIGLA METALICA DE RIGIDIZARE 193') ||
                            detail.DENUMIRE.includes('POP NITURI COLORATE 100 BUC 194') ||
                            detail.DENUMIRE.includes('LAMELA GARD ORIZONTAL 191') ||
                            detail.DENUMIRE.includes('SIPCA GARD 130'))
                    ) {
                        this.notify.warning(`Oferta contine articole din categoria gard! Creati oferta selectand categoria gard.`);
                        return;
                    }
                    if (this.offerData.offerData.selectedOfferType.key != 'VLX' && detail.quantity > 0 && detail.DENUMIRE.includes('VELUX')) {
                        this.notify.warning(`Oferta contine articole din categoria velux! Creati oferta selectand categoria velux.`);
                        return;
                    }
                }
            }
        }

        let serverData = JSON.parse(JSON.stringify(this.offerData));

        //filter categories, only articles with quantity
        let filteredCategories = [];
        this.offerData.categories.map((categ) => {
            let filteredCategory = {
                category: categ.category,
                articles: [],
            };
            categ.articles.map((article) => {
                let filteredArticle = {
                    articleName: article.articleName,
                    invalidPrice: article.invalidPrice,
                    totalQuantity: article.totalQuantity,
                    data: [],
                };
                article.data.map((art) => {
                    if (parseFloat(art.quantity) > 0) {
                        filteredArticle.data = [...filteredArticle.data, art];
                    }
                });
                if (filteredArticle.data.length > 0) {
                    filteredCategory.articles = [
                        ...filteredCategory.articles,
                        filteredArticle,
                    ];
                }
            });
            if (filteredCategory.articles.length > 0) {
                filteredCategories = [...filteredCategories, filteredCategory];
            }
        });

        serverData.categories = filteredCategories;
        serverData.addedValue = this.adaosValue;
        if (serverData.categories.length == 0) {
            this.notify.warningMessage('Oferta nu contine articole!');
            this.spinner.hide();
            return;
        }
        this.spinner.show();
        let res;
        try {
            res = await this.offerService.finishCommandV2(serverData);
        }
        catch (err) {
            this.spinner.hide();
            return;
        }
        console.log(res);
        this.offerData.data = {
            commandId: res.commandId,
            commandSeries: res.commandSeries,
            commandNumber: res.commandNumber,
        };
        this.offerData.commandData = {
            commandId: res.commandId,
            commandSeries: res.commandSeries,
            commandNumber: res.commandNumber,
        };
        this.offerData.status = 'COMANDA';
        this.spinner.hide();
        this.notify.successMessage(`Comanda ${res.commandNumber} a fost salvata!`);
        return;
    }

    getPriceByCategory(dealer, detail) {
        if (dealer.priceCategorySymbol == 'I') {
            return detail.C1;
        } else if (
            dealer.priceCategorySymbol == 'II' ||
            dealer.priceCategorySymbol == 'IV'
        ) {
            return detail.C2;
        } else if (dealer.priceCategorySymbol == 'III') {
            return detail.C3;
        }
    }

    async generateOfferPdf() {
        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                if (article.invalidPrice) {
                    this.notify.warning(
                        `Oferta contine preturi sub limita stabilita articol ${article.articleName}!`
                    );
                    return;
                }

                let dim = [];
                for (let detail of article.data) {
                    let fnd = dim.find(d => {
                        return d == detail.D1
                    })

                    if (detail.quantity && detail.quantity > 0 && fnd) {
                        this.notify.warning('Cumulati cantitatea pe o singura dimensiune!');
                        console.log("Inactiv", detail);
                        this.spinner.hide();
                        return;
                    } else {
                        dim.push(detail.D1)
                    }
                }
                ////
            }
        }
        await this.saveOffer(this.offerData.status, null);
        let body = {
            offer: this.offerData.categories,
            dealer: this.offerData.dealer,
            dealerAgent: this.offerData.dealerAgent,
            commandDate: this.offerData.commandDate,
            returned: this.offerData.returned,
            offerData: {
                selectedOfferType: this.selectedOfferType,
                selectedColor: this.selectedColor,
                selectedMaterial: this.selectedMaterial,
                selectedThickness: this.selectedThickness,
            },
            summary: {
                totalPrice: this.round2Decimals(this.totalPrice - this.discountPromo),
                totalWithOutDiscount: this.totalWithOutDiscount,
                discountValue: this.discountValue,
                discountPercent: this.discountPercent,
                discountPromo: this.discountPromo
            },
            client: this.offerData.client,
            includePrices: this.includePrices,
            id: this.offerData._id,
            commandNumber: this.offerData?.data ? this.offerData.data.commandNumber : '',
            offerNumber: this.offerData?.data ? this.offerData.data.offerNumber : '',
            indexlocal: this.offerData?.indexlocal ? this.offerData.indexlocal : null,
            advancePaymentValue: this.offerData?.advancePaymentValue ? this.offerData.advancePaymentValue : 0,
        };
        console.log(this.includePrices);
        this.spinner.show();
        let res: any = await this.offerService.generateOfferPdf(body);
        this.spinner.hide();

        this.offerData.actions = [
            {
                action: 'Pdf generat',
                date: new Date(),
            },
        ];
        this.offerData.files = [
            {
                name: `${res.pdfName.split('_')[0]}`,
                path: `public/${res.pdfName}`,
            },
        ];
        //todo duplicates command nodes
        ///await this.saveOffer(this.offerData.status, "PDF generat!");
        window.open(`${this.serviceUrl}/public/${res.pdfName}`);
    }

    async generateCommandsInternalDocumentsPdf() {
        this.spinner.show();
        this.offerData.summary = {
            totalPrice: this.totalPrice,
            totalWithOutDiscount: this.totalWithOutDiscount,
            discountValue: this.discountValue,
            discountPercent: this.discountPercent,
        };

        let res: any = await this.offerService.generateCommandsInternalDocumentsPdf(
            this.offerData
        );
        this.spinner.hide();
        window.open(`${this.serviceUrl}/public/${res.pdfName}`);
    }

    async prefillClientData(offer) {
        if (!offer.offerData.selectedOfferType) {
            let contacts = JSON.parse(offer.contact);
            for (let contact of contacts) {
                if (!contact.phone) {
                    break;
                }
                const data: any = await this.offerService.getClientData(
                    contact.phone,
                    'phone'
                );
                if (data.rows.length > 0) {
                    this.onClientSelected({ option: { value: data.rows[0] } });
                    break;
                }
            }
        }
    }
    async prefillEditClientData(client) {
        let filteredCounties = this.counties.rows.filter(
            (county) => county.countyName == client.JUDET
        );
        this.selectedCounty = filteredCounties[0];
        let filteredLocalities;
        if (this.selectedCounty) {
            this.localities = await this.offerService.getLocalities(
                this.selectedCounty.countyId
            );
            this.filteredOptions = this.localities.rows;
            filteredLocalities = this.localities.rows.filter(
                (locality) => locality.localityName == client.DEN_LOCALIT
            );
        }

        /////////////////////////
        this.editClientData = {
            isPF: client.PERSOANAFIZICA == 1 ? true : false,
            clientId: client.CODPART,
            name: client.DEN_PART,
            agendaId: client.CODAGENDA,
            fiscalCode: client.CODFISCAL,
            tradeRegister: client.REGCOM,
            phone: client.TELEFON,
            county: this.selectedCounty,
            locality: filteredLocalities ? filteredLocalities[0] : '',
            street: client.ADRESA,
            scara: '',
            number: client.NUMBER,
            bloc: client.BLOC,
            etaj: client.ETAJ,
            apartament: client.APART
        };
        this.editClient = true;
    }

    async prefillSelectedClientData(client) {
        if (!client.CODFISCAL) {
            this.notify.warningMessage(
                'Partenerul selectat nu are completat CUI/CNP !'
            );
            return;
        }
        this.selectedClient = client;

        this.offerData.client = {
            fiscalCode: client.CODFISCAL,
            name: client.DEN_PART,
            phone: client.TELEFON,
            internalId: client.CODPART,
            company: client.PERSOANAFIZICA == 1 ? false : true,
            address: {
                address: client.ADRESA,
                city: client.DEN_LOCALIT,
                conunty: client.JUDET,
                headquarter: client.headquarter,
                //todo add headquarter
            },
        };

        this.showClientsModal = null;
    }
    async insertNewPartner() {
        if (!this.newClientData?.locality?.localityId) {
            this.notify.warningMessage('Selectati localitatea!');
            return 0;
        }
        this.spinner.show();
        this.newClientData.name = this.newClientData.name.toUpperCase();
        const insertResult: any = await this.offerService.insertPartner(
            this.newClientData
        );
        console.log(insertResult);
        if (insertResult.Error == 'ok') {
            this.spinner.hide();
            this.notify.successMessage('Partener salvat in mentor!');
            this.addNewClient = false;
            this.clientFiscalCodeSearch = this.newClientData.fiscalCode;
            await this.onFiscalCodeChange();
            await this.prefillSelectedClientData(this.clients[0]);
            this.showClientsModal = false;
        } else {
            this.spinner.hide();
            this.notify.errorMessage(insertResult.Error);
        }
    }

    async editPartner() {
        if (
            !this.editClientData?.locality ||
            !this.editClientData?.locality?.localityId
        ) {
            this.notify.warningMessage('Selectati localitatea!');
            return 0;
        }
        console.log(this.editClientData);
        //filter client
        await this.offerService.editClient(this.editClientData);

        this.clientFiscalCodeSearch = this.editClientData.fiscalCode;
        this.onFiscalCodeChange();
        this.editClient = false;
    }

    async getDealers() {
        this.dealers = await this.offerService.getDealers();
    }

    async loadDealerAgents(event) {
        //change prices based on client
        // this.differentClient = false;
        // for (let category of this.offerData.categories || []) {
        //     for (let article of category.articles) {
        //         for (let detail of article.data) {

        //             if (event.value.priceCategorySymbol == "I") {
        //                 detail.agentMinimumPrice = this.round2Decimals(detail.C1);
        //                 detail.agentPrice = this.round2Decimals(detail.C1);
        //             }
        //             else if (event.value.priceCategorySymbol == "II" || event.value.priceCategorySymbol == "IV") {
        //                 detail.agentMinimumPrice = this.round2Decimals(detail.C2);
        //                 detail.agentPrice = this.round2Decimals(detail.C2);
        //             }
        //             else if (event.value.priceCategorySymbol == "III") {
        //                 detail.agentMinimumPrice = this.round2Decimals(detail.C3);
        //                 detail.agentPrice = this.round2Decimals(detail.C3);e
        //             }
        //         }
        //     }
        // }
        // this.recalculate(null);

        /////
        this.spinner.show();
        this.selectedDealer = event.value;
        //this.offerData.dealer = this.selectedDealer;

        /////prefill dealer data
        const data: any = await this.offerService.getClientData(
            this.selectedDealer.fiscalCode,
            'cui'
        );
        if (data.rows.length > 0) {
            this.onClientSelected({ option: { value: data.rows[0] } });
        }
        ////
        this.dealerAgents = await this.offerService.loadDealerAgents(
            this.selectedDealer.partnerId
        );
        this.checkDealerStatus(this.selectedDealer.partnerId, true)
        this.recalculate(true)
        this.spinner.hide();
    }

    async onSelectedDealerAgent(event) {
        this.selectedDealerAgent = event.value;
        this.offerData.client['address'] = {
            address: this.selectedDealerAgent.ADRESA,
            city: this.selectedDealerAgent.DEN_LOCALIT,
            conunty: this.selectedDealerAgent.JUDET,
            headquarter: this.selectedDealerAgent.headquarter,
        };
    }

    loadAgentPrice(P) {
        this.offerData.priceVariationKey = P;
        for (let category of this.offerData.categories || []) {
            for (let article of category.articles) {
                for (let detail of article.data) {
                    if (detail[P] > detail.agentMinimumPrice && !detail.quantity) {
                        detail.agentPrice = detail[P];
                    }
                    if (detail[P] == detail.agentMinimumPrice && !detail.quantity) {
                        detail.agentPrice = detail[P];
                    }
                }
            }
        }
        this.recalculate(null);
    }

    commandTypeChanged(event) {
        console.log("changed type")
        if (event.value == 'Client') {
            this.isDealerTrusted = 'ok'
            delete this.offerData.dealer;
            delete this.offerData.dealerAgent;
        } else {
            this.isDealerTrusted = 'ok';
        }
    }

    compareDealers(o1, o2) {
        return o1.partnerId == o2.partnerId;
    }
    compareDealerAgents(o1, o2) {
        return o1.agentId == o2.agentId;
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    showColorWarningDialog(data) {
        if (data.length == 1 && this.scannedItems.length == 0) {
            return;
        }
        if (data.length > 0) {
            let dialogRef = this.dialog.open(DecisionModalComponent, {
                data: {
                    title: 'dialog.title.warning',
                    message: 'offers.cart_color_warning',
                    items: data,
                    showClose: false,
                },
            });
        }
    }

    round4Decimals(value) {
        return Math.round(value * 10000) / 10000;
    }

    delay(timeout) {
        return new Promise((resolve) => {
            setTimeout(resolve, timeout);
        });
    }

    isTigla(articleName) {
        return articleName.includes('TIGLA METALICA');
    }

    async setCountyDelivery(event) {
        this.offerData.deliverAddress = event.value.countyName;
        this.spinner.show();
        this.localities = await this.offerService.getLocalities(
            event.value.countyId
        );
        this.filteredOptions = this.localities.rows;
        this.spinner.hide();
    }

    async setLocalityDelivery(event) {
        this.offerData.deliverAddress += ' ' + event.value.localityName;
    }

    alternativeDeliveryAdress() {
        if (!this.deliverStreetAlternative) {
            this.offerData.deliverAddress += ' ' + "";
        } else {
            this.offerData.deliverAddress += ' ' + this.deliverStreetAlternative;
        }
        this.showAlternativeDelivery = false;
        this.showButtonAlternativeAddress = false;
    }

    deleteAlternativeAddress() {
        this.filteredOptions = undefined;
        this.offerData.deliverAddress = '';
        this.showButtonAlternativeAddress = true;
        this.deliverStreetAlternative = '';
        this.selectedValueCounty = this.counties;
    }

    closeAlternativeAddress() {
        this.showAlternativeDelivery = false;
        this.showButtonAlternativeAddress = true;
        this.deliverStreetAlternative = '';
    }

    openNewAddressAlternative() {
        this.showAlternativeDelivery = true;
    }
    checkValueD1Real(d1Real, sub, index, items) {
        var regExp = /[a-zA-Z]/g;
        if (regExp.test(d1Real)) {
            this.notify.warning('Valoarea nu trebuie sa contina litere sau caractere speciale');
            sub.errorD1Real = true;
            return;
        }
        let roundD1SubPrevious;
        if (index > 0) {
            roundD1SubPrevious = this.round2Decimals(items[index - 1].D1);
        } else {
            roundD1SubPrevious = 0;
        }

        let roundD1Sub = this.round2Decimals(sub.D1)
        if (d1Real.length <= 4) {

            if (d1Real < roundD1Sub && d1Real > roundD1SubPrevious || d1Real.length === 0) {
                sub.errorD1Real = false;
            } else {
                sub.errorD1Real = true;
            }

        }
    }

    checkValueD1(d1Real, sub) {
        var regExp = /[a-zA-Z]/g;
        if (regExp.test(d1Real)) {
            this.notify.warning('Valoarea nu trebuie sa contina litere sau caractere speciale');
            sub.errorD1 = true;
            return;
        }
        if (d1Real >= sub.LMIN && d1Real <= sub.LMAX) {


            sub.errorD1 = false;
        }
        else {
            sub.errorD1 = true;
        }


    }

    checkD1Input(event) {
        if (event && event.keyCode == 188) {
            this.notify.infoMessage('Folositi punctul ca separator pentru zecimale!');
            event.preventDefault();
            return;
        }
    }

    showD1Real(item) {
        if (item.includes("SIPCA GARD 130") ||
            item.includes("LAMELA GARD ORIZONTAL 191") ||
            item.includes("PROFIL CAPAC INCHIDERE GARD ORIZONTAL 205") ||
            item.includes("LAMBRIU METALIC 122") ||
            item.includes("TIGLA METALICA ALPIN 103") ||
            item.includes("TIGLA METALICA ALPIN 3D PLUS 104") ||
            item.includes("TIGLA METALICA MEDITERAN 105") ||
            item.includes("TIGLA METALICA MEDITERAN PLUS 106") ||
            item.includes("TABLA CLICK 101") ||
            item.includes("TABLA CLICK PLUS 102")) {
            return true
        }
        return false
    }

    async onBasicUpload(event) {
        console.log(event);
        const formData = new FormData();
        formData.append('file', event.currentFiles[0]);

        //console.log('boundary:', formData._boundary);
        await this.offerService.saveFile(formData);

        console.log(event.currentFiles[0]);
        if (this.offerData.files) {
            this.offerData.files = [
                ...this.offerData.files,
                {
                    name: event.currentFiles[0].name,
                    path: `public/agent1/${event.currentFiles[0].name}`,
                    user: 'agent1',
                },
            ];
        } else {
            this.offerData.files = [
                {
                    name: event.currentFiles[0].name,
                    path: `public/agent1/${event.currentFiles[0].name}`,
                    user: 'agent1',
                },
            ];
        }

        this.fileUpload.clear();

        this.saveOffer(this.offerData.status, null);
    }

    preventScrool(event) {
        event.preventDefault();
    }

    async validateFiscalCode(fiscalCode) {
        fiscalCode = fiscalCode.replace('RO', '').replace('ro', '').trim();
        if (isNaN(parseInt(fiscalCode))) {
            this.notify.warningMessage('Cod fiscal eronat.');
            return;
        }
        fiscalCode = parseInt(fiscalCode);
        let res: any = await this.offerService
            .validateFiscalCode(fiscalCode)
            .toPromise();
        if (!res.denumire) {
            this.notify.warningMessage(
                'Cod fiscal eronat. Nu a putut fi gasit in baza de date ANAF.'
            );
        } else {
            this.validFiscalCode = true;
            this.validatedPartnerData = res;
            this.newClientData.name = this.validatedPartnerData.denumire;
            this.newClientData.fiscalCode = this.validatedPartnerData.cui;
        }
    }

    checkIfInactive(detail) {

        if (
            this.offerData.offerData.selectedOfferType.key != 'GA' && this.offerData.offerData.selectedOfferType.key != 'GAZ' && this.offerData.offerData.selectedOfferType.key != 'VLX' && this.offerData.offerData.selectedOfferType.key != 'OP' &&
            (detail.DENUMIRE.includes('PROFIL METALIC DE FIXARE PE STALP 192') ||
                detail.DENUMIRE.includes('RIGLA METALICA DE RIGIDIZARE 193') ||
                detail.DENUMIRE.includes('POP NITURI COLORATE 100 BUC 194') ||
                detail.DENUMIRE.includes('LAMELA GARD ORIZONTAL 191') ||
                detail.DENUMIRE.includes('VELUX') ||
                detail.DENUMIRE.includes('SIPCA GARD 130') ||
                detail.DENUMIRE.includes('APARAT NITUIT') ||
                detail.DENUMIRE.includes('CIOCAN TEFLON') ||
                detail.DENUMIRE.includes('CIOCAN ROTUND') ||
                detail.DENUMIRE.includes('CLESTE FALTUIT') ||
                detail.DENUMIRE.includes('CLESTE PLIERE') ||
                detail.DENUMIRE.includes('FOARFECA TABLA') ||
                detail.DENUMIRE.includes('FOARFECA CIRCULARA') ||
                detail.DENUMIRE.includes('FOARFECA COMBINATA') ||
                detail.DENUMIRE.includes('SCARA DE ACCES') ||
                detail.DENUMIRE.includes('PRISMA TEFLON') ||
                detail.DENUMIRE.includes('CLESTE FALCI') ||
                detail.DENUMIRE.includes('TRASATOR GRADAT') ||
                detail.DENUMIRE.includes('GARD ORIZONTAL') ||
                detail.DENUMIRE.includes('CUIE 8') ||
                detail.DENUMIRE.includes('HOL SURUB')

            )) {
            return true;
        }
        return false;
    }

    setAdvancePaymentValue(isCustody) {

        if (isCustody) {
            this.offerData.isAdvancePayment = true;
            this.calculateAdvancedPayment(this.offerData)
        } else {
            this.offerData.isAdvancePayment = false;
            this.offerData.advancePaymentValue = 0;
        }
    }
    showCategory(item) {
        let show = false;
        if (item.category == 'ELEMENTE OPTIONALE' && (this.offerData.offerData.selectedOfferType.key == 'AA' || this.offerData.offerData.selectedOfferType.key == 'AP' || this.offerData.offerData.selectedOfferType.key == 'MM' || this.offerData.offerData.selectedOfferType.key == 'MP' || this.offerData.offerData.selectedOfferType.key == 'CC' || this.offerData.offerData.selectedOfferType.key == 'CP')) {

            for (let article of item.articles) {
                if (article.data[0].quantity) {
                    article.hideArticle = false;
                    show = true;
                }
                else {
                    article.hideArticle = true;
                }
            }
            return show;
        }

        if (item.category == 'SCULE ACOPERIS' && (this.offerData.offerData.selectedOfferType.key == 'AA' || this.offerData.offerData.selectedOfferType.key == 'AP' || this.offerData.offerData.selectedOfferType.key == 'MM' || this.offerData.offerData.selectedOfferType.key == 'MP' || this.offerData.offerData.selectedOfferType.key == 'CC' || this.offerData.offerData.selectedOfferType.key == 'CP')) {

            for (let article of item.articles) {
                if (article.data[0].quantity) {
                    article.hideArticle = false;
                    show = true;
                }
                else {
                    article.hideArticle = true;
                }
            }
            return show;
        }




        if (this.offerData.offerData.selectedOfferType.key == 'GA' || this.offerData.offerData.selectedOfferType.key == 'GAZ') {

            for (let article of item.articles) {
                if ((item.category == 'ELEMENTE OPTIONALE' || item.category == 'VELUX') && article.quantity) {
                    article.hideArticle = false;
                    show = true;
                }


                else if (article.data[0].INACTIV == 1 && !article.quantity) {
                    article.hideArticle = true;
                } else {
                    article.hideArticle = false;
                    show = true;
                }
            }
            return show;
        }
        if (this.offerData.offerData.selectedOfferType.key == 'VLX') {
            if (item.category == 'ELEMENTE OPTIONALE') {
                return false;
            }
            return true;
        }
        if (this.offerData.offerData.selectedOfferType.key == 'OP') {
            if (item.category == 'TABLA' ||
                item.category == 'VELUX' ||
                item.category == 'TABLA CUTATA' ||
                item.category == 'ACCESORII ETANSARE-FIXARE' ||
                item.category == 'GARD.VERTICAL' ||
                item.category == 'LAMBRIU METALIC' ||
                item.category == 'GARD ORIZONTAL') {

                //scos lambriu metail 3005 br 0.6
                return false;
            }
            return true;
        }

        for (let article of item.articles) {
            if ((article.articleName.includes('PROFIL METALIC DE FIXARE PE STALP 192') ||
                article.articleName.includes('RIGLA METALICA DE RIGIDIZARE 193') ||
                article.articleName.includes('POP NITURI COLORATE 100 BUC 194') ||
                article.articleName.includes('LAMELA GARD ORIZONTAL 191') ||
                article.articleName.includes('VELUX') ||
                article.articleName.includes('SIPCA GARD 130') ||
                article.articleName.includes('APARAT NITUIT') ||
                article.articleName.includes('CIOCAN TEFLON') ||
                article.articleName.includes('CIOCAN ROTUND') ||
                article.articleName.includes('CLESTE FALTUIT') ||
                article.articleName.includes('CLESTE PLIERE') ||
                article.articleName.includes('FOARFECA TABLA') ||
                article.articleName.includes('FOARFECA CIRCULARA') ||
                article.articleName.includes('FOARFECA COMBINATA') ||
                article.articleName.includes('SCARA DE ACCES') ||
                article.articleName.includes('PRISMA TEFLON') ||
                article.articleName.includes('CLESTE FALCI') ||
                article.articleName.includes('BURLAN RECTANGULAR PROMO') ||
                article.articleName.includes('TRASATOR GRADAT') ||
                article.articleName.includes('GARD ORIZONTAL') ||
                article.articleName.includes('CUIE 8') ||
                article.articleName.includes('HOL SURUB')
            ) && !article.quantity) {
                article.hideArticle = true;
            }
            else if (article.data[0].INACTIV == 1) {
                let noQuantity = true;
                for (let detail of article.data) {
                    if (detail.quantity) {
                        noQuantity = false;
                    }
                }
                if (noQuantity) {
                    article.hideArticle = true;
                }
                else {
                    show = true;
                }
            }
            else {
                article.hideArticle = false;
                show = true;
            }
        }
        return show;
    }

    calculateAdvancedPayment(data) {
        if (data.dealer && data.dealer.partnerTypeId != 16) {
            data.advancePaymentValue = this.round2Decimals(this.totalPrice - this.adaosValue * 1.19)
        } else {
            data.advancePaymentValue = this.totalPrice - this.offerData.discountPromo;
        }
    }

    async checkDealerStatus(dealerId, showWarning) {
        let response = await this.offerService.getSoldDetail(dealerId)
        let docLength = response["documents"].length

        this.creditValue = response["creditValue"];
        this.soldValue = response["soldValue"]
        if (docLength > 0) {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            //let lastInvoicePayment = response["documents"][docLength - 1].invoicePaymentDeadline;
            let lastInvoicePayment;
            for (let invoice of response['documents']) {

                if (invoice.invoiceRest >= 1 && invoice.type == "FOUT") {
                    lastInvoicePayment = invoice.invoicePaymentDeadline;
                    break;
                }

            }
            let invoicePaymentDeadline = Math.ceil((today.getTime() - (new Date(lastInvoicePayment).getTime())) / (1000 * 60 * 60 * 24))
            if (invoicePaymentDeadline >= 7) {
                this.isDealerTrusted = 'error';
                if (showWarning) {
                    this.notify.error("Dealerul are facturi scadente mai vechi de 7 zile")
                }
            } else if (invoicePaymentDeadline < 7 && invoicePaymentDeadline > 2) {
                if (showWarning) {
                    this.notify.warning("Dealerul are facturi scadente intre 3 si 7 zile")
                }
                this.isDealerTrusted = 'ok';
            } else {
                this.isDealerTrusted = 'ok';
            }
        } else {
            this.isDealerTrusted = 'ok';
        }
        if (response["creditValue"] < response["soldValue"] + this.totalPrice) {
            this.isDealerTrusted = 'error';
        }
    }
    checkCredit() {
        if (this.offerData.invoiceType == "Dealer") {
            if (this.creditValue < this.soldValue + this.totalPrice) {
                this.isDealerTrusted = 'error';
            }
        }
    }
    checkAdvancePayment(event) {
        if (!this.offerData.advancePaymentValue) {
            //69=point 190 = e 187=add 189=substrat 107 = add + num lk 109 = substract + num lk
            if (event && (event.keyCode == 69 || event.keyCode == 190 || event.keyCode == 187 || event.keyCode == 189 || event.keyCode == 107 || event.keyCode == 109)) {
                this.notify.warning('Caracter neacceptat')
                this.offerData.advancePaymentValue = '';

                event.preventDefault();
                return;
            }
        }
    }

    verifyPayment(event) {
        if (event.value == "TBI") {
            window.open("https://tbibank.ro/lps/data/CARETTA/caretta_ro.html");
        }
    }

    filterImage(image) {
        this.offerData.files = this.offerData.files.filter((f) => { return f.name != image.name })
    }
    checkCommandProcentual() {
        let priceRoofingSheets = 0;
        let limitPercent = 35;
        let roffingValue = 0;
        let hasTigla = false;
        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                if (article.totalQuantity && article.data[0].PROMOTIE == 1 && (article.articleName.includes("TIGLA METALICA") || article.articleName.includes("TABLA CLICK"))) {
                    priceRoofingSheets += article.totalQuantity * article.data[0].agentPrice;
                }
                for (let detail of article.data) {
                    if (detail.quantity && (article.articleName.includes("TIGLA METALICA") || article.articleName.includes("TABLA CLICK"))) {
                        hasTigla = true;
                    }
                    if (detail.quantity && detail.promo1Id && detail.promoPercentage >= 0) {
                        roffingValue += detail.quantity * (detail.D1 ? detail.D1 : 1) * (detail.D2 ? detail.D2 : 1) * detail.agentPrice;
                    }
                }
            }
        }
        this.percentRoofingPROMOSheets = this.round2Decimals((priceRoofingSheets * 100) / this.totalPrice);
        this.accesoryProcent = this.round2Decimals((roffingValue * 100) / this.totalPrice);
        if (Number.isNaN(this.accesoryProcent)) {
            this.accesoryProcent = 0;
        }

        if (this.offerData.dealer && (this.offerData.dealer.partnerId == 5739 || this.offerData.dealer.partnerId == 1403 || this.offerData.dealer.partnerId == 34043 || this.username == "ciprian.marcu")) {
            this.promoRoofError = false;
            return false;
        }



        if (this.accesoryProcent < limitPercent && priceRoofingSheets > 0) {
            this.promoRoofError = true;
            return true;
        } else if (this.accesoryProcent >= limitPercent) {
            this.promoRoofError = false;
            return false;
        }


    }

    addArtileDimension(detail) {
        let newDetail = Object.assign({}, detail[0]);
        newDetail.D1 = 0;
        detail.push(newDetail)
    }

    getModule(d1, d2) {
        return this.carettaService.getModule(d1, d2);
    }

    darkMode() {

        if (!this.darkModeEnabled) {
            this.store.set('darkModeEnabled', false);
            disableDarkMode();

        } else {
            enableDarkMode({});
            this.store.set('darkModeEnabled', true);
        }
    }

    selectProvider(providerName) {
        this.offerData.offerData.providerName = providerName;
        for (let classData of this.classes) {
            if (classData.name.includes(providerName)) {
                this.offersType.push({
                    name: classData.name,
                    key: classData.symbol
                })
            }

            // offersType = [
            //     { name: 'ȚIGLĂ METALICĂ DAILY', key: 'DA' },
        }

    }

    changeFence() {
        this.numarPanouri = 0;
        this.distantaStalpi = 0;
        this.stalpi = 0;
        this.inaltimeStalpi = 0;
        this.sipci = 0;
        this.lungimeSipci = 0;
        this.profil = 0;
        this.lungimeProfil = 0;
        this.capace = 0;
        this.lungimeCapace = 0;
        this.capaceinchidere = 0;
        this.lungimeCapaceinchidere = 0;
        this.rigle = 0;
        this.lungimeRigle = 0;
        this.popnituri = 0;

        this.selectedFenceType = null;
        this.selectedFenceCategory = null;
        this.offerData.fences = [];
    }

    recalculateDistStalpi(event) {
        this.distantaStalpi = this.round2Decimals(this.distantaStalpi);
        this.numarPanouri = Math.floor(this.numarPanouri);
        if (this.selectedFenceType.name == 'ZEBRA.ORIZONTAL CASETA X 140' && this.activeFenceIndex > 0) {
            this.sipci = 0;
        }
        else {
            this.sipci = Math.floor(this.sipci);
        }

        this.distantaSipci = Math.floor(this.distantaSipci);
        this.rigle = 0;
        this.lungimeSipci = this.distantaStalpi;
        if (this.distantaStalpi <= 1.2) {
            this.rigle = 0;
        }
        else if (this.distantaStalpi > 1.2 && this.distantaStalpi <= 2.2) {
            this.rigle = 1;
        }
        else if (this.distantaStalpi > 2.2 && this.distantaStalpi <= 3.2) {
            this.rigle = 2;
        }
        else if (this.distantaStalpi > 3.2 && this.distantaStalpi <= 4.2) {
            this.rigle = 3;
        }
        else if (this.distantaStalpi > 4.2 && this.distantaStalpi <= 5.2) {
            this.rigle = 4;
        }
        else if (this.distantaStalpi > 5.2 && this.distantaStalpi <= 6.2) {
            this.rigle = 5;
        }
        else if (this.distantaStalpi > 6.2 && this.distantaStalpi <= 7.2) {
            this.rigle = 6;
        }
        this.sipciObs = "";
        if (this.rigle == 1) {
            this.sipciObs = "gaura la capat + la mijloc (" + this.round2Decimals(((this.lungimeSipci - 0.01) * 1000) / 2) + "mm)";
        }
        else if (this.rigle == 2) {
            this.sipciObs = "Sipca se gaureste de 2 ori, la pas de  " + this.round2Decimals(((this.lungimeSipci - 0.01) * 1000) / 3) + "mm";
        }
        else if (this.rigle == 3) {
            this.sipciObs = "Sipca se gaureste de 3 ori, la pas de " + this.round2Decimals(((this.lungimeSipci - 0.01) * 1000) / 4) + "mm";
        }
        else if (this.rigle == 4) {
            this.sipciObs = "Sipca se gaureste de 4 ori, la pas de " + this.round2Decimals(((this.lungimeSipci - 0.01) * 1000) / 5) + "mm";
        }
        else if (this.rigle == 5) {
            this.sipciObs = "Sipca se gaureste de 5 ori, la pas de " + this.round2Decimals(((this.lungimeSipci - 0.01) * 1000) / 6) + "mm";
        }
        else if (this.rigle == 6) {
            this.sipciObs = "Sipca se gaureste de 6 ori, la pas de " + this.round2Decimals(((this.lungimeSipci - 0.01) * 1000) / 7) + "mm";
        }





        this.profil = 2;
        //this.rigle = this.numarPanouri;
        this.capace = 1;
        this.lungimeSipci = this.distantaStalpi;
        this.lungimeCapace = this.distantaStalpi;
        this.lungimeRigle = this.inaltimeStalpi;
        this.lungimeCapaceinchidere = this.distantaStalpi;
        this.lungimeProfil = this.inaltimeStalpi;


        //this.sipci = Math.floor((this.inaltimeStalpi - 0.02) / 0.12);


        this.calcPopnituri();
        this.changeInaltimeStalpi(null);
    }

    recalculateInaltimeStalpi(event) {


        // public int InaltimeStalpX135()
        // {
        //     return (int)(20 + numX135CateSipci.Value * 130);
        // }
        this.inaltimeStalpi = 0.02 + this.sipci * 0.12;
        this.calcPopnituri()

    }
    changeRigle(event) {
        this.calcPopnituri()
    }

    changeInaltimeStalpi(event) {
        this.inaltimeStalpi = this.round2Decimals(this.inaltimeStalpi);
        this.lungimeRigle = this.inaltimeStalpi;


        if (this.selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135' || this.selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135 2F') {
            this.sipci = Math.round((this.inaltimeStalpi - 0.04) / 0.13);
        } else if (this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 118') {
            this.sipci = Math.floor(this.distantaStalpi / (0.113 + (this.distantaSipci ? this.distantaSipci / 100 : 0)));
            if (this.distantaStalpi % (0.113 + (this.distantaSipci ? this.distantaSipci / 100 : 0)) > 0) {
                this.sipci = this.sipci + 1;
            }
        } else if (this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 115') {
            this.sipci = Math.floor(this.distantaStalpi / (0.113 + (this.distantaSipci ? this.distantaSipci / 100 : 0)));
            if (this.distantaStalpi % (0.113 + (this.distantaSipci ? this.distantaSipci / 100 : 0)) > 0) {
                this.sipci = this.sipci + 1;
            }
        } else if (this.selectedFenceType.name == 'ZEBRA.ORIZONTAL CASETA X 140') {
            if ((this.sipci && isFinite(this.sipci) && this.selectedFenceType.name == 'ZEBRA.ORIZONTAL CASETA X 140')) {

                this.inaltimeStalpi = this.round2Decimals((((this.distantaSipci ? this.distantaSipci / 100 : 0) + 0.14) * this.sipci));
            }
            else {
                this.sipci = this.round2Decimals((this.inaltimeStalpi) / ((this.distantaSipci ? this.distantaSipci / 100 : 0) + 0.14))
                if (this.sipci < 0) {
                    this.sipci = 0;
                }
            }
        }
        else if (this.selectedFenceType.name == 'ZEBRA.ORIZONTAL X 174') {
            this.sipci = Math.floor((this.inaltimeStalpi - 0.02) / 0.15);
        }
        else {
            this.sipci = Math.floor((this.inaltimeStalpi - 0.02) / 0.12);
        }




        this.lungimeProfil = this.inaltimeStalpi;
        this.calcPopnituri()
    }

    calcPopnituri() {

        if (this.numarPanouri > 0) {
            this.popnituri = (this.sipci * 4 + 4 + this.rigle * (this.sipci + 1)) * this.numarPanouri;

            if (this.popnituri % 100 > 0) {
                this.popnituri = this.popnituri - (this.popnituri % 100) + 100;
            }
        }
        else {
            this.popnituri = 0;
        }
        if (this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 118' || this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 115') {
            if (this.sipci > 0) {

                this.popnituri = (this.sipci * 4);

                if (this.popnituri % 250 > 0) {
                    this.popnituri = this.popnituri - (this.popnituri % 250) + 250;
                }
            }
            else {
                this.popnituri = 0;
            }
        }
    }

    adaugaPanou() {
        if (!this.tipSipca && (this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 118' || this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 115')) {
            this.notify.warningMessage("Selectati tip sipca!");
            return;
        }

        if (!this.distantaStalpi || !this.inaltimeStalpi || !this.numarPanouri) {
            this.notify.warningMessage("Completati datele necesare!");
            return;
        }
        if (!this.offerData.fences) {
            this.offerData.fences = [{
                distantaStalpi: this.distantaStalpi,
                inaltimeStalpi: this.inaltimeStalpi,
                numarPanouri: this.numarPanouri,
                distantaSipci: this.distantaSipci,
                tipSipca: this.tipSipca
            }]
        } else {
            this.offerData.fences.push({
                distantaStalpi: this.distantaStalpi,
                inaltimeStalpi: this.inaltimeStalpi,
                numarPanouri: this.numarPanouri,
                distantaSipci: this.distantaSipci,
                tipSipca: this.tipSipca
            })
        }

        this.numarPanouri = 0;
        this.distantaStalpi = 0;
        this.stalpi = 0;
        this.inaltimeStalpi = 0;
        this.sipci = 0;
        this.lungimeSipci = 0;
        this.profil = 0;
        this.lungimeProfil = 0;
        this.capace = 0;
        this.lungimeCapace = 0;
        this.rigle = 0;
        this.lungimeRigle = 0;
        this.popnituri = 0;
        this.distantaSipci = 0;

        if (this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 118' || this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 115') {
            this.numarPanouri = 1;
        }

    }

    activateFenceTab(event) {
        if (this.activeFenceIndex > 0 && this.offerData?.fences && this.offerData.fences.length > 0) {
            this.distantaStalpi = this.offerData.fences[this.activeFenceIndex - 1].distantaStalpi;
            this.inaltimeStalpi = this.offerData.fences[this.activeFenceIndex - 1].inaltimeStalpi;
            this.numarPanouri = this.offerData.fences[this.activeFenceIndex - 1].numarPanouri;
            this.distantaSipci = this.offerData.fences[this.activeFenceIndex - 1].distantaSipci;
            this.tipSipca = this.offerData.fences[this.activeFenceIndex - 1].tipSipca;
        }

        this.recalculateDistStalpi(null);
    }

    populateQuantities() {
        this.activeFenceIndex = 0;
        if (!this.offerData?.fences) {
            this.confEnabled = false;

            this.numarPanouri = 0;
            this.distantaStalpi = 0;
            this.stalpi = 0;
            this.inaltimeStalpi = 0;
            this.sipci = 0;
            this.lungimeSipci = 0;
            this.profil = 0;
            this.lungimeProfil = 0;
            this.capace = 0;
            this.lungimeCapace = 0;
            this.capaceinchidere = 0;
            this.lungimeCapaceinchidere = 0;
            this.rigle = 0;
            this.lungimeRigle = 0;
            this.popnituri = 0;
            this.notify.successMessage("Nu au fost salvate articole!")
            this.recalculate(true);
            return;
        }

        //clear qunatities

        for (let category of this.offerData.categories) {
            for (let article of category.articles) {
                for (let detail of article.data) {
                    detail.quantity = 0;
                }
            }
        }

        let i = 1;
        for (let fence of this.offerData?.fences) {
            this.numarPanouri = 0;
            this.distantaStalpi = 0;
            this.stalpi = 0;
            this.inaltimeStalpi = 0;
            this.sipci = 0;
            this.lungimeSipci = 0;
            this.profil = 0;
            this.lungimeProfil = 0;
            this.capace = 0;
            this.lungimeCapace = 0;
            this.capaceinchidere = 0;
            this.lungimeCapaceinchidere = 0;
            this.rigle = 0;
            this.lungimeRigle = 0;
            this.popnituri = 0;
            this.activeFenceIndex = i;
            this.activateFenceTab(null);


            this.distantaStalpi = fence.distantaStalpi;
            this.inaltimeStalpi = fence.inaltimeStalpi;
            this.numarPanouri = fence.numarPanouri;
            this.recalculateDistStalpi(null);

            for (let category of this.offerData.categories) {
                for (let article of category.articles) {
                    if (this.selectedFenceType.name == "ZEBRA.ORIZONTAL X 121") {
                        if (article.articleName.includes("POP NITURI COLORATE 100 BUC 194")) {
                            article.data[0].quantity += this.popnituri / 100;
                        }

                        if (article.articleName.includes("LAMELA FRONT 30 GARD ORIZONTAL X121 367")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci - 0.01);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;
                                if (this.sipciObs) {
                                    found.observations = this.sipciObs;
                                }
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci - 0.01);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;
                                if (this.sipciObs) {
                                    clonedDetail.observations = this.sipciObs;
                                }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 35 GARD ORIZONTAL X121 368")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.profil * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.profil * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC INCHIDERE 35 GARD ORIZONTAL X121 369")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci);
                            })
                            if (found) {
                                found.quantity += this.capace * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci);
                                clonedDetail.quantity = this.capace * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("RIGLA DE RIGIDIZARE GARD ORIZONTAL X121 391")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.rigle * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.rigle * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC RIGLA DE RIGIDIZARE X121 396")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.rigle * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.rigle * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                    } else if (this.selectedFenceType.name == "ZEBRA.ORIZONTAL X 121 2F") {
                        if (article.articleName.includes("POP NITURI COLORATE 100 BUC 194")) {
                            article.data[0].quantity += this.popnituri / 100;
                        }

                        if (article.articleName.includes("LAMELA FRONT 30 GARD ORIZONTAL X121 2F 392")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci - 0.01);
                            })
                            if (found) {
                                found.quantity += this.sipci * 2 * this.numarPanouri;

                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci - 0.01);
                                clonedDetail.quantity = this.sipci * 2 * this.numarPanouri;

                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 70 GARD ORIZONTAL X121 2F 388")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.profil * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.profil * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC INCHIDERE 70 GARD ORIZONTAL X121 2F 389")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci);
                            })
                            if (found) {
                                found.quantity += this.capace * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci);
                                clonedDetail.quantity = this.capace * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }


                    } else if (this.selectedFenceType.name == "ZEBRA.ORIZONTAL X 135") {
                        if (article.articleName.includes("POP NITURI COLORATE 100 BUC 194")) {
                            article.data[0].quantity += this.popnituri / 100;
                        }

                        if (article.articleName.includes("LAMELA FRONT 65 GARD ORIZONTAL X135 365")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci - 0.01);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;
                                if (this.sipciObs) {
                                    found.observations = this.sipciObs;
                                }
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci - 0.01);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;
                                if (this.sipciObs) {
                                    clonedDetail.observations = this.sipciObs;
                                }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 25 GARD ORIZONTAL X135")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.profil * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.profil * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC INCHIDERE 25 GARD ORIZONTAL X135 370")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci);
                            })
                            if (found) {
                                found.quantity += this.capace * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci);
                                clonedDetail.quantity = this.capace * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("RIGLA DE RIGIDIZARE GARD ORIZONTAL X135 390")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.rigle * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.rigle * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC RIGLA DE RIGIDIZARE X135 397")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.rigle * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.rigle * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }
                    } else if (this.selectedFenceType.name == "ZEBRA.ORIZONTAL X 135 2F") {
                        if (article.articleName.includes("POP NITURI COLORATE 100 BUC 194")) {
                            article.data[0].quantity += this.popnituri / 100;
                        }

                        if (article.articleName.includes("LAMELA FRONT 65 GARD ORIZONTAL X135 2F 404")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci - 0.01);
                            })
                            if (found) {
                                found.quantity += this.sipci * 2 * this.numarPanouri;

                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci - 0.01);
                                clonedDetail.quantity = this.sipci * 2 * this.numarPanouri;

                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 50 GARD ORIZONTAL X135 2F 405")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.profil * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.profil * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC INCHIDERE 50 GARD ORIZONTAL X135 2F 406")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci);
                            })
                            if (found) {
                                found.quantity += this.capace * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci);
                                clonedDetail.quantity = this.capace * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }


                    } else if (this.selectedFenceType.name == "ZEBRA.ORIZONTAL X 174") {
                        if (article.articleName.includes("POP NITURI COLORATE 100 BUC 194")) {
                            article.data[0].quantity += this.popnituri / 100;
                        }

                        if (article.articleName.includes("LAMELA 170MM GARD ORIZONTAL X174 360")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci - 0.01);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;
                                if (this.sipciObs) {
                                    found.observations = this.sipciObs;
                                }
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci - 0.01);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;
                                if (this.sipciObs) {
                                    clonedDetail.observations = this.sipciObs;
                                }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP X174 361")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.profil * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.profil * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC INCHIDERE GARD ORIZONTAL X174 363")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci);
                            })
                            if (found) {
                                found.quantity += this.capace * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci);
                                clonedDetail.quantity = this.capace * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("RIGLA DE RIGIDIZARE GARD ORIZONTAL X174 362")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.rigle * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.rigle * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CAPAC RIGLA DE RIGIDIZARE X174 395")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.rigle * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.rigle * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }
                    }
                    else if (this.selectedFenceType.name == "ZEBRA.VERTICAL Y 118") {
                        if (article.articleName.includes("SURUBURI METAL 4.8*19MM-250/CUT 150")) {
                            article.data[0].quantity += this.popnituri / 250;
                        }

                        if (this.tipSipca == "dreapta" && article.articleName.includes("SIPCA GARD CAPAT DREPT Y118 359")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.inaltimeStalpi);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;

                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.inaltimeStalpi);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;

                                article.data.push(clonedDetail);
                            }

                        }

                        if (this.tipSipca == "rotunda" && article.articleName.includes("SIPCA GARD Y118  358")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.inaltimeStalpi);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.inaltimeStalpi);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }
                    } else if (this.selectedFenceType.name == "ZEBRA.VERTICAL Y 115") {
                        if (article.articleName.includes("SURUBURI METAL 4.8*19MM-250/CUT 150")) {
                            article.data[0].quantity += this.popnituri / 250;
                        }

                        if (this.tipSipca == "dreapta" && article.articleName.includes("SIPCA GARD BIVALENTA CAPAT DREPT Y115 377")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.inaltimeStalpi);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;

                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.inaltimeStalpi);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;

                                article.data.push(clonedDetail);
                            }

                        }

                        if (this.tipSipca == "rotunda" && article.articleName.includes("SIPCA GARD BIVALENTA Y115 364")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.inaltimeStalpi);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.inaltimeStalpi);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;
                                // if (this.sipciObs) {
                                //     clonedDetail.observations = this.sipciObs;
                                // }
                                article.data.push(clonedDetail);
                            }

                        }
                    } else if (this.selectedFenceType.name == "ZEBRA.ORIZONTAL CASETA X 140") {
                        if (article.articleName.includes("POP NITURI COLORATE 100 BUC 194")) {
                            article.data[0].quantity += this.popnituri / 100;
                        }

                        if (article.articleName.includes("CASETA INTERIOR 140 GARD ORIZONTAL 373")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci - 0.01);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;

                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci - 0.01);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;

                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("CASETA EXTERIOR 140 GARD ORIZONTAL 374")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeSipci - 0.01);
                            })
                            if (found) {
                                found.quantity += this.sipci * this.numarPanouri;

                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeSipci - 0.01);
                                clonedDetail.quantity = this.sipci * this.numarPanouri;

                                article.data.push(clonedDetail);
                            }

                        }

                        if (article.articleName.includes("PROFIL METALIC DE FIXARE PE STALP 25 CASETA X140 398")) {
                            //check if dimension exist already
                            let found = article.data.find(i => {
                                return i.D1 == this.round2Decimals(this.lungimeProfil);
                            })
                            if (found) {
                                found.quantity += this.profil * this.numarPanouri;
                            } else {
                                let clonedDetail = JSON.parse(JSON.stringify(article.data[0]));
                                clonedDetail.D1 = this.round2Decimals(this.lungimeProfil);
                                clonedDetail.quantity = this.profil * this.numarPanouri;
                                article.data.push(clonedDetail);
                            }

                        }
                    }
                }
            }
            i++;
        }
        i = 0;
        this.confEnabled = false;

        this.numarPanouri = 0;
        this.distantaStalpi = 0;
        this.stalpi = 0;
        this.inaltimeStalpi = 0;
        this.sipci = 0;
        this.lungimeSipci = 0;
        this.profil = 0;
        this.lungimeProfil = 0;
        this.capace = 0;
        this.lungimeCapace = 0;
        this.capaceinchidere = 0;
        this.lungimeCapaceinchidere = 0;
        this.rigle = 0;
        this.lungimeRigle = 0;
        this.popnituri = 0;
        this.notify.successMessage("Cantitati simulator transferate in oferta!")
        this.recalculate(true);
    }
    getSipci(value) {

        if (this.selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135' || this.selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135 2F') {
            return Math.round((value - 0.04) / 0.13)
        }
        else if (this.selectedFenceType.name == 'ZEBRA.ORIZONTAL X 174') {
            return Math.round((value - 0.02) / 0.15)
        }
        else {
            return Math.round((value - 0.02) / 0.12)
        }
    }

    selectFence(fence) {
        this.selectedFenceCategory = true;
        this.selectedFenceType = fence;
        this.offerData.fenceKey = this.selectedFenceType.name;
        if (this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 118' || this.selectedFenceType.name == 'ZEBRA.VERTICAL Y 115') {
            this.numarPanouri = 1;
        }
    }

    initLocalities() {
        this.filteredOptions = this.localities.rows;
    }

    // &&
    // detail.D1 == temporaryArticle.D1 &&
    // detail.D2 == temporaryArticle.D2 &&
    // detail.D3 == temporaryArticle.D3

}



