import { OnInit, Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
    selector: 'app-pos-keyboard',
    templateUrl: './pos-keyboard.html',
    styleUrls: ['./pos-keyboard.css']
  })
  export class PosKeyboard implements OnInit {

    @Input() type;
    @Output() write = new EventEmitter<any>();
    @Output() finished = new EventEmitter<any>();

    ngOnInit(): void {
       
    }


    handleKey(key) {
        this.write.emit(key);
    }

    finish() {
        this.finished.emit();
    }

  }