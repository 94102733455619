import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-article-observation-modal',
  templateUrl: './article-observation-modal.component.html',
  styleUrls: ['./article-observation-modal.component.scss']
})
export class ArticleObservationModalComponent implements OnInit {
  @Input() article;
  @Output() getSearchStatusChange = new EventEmitter<boolean>();
  showModal = false;
  constructor() { }

  ngOnInit(): void {
  }

  saveObs() {
    this.getSearchStatusChange.emit(this.article)
  }

}
