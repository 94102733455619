import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-decision-modal',
  templateUrl: './decision-modal.component.html',
  styleUrls: ['./decision-modal.component.css']
})
export class DecisionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DecisionModalComponent>) { }

  ngOnInit() {
    this.data.showClose = this.data.showClose !== undefined ? this.data.showClose : true;
  }

  closeDialog(answer: boolean) {
    this.dialogRef.close(answer);
  }

}

