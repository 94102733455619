import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductionUploadService } from './production-upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-production-upload',
  templateUrl: './production-upload.component.html',
  styleUrls: ['./production-upload.component.css']
})
export class ProductionUploadComponent implements OnInit {

  fileToUpload: File = null;

  ngOnInit() {
  }

  constructor(
    @Inject('LOCALSTORAGE') private store: any,
    @Inject( MAT_DIALOG_DATA ) public data: any,
    public dialogRef: MatDialogRef<ProductionUploadComponent>,
    private productionUploadServce: ProductionUploadService,
    private snackBar: MatSnackBar) {}

  cancel(): void {
      this.dialogRef.close();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile() {
    this.productionUploadServce.saveFile(this.fileToUpload).subscribe( data => {
      console.log(data);
      let message = `Au fost importate cu succes ${data["nbDocs"]} linii din ${data["total"]}`;
      this.snackBar.open(message, '', {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom"
      });

      this.dialogRef.close();
    });
  }

}

