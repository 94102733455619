<div class="closeDialog">
  <span uiSref="app.home" (click)="cancel()" class="about-title" uiSrefActive="active">{{ data.appTitle | translate}}
    {{appVersion}}</span>
  <button class="mat-icon-button svg25Px pull-right" (click)="cancel()" mat-button>
    <div [inlineSVG]="'/assets/images/close.svg'"></div>
  </button>
</div>

<div class="dialog" mat-dialog-content>
  <mat-list>
    <mat-list-item *ngIf="data.client">
      <h4>{{'app.client' | translate}}:
        <span class="labelText"> {{ data.clientName }} - {{data.client}} </span></h4>
    </mat-list-item>
    <mat-list-item *ngIf="data.company">
      <h4>{{'app.company' | translate}}:
        <span class="labelText">{{ data.company }} </span></h4>
      <span (click)="darkMode()">DARKMODE</span>
    </mat-list-item>

    <mat-list-item *ngIf="data.client">
      <h4>{{'app.user' | translate}}:
        <span class="labelText"> {{ data.username }}</span> </h4>
    </mat-list-item>
    <mat-list-item *ngIf="data.client">
      <h4>{{'app.constants' | translate}}:
        <span class="labelText"><a (click)="accessConstants()" href="javascript:;">Deschide</a></span></h4>
    </mat-list-item>
    <mat-list-item>
      <h4 (click)="showNotification()">Abonare notificari</h4>
    </mat-list-item>
  </mat-list>
</div>