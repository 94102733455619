import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarettaService {
  constructor() {
  }

  getModule(d1, d2) {

    if (d1 && d2) {
      if (d1.toFixed(2) == "0.44" && d2.toFixed(2) == "1.20") {
        return "M1";
      }
      if (d1.toFixed(2) == "0.79" && d2.toFixed(2) == "1.20") {
        return "M2";
      }
      if (d1.toFixed(2) == "1.14" && d2.toFixed(2) == "1.20") {
        return "M3";
      }
      if (d1.toFixed(2) == "1.49" && d2.toFixed(2) == "1.20") {
        return "M4";
      }
      if (d1.toFixed(2) == "1.84" && d2.toFixed(2) == "1.20") {
        return "M5";
      }
      if (d1.toFixed(2) == "2.19" && d2.toFixed(2) == "1.20") {
        return "M6";
      }
      if (d1.toFixed(2) == "2.54" && d2.toFixed(2) == "1.20") {
        return "M7";
      }
      if (d1.toFixed(2) == "2.89" && d2.toFixed(2) == "1.20") {
        return "M8";
      }
      if (d1.toFixed(2) == "3.24" && d2.toFixed(2) == "1.20") {
        return "M9";
      }
      if (d1.toFixed(2) == "3.59" && d2.toFixed(2) == "1.20") {
        return "M10";
      }
      if (d1.toFixed(2) == "3.94" && d2.toFixed(2) == "1.20") {
        return "M11";
      }
      if (d1.toFixed(2) == "4.29" && d2.toFixed(2) == "1.20") {
        return "M12";
      }
      if (d1.toFixed(2) == "4.64" && d2.toFixed(2) == "1.20") {
        return "M13";
      }
      if (d1.toFixed(2) == "4.99" && d2.toFixed(2) == "1.20") {
        return "M14";
      }
      if (d1.toFixed(2) == "5.34" && d2.toFixed(2) == "1.20") {
        return "M15";
      }

      if (d1.toFixed(2) == "0.47" && d2.toFixed(2) == "1.12") {
        return "M1";
      }
      if (d1.toFixed(2) == "0.82" && d2.toFixed(2) == "1.12") {
        return "M2";
      }
      if (d1.toFixed(2) == "1.17" && d2.toFixed(2) == "1.12") {
        return "M3";
      }
      if (d1.toFixed(2) == "1.52" && d2.toFixed(2) == "1.12") {
        return "M4";
      }
      if (d1.toFixed(2) == "1.87" && d2.toFixed(2) == "1.12") {
        return "M5";
      }
      if (d1.toFixed(2) == "2.22" && d2.toFixed(2) == "1.12") {
        return "M6";
      }
      if (d1.toFixed(2) == "2.57" && d2.toFixed(2) == "1.12") {
        return "M7";
      }
      if (d1.toFixed(2) == "2.92" && d2.toFixed(2) == "1.12") {
        return "M8";
      }
      if (d1.toFixed(2) == "3.27" && d2.toFixed(2) == "1.12") {
        return "M9";
      }
      if (d1.toFixed(2) == "3.62" && d2.toFixed(2) == "1.12") {
        return "M10";
      }
      if (d1.toFixed(2) == "3.97" && d2.toFixed(2) == "1.12") {
        return "M11";
      }
      if (d1.toFixed(2) == "4.32" && d2.toFixed(2) == "1.12") {
        return "M12";
      }
      if (d1.toFixed(2) == "4.67" && d2.toFixed(2) == "1.12") {
        return "M13";
      }
      if (d1.toFixed(2) == "5.02" && d2.toFixed(2) == "1.12") {
        return "M14";
      }
      if (d1.toFixed(2) == "5.37" && d2.toFixed(2) == "1.12") {
        return "M15";
      }
    }
    return null;
  }
}