import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusDirective } from './autofocus.directive';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatCardModule } from '@angular/material/card'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatRadioModule } from '@angular/material/radio';







import { TranslateModule } from '@ngx-translate/core';
import { DecisionModalComponent } from '../decision-modal/decision-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';

import { DebounceDirective } from './debounce.directive';

import { InlineSVGModule } from 'ng-inline-svg';
import { PriceFormatPipe } from './price-format.pipe';
import { PosKeyboard } from './pos-keyboard/pos-keyboard';
import { DateFormatPipe } from './date-format.pipe';
import { MatMomentDateModule } from '@angular/material-moment-adapter';



@NgModule({
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        MatProgressSpinnerModule, MatButtonModule, MatInputModule, MatTableModule, MatSelectModule,
        MatSnackBarModule, MatCardModule, MatCheckboxModule, MatIconModule, MatToolbarModule,
        MatSidenavModule, MatListModule, MatExpansionModule, MatChipsModule, MatAutocompleteModule,
        MatTabsModule, MatDialogModule, MatPaginatorModule, MatTooltipModule, MatSliderModule, MatMenuModule, MatBadgeModule, MatGridListModule, MatProgressBarModule,
        TranslateModule,
        InlineSVGModule
    ],
    exports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        MatProgressSpinnerModule, MatButtonModule, MatInputModule, MatTableModule, MatSelectModule,
        MatSnackBarModule, MatCardModule, MatCheckboxModule, MatIconModule, MatToolbarModule,
        MatSidenavModule, MatListModule, MatExpansionModule, MatChipsModule, MatAutocompleteModule,
        MatTabsModule, MatDialogModule, MatPaginatorModule, MatRadioModule, MatTooltipModule, MatSliderModule, MatMenuModule, MatBadgeModule, MatGridListModule, MatProgressBarModule, MatDatepickerModule, MatMomentDateModule,
        TranslateModule,
        InlineSVGModule,
        SpinnerComponent, AutofocusDirective, DebounceDirective,
        DateFormatPipe,
        PriceFormatPipe,
        PosKeyboard
    ],
    declarations: [
        DecisionModalComponent,
        SpinnerComponent,
        AutofocusDirective,
        DebounceDirective,
        PriceFormatPipe,
        PosKeyboard,
        DateFormatPipe
    ]
})
export class SharedModulesModule { }
