<div>

  <mat-toolbar color="primary" [style.border-bottom]="'3px solid'+color">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="headerTitle" routerLink="/app/home">{{username}}
      <span> - {{ title | translate }} </span>
    </h1>
    <h2 class="headerTitle">

    </h2>
    <div style="  WIDTH: 100%; text-align: right; margin-right: 21px;">ENODE</div>
  </mat-toolbar>

  <mat-sidenav-container class="main-container">


    <mat-sidenav #sidenav [mode]="mode.value" [(opened)]="openedSidenav">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node (click)="sidenav.toggle(); handleNode(node)"
          style="cursor: pointer;font-size: 1.2em;color: #536c79;border-bottom: rgb(214, 214, 214) solid 1px;"
          *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <span style="width: 30px;height: 30px;margin: 5px;margin-left: 25px;"
            [inlineSVG]="'/assets/images/'+node.filename.split('|')[1]+'.svg'"></span>
          {{node.filename.split("|")[0] | translate}}
        </mat-tree-node>
        <mat-tree-node
          style="cursor: pointer;font-size: 1.2em;color: #536c79;border-bottom: rgb(214, 214, 214) solid 1px;"
          *matTreeNodeDef="let node;when: hasChild" matTreeNodeToggle matTreeNodePadding>

          <mat-icon style="width:20px">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
          <span style="width:30px;height: 30px;margin:5px;"
            [inlineSVG]="'/assets/images/'+node.filename.split('|')[1]+'.svg'"></span>
          {{node.filename.split("|")[0] | translate}}

        </mat-tree-node>

      </mat-tree>
    </mat-sidenav>

    <mat-sidenav-content>

      <div *ngIf="loading" id="preloader">
        <div id="loader"></div>
        <div id="message">SE ADUC DATE. VA RUGAM ASTEPTATI!</div>
      </div>

      <!-- <p-sidebar  [(visible)]="loading" [fullScreen]="true"  [showCloseIcon]="false">
        <div style="    width: 100%;
        margin-top: 300px;
        font-size: 36px;
        color: #536c79;
        text-align: center;
        font-family: 'CarettaOffice';">SE ADUC DATE!</div>
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
      </p-sidebar> -->

      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>


  <!-- Import monetar data -->
  <p-dialog styleClass="dialog-constants" (onShow)="true" [(visible)]="openMonetar" showEffect="fade" resizable="false"
    appendTo="body" [draggable]="false" [closable]="false" [modal]="true">
    <p-header>
      <span class="dialog-title">{{'salesAssistant.import_casa_marcat'
        | translate}}</span>
      <div class="closeSvgRight" (click)="openMonetar = !openMonetar" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>
    <button color="primary" (click)="importMonetar()" mat-raised-button class="darkBlue"
      style="margin:0 auto;">{{'salesAssistant.import_casa_marcat'
      | translate}}</button>
  </p-dialog>

  <!-- Generate Z modal -->
  <p-dialog styleClass="dialog-constants" (onShow)="true" [(visible)]="openZ" showEffect="fade" resizable="false"
    appendTo="body" [draggable]="false" [closable]="false" [modal]="true">
    <p-header>
      <span class="dialog-title">{{'menu.generateZ' | translate}}</span>
      <div class="closeSvgRight" (click)="openZ = !openZ" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>
    <div style="text-align: center">
      <button class="mat-icon-button">
        <a [href]="sanitize(zRaportIntent)"><span style="width: 40px;" aria-label="Print"
            [inlineSVG]="'/assets/images/z.svg'"></span></a>
      </button>
    </div>
  </p-dialog>


  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#ffffff" type="ball-fussion"></ngx-spinner>

  <p-toast [style]="{ marginTop: '30px' }"></p-toast>
</div>