<div style="display:flex;" *ngIf=" offerData?.topCategories?.length>0">

  <div style="margin: 0 0 0 auto">
  </div>
  <div>
    <!---Start SEARCH AREA---->
    <div *ngIf="f2" class="searchArea">
      <div class="p-mt-3 p-mb-2">
        <span class="p-inputgroup">
          <input placeholder="Cautati articole" inputId="street" [style]="{'text-align':'left','width':'100%'}"
            type="text" pInputText [(ngModel)]="searchText" (input)="onSearchChange($event.target.value)" />
          <button (click)="searchText='';onSearchChange('')" type="button" style=" background: #689F38;border: 0px;"
            pButton pRipple icon="pi pi-times" styleClass="p-button-danger"></button>
        </span>
      </div>
      <div class="result" *ngIf="searchText && searchText.length>2 && foundArticles && foundArticles.length==0"
        style="    color: #536c79;  font-size: 20px;  text-align: center;">
        Nu au fost gasite articole cu numele precizat!
      </div>
      <div class="result" *ngIf="searchText && searchText.length>2 && foundArticles && foundArticles.length>0">
        <table id="resultsTable" style="width:100%">
          <tr style="font-size:14px;">
            <th class="name">Nume</th>
            <th class="unitMeasure">UM</th>
            <th class="quantity">Cant.</th>

            <th class="price">Pret fara TVA</th>
            <th class="listPrice">Pret Lista cu TVA</th>
            <th class="listPrice">Pret Lista fara TVA</th>
            <th class="listPrice">Subtotal</th>
            <th class="quantity">Stoc</th>
            <th class="quantity">INFO</th>
            <th *ngIf="debug" class="listPrice">Prod prop</th>
            <th *ngIf="debug" class="listPrice">Ext</th>
            <th *ngIf="debug" class="listPrice">Gest</th>
          </tr>
          <tr *ngFor="let art of foundArticles; let itemI = index">
            <td
              [ngClass]="(art.data[0]?.agentPrice?art.data[0].agentPrice:0)<(art?.data[0]?.agentMinimumPrice?art.data[0].agentMinimumPrice:9999) && art?.data[0]?.quantity ?'name selectedItemPriceProblem':(art?.data[0]?.quantity ?'selectedItem name':'name')">
              {{art.articleName}}
            </td>
            <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
            </td>
            <td style="background-color: #CCffCC;">
              <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
              <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="offerData.status=='COMANDA'" style="width: 100%; "
                (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity" type="number">
            </td>

            <td class="price">
              <!-- [disabled]="!differentClient" -->
              <input [disabled]="offerData.status=='COMANDA'" [ngClass]="art.invalidPrice?'alert':''"
                style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice" type="number">
            </td>
            <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
              {{round2Decimals(art.data[0].LISTA)}}
            </td>

            <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
              {{round2Decimals(art.data[0].LISTA/1.19)}}
            </td>
            <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
              {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
            </td>
            <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
              {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
            </td>

            <td class="infoBtn" (click)="getStoc(art.data[0])">
              <div style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
            </td>
            <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
            <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
            <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

        </table>
      </div>
    </div>
    <!---END Display Articles in search table---->

    <!---START Display Articles that are in shopping bag accordion---->
    <div *ngIf="f1" style="margin:5px; width:750px" class="orderedArticles">
      <mat-card>
        <div *ngIf="offerData.status!='COMANDA'" class="categoryTitle" style="text-align: center">Articole selectate
          pentru ofertare</div>
        <div *ngIf="offerData.status=='COMANDA'" class="categoryTitle" style="text-align: center">Articole din comanda
        </div>

        <table id="customers" style="width:100%">
          <tr style="font-size:14px;">
            <th class="name">Nume</th>
            <th class="unitMeasure">UM</th>
            <th class="quantity">Cant.</th>
            <th class="price">Pret fara TVA</th>
            <th class="listPrice">Pret Lista cu TVA</th>
            <th class="listPrice">Pret Lista fara TVA</th>
            <th class="listPrice">Subtotal</th>
            <th class="quantity">Stoc</th>
            <th class="quantity">INFO</th>
            <th *ngIf="debug" class="listPrice">Prod prop</th>
            <th *ngIf="debug" class="listPrice">Ext</th>
            <th *ngIf="debug" class="listPrice">Gest</th>
          </tr>
          <ng-template ngFor let-topcategory [ngForOf]="offerData.topCategories">
            <ng-template ngFor let-item [ngForOf]="topcategory.categories">
              <tr *ngFor="let art of item.articles; let itemI = index">
                <ng-container
                  *ngIf="art.data[0].quantity && art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                  <td
                    [ngClass]="(art.data[0].agentPrice?art.data[0].agentPrice:0)<art.data[0].agentMinimumPrice && art.data[0].quantity ?'name selectedItemPriceProblem':(art.data[0].quantity ?'selectedItem name':'name')">
                    {{art.articleName}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
                  </td>
                  <td style="background-color: #CCffCC;">
                    <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                      {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
                    <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="offerData.status=='COMANDA'"
                      style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity"
                      type="number">
                  </td>

                  <td class="price">
                    <input [disabled]="offerData.status=='COMANDA'" [ngClass]="art.invalidPrice?'alert':''"
                      style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                      type="number">
                  </td>
                  <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                    {{round2Decimals(art.data[0].LISTA)}}
                  </td>
                  <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                    {{round2Decimals(art.data[0].LISTA/1.19)}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                    {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                    {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
                  </td>
                  <td *ngIf="art.data[0].PRODUSPROPRIU!=2" class="infoBtn" (click)="getStoc(art.data[0])">
                    <div style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
                  </td>
                  <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                  <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                  <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                </ng-container>

                <ng-container *ngIf="art.data.length!=1 && art.data[0].quantity">
                  <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                    {{art.articleName}}
                  </td>
                  <td *ngIf="art.data[0].PROMOTIE==1" [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                    {{art.articleName}}
                  </td>
                  <td style="    text-align: center;color: #536c79;font-weight: bold;">
                    {{round2Decimals(getTotalQuantity(art))}} {{art.data[0].PRODUSPROPRIU}}
                  </td>
                  <td class="unitMeasure">{{art.data[0].UM}}</td>
                  <!-- [disabled]="differentClient" -->
                  <td class="price">
                    <input [disabled]="!differentClient" [ngClass]="art.invalidPrice?'alert':''"
                      (keyup)="propagateQuantity(art.data);recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                      style="width: 100%;" type="number">
                  </td>
                  <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                  <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                    {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                  </td>


                  <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                  <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                  <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

                </ng-container>
              </tr>
            </ng-template>
          </ng-template>
        </table>
      </mat-card>



    </div>
    <!---END Display Articles that are in shopping bag accordion---->


    <mat-accordion *ngIf="f3 &&( !searchText||searchText.length<=2)">
      <mat-expansion-panel (opened)="topCategory.open = true" (closed)="topCategory.open = false"
        *ngFor="let topCategory of offerData.topCategories " style="margin:5px; width:750px;">

        <mat-expansion-panel-header style="padding:0 15px;" [collapsedHeight]="'30px'" [expandedHeight]="'35px'">
          <mat-panel-title>
            <div class="categoryTitle" style="text-align: center">{{topCategory.topCategoryName}}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>



        <mat-accordion *ngIf="f3 && (!searchText||  searchText.length<=2) && topCategory.open ">
          <div *ngIf="topCategory.categories.length>1">
            <div *ngFor="let item of topCategory.categories">
              <mat-expansion-panel (opened)="item.open = true" (closed)="item.open = false" style="margin:1px;"
                id="{{item.category}}">
                <mat-expansion-panel-header style="padding:0 10px;" [collapsedHeight]="'30px'"
                  [expandedHeight]="'35px'">
                  <mat-panel-title>
                    <div class="categoryTitle" style="text-align: center">{{item.category}}
                      {{item.category=="SISTEM PLUVIAL"?pluvialType:""}}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <table id="customers" style="width:100%">
                  <tr style="font-size:14px;">
                    <th class="name">Nume</th>
                    <th class="unitMeasure">UM</th>

                    <th class="quantity">Cant.</th>

                    <th class="price">
                      <div>Pret</div>
                      <div>fara TVA</div>
                    </th>
                    <th class="listPrice">
                      <div>Pret Lista </div>
                      <div>cu TVA </div>
                    </th>

                    <th class="listPrice">Pret Lista fara TVA</th>
                    <th class="listPrice">Subtotal</th>
                    <th class="quantity">Stoc</th>
                    <th class="quantity">INFO</th>
                    <th *ngIf="debug" class="listPrice">Prod prop</th>
                    <th *ngIf="debug" class="listPrice">Ext</th>
                    <th *ngIf="debug" class="listPrice">Gest</th>
                  </tr>
                  <ng-container *ngIf="item.open">
                    <tr *ngFor="let art of item.articles; let itemI = index">
                      <ng-container
                        *ngIf="art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                        <td
                          [ngClass]="(art.data[0].agentPrice?art.data[0].agentPrice:0)<art.data[0].agentMinimumPrice && art.data[0].quantity ?'name selectedItemPriceProblem':(art.data[0].quantity ?'selectedItem name':'name')">
                          {{art.articleName}}
                        </td>
                        <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
                        </td>

                        <td style="background-color: #CCffCC;text-align: center;">
                          <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                            {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
                          <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="offerData.status=='COMANDA'"
                            style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity"
                            type="number">
                        </td>


                        <td class="price">
                          <input [disabled]="offerData.status=='COMANDA'" [ngClass]="art.invalidPrice?'alert':''"
                            style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                            type="number">
                        </td>
                        <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                          {{round2Decimals(art.data[0].LISTA)}}
                        </td>
                        <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                          {{round2Decimals(art.data[0].LISTA/1.19)}}
                        </td>
                        <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                          {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
                        </td>
                        <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                          {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
                        </td>

                        <td>
                          <div *ngIf="art.data[0].PRODUSPROPRIU!=2" class="infoBtn" (click)="getStoc(art.data[0])"
                            style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
                        </td>
                        <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                        <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                        <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                        <!-- <td>{{art.data[0].STOC}}</td> -->

                      </ng-container>
                    </tr>
                  </ng-container>
                </table>


              </mat-expansion-panel>
            </div>
          </div>
          <div *ngIf="topCategory.categories.length==1">
            <div *ngFor="let item of topCategory.categories">

              <table id="customers" style="width:100%">
                <tr style="font-size:14px;">
                  <th class="name">Nume</th>
                  <th class="unitMeasure">UM</th>

                  <th class="quantity">Cant.</th>

                  <th class="price">Pret fara TVA</th>
                  <th class="listPrice">Pret Lista cu TVA</th>
                  <th class="listPrice">Pret Lista fara TVA</th>
                  <th class="listPrice">Subtotal</th>
                  <th class="quantity">Stoc</th>
                  <th class="quantity">INFO</th>
                  <th *ngIf="debug" class="listPrice">Prod prop</th>
                  <th *ngIf="debug" class="listPrice">Ext</th>
                  <th *ngIf="debug" class="listPrice">Gest</th>
                </tr>
                <tr *ngFor="let art of item.articles; let itemI = index">
                  <ng-container
                    *ngIf="art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                    <td
                      [ngClass]="(art.data[0].agentPrice?art.data[0].agentPrice:0)<art.data[0].agentMinimumPrice && art.data[0].quantity ?'name selectedItemPriceProblem':(art.data[0].quantity ?'selectedItem name':'name')">
                      {{art.articleName}}
                    </td>
                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
                    </td>

                    <td style="background-color: #CCffCC;text-align: center;">
                      <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                        {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
                      <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="offerData.status=='COMANDA'"
                        style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity"
                        type="number">
                    </td>


                    <td class="price">
                      <input [disabled]="offerData.status=='COMANDA'" [ngClass]="art.invalidPrice?'alert':''"
                        style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                        type="number">
                    </td>
                    <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                      {{round2Decimals(art.data[0].LISTA)}}
                    </td>
                    <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                      {{round2Decimals(art.data[0].LISTA/1.19)}}
                    </td>
                    <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                      {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
                    </td>
                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                      {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
                    </td>

                    <td>
                      <div *ngIf="art.data[0].PRODUSPROPRIU!=2" class="infoBtn" (click)="getStoc(art.data[0])"
                        style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
                    </td>
                    <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                    <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                    <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                    <!-- <td>{{art.data[0].STOC}}</td> -->

                  </ng-container>

                  <ng-container *ngIf="art.data.length!=1 ">
                    <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                      {{art.articleName}}
                    </td>
                    <td *ngIf="art.data[0].PROMOTIE==1"
                      [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                      {{art.articleName}}
                    </td>
                    <td style="    text-align: center;color: #536c79;font-weight: bold;">
                      {{round2Decimals(getTotalQuantity(art))}}
                    </td>
                    <td class="unitMeasure">{{art.data[0].UM}}</td>
                    <!-- [disabled]="differentClient" -->
                    <td class="price">
                      <input [ngClass]="art.invalidPrice?'alert':''"
                        (keyup)="propagateQuantity(art.data);recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                        style="width: 100%;" type="number">

                    </td>
                    <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                    <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                      {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                    </td>

                    <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                    <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                    <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

                  </ng-container>
                </tr>

              </table>

            </div>
          </div>
        </mat-accordion>

      </mat-expansion-panel>
    </mat-accordion>
    <!-- aaaaaaaaaaaaaaaaaaaaaaaaaa -->

  </div>

  <div class="noprint" style="width:300px;margin: 0 auto 0 0;">
    <div style="margin:5px;width:275px;overflow: auto;">

      <!-- <mat-card style=" background: #2f404a;">
          <div style="color:#fff;font-size:16px; margin-top:10px">
            <div style="color:#3ac47d;text-align: center;font-size:22px; margin-top:5px" *ngIf="offerData">
              Total: {{totalPrice}} lei
            </div>
          </div>
          <div style="display:flex">
            <div (click)="showAdaos=!showAdaos" class="actionSvg delete" style="margin:auto"
              [inlineSVG]="'/assets/images/cash_earned.svg'"></div>
          
          </div>
          <div *ngIf="showAdaos" style="color:#27A2DB;text-align: center;font-size:18px; margin-top:5px">
            {{adaosValue}} lei | {{adaosPercent}} %</div>
        </mat-card> -->
      <mat-card style="padding: 5px;">
        <div style="display:flex;">
          <div style="width:50%">
            <div style="color:black;text-align: right;font-size:18px; margin-top:5px;margin-right: 15px;"
              *ngIf="offerData">
              {{totalPrice?totalPrice:0}} lei
            </div>
            <div style="display:flex;float: right;margin-right: 15px;">
              <div style="color:black; margin-top: 7px;"> {{round2Decimals(totalOfferWeight)}}</div>
              <!-- <div style="margin: auto auto auto 3px;" class="routeInfo kg" [inlineSVG]="'/assets/images/weight.svg'">
                </div> -->
              <img style="width: 24px ;height: 24px ;" src="/assets/images/kg.png" alt="Logo" />
            </div>
          </div>
          <div *ngIf="f2" style="width:45%">
            <!-- *ngIf="offerData.status!='COMANDA'" -->
            <div (click)="f1=true;f2=false;f3=false;"
              style="text-align: center;padding:10px;color: white;font-size: 14px;background:#333">
              <div><b>Vezi <br> COMANDA</b></div>
            </div>
          </div>

          <div *ngIf="!f2" style="width:45%">
            <!-- *ngIf="offerData.status!='COMANDA'" -->
            <div (click)="f1=false;f2=true;f3=true;"
              style="text-align: center;padding:10px;color: white;font-size: 14px;background:#333">
              <div><b>Vezi <br> articole</b></div>
            </div>
          </div>

          <!-- *ngIf="offerData.status!='COMANDA'" -->
          <div
            *ngIf="offerData.client && (offerData.client.soldValue+totalPrice<=offerData.client.creditValue && !invalidPrice)"
            class="actionSvg delete" style="width:45px;" [inlineSVG]="'/assets/images/tick.svg'"></div>
          <div
            *ngIf="offerData.client && !(offerData.client.soldValue+totalPrice<=offerData.client.creditValue && !invalidPrice)"
            class="actionSvg delete" style="width:65px;" [inlineSVG]="'/assets/images/error.svg'"></div>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="offerData.client && offerData.client.name" style="margin:5px;color: #536c79;">Sold:
          {{offerData.client.soldValue |
          number:'.0-0':'ro-RO'}} </div>
        <div *ngIf="offerData.client && offerData.client.name" style="margin:5px;color: #536c79;">Limita de credit:
          {{round2Decimals(offerData.client.creditValue) | number:'.0-0':'ro-RO'}}</div>

        <div>{{offerData.client.characterizationName}}</div>
      </mat-card>


      <mat-card style="margin:10px 0;">
        <div *ngIf="offerData.status=='COMANDA'">
          <div style="margin: 0 5px 10px 5px;font-size: 28px;font-weight: bold;text-align: center;color: #3ac47d;">
            {{offerData.data && !offerData.returned?offerData.data.commandSeries:'RETUR '}}
            {{offerData.data?offerData.data.commandNumber:''}}</div>
          <div style="display:flex;justify-content: center;margin-bottom: 10px;">
            <div (click)="cancelCommandFlag=true" style="padding:10px;color: #fff;font-size: 16px;background:red">
              Anuleaza comanda
            </div>
          </div>

        </div>

        <button *ngIf="offerData&&offerData.status!='COMANDA'" (click)="showClientsModal=true" pButton pRipple
          type="button" label="Alege din lista de clienti"
          class="p-button-success p-mt-2 finishAction customButton noMargin"></button>



        <p-divider *ngIf=" offerData.status=='COMANDA' && offerData.client &&offerData.client.name">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-user p-mr-2"></i>
            <span>Client</span>
          </div>
        </p-divider>
        <div style="color: #003048; font-size: 20px;text-align: center; padding: 10px 0;"
          *ngIf="offerData.client &&offerData.client.name ">
          <b>{{offerData.client.name}}</b>
        </div>

        <div *ngIf="offerData.client &&offerData.client.phone" class="p-mt-2">
          <p-divider>
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-phone p-mr-2"></i>
              <span>Telefon</span>
            </div>
          </p-divider>
          <div style="color:#003048; font-size: 17px;text-align: center; padding: 3px 0;font-family: 'CarettaOffice';">
            {{offerData.client.phone}}</div>
        </div>

        <p-divider *ngIf="offerData.client &&offerData.client.name">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-map-marker p-mr-2"></i>
            <span>Adresa</span>
          </div>
        </p-divider>

        <div *ngIf="offerData.client &&offerData.client.name"
          style="color:#003048;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
          {{offerData?.client?.address?.address}} {{offerData?.client?.address?.city}}
          {{offerData?.client?.address?.conunty}}</div>

        <div *ngIf="offerData.client &&offerData.client.email" class="p-mt-2">
          <p-divider>
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-envelope p-mr-2"></i>
              <span>Email</span>
            </div>
          </p-divider>
          <div style="color:#003048; font-size: 17px;text-align: center; padding: 3px 0;font-family: 'CarettaOffice';">
            {{offerData.client.email}}</div>
        </div>



        <button *ngIf="offerData&&offerData.status!='COMANDA'" (click)="showPaymentModal=true" pButton pRipple
          type="button" label="Seteaza modalitatea de plata"
          class="p-button-success p-mt-2 finishAction customButton noMargin"></button>

        <div>
          <div *ngIf="offerData.isAdvancePayment" style="margin-top:5px;">Plata avans {{offerData.advancePaymentValue}}
          </div>
          <div *ngIf="offerData.paymentType" style="margin-top:5px;">Modalitate de plata: {{offerData.paymentType}}
          </div>
          <div *ngIf="offerData.finishType" style="margin-top:5px;">Finalizare: {{offerData.finishType}}</div>
          <div *ngIf="offerData.observations" style="margin-top:5px;">Observatii: {{offerData.observations}}</div>
        </div>

        <!-- <div class="creditLimit">
            <span>Limita de credit: 1829384 Lei</span>
          </div>
          <div class="creditSold">
            <span>Sold actual: 567890 Lei</span>
          </div> -->

        <!-- <div class="creditStatus stop">
            <span>Status cont: STOP</span>
          </div> -->
      </mat-card>
      <!-- <div class="creditStatus ok">
                <div style="font-size:14px">Limita de credit: 60000 Lei</div>
                <div style="font-size:14px">Sold actual: 4580 Lei</div>
                <div>Status cont: OK</div>
              </div> -->

      <mat-card style="margin:10px 0;">
        <div style="color: #536c79; font-size: 20px;text-align: center;" *ngIf="offerData.client">
          <b>Adresa de livrare si transport</b>
        </div>

        <div *ngIf="offerData.status!='COMANDA'" (click)="showAlternativeDelivery=true"
          style="display:flex;justify-content: center;align-items:center;margin:10px 0;padding:10px;color: white;font-size: 16px;background:#333">
          <div>Seteaza adresa si transportul</div>
        </div>


        <div>
          <div *ngIf="offerData.deliverAddress">Adresa livrare {{offerData.deliverAddress}}</div>
          <div *ngIf="offerData.deliverDate">Termen livrare {{offerData.deliverDate | date: 'dd/MM/yyyy' }}</div>
          <div *ngIf="offerData.pickedByClient">Ridica clientul</div>
          <div *ngIf="offerData.transportType">Tip transport: {{offerData.transportType}}</div>
        </div>



      </mat-card>

      <mat-card *ngIf="offerData.status!='COMANDA'" style="margin:10px 10px">
        <div style="color: #536c79; font-size: 20px;text-align: center;" *ngIf="offerData.client">
          <b>FINALIZARE</b>
        </div>
        <button (click)="saveOffer('OFERTA',null)" pButton pRipple type="button" label="SALVEAZA OFERTA"
          class="p-button-success finishAction"></button>

        <button (click)="finishCommand()" pButton pRipple type="button" label="PLASEAZA COMANDA"
          class="p-button-success finishAction"></button>

        <button *ngIf="offerData&&offerData.status!='COMANDA'" (click)="generateOfferPdf()" pButton pRipple
          type="button" icon="pi pi-file-pdf"
          [label]="offerData.status!='COMANDA'?'Generează Oferta':'Generează Proforma'"
          class="p-button-success p-mt-2 finishAction customButton noMargin"></button>


        <button (click)="sendOfferEmail()" pButton pRipple type="button" label="TRIMITE OFERTA EMAIL"
          class="p-button-success finishAction"></button>
      </mat-card>
      <div class="p-text-bold" style="text-align:center;color:white;font-family: CarettaOffice;font-size: 22px;">V
        {{version}}</div>

    </div>
  </div>
</div>



<p-dialog [closable]="false" [style]="{width: '900px',height:'85%'}" [modal]="true" [draggable]="false"
  [(visible)]="showClientsModal">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Clienti!</span>
    <div class="closeSvgRight" (click)="showClientsModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>
  <div style="display:flex">
  </div>

  <table id="customers" style="width:100%">
    <tr style="font-size:14px;">
      <th class="name">
        <mat-form-field style="width:100%" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientNameSearch" (onDebounce)="onNameInputChange()" matInput
            placeholder="Nume">
        </mat-form-field>
      </th>
      <th class="quantity">
        <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientPhoneSearch" (onDebounce)="onPhoneInputChange()" matInput
            placeholder="Telefon">
        </mat-form-field>
      </th>
      <th class="unitMeasure">
        <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientFiscalCodeSearch" (onDebounce)="onFiscalCodeChange()" matInput
            placeholder="CNP/CUI">
        </mat-form-field>
      </th>
      <th class="unitMeasure">
        Reg. Com.
      </th>
      <th class="unitMeasure">
        Adresa
      </th>
      <!-- <th class="unitMeasure">
          Localitate
        </th> -->
      <!-- <th class="unitMeasure">
          Judet
        </th>
        <th class="unitMeasure">
          Localitate
        </th> -->
      <th class="unitMeasure">
        <div (click)="addNewClient=true" matTooltip="Adaugare client nou" class="editSvg"
          [inlineSVG]="'/assets/images/add.svg'"></div>
      </th>
    </tr>
    <tr *ngFor="let client of clients">
      <td> {{client.DEN_PART}} </td>
      <td> {{client.TELEFON}} </td>
      <td> {{client.CODFISCAL}} </td>
      <td> {{client.REGCOM}} </td>
      <!-- <td> {{client.DEN_LOCALIT}} {{client.JUDET}} </td> -->
      <td *ngIf="client.PERSOANAFIZICA==0">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.DEN_LOCALIT.toLowerCase()!=client.JUDET.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}</td>
      <td *ngIf="client.PERSOANAFIZICA==1">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.JUDET.toLowerCase()!=client.DEN_LOCALIT.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}
      </td>
      <td>
        <div style="display:flex">
          <!-- <div (click)="prefillEditClientData(client)" matTooltip="Adaugare locatie" class="actionSvg"
              [inlineSVG]="'/assets/images/addlocation.svg'"></div> -->
          <div (click)="prefillEditClientData(client)" matTooltip="Editare locatie" class="actionSvg"
            [inlineSVG]="'/assets/images/edit.svg'"></div>
          <div (click)="prefillSelectedClientData(client)" matTooltip="Confirma" style="margin-left: 5px;"
            class="actionSvg" [inlineSVG]="'/assets/images/confirm.svg'"></div>
        </div>
      </td>
      <!-- <td>{{art.data[0].STOC}}</td> -->
    </tr>

  </table>
</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="addNewClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Adaugare client nou!</span>
    <div class="closeSvgRight" (click)="addNewClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>


  <!-- <mat-form-field class="noLabel" style="width:100%">
      <mat-select [(ngModel)]="newClientData.clientType" required placeholder="Tip Client">
        <mat-option *ngFor="let client of clientTypes" [value]="client">{{client}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->


  <div class="p-mt-1">
    <span class="p-float-label">
      <p-dropdown inputId="clientType" styleClass="p-mb-2" name="value" [options]="clientTypes" [showClear]="true"
        [style]="{'width':'100%'}" [(ngModel)]="newClientData.clientType">
      </p-dropdown>
      <label for="clientType">Tip client</label>
    </span>
  </div>





  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="newClientData.name">
  </mat-form-field>
  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput [placeholder]="newClientData.clientType=='PF'?'CNP':'CUI'"
      [(ngModel)]="newClientData.fiscalCode">
  </mat-form-field>
  <mat-form-field *ngIf="offerData && newClientData.clientType!='PF'" class="noLabel" style="width:100%">
    <input type="text" matInput placeholder="Reg Com" [(ngModel)]="newClientData.tradeRegister">
  </mat-form-field>
  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Telefon" [(ngModel)]="newClientData.phone">
  </mat-form-field>


  <div class=" p-mt-1" *ngIf="counties">
    <span class="p-float-label">
      <p-dropdown appendTo="body" autoDisplayFirst="false" inputId="dropdownCountyClient" styleClass="p-mb-2"
        [options]="counties.rows" [filter]="true" [showClear]="true" [style]="{'width':'100%'}" optionLabel="countyName"
        emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="value.countyName"
        (onChange)="loadLocalities($event)">
      </p-dropdown>
      <label for="dropdownCountyClient">Judet</label>
    </span>
  </div>

  <div class=" p-mt-1" *ngIf="filteredOptions">
    <span class="p-float-label">
      <p-dropdown appendTo="body" autoDisplayFirst="false" inputId="dropdownLocality"
        [(ngModel)]="newClientData.locality" styleClass="p-mb-2" [options]="filteredOptions" [filter]="true"
        [showClear]="true" (onClick)="initLocalities()" [style]="{'width':'100%'}"
        emptyFilterMessage="Nu au fost gasite rezultate!" optionLabel="localityName" filterBy="localityName">
        <ng-template let-loc pTemplate="item">
          <div style="font-size:14px;margin-top:4px">{{loc.label}} <span
              *ngIf="loc.localityName!=loc.village">({{loc.village }})</span></div>
        </ng-template>
      </p-dropdown>
      <label for="dropdownLocality">Oraș/Localitate</label>
    </span>
  </div>



  <div style="display:flex">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input matInput placeholder="Strada" [(ngModel)]="newClientData.street">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Nr." [(ngModel)]="newClientData.number">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Scara" [(ngModel)]="newClientData.scara">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Bloc" [(ngModel)]="newClientData.bloc">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Etaj" [(ngModel)]="newClientData.etaj">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Ap." [(ngModel)]="newClientData.apartament">
    </mat-form-field>

  </div>
  <div (click)="insertNewPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="editClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Editare date client!</span>
    <div class="closeSvgRight" (click)="editClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="editClientData.name">
  </mat-form-field>
  <mat-form-field *ngIf="offerData && !editClientData.fiscalCode" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>
  <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Reg Com" [(ngModel)]="editClientData.tradeRegister">
  </mat-form-field>
  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Telefon" [(ngModel)]="editClientData.phone">
  </mat-form-field>

  <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select [(value)]="selectedCounty" (selectionChange)='loadLocalities($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-form-field style="width:100%" class="example-full-width noLabel">
    <input type="text" [(ngModel)]="editClientData.locality" placeholder="Oraș/Localitate" aria-label="Number" matInput
      [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="editClientData.locality=''">
      <mat-icon>close</mat-icon>
    </button>
    <!-- [displayWith]="displayLocalityName" -->
    <mat-autocomplete #matAutocompleteLocality="matAutocomplete">
      <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
        {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village }})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div style="display:flex">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input matInput placeholder="Strada" [(ngModel)]="editClientData.street">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Nr." [(ngModel)]="editClientData.number">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Scara" [(ngModel)]="editClientData.scara">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Bloc" [(ngModel)]="editClientData.bloc">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Etaj" [(ngModel)]="editClientData.etaj">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Ap." [(ngModel)]="editClientData.apartament">
    </mat-form-field>

  </div>
  <div (click)="editPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{'max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="stockDetail">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Alege cantitatea</span>
    <div class="closeSvgRight" (click)="stockDetail=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <div *ngIf="selectedArticle" style="color: #536c79;font-size: 20px;padding: 7px;">
    {{selectedArticle.DENUMIRE}}</div>

  <table id="customers" style="width:100%">
    <tr style="font-size:14px;">

      <th style="width:150px;padding:5px;">Gestiune</th>

      <th style="width:50px;padding:5px;">Stoc</th>
      <th style="width:50px;padding:5px;">UM</th>
      <th style="width:50px;padding:5px;">Cantitate</th>
    </tr>
    <tr *ngFor="let art of stockDetail">
      <ng-container>
        <td>{{art.managementName}}</td>
        <td> {{art.stock}} </td>
        <td> {{art.measureUnit}} </td>
        <td><input [disabled]="art.managementName!='DEPOZIT'" type="number" [(ngModel)]="art.conversionQuantity"
            style="width: 65px;">
        </td>

      </ng-container>


    </tr>

  </table>
  <button *ngIf="offerData&&offerData.status!='COMANDA'"
    (click)="saveQuantity(selectedArticle,stockDetail);getTotalPrice();" pButton pRipple type="button"
    label="SALVEAZA DATELE" class="p-button-success p-mt-2 finishAction noMargin"></button>

</p-dialog>

<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="cancelCommandFlag">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Anulare comanda
      {{offerData&&offerData.data?offerData.data.commandNumber:''}}</span>
    <div class="closeSvgRight" (click)="cancelCommandFlag=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>
  <div>Doriti anularea comenzii?</div>
  <mat-form-field class="noLabel" style="width:100%">
    <textarea style="height: 90px;" [(ngModel)]="cancelObservations" matInput placeholder="Motiv anulare"></textarea>
  </mat-form-field>
  <div (click)="cancelCommand()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>


<!-- payment type -->
<p-dialog *ngIf="offerData" [closable]="false" [style]="{'width':'355px','max-height': '90%'}" [draggable]="false"
  [modal]="true" [(visible)]="showPaymentModal">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Modalitatea de plata</span>
    <div class="closeSvgRight" (click)="showPaymentModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" *ngIf="offerData && !offerData.returned"
    [(ngModel)]="offerData.isAdvancePayment">Plata avans</mat-checkbox>
  <mat-form-field *ngIf="offerData.isAdvancePayment" class="noLabel" style="width:100%">
    <input [disabled]="offerData.status=='COMANDA'?true:false" type="number" [(ngModel)]="offerData.advancePaymentValue"
      matInput placeholder="Suma avans">
  </mat-form-field>


  <div class=" p-mt-3">
    <div class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" inputId="finishTypes" appendTo="body" [options]="finishTypes" name="value"
        [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="offerData.finishType">
      </p-dropdown>
      <label for="finishTypes">Finalizare</label>
    </div>
  </div>

  <div class=" p-mt-3">
    <div class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" inputId="paymentTypes" appendTo="body" [options]="paymentTypes"
        name="value" [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="offerData.paymentType">
      </p-dropdown>
      <label for="paymentTypes">Modalitate de plata</label>
    </div>
  </div>

  <mat-form-field *ngIf="offerData.paymentType=='op' || offerData.paymentType=='cec'|| offerData.paymentType=='bo'"
    class="noLabel" style="width:100%">
    <input [disabled]="offerData.status=='COMANDA'?true:false" type="number" [(ngModel)]="offerData.paymentTerm"
      matInput placeholder="Termen zile">
  </mat-form-field>


  <mat-form-field class="noLabel" style="width:100%">
    <textarea [disabled]="offerData.status=='COMANDA'?true:false" style="height: 90px;"
      [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.observations" matInput
      placeholder="Observatii"></textarea>
  </mat-form-field>

  <button *ngIf="offerData&&offerData.status!='COMANDA'" (click)="showPaymentModal=false" pButton pRipple type="button"
    label="SALVEAZA DATELE" class="p-button-success p-mt-2 finishAction noMargin"></button>
</p-dialog>


<!-- Adresa livrare alternativa -->
<p-dialog *ngIf="offerData" [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false"
  [modal]="true" [(visible)]="showAlternativeDelivery">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Livrare si transport!</span>
    <div class="closeSvgRight" (click)="showAlternativeDelivery=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <div class=" p-mt-4" *ngIf="counties">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownCountyClient" [options]="counties.rows"
        [filter]="true" [showClear]="true" [style]="{'width':'100%'}" optionLabel="countyName"
        emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="countyName" (onChange)="setCountyDelivery($event)">
      </p-dropdown>
      <label for="dropdownCountyClient">Judet</label>
    </span>
  </div>

  <div class=" p-mt-4" *ngIf="filteredOptions">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownLocality"
        [(ngModel)]="newClientData.locality" [options]="filteredOptions" [filter]="true" [showClear]="true"
        (onClick)="initLocalities()" [style]="{'width':'100%'}" emptyFilterMessage="Nu au fost gasite rezultate!"
        optionLabel="localityName" filterBy="localityName" (onChange)="setLocalityDelivery($event)">
        <ng-template let-loc pTemplate="item">
          <div style="font-size:14px;margin-top:4px"> {{loc.localityName}} <span
              *ngIf="loc && loc.localityName!=loc.village">({{loc.village }})</span></div>
        </ng-template>
      </p-dropdown>
      <label for="dropdownLocality">Oraș/Localitate</label>
    </span>
  </div>

  <mat-form-field class="noLabel" style="width:100%">
    <input [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.deliverDate" matInput
      [min]="minDate" [matDatepicker]="picker"
      [placeholder]="offerData.returned?'Data estimativa de retur':'Termen livrare'">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <!-- <div class="p-field p-col-12 p-md-4">
      <label for="spanish">Spanish</label>
      <p-calendar  [locale]="es"  inputId="spanish"></p-calendar>
    </div> -->

  Transport

  <div style="margin:10px;">
    <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.pickedByClient">Ridica
      Clientul
    </mat-checkbox>
  </div>

  <div *ngIf="offerData.pickedByClient" class="p-mt-2">
    <span class="p-float-label">
      <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="offerData.driverName" />
      <label for="street">Sofer</label>
    </span>
  </div>
  <div *ngIf="offerData.pickedByClient" class="p-mt-2">
    <span class="p-float-label">
      <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="offerData.carNumber" />
      <label for="street">Numar Masina</label>
    </span>
  </div>
  <div *ngIf="offerData.pickedByClient" class="p-mt-2">
    <span class="p-float-label">
      <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="offerData.trailerNumber" />
      <label for="street">Numar Remorca</label>
    </span>
  </div>

  <button *ngIf="offerData&&offerData.status!='COMANDA'" (click)="alternativeDeliveryAdress()" pButton pRipple
    type="button" label="SALVEAZA DATELE" class="p-button-success p-mt-2 finishAction noMargin"></button>

</p-dialog>

<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="large" color="#7fc3d6" type="ball-circus"></ngx-spinner>