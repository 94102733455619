import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '../../environments/environment';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable, of as observableOf } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { TreeStructure, LinkNode, FlatNode } from './tree-structure.service'

import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { NgZone, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { AboutComponent } from '../about/about.component';
import { ProductionUploadComponent } from '../production/production-upload/production-upload.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalesAssistantScanService } from '../sales-assistant/sales-assistant-scan/sales-assistant-scan.service';
import { NotifyService } from '../helpers/notify.service';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.css'],
  providers: [TreeStructure]
})
export class MainContainerComponent {
  @ViewChild('spinnerElement')
  spinnerElement: ElementRef
  mode = new UntypedFormControl('over');
  color = environment.color;
  isRoot = false;
  isAdmin = false;
  title = '';
  appTitle = '';
  username = '';
  company = '';
  client = '';
  clientName;
  openedSidenav = false;
  openMonetar = false;
  openZ = false;
  blockImportButton = false;
  openSynchronize = false;
  showCloseButton = false;
  showRefreshButton = false;
  openExportSynchronize = false;
  forceImport = false;
  alert: any = false;

  zRaportIntent = `intent://scan/#Intent;scheme=zxing;package=com.datecs.demo;S.printType=zRaport;end`;

  @ViewChild("sidenav") sidenavComponent: any;

  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<LinkNode, FlatNode>;
  dataSource: MatTreeFlatDataSource<LinkNode, FlatNode>;
  loading=false;
  constructor(database: TreeStructure,
    @Inject('LOCALSTORAGE') private store: any,
    public dialog: MatDialog,
    public sidenav: MatSidenavModule,
    private router: Router,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService,
    private notify: NotifyService,
    public salesAssistantScanService: SalesAssistantScanService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer) {

    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
      this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<FlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => this.dataSource.data = data);

    router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    let user: any = this.store.get("localuser");
    let company: any = this.store.get("company");
    let client: any = this.store.get("client");

    this.username = user != null ? user.username : '';
    this.company = company !== null ? company.uid : '';
    this.client = client !== null ? client.uid : '';
    this.clientName = client !== null ? client.name : '';

    if (user != null && user.menu.length == 1 && user.menu[0] === 'ALL') {
      this.isRoot = true;
    } else if (user != null && user.menu.length == 1 && user.menu[0] === 'ADM') {
      this.isAdmin = true;
    }
    if (this.isAdmin || this.isRoot) {
      this.appTitle = "app.manage"
    } else {
      this.appTitle = "app.title";
    }
    this.title = "app.title";

  }

  // menu helper functions
  transformer = (node: LinkNode, level: number) => {
    return new FlatNode(!!node.children, node.filename, level, node.link);
  }

  private _getLevel = (node: FlatNode) => node.level;

  private _isExpandable = (node: FlatNode) => node.expandable;

  private _getChildren = (node: LinkNode): Observable<LinkNode[]> => observableOf(node.children);

  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  handleNode(node) {
    if (node.link.indexOf("()") > 0) {
      eval("this." + node.link);
    } else {
      this.router.navigateByUrl(node.link);
    }
  }

  openAboutPopup() {
    this.openedSidenav = false;
    const data = {
      "client": this.client,
      "company": this.company,
      "appTitle": this.appTitle,
      "clientName": this.clientName,
      "username": this.username
    };

    this.dialog.open(AboutComponent, {
      width: 'auto',
      disableClose: true,
      data: data
    });
  }

  openImportPopup() {
    this.openedSidenav = false;
    const data = {
      "client": this.client,
      "company": this.company,
      "appTitle": this.appTitle,
      "clientName": this.clientName,
      "username": this.username
    };

    this.dialog.open(ProductionUploadComponent, {
      width: 'auto',
      disableClose: true,
      data: data
    });
  }

  openMonetarPopup() {
    this.salesAssistantScanService.getMonetarItems().subscribe((result: any) => {
      if (result.length > 0) {
        this.openMonetar = true;
      } else {
        this.notify.warning("salesAssistant.noMonetary");
      }
    })
  }

  openZPopup() {
    this.openZ = true;
  }

  forceImportFct() {
    this.forceImport = true;
    this.alert = false;
  }

  openSynchronizePopup() {
    this.alert = false;
    this.forceImport = false;
    this.showCloseButton = false;
    this.showRefreshButton = false;
    this.openSynchronize = true;
  }

  openExportSynchronizePopup() {
    this.alert = false;
    this.showCloseButton = false;
    this.showRefreshButton = false;
    this.openExportSynchronize = true;
  }

  importMonetar() {
    this.blockImportButton = true;
    this.salesAssistantScanService.importMonetar().subscribe(result => {
      this.blockImportButton = false;
      if (result['result'] == "ok") {
        this.notify.success("salesAssistant.success_import_monetar");
        this.openMonetar = false;
      } else {
        this.notify.error("salesAssistant.monetaryImportFailed");
      }
    });
  }

  checkPermision(permission): boolean {
    let permissions = this.store.get("localuser").menu;
    return permissions.includes(permission);
  }

  setTitle(title) {
    this.title = title;
  }

  finishImport(event) {
    if (event.finish) {
      this.showCloseButton = true;
      if (this.router.url == "/app/offline/assistant") {
        this.showRefreshButton = true;
      }
    }
  }

  closeAndRefresh() {
    location.reload();
  }
  showAlert(alert) {
    if (alert && (alert.alert || alert.noData)) {
      this.alert = alert.alert;
    } else {
      this.alert = true;
    }
  }
  spinnerShow() {
    this.spinner.show();
  }
  spinnerHide() {
    this.spinner.hide();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
