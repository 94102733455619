import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class CompanyListModalService {
    serviceUrl = environment.serviceUrl;
    constructor(private http: HttpClient, @Inject('LOCALSTORAGE') private store: any) {
    }

    getCompanyList() {
        const client = this.store.get('client');
        const localuser = this.store.get('localuser');
        const url = `${this.serviceUrl}/admin/companies/user/${client.uid}/${localuser.username}`;
        return this.http.get(url);
    }
}
