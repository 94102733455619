import { NgModule } from '@angular/core';

import { SharedModulesModule } from '../helpers/shared-modules.module';
import { MainContainerComponent } from './main-container.component'
import { MainContainerRoutingModule } from './main-container-routing.module'
import { MatTreeModule } from '@angular/material/tree'
import { NgxSpinnerModule } from 'ngx-spinner';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { SalesAssistantScanService } from '../sales-assistant/sales-assistant-scan/sales-assistant-scan.service';
import { ToastModule } from 'primeng/toast';

@NgModule({
    imports: [
        SharedModulesModule,
        MatTreeModule,
        MainContainerRoutingModule,
        NgxSpinnerModule,
        DialogModule,
        ProgressBarModule, SidebarModule,ToastModule

    ],
    declarations: [MainContainerComponent],
    providers: [SalesAssistantScanService]
})
export class MainContainerModule { }