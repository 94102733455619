import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {  MatDialogModule} from '@angular/material/dialog';
import {  MatIconModule } from '@angular/material/icon';
import {  MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NgVirtualKeyboardDirective } from './virtual-keyboard.directive';
import { VirtualKeyboardComponent } from './virtual-keyboard.component';
import { VirtualKeyboardKeyComponent } from './virtual-keyboard-key.component';
import { VirtualKeyboardService } from './virtual-keyboard.service';

@NgModule({
    declarations: [
        NgVirtualKeyboardDirective,
        VirtualKeyboardComponent,
        VirtualKeyboardKeyComponent,
    ],
    providers: [
        VirtualKeyboardService,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
    ],
    exports: [
        NgVirtualKeyboardDirective,
    ]
})

export class NgVirtualKeyboardModule { }
