<div class="mainCointainer" style="display:flex;" *ngIf=" offerData?.topCategories?.length>0">




  <div class="tableDataContainer">
    <!---Start SEARCH AREA---->
    <div *ngIf="f2">
      <p-card class="searchArea" style="width: calc(100% - 275px);">
        <div class="p-grid p-mb-2">
          <div class="p-col-6">
            <span class="p-inputgroup">
              <input class="searchInput" placeholder="Cautati articole" [style]="{'text-align':'left','width':'100%'}"
                type="text" pInputText [(ngModel)]="searchText" (input)="onSearchChange($event.target.value)" />
              <button (click)="searchText='';onSearchChange('')" type="button"
                style=" background: #FFE600;color:#394040; border: 1px solid #FFE600;" pButton pRipple
                icon="pi pi-times" styleClass="p-button-danger"></button>

            </span>

            <small *ngIf="searchText && searchText.length>0 && searchText.length<3" id="searchArt-help"
              style="color:red;">Minim 3
              caractere.</small>
          </div>
          <div class="p-col-6">
            <p-dropdown [(ngModel)]="selectedLocation" [autoDisplayFirst]="false" placeholder="Locatie stoc"
              [options]="locations" [filter]="true" [showClear]="true" [style]="{'width':'280px','margin':'0 5px'}"
              optionLabel="locationName" filterBy="locationName" (onChange)="getStockOfLocation($event)">
            </p-dropdown>
          </div>
        </div>

        <div class="result" *ngIf="searchText && searchText.length>2 ">
          <table id="resultsTable" style="width:100%">
            <tr style="font-size:14px;">
              <th class="name">Nume</th>
              <th class="unitMeasure">UM</th>
              <th class="quantity">Cant.</th>
              <th class="listPrice">Leg.</th>

              <th class="price">Pret</th>
              <th class="listPrice">Pret Lista</th>
              <th class="listPrice">Subtotal</th>
              <th class="quantity">Stoc.</th>
              <th class="quantity">INFO</th>
              <th *ngIf="debug" class="listPrice">Prod prop</th>
              <th *ngIf="debug" class="listPrice">Ext</th>
              <th *ngIf="debug" class="listPrice">Gest</th>
            </tr>
            <ng-template ngFor let-topcategory [ngForOf]="offerData.topCategories">
              <ng-template ngFor let-item [ngForOf]="topcategory.categories">
                <tr *ngFor="let art of item.articles; let itemI = index">
                  <ng-container
                    *ngIf="art.found && art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                    <td
                      [ngClass]="(art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):0)<round2Decimals(art.data[0].LISTA) && art.data[0].quantity ?'name selectedItemPriceProblem':(art.data[0].quantity ?'selectedItem name':'name')">
                      {{art.articleName}}
                    </td>
                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
                    </td>
                    <td class="noPading" style="background-color: #CCffCC;">
                      <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                        {{art.noLocation &&
                        art.data[0].quantity>0?"?":(art.data[0].quantity?round2Decimals(art.data[0].quantity):"")}}</b>
                      <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="!canEditQuantityPrice"
                        style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity"
                        type="number">
                    </td>
                    <td class="noPading" style="background-color: #CCffCC;">
                      <b>{{art.noLocation&&art.data[0].conversionQuantitySum&&
                        art.data[0].quantity>0
                        ?"?":(art.data[0].conversionQuantitySum?round2Decimals(art.data[0].conversionQuantitySum):"")}}</b>
                    </td>
                    <td class="price noPading">
                      <!-- [disabled]="!differentClient" offerData.status=='COMANDA' -->
                      <input [disabled]="!canEditQuantityPrice " [ngClass]="art.invalidPrice?'alert':''"
                        style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                        type="number">
                    </td>
                    <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                      {{round2Decimals(art.data[0].LISTA)}}
                    </td>
                    <td [ngClass]="art.data[0].quantity?'listPrice selectedItem':'listPrice'">
                      {{art.data[0].quantity>0 &&
                      getSubtotal(art.data[0].agentPrice,art.data[0].quantity)?getSubtotal(art.data[0].agentPrice,art.data[0].quantity):""}}
                    </td>
                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                      {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
                    </td>
                    <td *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])">
                      <div style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
                    </td>
                    <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                    <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                    <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                    <!-- <td>{{art.data[0].STOC}}</td> -->

                  </ng-container>

                  <ng-container *ngIf="art.data.length!=1 && art.found">
                    <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                      {{art.articleName}}{{art.found}}
                    </td>
                    <td *ngIf="art.data[0].PROMOTIE==1"
                      [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                      {{art.articleName}}
                    </td>
                    <td style="color: #536c79;font-weight: bold;">
                      {{round2Decimals(getTotalQuantity(art))}}
                    </td>
                    <td class="unitMeasure">{{art.data[0].UM}}</td>
                    <!-- [disabled]="differentClient" -->
                    <td class="price"><input [disabled]="!differentClient" [ngClass]="art.invalidPrice?'alert':''"
                        (keyup)="propagateQuantity(art.data);recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                        style="width: 100%;" type="number"></td>
                    <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                    <td [ngClass]="art.data[0].quantity?'listPrice selectedItem':'listPrice'">
                      {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                    </td>
                    <td class="quantity">INFO</td>

                    <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                    <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                    <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

                  </ng-container>
              </ng-template>
            </ng-template>
          </table>
        </div>

      </p-card>
    </div>
    <!---END Display Articles in search table---->

    <!---START Display Articles that are in shopping bag accordion---->
    <div *ngIf="f1" style="margin:5px;" class="orderedArticles">
      <mat-card>
        <div *ngIf="offerData.status!='COMANDA'" class="categoryTitle" style="text-align: center">Articole selectate
          pentru ofertare</div>
        <div *ngIf="offerData.status=='COMANDA'" class="categoryTitle" style="text-align: center">Articole din comanda
        </div>

        <table id="customers" style="width:100%">
          <tr style="font-size:14px;">
            <th class="name">Nume</th>
            <th class="unitMeasure">UM</th>
            <th class="quantity">Cant.</th>
            <th class="listPrice">Leg.</th>
            <th class="price">Pret</th>
            <th class="listPrice">Pret Lista</th>
            <th class="listPrice">Subtotal</th>
            <th class="quantity">Stoc.</th>
            <th class="quantity">INFO</th>
            <th *ngIf="debug" class="listPrice">Prod prop</th>
            <th *ngIf="debug" class="listPrice">Ext</th>
            <th *ngIf="debug" class="listPrice">Gest</th>
          </tr>
          <ng-template ngFor let-topcategory [ngForOf]="offerData.topCategories">
            <ng-template ngFor let-item [ngForOf]="topcategory.categories">
              <tr *ngFor="let art of item.articles; let itemI = index">
                <ng-container
                  *ngIf="art.data[0].quantity && art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                  <td
                    [ngClass]="((art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):0)<round2Decimals(art.data[0].LISTA) && art.data[0].quantity)  ?'name selectedItemPriceProblem':(art.data[0].quantity ?'selectedItem name':'name')">
                    {{art.articleName}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
                  </td>
                  <td class="noPading" style="background-color: #CCffCC;">
                    <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                      {{art.noLocation &&
                      art.data[0].quantity>0?"?":(art.data[0].quantity?round2Decimals(art.data[0].quantity):"")}}</b>
                    <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="!canEditQuantityPrice" style="width: 100%; "
                      (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity" type="number">
                  </td>
                  <td class="noPading" style="background-color: #CCffCC;">
                    <b>{{art.noLocation&&art.data[0].conversionQuantitySum&&
                      art.data[0].quantity>0
                      ?"?":(art.data[0].conversionQuantitySum?round2Decimals(art.data[0].conversionQuantitySum):"")}}</b>
                  </td>
                  <td class="price noPading">
                    <input [disabled]="!canEditQuantityPrice" [ngClass]="art.invalidPrice?'alert':''"
                      style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                      type="number">
                  </td>
                  <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                    {{round2Decimals(art.data[0].LISTA)}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'listPrice selectedItem':'listPrice'">
                    {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                    {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
                  </td>
                  <td *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])">
                    <div style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
                  </td>
                  <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                  <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                  <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                </ng-container>

                <ng-container *ngIf="art.data.length!=1 && art.data[0].quantity">
                  <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                    {{art.articleName}}
                  </td>
                  <td *ngIf="art.data[0].PROMOTIE==1" [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                    {{art.articleName}}
                  </td>
                  <td style="color: #536c79;font-weight: bold;">
                    {{round2Decimals(getTotalQuantity(art))}} {{art.data[0].PRODUSPROPRIU}}
                  </td>
                  <td class="unitMeasure">{{art.data[0].UM}}</td>
                  <!-- [disabled]="differentClient" -->
                  <td class="price"><input [disabled]="!differentClient" [ngClass]="art.invalidPrice?'alert':''"
                      (keyup)="propagateQuantity(art.data);recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                      style="width: 100%;" type="number"></td>
                  <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                  <td [ngClass]="art.data[0].quantity?'listPrice selectedItem':'listPrice'">
                    {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                  </td>


                  <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                  <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                  <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

                </ng-container>
              </tr>
            </ng-template>
          </ng-template>
        </table>
      </mat-card>



    </div>
    <!---END Display Articles that are in shopping bag accordion---->

    <!---START Display Articles in category accordion---->
    <p-accordion *ngIf="f3 && !searchText">
      <p-accordionTab [transitionOptions]="'0ms'" *ngFor="let topCategory of offerData.topCategories"
        [header]="topCategory.topCategoryName">
        <div *ngIf="topCategory.categories.length>1">
          <p-accordion>
            <p-accordionTab [transitionOptions]="'0ms'" *ngFor="let item of topCategory.categories"
              [header]="item.category.split('.')[1]" (onOpen)="item.open = true" (onClose)="item.open = false">
              <ng-template pTemplate="content">
                <table id="customers" style="width:100%">
                  <tr style="font-size:14px;">
                    <th class="name">Nume</th>
                    <th class="unitMeasure">UM</th>

                    <th class="quantity">Cant.</th>
                    <th class="listPrice">Leg.</th>

                    <th class="price">Pret</th>
                    <th class="listPrice">Pret Lista</th>
                    <th class="listPrice">Subtotal</th>
                    <th class="quantity">Stoc.</th>
                    <th class="quantity">INFO</th>
                    <th *ngIf="debug" class="listPrice">Prod prop</th>
                    <th *ngIf="debug" class="listPrice">Ext</th>
                    <th *ngIf="debug" class="listPrice">Gest</th>
                  </tr>

                  <tr *ngFor="let art of item.articles; let itemI = index">
                    <ng-container
                      *ngIf="art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                      <td
                        [ngClass]="((art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):0)<round2Decimals(art.data[0].LISTA) && art.data[0].quantity) ||  (art.data[0].quantity>art.data[0].STOC) ?'name selectedItemPriceProblem':(art.data[0].quantity ?'selectedItem name':'name')">
                        {{cutArticleNames(item.category,art.articleName)}}
                      </td>
                      <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
                      </td>

                      <td class="noPading">
                        <b *ngIf="art.data[0].PRODUSPROPRIU!=2" style="color:blue">
                          {{art.noLocation &&
                          art.data[0].quantity>0?"?":(art.data[0].quantity?round2Decimals(art.data[0].quantity):"")}}</b>
                        <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="!canEditQuantityPrice"
                          style="width: 100%; " (blur)="ckeckLocation(art.data[0])" (keyup)="recalculate($event)"
                          [(ngModel)]="art.data[0].quantity" type="number">

                      </td>
                      <td class="noPading" style="background-color: #CCffCC;">
                        <b>{{art.noLocation&&art.data[0].conversionQuantitySum&&
                          art.data[0].quantity>0
                          ?"?":(art.data[0].conversionQuantitySum?round2Decimals(art.data[0].conversionQuantitySum):"")}}</b>
                      </td>

                      <td class="price noPading">
                        <input [disabled]="!canEditQuantityPrice" [ngClass]="art.invalidPrice?'alert':''"
                          style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                          type="number">


                      </td>
                      <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                        {{round2Decimals(art.data[0].LISTA)}}
                      </td>
                      <td [ngClass]="art.data[0].quantity?'listPrice selectedItem':'listPrice'">
                        {{art.data[0].quantity>0 &&
                        getSubtotal(art.data[0].agentPrice,art.data[0].quantity)?getSubtotal(art.data[0].agentPrice,art.data[0].quantity):""}}
                      </td>
                      <td
                        [ngClass]="!art.data[0].quantity || (art.data[0].quantity<=art.data[0].STOC) ?'unitMeasure selectedItem':'unitMeasure selectedItemPriceProblem'">
                        {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
                      </td>


                      <td>
                        <div *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])"
                          style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
                      </td>
                      <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                      <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                      <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>


                    </ng-container>

                    <ng-container *ngIf="art.data.length!=1 ">
                      <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                        {{art.articleName}}
                      </td>
                      <td *ngIf="art.data[0].PROMOTIE==1"
                        [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                        {{art.articleName}}
                      </td>
                      <td style="color: #536c79;font-weight: bold;">
                        {{round2Decimals(getTotalQuantity(art))}}
                      </td>
                      <td class="unitMeasure">{{art.data[0].UM}}</td>

                      <td class="price"><input [ngClass]="art.invalidPrice?'alert':''"
                          (keyup)="propagateQuantity(art.data);recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                          style="width: 100%;" type="number"></td>
                      <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                      <td [ngClass]="art.data[0].quantity?'listPrice selectedItem':'listPrice'">
                        {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                      </td>

                      <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                      <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                      <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

                    </ng-container>
                  </tr>
                </table>

              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>


        <div *ngIf="topCategory.categories.length==1">
          <div *ngFor="let item of topCategory.categories">

            <table id="customers" style="width:100%">
              <tr style="font-size:14px;">
                <th class="name">Nume</th>
                <th class="unitMeasure">UM</th>

                <th class="quantity">Cant.</th>
                <th class="listPrice">Leg.</th>

                <th class="price">Pret</th>
                <th class="listPrice">Pret Lista</th>
                <th class="listPrice">Subtotal</th>
                <th class="quantity">Stoc.</th>
                <th class="quantity">INFO</th>
                <th *ngIf="debug" class="listPrice">Prod prop</th>
                <th *ngIf="debug" class="listPrice">Ext</th>
                <th *ngIf="debug" class="listPrice">Gest</th>
              </tr>
              <tr *ngFor="let art of item.articles; let itemI = index">
                <ng-container
                  *ngIf="art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                  <td
                    [ngClass]="(art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):0)<round2Decimals(art.data[0].LISTA) && art.data[0].quantity ?'name selectedItemPriceProblem':(art.data[0].quantity ?'selectedItem name':'name')">
                    {{cutArticleNames(item.category,art.articleName)}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
                  </td>

                  <td class="noPading" style="background-color: #CCffCC;text-align: center;">
                    <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                      {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
                    <input *ngIf="art.data[0].PRODUSPROPRIU==2" [disabled]="!canEditQuantityPrice" style="width: 100%; "
                      (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity" type="number">
                  </td>
                  <td class="noPading" style="background-color: #CCffCC;text-align: center;">
                    <b>{{art.data[0].conversionQuantitySum?round2Decimals(art.data[0].conversionQuantitySum):""}}</b>
                  </td>

                  <td class="price noPading">
                    <input [disabled]="!canEditQuantityPrice" [ngClass]="art.invalidPrice?'alert':''"
                      style="width: 100%; " (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                      type="number">
                  </td>
                  <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                    {{round2Decimals(art.data[0].LISTA)}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                    {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
                  </td>
                  <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                    {{art.data[0].STOC | number:'.0-0':'ro-RO'}}
                  </td>

                  <td>
                    <div *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])"
                      style="width:25px;height: 25px;" [inlineSVG]="'/assets/images/info_int.svg'"></div>
                  </td>
                  <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                  <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                  <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                  <!-- <td>{{art.data[0].STOC}}</td> -->

                </ng-container>

                <ng-container *ngIf="art.data.length!=1 ">
                  <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                    {{art.articleName}}
                  </td>
                  <td *ngIf="art.data[0].PROMOTIE==1" [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                    {{art.articleName}}
                  </td>
                  <td style="    text-align: center;color: #536c79;font-weight: bold;">
                    {{round2Decimals(getTotalQuantity(art))}}
                  </td>
                  <td class="unitMeasure">{{art.data[0].UM}}</td>
                  <!-- [disabled]="differentClient" -->
                  <td class="price"><input [ngClass]="art.invalidPrice?'alert':''"
                      (keyup)="propagateQuantity(art.data);recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                      style="width: 100%;" type="number"></td>
                  <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                  <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                    {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                  </td>

                  <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                  <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                  <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

                </ng-container>
              </tr>

            </table>

          </div>
        </div>

      </p-accordionTab>
    </p-accordion>

    <!-- aaaaaaaaaaaaaaaaaaaaaaaaaa -->

  </div>



  <div class="noprint" style="width:285px;margin: 0 auto 0 0;">
    <div style="margin:5px;width:275px;overflow: auto;">

      <mat-card style="padding: 5px;">
        <div style="display:flex;">
          <div style="width:50%">
            <div style="color:black;text-align: right;font-size:18px; margin-top:5px;margin-right: 15px;"
              *ngIf="offerData">
              {{totalPrice?totalPrice:0}} lei
            </div>
            <div style="display:flex;float: right;margin-right: 15px;">
              <div style="color:black; margin-top: 7px;"> {{round2Decimals(totalOfferWeight)}} KG</div>
              <!-- <div style="margin: auto auto auto 3px;" class="routeInfo kg" [inlineSVG]="'/assets/images/weight.svg'">
              </div> -->
              <!-- <img style="width: 24px ;height: 24px ;" src="/assets/images/kg.png" alt="Logo" /> -->
            </div>
          </div>
          <!-- <div *ngIf="f2" style="width:45%">
            <div (click)="f1=true;f2=false;f3=false;"
              style="text-align: center;padding:10px;color: #394040;font-size: 14px;background:#FFE600">
              <div><b>Vezi <br> COMANDA</b></div>
            </div>
          </div> -->

          <button *ngIf="f2" (click)="f1=true;f2=false;f3=false;" pButton pRipple type="button" label="VEZI COMANDA"
            class="p-button-success finishAction noMargin" style="width: 130px;padding: 0;"></button>

          <button *ngIf="!f2" (click)="f1=false;f2=true;f3=true;" pButton pRipple type="button" label="VEZI ARTICOLE"
            class="p-button-success finishAction noMargin" style="width: 120px;padding: 0;"></button>

          <!-- <div *ngIf="!f2" style="width:45%">
            <div (click)="f1=false;f2=true;f3=true;"
              style="text-align: center;padding:10px;color: #394040;font-size: 14px;background:#FFE600">
              <div><b>Vezi <br> articole</b></div>
            </div>
          </div> -->

          <div
            *ngIf="offerData.client && (offerData.client.soldValue+totalPrice<=offerData.client.creditValue && !invalidPrice)"
            class="actionSvg delete" style="width:45px;" [inlineSVG]="'/assets/images/tick.svg'">
          </div>
          <div
            *ngIf="offerData.client && !(offerData.client.soldValue+totalPrice<=offerData.client.creditValue && !invalidPrice)"
            class="actionSvg delete" style="width:65px;" [inlineSVG]="'/assets/images/error.svg'"></div>
        </div>

      </mat-card>


      <mat-card style="margin:10px 0;">
        <div *ngIf="offerData.status=='COMANDA'">
          <div *ngIf="dispecer"
            style="margin: 0 5px 10px 5px;font-size: 28px;font-weight: bold;text-align: center;color: #394040;">
            MODIFICARE
          </div>
          <div style="margin: 0 5px 10px 5px;font-size: 28px;font-weight: bold;text-align: center;color: #3ac47d;">
            {{offerData.data && !offerData.returned?offerData.data.commandSeries:'RETUR '}}
            {{offerData.data?offerData.data.commandNumber:''}}</div>

          <div *ngIf="offerData.data"
            style="margin: 0 5px 10px 5px;font-size: 28px;font-weight: bold;text-align: center;color: #3ac47d;">
            <div *ngFor="let command of offerData.data">
              {{command.commandSeries}}
              {{command.commandNumber}}
            </div>
          </div>
          <!-- <div style="display:flex;justify-content: center;margin-bottom: 10px;">
            <div (click)="cancelCommandFlag=true" style="padding:10px;color: #fff;font-size: 16px;background:red">
              Anuleaza comanda
            </div>
          </div> -->

          <button (click)="cancelCommandFlag=true" pButton pRipple type="button" label="ANULEAZA COMANDA"
            class="p-button-success p-mt-2 p-mb-2 customButton"></button>

          <button *ngIf="offerData.status=='COMANDA' && !dispecer && hasDispecerAccess  && !editTransport"
            (click)="activateDispecer()" pButton pRipple type="button" label="MODIFICA LOCATIE"
            class="p-button-success p-mt-2 p-mb-2 customButton"></button>

          <button *ngIf="offerData.status=='COMANDA' && dispecer && hasDispecerAccess && !editTransport"
            (click)="modifyCommandFlag=true" pButton pRipple type="button" label="SALVEAZA NOUA LOCATIE"
            class="p-button-success p-mt-2 p-mb-2 customButton"></button>

        </div>
        <div style="color: #536c79; font-size: 20px;text-align: center;" *ngIf="offerData.client">
          <b>Client</b>
        </div>

        <button *ngIf="offerData.status!='COMANDA'" (click)="showClientsModal=true" pButton pRipple type="button"
          label="ALEGE DIN LISTA CLIENTI" class="p-button-success p-mt-2 p-mb-2 customButton"></button>

        <div style="color: #536c79; font-size: 20px;text-align: center; padding: 7px;" *ngIf="offerData.client.name">
          {{offerData.client.name}}</div>

        <mat-divider></mat-divider>
        <div *ngIf="clientSoldData" style="text-align:left;">
          <div style="margin:5px;color: #536c79;font-weight:bold;">LIMITA:
            {{round2Decimals(clientSoldData.creditValue) | number:'.0-0':'ro-RO'}}</div>
          <div style="margin:5px;color: #536c79;font-weight:bold;">SOLD:
            {{clientSoldData.soldValue | number:'.0-0':'ro-RO'}} </div>
          <div style="margin:5px;color: #536c79;">SOLD ACOPERIT:
            {{round2Decimals(clientSoldData.BO) | number:'.0-0':'ro-RO'}} </div>
          <div style="margin:5px;color: #536c79;">SOLD NEACOPERIT:
            {{clientSoldData.sold | number:'.0-0':'ro-RO'}}</div>
        </div>



        <mat-divider *ngIf="offerData.client.name"></mat-divider>

        <div *ngIf="offerData.client &&offerData.client.phone" class="p-mt-2">
          <p-divider>
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-phone p-mr-2"></i>
              <span>Telefon</span>
            </div>
          </p-divider>
          <div style="color:#003048; font-size: 17px;text-align: center; padding: 3px 0;font-family: 'CarettaOffice';">
            {{offerData.client.phone}}</div>
        </div>

        <div *ngIf="offerData.client &&offerData.client.email" class="p-mt-2">
          <p-divider>
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-envelope p-mr-2"></i>
              <span>Email</span>
            </div>
          </p-divider>
          <div style="color:#003048; font-size: 17px;text-align: center; padding: 3px 0;font-family: 'CarettaOffice';">
            {{offerData.client.email}}</div>
        </div>




        <p-divider *ngIf="offerData.client &&offerData.client.name">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-map-marker p-mr-2"></i>
            <span>Adresa</span>
          </div>
        </p-divider>

        <div *ngIf="offerData.client &&offerData.client.name"
          style="color:#003048;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
          {{offerData?.client?.address?.address}} {{offerData?.client?.address?.city}}
          {{offerData?.client?.address?.conunty}}</div>


        <div style="color: #536c79; font-size: 20px;text-align: center;padding: 5px 0;" *ngIf="offerData.client">
          <b>Plata</b>
        </div>

        <button *ngIf="offerData.status!='COMANDA'" (click)="showPaymentModal=true" pButton pRipple type="button"
          label="SETEAZA MODALITATEA DE PLATA" class="p-button-success p-mt-2 p-mb-2 customButton"></button>

        <div>
          <div *ngIf="offerData.isAdvancePayment && offerData.advancePaymentValue>0" class="infoText">Plata avans
            {{offerData.advancePaymentValue}}</div>
          <div *ngIf="offerData.paymentType && offerData.paymentType!='undefined'" class="infoText">Modalitate de plata:
            {{offerData.paymentType}}
          </div>
          <div *ngIf="offerData.paymentTerm && offerData.paymentTerm !='undefined' && offerData.paymentTerm>0 "
            class="infoText">Termen de plata:
            {{offerData.paymentTerm==1?offerData.paymentTerm +" zi":offerData.paymentTerm+" zile"}}</div>
          <div *ngIf="offerData.finishType && offerData.finishType !='undefined' " class="infoText">Finalizare:
            {{offerData.finishType}}</div>
          <div *ngIf="offerData.observations && offerData.observations !='undefined'" class="infoText">Observatii:
            {{offerData.observations}}</div>
        </div>

        <mat-divider></mat-divider>

        <div style="color: #536c79; font-size: 20px;text-align: center;padding: 5px 0;" *ngIf="offerData.client">
          <b>Livrare si transport</b>
        </div>

        <button *ngIf="offerData.status!='COMANDA'" (click)="showAdressAndTransport()" pButton pRipple type="button"
          label="SETEAZA ADRESA SI TRANSPORTUL" class="p-button-success p-mt-2 p-mb-2 customButton"></button>

        <div>
          <!-- <div *ngIf="offerData.deliverAddress" class="infoText">Adresa livrare {{offerData.deliverAddress}}</div> -->

          <p-divider *ngIf="offerData.deliverAddress">
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-map-marker p-mr-2"></i>
              <span>Adresa livrare</span>
            </div>
          </p-divider>

          <div *ngIf="offerData.deliverAddress"
            style="color:#003048;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
            {{offerData.deliverAddress}}</div>



          <!-- <div *ngIf="offerData.deliveryContactPhone" class="infoText">Contact {{offerData.deliveryContactPhone}}</div> -->

          <p-divider *ngIf="offerData.deliveryContactPhone">
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-phone p-mr-2"></i>
              <span>Contact</span>
            </div>
          </p-divider>

          <div *ngIf="offerData.deliveryContactPhone"
            style="color:#003048;padding: 0;text-align: center;font-family: 'CarettaOffice';">
            {{offerData.deliveryContactPhone}}</div>

          <!-- <div *ngIf="offerData.deliverDate" class="infoText"> Termen livrare {{offerData.deliverDate | date:
            'dd/MM/yyyy' }}</div> -->

          <p-divider *ngIf="offerData.deliverDate">
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-calendar p-mr-1"></i>
              <span>Termen livrare</span>
            </div>
          </p-divider>

          <div *ngIf="offerData.deliverDate"
            style="color:#003048;padding: 0;text-align: center;font-family: 'CarettaOffice';">
            {{offerData.deliverDate | date: 'dd/MM/yyyy'}}</div>

          <div *ngIf="offerData.pickedByClient" class="infoText" style="display:flex">
            <div>Ridica clientul</div>
            <div (click)="op.toggle($event)" style="margin: -2px 3px;width:25px;height: 25px;"
              [inlineSVG]="'/assets/images/info_int.svg'"></div>

          </div>
          <div *ngIf="offerData.coupling" class="infoText">
            Cuplaj
          </div>
          <div *ngIf="offerData.transportType" class="infoText">
            Tip transport: {{offerData.transportType}}
          </div>

          <button *ngIf="offerData.status=='COMANDA' && !editTransport && hasDispecerAccess"
            (click)="editTransport=true" pButton pRipple type="button" label="MODIFICA TRANSPORT"
            class="p-button-success p-mt-2 p-mb-2 customButton"></button>
        </div>
      </mat-card>


      <mat-card *ngIf="offerData.status!='COMANDA'" style="margin:10px 10px">
        <div style="color: #536c79; font-size: 20px;text-align: center;" *ngIf="offerData.client">
          <b>FINALIZARE</b>
        </div>

        <p-divider *ngIf=" offerData.status=='FINANCIAL_VALIDATION'">
          <div class=" p-d-inline-flex p-ai-center" style="color: #0097d2;">
            <i class="pi pi-spinner p-mr-2"></i>
            <span>Ofertă în verificare</span>
          </div>
        </p-divider>
        <p-divider *ngIf=" offerData.status=='DENIED'">
          <div class=" p-d-inline-flex p-ai-center" style="color: red;">
            <i class="pi pi-exclamation-triangle p-mr-2"></i>
            <span>Ofertă respinsă</span>
          </div>
        </p-divider>
        <p-divider *ngIf=" offerData.status=='APPROVED'">
          <div class=" p-d-inline-flex p-ai-center" style="color: green;">
            <i class="pi pi-check p-mr-2"></i>
            <span>Comandă Acceptată</span>
          </div>
        </p-divider>

        <button *ngIf="offerData.status=='OFERTA'" (click)="saveOffer('OFERTA',null)" pButton pRipple type="button"
          label="SALVEAZA OFERTA" class="p-button-success p-mt-2 p-mb-2 finishAction"></button>

        <button *ngIf="offerData.status=='OFERTA'  && offerData.isAdvancePayment && offerData.advancePaymentValue!=0"
          (click)="saveOffer('FINANCIAL_VALIDATION',null)" pButton pRipple type="button" label="VALIDEAZA PROFORMA"
          class="p-button-success p-mt-2 p-mb-2 finishAction"></button>

        <button
          *ngIf="offerData.status=='APPROVED' || ( !offerData.isAdvancePayment || offerData.advancePaymentValue==0)"
          (click)="finishCommand()" pButton pRipple type="button" label="PLASEAZA COMANDA"
          class="p-button-success p-mt-2 p-mb-2 finishAction"></button>

        <button *ngIf="offerData" (click)="generateOfferPdf()" pButton pRipple type="button" icon="pi pi-file-pdf"
          label="GENEREAZĂ PDF" class="p-button-success p-mt-2 finishAction noMargin"></button>
      </mat-card>

      <mat-card *ngIf="offerData.status=='COMANDA'" style="margin:10px 10px">
        <button *ngIf="offerData" (click)="generateOfferPdf()" pButton pRipple type="button" icon="pi pi-file-pdf"
          label="Generează Proforma" class="p-button-success p-mt-2 finishAction noMargin"></button>
      </mat-card>
      <div class="p-text-bold" style="text-align:center;color:white;font-family: CarettaOffice;font-size: 22px;">V
        {{version}}</div>
    </div>
  </div>
</div>

<p-dialog [closable]="false" [style]="{width: '900px',height:'85%'}" [modal]="true" [draggable]="false"
  [(visible)]="showClientsModal">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Clienti!</span>
    <div class="closeSvgRight" (click)="showClientsModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>
  <div style="display:flex">
  </div>

  <table id="clients" style="width:100%">
    <tr style="font-size:14px;">
      <th style="width:230px;padding:0;">
        <!-- <mat-form-field style="width:100%" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientNameSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onNameInputChange()" matInput placeholder="Nume">
        </mat-form-field> -->

        <div class="p-float-label p-input-icon-right" [style]="{'width':'100%'}">
          <i *ngIf="nameSearch" class="pi pi-spin pi-spinner"></i>
          <input [debounce]="500" (onDebounce)="onNameInputChange()" class="searchInput" inputId="name"
            [style]="{'text-align':'left','width':'100%'}" type="text" pInputText [(ngModel)]="clientNameSearch" />
          <label for="name">Nume</label>
        </div>

      </th>
      <th style="width:100px;padding:0;">
        <!-- <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientPhoneSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onPhoneInputChange()" matInput placeholder="Telefon">
        </mat-form-field> -->
        <span class="p-float-label">
          <input [debounce]="500" (onDebounce)="onPhoneInputChange()" class="searchInput" inputId="phone"
            [style]="{'text-align':'left','width':'100%'}" type="text" pInputText [(ngModel)]="clientPhoneSearch" />
          <label for="phone">Telefon</label>
        </span>
      </th>
      <th style="width:100px;padding:0;">
        <!-- <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientFiscalCodeSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onFiscalCodeChange()" matInput placeholder="CNP/CUI">
        </mat-form-field> -->
        <span class="p-float-label">
          <input [debounce]="500" (onDebounce)="onFiscalCodeChange()" class="searchInput" inputId="cui"
            [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
            [(ngModel)]="clientFiscalCodeSearch" />
          <label for="cui">CNP/CUI</label>
        </span>
      </th>
      <th style="width:100px;">
        Reg. Com.
      </th>
      <th class="unitMeasure">
        Adresa
      </th>

      <th class="unitMeasure">
        <div (click)="addNewClient=true" matTooltip="Adaugare client nou" class="editSvg"
          [inlineSVG]="'/assets/images/add.svg'"></div>
      </th>
    </tr>
    <tr *ngFor="let client of clients">
      <td (click)="prefillSelectedClientData(client)"> {{client.DEN_PART}} </td>
      <!-- <td (click)="prefillSelectedClientData(client)"> {{client.TELEFON}} </td>
            <td (click)="prefillSelectedClientData(client)"> {{client.CODFISCAL}} </td> -->
      <td (click)="prefillSelectedClientData(client)"> ****{{client?.TELEFON?.slice(client.TELEFON.length -4)}} </td>
      <td (click)="prefillSelectedClientData(client)"> ****{{client?.CODFISCAL?.slice(client.CODFISCAL.length - 4)}} </td>

      <td (click)="prefillSelectedClientData(client)"> {{client.REGCOM}} </td>
      <!-- <td> {{client.DEN_LOCALIT}} {{client.JUDET}} </td> -->
      <td (click)="prefillSelectedClientData(client)" *ngIf="client.PERSOANAFIZICA==0">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.DEN_LOCALIT.toLowerCase()!=client.JUDET.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}</td>
      <td (click)="prefillSelectedClientData(client)" *ngIf="client.PERSOANAFIZICA==1">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.JUDET.toLowerCase()!=client.DEN_LOCALIT.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}
      </td>
      <td>
        <div (click)="prefillEditClientData(client)" matTooltip="Editare locatie" class="actionSvg"
          [inlineSVG]="'/assets/images/edit.svg'"></div>
      </td>
    </tr>

  </table>
</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="addNewClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Adaugare client nou!</span>
    <div class="closeSvgRight" (click)="addNewClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>


  <div class="p-mt-4">
    <span class="p-float-label">
      <p-dropdown appendTo="body" [autoDisplayFirst]="false" inputId="clientType" name="value" [options]="clientTypes"
        [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="newClientData.clientType">
      </p-dropdown>
      <label for="clientType">Tip client</label>
    </span>
  </div>

  <div *ngIf="offerData && newClientData.clientType && !validFiscalCode && newClientData.clientType!='PF'"
    class=" p-mt-4" style="display:flex;">
    <span style="width: calc(100% - 100px);margin-right: 10px;" class="p-float-label">
      <input required inputId="CUI" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="newClientData.fiscalCode" />
      <label for="CUI">CUI</label>
    </span>

    <button (click)="validateFiscalCode(newClientData.fiscalCode)" pButton type="button" label="Valideaza"
      class="p-button-outlined"></button>
  </div>




  <div *ngIf="newClientData.clientType=='PF' || validFiscalCode">

    <div class="p-mt-4">
      <span class="p-float-label">
        <input [disabled]="validFiscalCode" inputId="clientName" [style]="{'text-align':'left','width':'100%'}"
          type="text" pInputText [(ngModel)]="newClientData.name" />
        <label for="clientName">Nume Client</label>
      </span>
    </div>

    <div class="p-mt-4">
      <span class="p-float-label">
        <input [disabled]="validFiscalCode" required inputId="typeClient" [style]="{'text-align':'left','width':'100%'}"
          type="text" pInputText [(ngModel)]="newClientData.fiscalCode" />
        <label for="typeClient">{{newClientData.clientType=='PF'?'CNP':'CUI'}}</label>
      </span>
    </div>
    <div *ngIf="offerData && newClientData.clientType!='PF'" class="p-mt-4">
      <span class="p-float-label">
        <input required inputId="reg" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="newClientData.tradeRegister" />
        <label for="reg">Reg Com</label>
      </span>
    </div>
    <div class="p-mt-4">
      <span class="p-float-label">
        <input required inputId="phone" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="newClientData.phone" />
        <label for="phone">Telefon</label>
      </span>
    </div>



    <!-- <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="newClientData.name">
    </mat-form-field>

    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput [placeholder]="newClientData.clientType=='PF'?'CNP':'CUI'"
        [(ngModel)]="newClientData.fiscalCode">
    </mat-form-field>
    <mat-form-field *ngIf="offerData && newClientData.clientType!='PF'" class="noLabel" style="width:100%">
      <input type="text" matInput placeholder="Reg Com" [(ngModel)]="newClientData.tradeRegister">
    </mat-form-field>
    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput placeholder="Telefon" [(ngModel)]="newClientData.phone">
    </mat-form-field> -->


    <div class=" p-mt-4" *ngIf="counties">
      <span class="p-float-label">
        <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownCountyClient" [options]="counties.rows"
          [filter]="true" [showClear]="true" [style]="{'width':'100%'}" optionLabel="countyName"
          emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="countyName" (onChange)="loadLocalities($event)">
        </p-dropdown>
        <label for="dropdownCountyClient">Judet</label>
      </span>
    </div>

    <div class=" p-mt-4" *ngIf="filteredOptions">
      <span class="p-float-label">
        <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownLocality"
          [(ngModel)]="newClientData.locality" [options]="filteredOptions" [filter]="true" [showClear]="true"
          (onClick)="initLocalities()" [style]="{'width':'100%'}" emptyFilterMessage="Nu au fost gasite rezultate!"
          optionLabel="localityName" filterBy="localityName">
          <ng-template let-loc pTemplate="item">
            <div style="font-size:14px;margin-top:4px"> {{loc.localityName}} <span
                *ngIf="loc && loc.localityName!=loc.village">({{loc.village }})</span></div>
          </ng-template>
        </p-dropdown>
        <label for="dropdownLocality">Oraș/Localitate</label>
      </span>
    </div>



    <div style="display:flex">
      <mat-form-field style="width:100%" class="example-full-width noLabel">
        <input matInput placeholder="Strada" [(ngModel)]="newClientData.street">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Nr." [(ngModel)]="newClientData.number">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Scara" [(ngModel)]="newClientData.scara">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Bloc" [(ngModel)]="newClientData.bloc">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Etaj" [(ngModel)]="newClientData.etaj">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Ap." [(ngModel)]="newClientData.apartament">
      </mat-form-field>

    </div>
    <div (click)="insertNewPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

  </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="editClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Editare date client!</span>
    <div class="closeSvgRight" (click)="editClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <div class="p-mt-4">
    <span class="p-float-label">
      <input required inputId="phone" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="editClientData.name" />
      <label for="phone">Nume Client</label>
    </span>
  </div>

  <mat-form-field *ngIf="offerData && !editClientData.fiscalCode" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>

  <div *ngIf="!editClientData.isPF" class="p-mt-4">
    <span class="p-float-label">
      <input required inputId="phone" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="editClientData.tradeRegister" />
      <label for="phone">Reg Com</label>
    </span>
  </div>

  <div class="p-mt-4">
    <span class="p-float-label">
      <input required inputId="phone" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="editClientData.phone" />
      <label for="phone">Telefon</label>
    </span>
  </div>

  <div class="p-mt-4 p-mb-2" *ngIf="counties">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownCountyClient" [options]="counties.rows"
        [(ngModel)]="editClientData.county" [filter]="true" [showClear]="true" [style]="{'width':'100%'}"
        optionLabel="countyName" emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="countyName"
        (onChange)="loadLocalities($event)">
      </p-dropdown>
      <label for="dropdownCountyClient">Judet</label>
    </span>
  </div>

  <div class="p-mt-4 p-mb-3" *ngIf="filteredOptions">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownLocality"
        [(ngModel)]="editClientData.locality" [options]="filteredOptions" [filter]="true" [showClear]="true"
        [style]="{'width':'100%'}" emptyFilterMessage="Nu au fost gasite rezultate!" optionLabel="localityName"
        filterBy="localityName">
        <ng-template let-loc pTemplate="item">
          <div style="font-size:14px;margin-top:4px"> {{loc.localityName}} <span
              *ngIf="loc && loc.localityName!=loc.village">({{loc.village }})</span></div>
        </ng-template>
      </p-dropdown>
      <label for="dropdownLocality">Oraș/Localitate</label>
    </span>
  </div>

  <div style="display:flex">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input matInput placeholder="Strada" [(ngModel)]="editClientData.street">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Nr." [(ngModel)]="editClientData.number">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Scara" [(ngModel)]="editClientData.scara">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Bloc" [(ngModel)]="editClientData.bloc">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Etaj" [(ngModel)]="editClientData.etaj">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Ap." [(ngModel)]="editClientData.apartament">
    </mat-form-field>

  </div>
  <div (click)="editPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{'max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="stockDetail">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Alege cantitatea</span>
    <div class="closeSvgRight" (click)="stockDetail=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <div *ngIf="selectedArticle" style="color: #536c79;font-size: 20px;padding: 7px;">
    <div>{{selectedArticle.DENUMIRE}} </div>
    <div *ngIf="hasDispecerAccess"> Cantitate conform oferta {{selectedArticle.conversionQuantitySum}}</div>
  </div>



  <table *ngIf="selectedArticle" id="customers" style="width:100%">
    <tr style="font-size:14px;">
      <th style="width:200px;padding:5px;">Furnizor</th>
      <th style="width:150px;padding:5px;">Locatie</th>

      <th style="width:50px;padding:5px;">Stoc</th>
      <th style="width:50px;padding:5px;">Leg.</th>
      <th style="width:105px;padding:5px;">{{selectedArticle.UM}}/Legatura</th>
    </tr>
    <tr *ngFor="let art of stockDetail">
      <ng-container>
        <td [class]="art.inactive?'inactiveLocation':''">{{art.supplierName}}</td>
        <td>{{art.location}}</td>
        <td> {{getCalculatedStock(selectedArticle,art) | number:'.0-0':'ro-RO'}} </td>
        <td class="inputWide">
          <!-- <input [disabled]="offerData&&offerData.status=='COMANDA' && !dispecer " type="number"
            [(ngModel)]="art.conversionQuantity" style="width: 65px;"> -->
          <input [disabled]="offerData&&offerData.status=='COMANDA' && !dispecer " [(ngModel)]="art.conversionQuantity"
            type="number" class="p-inputtext-sm" style="border: 1px solid #536c79;
            border-radius: 0;    padding: 0.2375rem 0.4375rem;width:70px;" pInputText />
        </td>


        <td>
          {{art.conversionValue | number:'.0-0':'ro-RO'}}
          <!-- <input style="width: 55px;" *ngIf="art" type="number" [(ngModel)]="art.conversionValue"
            placeholder="KG/Legatura">
          <div class="confirmConversionSvg" (click)="saveConversion(selectedArticle,art)"
            [inlineSVG]="'/assets/images/confirm.svg'"></div> -->
        </td>
      </ng-container>


    </tr>

  </table>

  <button *ngIf="offerData&&offerData.status!='COMANDA' || dispecer"
    (click)="saveQuantity(selectedArticle,stockDetail);getTotalPrice()" pButton pRipple type="button"
    label="SALVEAZA DATELE" class="p-button-success p-mt-2 finishAction noMargin"></button>
</p-dialog>

<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="cancelCommandFlag">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Anulare comanda
      {{offerData&&offerData.data?offerData.data.commandNumber:''}}</span>
    <div class="closeSvgRight" (click)="cancelCommandFlag=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>
  <div>Doriti anularea comenzii?</div>
  <mat-form-field class="noLabel" style="width:100%">
    <textarea style="height: 90px;" [(ngModel)]="cancelObservations" matInput placeholder="Motiv anulare"></textarea>
  </mat-form-field>
  <div (click)="cancelCommand()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>

<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="modifyCommandFlag">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Modificare comanda
      {{offerData&&offerData.data?offerData.data.commandNumber:''}}</span>
    <div class="closeSvgRight" (click)="modifyCommandFlag=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>
  <div>Doriti modificarea comenzii?</div>
  <mat-form-field class="noLabel" style="width:100%">
    <textarea style="height: 90px;" [(ngModel)]="cancelObservations" matInput placeholder="Motiv modificare"></textarea>
  </mat-form-field>
  <div (click)="cancelCommand()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>


<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '400px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="editTransport">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Modificare tip transport</span>
    <div class="closeSvgRight" (click)="editTransport=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <div class="p-mt-4" *ngIf="editTransport && hasDispecerAccess">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" inputId="transportType" appendTo="body" [options]="transportTypes"
        name="value" [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="offerData.transportType">
      </p-dropdown>
      <label for="transportType">Tip transport</label>
    </span>
  </div>

  <button *ngIf="offerData.status=='COMANDA' && editTransport && hasDispecerAccess" (click)="changeTransportType()"
    pButton pRipple type="button" label="SALVEAZA TIP TRANSPORT"
    class="p-button-success p-mt-2 p-mb-2 customButton"></button>
</p-dialog>


<!-- payment type -->
<p-dialog *ngIf="offerData" [closable]="false" [style]="{'width':'390px','max-height': '90%'}" [draggable]="false"
  [modal]="true" [(visible)]="showPaymentModal">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">MODALITATEA DE PLATA</span>
    <div class="closeSvgRight" (click)="showPaymentModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <mat-checkbox (click)="setAdvancedPayment()" *ngIf="offerData && !offerData.returned"
    [(ngModel)]="offerData.isAdvancePayment">Plata avans</mat-checkbox>
  <mat-form-field *ngIf="offerData.isAdvancePayment" class="noLabel" style="width:100%">
    <input type="number" [(ngModel)]="offerData.advancePaymentValue" matInput placeholder="Suma avans">
  </mat-form-field>


  <div class=" p-mt-4">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" inputId="finishTypes" appendTo="body" [options]="finishTypes" name="value"
        [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="offerData.finishType"
        (onChange)="setPaymentTypes($event)">
      </p-dropdown>
      <label for="finishTypes">Finalizare</label>
    </span>
  </div>

  <div *ngIf="offerData.finishType&& offerData.finishType!='undefined'" class=" p-mt-4">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" inputId="paymentTypes" appendTo="body" [options]="paymentTypes"
        name="value" [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="offerData.paymentType">
      </p-dropdown>
      <label for="paymentTypes">Modalitate de plata</label>
    </span>
  </div>

  <div *ngIf="offerData.paymentType=='op' || offerData.paymentType=='cec'|| offerData.paymentType=='bo'" class="p-mt-4">
    <span class="p-float-label">
      <p-inputNumber [style]="{'width':'100%'}" inputId="integeronly" [min]="0" [max]="90" mode="decimal"
        [useGrouping]="false" [(ngModel)]="offerData.paymentTerm">
      </p-inputNumber>
      <label for="payterm">Termen zile</label>
    </span>
  </div>

  <mat-form-field class="noLabel" style="width:100%">
    <textarea style="height: 90px;" [(ngModel)]="offerData.observations" matInput placeholder="Observatii"></textarea>
  </mat-form-field>

  <button *ngIf="offerData&&offerData.status!='COMANDA'"
    (click)="validatePayment()?showPaymentModal=false:showPaymentModal=true" pButton pRipple type="button"
    label="SALVEAZA DATELE" class="p-button-success p-mt-2 finishAction noMargin"></button>
</p-dialog>


<!-- Adresa livrare alternativa -->
<p-dialog *ngIf="offerData" [closable]="false" [style]="{width: '500px','max-height': '90%'}" [draggable]="false"
  [modal]="true" [(visible)]="showAlternativeDelivery">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">LIVRARE SI TRANSPORT!</span>
    <div class="closeSvgRight" (click)="showAlternativeDelivery=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  

  <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select [(ngModel)]="selectedValueCounty" (selectionChange)='setCountyDelivery($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field *ngIf="filteredOptions" style="width:100%" class="example-full-width noLabel">
    <mat-label>Localitate</mat-label>
    <mat-select (selectionChange)='setLocalityDelivery($event)'>
      <mat-option *ngFor="let locality of localities.rows" [value]="locality">
        {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village }})</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <div style="display:flex" *ngIf="filteredOptions">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input [(ngModel)]="deliverStreetAlternative" matInput placeholder="Strada">
    </mat-form-field>
  </div> -->

  <div class="p-mt-4">
    <span class="p-float-label">
      <input inputId="delivery" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="deliverStreetAlternative" />
      <label for="delivery">Strada</label>
    </span>
  </div>

  <div class="p-grid p-fluid">
    <div class="p-col-6 p-mt-4">
      <span class="p-float-label">
        <input type="tel" pattern="[0-9]+" inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text"
          pInputText [(ngModel)]="offerData.deliveryContactPhone" />
        <label for="street">Telefon contact</label>
      </span>
    </div>
    <div class="p-col-6 p-mt-4">
      <span class="p-float-label">
        <p-calendar [minDate]="yesterday" [showIcon]="true" [style]="{'text-align':'left','width':'100%'}"
          [panelStyle]="{'width':'30%','min-width': '35vw'}" appendTo="body" [firstDayOfWeek]="1" [touchUI]="true"
          [readonlyInput]="true" dateFormat="dd.mm.yy" inputId="deliverDate" [(ngModel)]="offerData.deliverDate">
        </p-calendar>
        <label for="deliverDate">Termen livrare</label>
      </span>
    </div>
  </div>

  Transport

  <div class="p-mt-4">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" inputId="transportType" appendTo="body" [options]="transportTypes"
        name="value" [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="offerData.transportType">
      </p-dropdown>
      <label for="transportType">Tip transport</label>
    </span>
  </div>

  <!-- <div style="margin:10px;">
    <mat-checkbox [(ngModel)]="offerData.pickedByClient" (ngModelChange)="onChangePickedClient($event)">
      Ridica Clientul
    </mat-checkbox>
  </div> -->

  <div class=" p-mt-2">
    <div class="p-field-checkbox">
      <p-checkbox [(ngModel)]="offerData.pickedByClient" binary="true" inputId="pickedByClient"></p-checkbox>
      <label for="pickedByClient"> Ridică Clientul</label>
    </div>
  </div>

  <div *ngIf="offerData.pickedByClient" class="p-mt-4">
    <span class="p-float-label">
      <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="offerData.pickedByClientData.driverName" />
      <label for="street">Sofer</label>
    </span>
  </div>
  <div *ngIf="offerData.pickedByClient" class="p-grid p-fluid">
    <div class="p-col-6 p-mt-4">
      <span class="p-float-label">
        <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="offerData.pickedByClientData.driverPhone" />
        <label for="street">Telefon sofer</label>
      </span>
    </div>
    <div class="p-col-6 p-mt-4">
      <span class="p-float-label">
        <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="offerData.pickedByClientData.driverID" />
        <label for="street">Serie-Numar buletin</label>
      </span>
    </div>
  </div>

  <div *ngIf="offerData.pickedByClient" class="p-grid p-fluid">
    <div class="p-col-6 p-mt-4">
      <span class="p-float-label">
        <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="offerData.pickedByClientData.carNumber" />
        <label for="street">Numar Masina</label>
      </span>
    </div>
    <div class="p-col-6 p-mt-4">
      <span class="p-float-label">
        <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="offerData.pickedByClientData.trailerNumber" />
        <label for="street">Numar Remorca</label>
      </span>
    </div>
  </div>

  <!-- <div *ngIf="!offerData.pickedByClient" style="margin:10px;">
    <mat-checkbox [(ngModel)]="offerData.coupling" (ngModelChange)="onChangeCoupling($event)">
      Cuplaj
    </mat-checkbox>
  </div> -->

  <div *ngIf="!offerData.pickedByClient" class="p-mt-2">
    <div class="p-field-checkbox">
      <p-checkbox [(ngModel)]="offerData.coupling" binary="true" inputId="pickedByClient"></p-checkbox>
      <label for="pickedByClient"> Cuplaj</label>
    </div>
  </div>



  <button (click)="alternativeDeliveryAdress()" pButton pRipple type="button" label="SALVEAZA DATELE"
    class="p-button-success p-mt-2 finishAction noMargin"></button>



</p-dialog>

<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '250px'}">
  <ng-template pTemplate>
    <div>Nume Sofer {{offerData.pickedByClientData.driverName}}</div>
    <div>Telefon Sofer {{offerData.pickedByClientData.driverPhone}}</div>
    <div>Serie-Numar buletin {{offerData.pickedByClientData.driverID}}</div>
    <div>Numar Masina {{offerData.pickedByClientData.carNumber}}</div>
    <div>Numar Remorca {{offerData.pickedByClientData.trailerNumber}}</div>
  </ng-template>
</p-overlayPanel>

<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="large" color="#7fc3d6" type="ball-circus"></ngx-spinner>