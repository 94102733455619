import { Component, OnInit, ViewChild, HostListener, Inject } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import { OfferService } from "../services/offer.serice";
import { FilterComponent } from '../../../helpers/filter.component';
import { CarettaService } from '../../../helpers/caretta.helper'
import { NotifyService } from "../../../helpers/notify.service";
import { environment } from '../../../../environments/environment';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends FilterComponent implements OnInit {
  data;
  dataBkp;
  showDetailModal = false;
  internallCommands;
  internallCommandData;
  articlesFromInternallCommand;
  articlesFromPackage;

  showCustodyModal = false;
  showVerifyModal = false;
  selectedCommand;
  selectedPackage;
  selectedPrintUrl;
  loadingData = false;
  serviceUrl = environment.serviceUrl;
  _limitNumber;
  showStatus = false;

  visibleSidebar5 = true;

  finishTypes = [
    { label: 'CM', value: 'casaMarcat' },
    { label: 'F', value: 'factura' },
    { label: 'A', value: 'aviz' }
  ]

  // paymentTypes = [
  //   { label: 'CM', value: 'casaMarcat' },
  //   { label: 'OP', value: 'op' },
  //   { label: 'CASH', value: 'cash' }
  // ]

  paymentTypes = [
    { label: 'Cash varsamant', value: 'cash' },
    { label: 'Cash bon fiscal', value: 'cashbon' },
    { label: 'OP', value: 'op' },
    { label: 'CARD', value: 'card' },
    { label: 'Rate TBI', value: 'TBI' }
  ];

  calculatedStatuses = [
    { label: 'Comanda', value: 'UNDISTRIBUTED' },
    { label: 'Productie', value: 'INPROGRESS' },
    { label: 'Completa', value: 'COMPLETE' },
    { label: 'Factura', value: 'INVOICE' },
    { label: 'Validare', value: 'VERIFY' },
    { label: 'Custodie', value: 'CUSTODY' },
    { label: 'Retur', value: 'RETURNED' }
  ]

  token;
  ecrm;


  showSearch = false;
  @ViewChild('dt') table2;
  constructor(private router: Router, private route: ActivatedRoute, private offerService: OfferService,
    private carettaService: CarettaService, private notify: NotifyService, @Inject('LOCALSTORAGE') private store: any, private spinner: NgxSpinnerService) {
    super();
  }
  routeData = {
    routeName: "",
    delegate: {},
    driverName: "",
    car: "",
    name: "",
    commands: [],
    routeDate: new Date(),
    uuid: null,
  };
  company;
  hasReprintRight = false;
  hasDashoardRight = false;
  dd = new Date()
  stopDate = new Date();
  startDate = new Date(this.dd.setMonth(this.dd.getMonth() - 1));

  async ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.ecrm = this.route.snapshot.paramMap.get('ecrm');
    if (this.token) {
      this.store.set('localuser', { token: this.token });
    }



    this.route.data
      .subscribe((data) => {
        this.data = data.dashboardData;
        this.dataBkp = this.data;
      });
    this.company = this.decodeToken(this.store.get('localuser').token).company;
    this.hasReprintRight = await this.checkAccess('REPRINT_LABEL');
    this.hasDashoardRight = await this.checkAccess('DASHBOARD_ENODE');
  }

  getPaymentString(key) {
    let paymentTypes = {
      'cash': 'Cash varsamant',
      'cashbon': 'Cash bon fiscal',
      'op': 'OP',
      'card': 'CARD',
      'TBI': 'Rate TBI'
    };
    return paymentTypes[key]
  }

  async loadAllData() {
    this.loadingData = true;
    this.data = await this.offerService.loadDashboardData(this.route.snapshot.data.type, false, this.startDate, this.stopDate);
    this.loadingData = false;
  }

  async clickTable(row) {
    this.showStatus = false;
    this.selectedCommand = row;
    if (row.calculatedStatus == "CUSTODY") {
      this.showCustodyModal = true;
      return;
    } else if (row.calculatedStatus == "VERIFY") {
      this.showVerifyModal = true;
      return;
    }

    this.showDetailModal = true;
    if (row.calculatedStatus == "INPROGRESS" || row.calculatedStatus == "COMPLETE" || row.calculatedStatus == "INVOICE") {
      //   //load internall commands
      let data: any = await this.offerService.loadCommandData(row.commandId)
      for (const article of data.internallCommands) {
        if (article.packages.length > 0) {
          this.showStatus = true;
        }
      }
      this.internallCommands = data.internallCommands;
      return;
    }

  }

  goToOffer(row) {

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/offer`, { offerId: row.offerUuid }])
    );

    window.open(url, '_blank');


  }

  async loadRemainingInternallCommandsArticles(internallCommandId) {
    let data: any = await this.offerService.loadRemainingInternallCommandsArticles(internallCommandId)
    this.articlesFromInternallCommand = data;
    for (let article of this.articlesFromInternallCommand) {
      let mDimension = this.getModule(article.d1, article.d2);
      if (mDimension) {
        article.displayName = `${this.round2Decimals(article.quantity1)} x ${mDimension} ${article.name}`
      }
      else if (!mDimension && article.d1 > 0 && article.d2 > 0) {
        article.displayName = `${this.round2Decimals(article.quantity1)} ${article.measureUnit} x ${this.round2Decimals(article.d1)} ${this.round2Decimals(article.d2)}  ${article.name}`
      }
      else if (!mDimension && article.d1 > 0 && !article.d2) {
        article.displayName = `${this.round2Decimals(article.quantity1)} ${article.measureUnit} x ${this.round2Decimals(article.d1)} ML ${article.name}`
      }
      else {
        article.displayName = `${this.round2Decimals(article.quantity1)} ${article.measureUnit} x ${article.name}`
      }

    }
  }

  setPackage(packageData, internallCommand) {
    this.internallCommandData = internallCommand.data;
    this.selectedPackage = packageData;
    this.selectedPrintUrl = internallCommand.printUrl;
    //this.articlesFromPackage = this.selectedPackage.articles;
    for (let article of this.selectedPackage.data.articles) {
      article.displayName = `${this.round2Decimals(article.producedQuantity1)} x ${this.getModule(article.d1, article.d2) ? this.getModule(article.d1, article.d2) : ''}${!this.getModule(article.d1, article.d2) && article.d1 > 0 ? this.round2Decimals(article.d1) + article.measureUnit : article.measureUnit} ${article.name}`
      let mDimension = this.getModule(article.d1, article.d2);
      if (mDimension) {
        article.displayName = `${this.round2Decimals(article.producedQuantity1)} BUC x ${mDimension} ${article.name}`
      }
      else if (!mDimension && article.d1 > 0 && article.d2 > 0) {
        article.displayName = `${this.round2Decimals(article.producedQuantity1)} BUC x (${this.round2Decimals(article.d1)}M/${this.round2Decimals(article.d2)}M)  ${article.name}`
      }
      else if (!mDimension && article.d1 > 0 && !article.d2) {
        article.displayName = `${this.round2Decimals(article.producedQuantity1)} BUC x ${this.round2Decimals(article.d1)} ML ${article.name}`
      }
      else {
        article.displayName = `${this.round2Decimals(article.producedQuantity1)} ${article.measureUnit} x ${article.name}`
      }
    }
  }

  getModule(d1, d2) {
    return this.carettaService.getModule(d1, d2);
  }

  round2Decimals(value) {
    return Math.round(value * 100) / 100;
  }

  async changeStatusCustody() {
    //check access
    let res: any = await this.offerService.checkAccess("VALIDATE_CUSTODY").toPromise()
    if (!res.hasAccess) {
      this.notify.warningMessage("Nu aveti acces la validarea custodiilor!");
      this.showCustodyModal = false;
      return;
    }

    await this.offerService.changeStatus(this.selectedCommand.commandId, "IN_PROGRESS");
    this.notify.successMessage("Comanda a fost trimisa cu succes catre logistica!");
    this.showCustodyModal = false;
    window.location.reload();
  }

  async changeStatusVerify() {
    this.showVerifyModal = false;

    let res: any = await this.offerService.checkAccess("VALIDATE_TECHNICAL_DRAW").toPromise()
    if (!res.hasAccess) {
      this.notify.warningMessage("Nu aveti acces la validarea tehnica!");
      return;
    }

    if (this.selectedCommand.custody) {
      this.spinner.show();
      await this.offerService.changeStatus(this.selectedCommand.commandId, "CUSTODY");

      this.spinner.hide();
      this.notify.successMessage("Statusul comenzii schimbat in Custodie!");
    }
    else if (this.selectedCommand.pickedClient) {
      this.spinner.show();
      await this.offerService.changeStatus(this.selectedCommand.commandId, "IN_PROGRESS");
      this.routeData.routeName = `RC ${this.selectedCommand.commandNumber} ${this.selectedCommand.clientName}`;
      this.routeData.commands.push(this.selectedCommand.commandUuid);
      this.routeData.driverName = "CLIENT";

      await this.offerService.saveRouteData(this.routeData, this.selectedCommand.pickedClient);

      this.spinner.hide();
      this.notify.successMessage("Traseu generat pentru comanda ridicata de client!");
    }
    else {
      this.spinner.show();
      await this.offerService.changeStatus(this.selectedCommand.commandId, "IN_PROGRESS");

      this.spinner.hide();
      this.notify.successMessage("Comanda a fost trimisa cu succes catre logistica!");
    }

    let dashboardData = await this.offerService.loadDashboardData(this.route.snapshot.data.type, false, this.startDate, this.stopDate);
    this.data = dashboardData;
    this.dataBkp = this.data;
  }


  onDateSelect(value, specificDate, filter) {
    let formattedDate = this.formatDate(value);
    this.table2.filter(formattedDate, specificDate, filter)
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  clearTable() {
    this.table2.clear();
  }

  async reprintLabel() {
    console.log(this.internallCommandData)
    console.log(this.selectedPackage)

    let packageData = {
      commandData: this.selectedCommand.commandSeries + " " + this.selectedCommand.commandNumber,
      uuid: this.selectedPackage.uuid,
      articleLocation: this.internallCommandData.articleLocation,
      machineSymbol: this.internallCommandData.machineSymbol,
      printUrl: this.selectedPrintUrl
    }

    console.log(packageData)

    await this.offerService.reprintLabelCaretta(packageData);

    this.notify.successMessage("Eticheta a fost trimisa catre imprimanta!")
  }

  async reprintLabelv2() {
    let response: any = await this.offerService.reprintLabelCarettav2(this.selectedPackage.uuid);

    //send label to local print service
    //await this.offerService.printLocal(response.labelTxtv2);

    this.notify.successMessage("Eticheta a fost trimisa catre imprimanta!")
  }

  async reprintLabelGlobalv2() {
    let response: any = await this.offerService.reprintLabelGlobalv2(this.selectedPackage.uuid);

    //send label to local print service
    //await this.offerService.printLocal(response.labelTxtv2);

    this.notify.successMessage("Eticheta a fost trimisa catre imprimanta!")
  }

  filterMobile(typeOfData) {
    console.log(typeOfData)
  }

  async checkAccess(actionKey) {
    let res: any = await this.offerService.checkAccess(actionKey).toPromise()
    return res.hasAccess;
  }

  decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  async generatePalletPdf(internallCommand) {
    this.spinner.show();
    let res: any = await this.offerService.generatePalletPdf(internallCommand.uuid).toPromise();
    this.spinner.hide();
    window.open(`${this.serviceUrl}/public/${res.pdfName}`);
    //this.spinner.hide();
  }

  async populatePrice() {
    let res: any = await this.offerService.populatePrice();
  }

  // async fixDeliveryNote() {
  //   let res: any = await this.offerService.fixDeliveryNote();
  // }

}
