import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './custom-http-interceptor';
import { getLocalStorage } from './local-store-management';
import { ConstantsService } from '../constants/constants.service';
import { SpinnerService } from './spinner/spinner.service';
import { NotifyService } from './notify.service';

@NgModule({})
export class SharedProvidersModule {
  static forRoot(): ModuleWithProviders<SharedProvidersModule> {
    return {
      ngModule: SharedProvidersModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CustomHttpInterceptor,
          multi: true
        },
        {
          provide: 'LOCALSTORAGE',
          useFactory: getLocalStorage
        },
        ConstantsService,
        SpinnerService,
        NotifyService
      ]
    };
  }
}
 