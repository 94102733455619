import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductionUploadService {
  serviceUrl = environment.serviceUrl;
  constructor(@Inject('LOCALSTORAGE') private store: any, private http: HttpClient) { }

  saveFile(fileToUpload: File) {
    let client = JSON.parse(localStorage.getItem('client'));
    let company = JSON.parse(localStorage.getItem('company'));
    let user = JSON.parse(localStorage.getItem('localuser'));
    const url = `${this.serviceUrl}/productie/importLinie/${client.uid}/${user.username}/${company.uid}`;

    const formData: FormData = new FormData();
    formData.append('prodLineFile', fileToUpload, fileToUpload.name);
    return this.http.post(url, formData);
  }

}
