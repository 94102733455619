<div style="display:flex;justify-content: center; margin-top:10px">
  <div *ngIf="!offerData || offerData.categories.length==0"
    style="display:flex;justify-content: center; margin-top:10px">


    <div *ngIf="selectedOfferType">
      <div class="selectedOfferDetail">
        {{selectedOfferType.name}}
      </div>
    </div>

    <div *ngIf="selectedColor">
      <div class="selectedOfferDetail">
        {{selectedColor}}</div>

    </div>
    <div *ngIf="selectedMaterial">
      <div class="selectedOfferDetail">
        {{selectedMaterial}}</div>
    </div>

    <div *ngIf="selectedThickness">
      <div class="selectedOfferDetail">
        {{selectedThickness}}</div>

    </div>

    <div *ngIf="!selectedOfferType">
      <div (click)="getMaterial(offerType)" *ngFor="let offerType of offersType" class="offerDetail">
        {{offerType.name}}
      </div>
    </div>
    <div *ngIf="!selectedMaterial">
      <div (click)="getColors(material) " class="offerDetail" *ngFor="let material of materials">{{material}}</div>
    </div>
    <div style="display: flex;width: 240px;padding: 0px;margin: 0px;flex-wrap: wrap;" *ngIf="!selectedColor">
      <div (click)="getThickness(color) " class="offerDetail" *ngFor="let color of colors">{{color}}</div>
    </div>

    <div *ngIf="!selectedThickness">
      <div (click)="selectThickness(item)" class="offerDetail" *ngFor="let item of thickness">{{item}}</div>
    </div>
  </div>

</div>

<div style="display:flex;" *ngIf="offerData && offerData.categories.length>0">

  <div style="margin: 0 0 0 auto;">
    <mat-card style="margin:5px; width:650px" *ngFor="let item of offerData.categories">
      <div class="categoryTitle" style="text-align: center">{{item.category}}
        {{item.category=="SISTEM PLUVIAL" ? 
         offerData.offerData && offerData.offerData.pluvialType?(offerData.offerData.pluvialType):pluvialType
         :''}}</div>
      <table id="customers" style="width:100%">
        <tr style="font-size:14px;">
          <th class="name">Nume</th>
          <th class="quantity">Cant.</th>
          <th class="unitMeasure">UM</th>
          <th class="listPrice">Extensie</th>
          <th class="listPrice">Gestiune Productie</th>
          <th class="listPrice">Gestiune Predare</th>
        </tr>
        <tr *ngFor="let art of item.articles; let itemI = index">

          <ng-container
            *ngIf="art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
            <td [ngClass]="art.data[0].quantity?'name selectedItem':'name'">{{art.articleName}}
            </td>
            <td style="background-color: #CCffCC;">
              <input
                [disabled]="!art.data[0].articleLocation||!art.data[0].simbolGestiuneProductie||!art.data[0].simbolGestiune || offerData.status=='COMMAND-PRODUCTION'"
                #quantityInputs (keyup)="recalculate($event)" class="quantity" [(ngModel)]="art.data[0].quantity"
                type="number">
            </td>
            <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
            </td>

            <td> {{art.data[0].articleLocation}} </td>

            <td> {{art.data[0].simbolGestiuneProductie}} </td>
            <td> {{art.data[0].simbolGestiune}} </td>
          </ng-container>

          <ng-container *ngIf="art.data.length!=1 ">
            <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
              {{art.articleName}}
            </td>
            <td *ngIf="art.data[0].PROMOTIE==1" [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
              {{art.articleName}}
            </td>
            <td style="    text-align: center;color: #536c79;font-weight: bold;">
              {{round2Decimals(getTotalQuantity(art))}}
            </td>
            <td class="unitMeasure">{{art.data[0].UM}}</td>


            <td> {{art.data[0].articleLocation}} </td>

            <td> {{art.data[0].simbolGestiuneProductie}} </td>
            <td> {{art.data[0].simbolGestiune}} </td>

          </ng-container>
        </tr>

      </table>


    </mat-card>
  </div>
  <div>
    <span *ngFor="let item of offerData.categories; let i = index">
      <mat-card style="margin:5px; width:280px" *ngIf="hasMultipleArts(item)">
        <div class="categoryTitle" style="text-align: center">{{item.category}}</div>


        <ng-container *ngFor="let art of item.articles;">
          <ng-container *ngIf="art.data.length!=1">
            <div *ngIf="art.data[0].PROMOTIE!=1" class="articleTitle">{{art.articleName}}</div>
            <div *ngIf="art.data[0].PROMOTIE==1" style="color:#8e24aa" class="articleTitle">{{art.articleName}}</div>

            <table id="customers" style="width:100%">
              <tr>
                <th *ngIf="!art.data[0].D2" class="unitMeasure center fs12 singleDimension">Lung</th>
                <th *ngIf="art.data[0].D2" class="unitMeasure center fs12">Lung</th>
                <th *ngIf="art.data[0].D2" class="unitMeasure center fs12">Lat</th>

                <th
                  *ngIf="selectedOfferType &&(selectedOfferType.key=='AA' || selectedOfferType.key=='AP' || selectedOfferType.key=='MM' || selectedOfferType.key=='MP') && isTigla(art.articleName)"
                  class="unitMeasure center fs12"></th>
                <th class="detailQuantity center fs12">{{art.data[0].UM1}}</th>
                <th class="center fs12"> {{art.data[0].UM}}</th>
              </tr>
              <tr *ngFor="let sub of art.data;let ii = index">

                <ng-container *ngIf="(!showZeroQuantityArticles)|| (showZeroQuantityArticles && sub.quantity>0)">
                  <td>{{round2Decimals(sub.D1)}}</td>
                  <td *ngIf="sub.D2">{{sub.D2>0?round2Decimals(sub.D2):''}}</td>
                  <td
                    *ngIf="selectedOfferType && (selectedOfferType.key=='AA' || selectedOfferType.key=='AP' || selectedOfferType.key=='MM' || selectedOfferType.key=='MP') && isTigla(art.articleName)">
                    M{{ii+1}}</td>
                  <td style=" background-color: #CCffCC;"><input (keyup)="recalculate($event)" class="detailQuantity"
                      [(ngModel)]="sub.quantity" type="number"></td>
                  <td>{{round2Decimals(getArticleTotalQuantity(sub))}}
                  </td>

                </ng-container>
              </tr>
            </table>
          </ng-container>
        </ng-container>

      </mat-card>
    </span>
  </div>


  <div class="noprint" style="width:300px;margin: 0 auto 0 0;">
    <div style="margin:5px;width:275px;overflow: auto;">

      <mat-card style=" background: #2f404a;">

        <div style="color:#fff;text-align: center;font-size:22px;" *ngIf="offerData">
          {{offerData.contact.name}}
        </div>
        <div style="color:#fff;text-align: center;font-size:18px; margin-top:5px" *ngIf="offerData">
          {{offerData.contact.phone}}
        </div>
        <div style="color:#fff;font-size:16px; margin-top:10px">
          <div style="text-align: center" *ngIf="selectedOfferType">
            {{selectedOfferType.name}}
          </div>
          <div style="text-align: center" *ngIf="selectedOfferType">
            {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
          </div>
          <div
            *ngIf="selectedOfferType && (selectedOfferType.key=='AA' || selectedOfferType.key=='AP' || selectedOfferType.key=='MM' || selectedOfferType.key=='MP') "
            style="display:flex;justify-content: center;margin-top:10px;">
            <img [src]="'./assets/caretta/'+selectedOfferType.key+'_'+selectedColor+'_'+selectedMaterial+'.png'"
              alt="Imagine indisponibila" width="250" height="175">
          </div>
        </div>
      </mat-card>
      <mat-card>

        <mat-divider></mat-divider>
        <div style="width:100%">
          <div *ngIf="offerData.status=='OFFER-PRODUCTION'" (click)="saveOffer()"
            style="text-align:center;padding:10px;margin: 5px 0;color: #E0004D;font-size: 19px;background:#003349;font-weight: bold;">
            SALVARE OFERTA</div>
          <div *ngIf="offerData.status=='OFFER-PRODUCTION'" (click)="generateProductionInternallCommands()"
            style="text-align:center;padding:10px;margin: 5px 0;color: #E0004D;font-size: 19px;background:#003349;font-weight: bold;">
            GENEREAZA COMANDA</div>

          <div *ngIf="offerData.status=='COMMAND-PRODUCTION'"
            style="text-align:center;    padding: 10px 0 0 0;  margin: 5px 0 0 0;color: #E0004D;font-size: 22px;font-weight: bold;">
            <div>COMANDA PLASATA</div>
            <div>
              <div *ngFor="let internallCommand of offerData.internallCommands">
                {{internallCommand.data.commandSeries}} {{internallCommand.data.commandNumber}}
              </div>
            </div>

            <div *ngIf="internalCommandNumbers != null">
              <div *ngFor="let internalCommands of internalCommandNumbers.internalCommands">
                {{internalCommands.data.commandSeries}} {{internalCommands.data.commandNumber}}
              </div>
            </div>


          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<p-dialog [closable]="false" [positionTop]="20" [draggable]="false" [modal]="true" [(visible)]="showPluvialTypeModal"
  [style]="{'top': '80px'}">
  <p-header>
    <span class="dialog-title">Sistem pluvial!</span>
  </p-header>


  <mat-radio-group [(ngModel)]="pluvialType" aria-label="Tip sistem pluvial" style="text-align: center;">
    <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="MAT">MAT</mat-radio-button>
    <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="BR">BR</mat-radio-button>
  </mat-radio-group>

  <div class="confirmSvg" (click)="checkPluvialType();" [inlineSVG]="'/assets/images/checked.svg'">
  </div>
</p-dialog>














<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="large" color="#7fc3d6" type="ball-circus"></ngx-spinner>