import { Component, OnInit, OnDestroy, Inject, HostListener, ViewChild } from "@angular/core";
import { OfferService } from "../services/offer.serice";
import { CartDataModel } from "../../../helpers/datatable/data/cart-data.model";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { NotifyService } from "../../../helpers/notify.service";
import { MatDialog } from "@angular/material/dialog";
import { DecisionModalComponent } from "../../../decision-modal/decision-modal.component";
import { OfferModel } from "../../models/offer.model";
import { environment } from "../../../../environments/environment";
import { DateAdapter } from '@angular/material/core';
import { UntypedFormControl } from '@angular/forms';

import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { LocationStrategy } from '@angular/common';


@Component({
    selector: 'enode-offerinter-details',
    templateUrl: './offer-details.component.html',
    styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailsInterComponent implements OnInit, OnDestroy {

    showZeroQuantityArticles = false;
    firstLoad = true;
    scannedItems: CartDataModel[] = [];
    currentOffer: any = { articles: [], offerData: {} };
    colors;
    materials;
    thickness;
    selectedOfferType;
    selectedColor;
    selectedMaterial;
    selectedThickness;

    totalPrice = 0;
    totalMinimumPrice = 0;
    totalWithOutDiscount = 0;
    discountValue = 0;
    discountPercent = 0;
    adaosValue;
    adaosPercent;
    showAdaos = false;
    includePrices = false;
    generateNewOffer = false;

    temporaryArticles = [];
    clientData;
    offerId;
    tempOfferId;

    finishTypes = [
        { label: 'Factura', value: 'factura' },
        { label: 'Aviz', value: 'aviz' }
    ]
    paymentTypes = [
        { label: 'OP', value: 'op' },
        { label: 'CEC', value: 'cec' },
        { label: 'BO', value: 'bo' },
        { label: 'Cash', value: 'cash' }
    ];

    transportTypes = [
        { label: 'Decopertata', value: 'decopertata' },
        { label: 'Macara', value: 'macara' },
        { label: 'Prelata', value: 'prelata' }

    ]

    clientTypes = [
        { label: 'Persoana fizica', value: 'PF' },
        { label: 'Persoana juridica', value: 'PJ' }
    ];
    clientId;
    serviceUrl = environment.serviceUrl;

    invoiceTypes = OfferModel.InvoiceType;
    minDate = new Date();

    selectedClient: any = {};
    selectedDealer: any = {};
    selectedDealerAgent: any = {};
    dealerAgents: any = [];

    offerData;
    token;
    clients = [];

    selectedPhone;
    selectedCounty;
    onDestroy$ = new Subject<boolean>();
    pluvialType = "SL";
    showPluvialTypeModal = false;
    debug = false;

    //temporary counties todo
    counties;
    localities;
    myControl = new UntypedFormControl();
    filteredOptions;

    addNewClient = false;
    editClient = false;
    newClientType;
    showClientsModal = false;

    clientNameSearch;
    clientPhoneSearch;
    clientFiscalCodeSearch;
    differentClient = true;

    stockDetail;

    newClientData = {
        clientType: "",
        name: "",
        fiscalCode: "",
        tradeRegister: "",
        phone: "",
        county: "",
        locality: null,
        street: "",
        scara: "",
        number: "",
        bloc: "",
        etaj: "",
        apartament: ""
    }
    editClientData = {
        isPF: false,
        clientId: "",
        agendaId: "",
        name: "",
        fiscalCode: "",
        tradeRegister: "",
        phone: "",
        county: "",
        locality: null,
        street: "",
        scara: "",
        number: "",
        bloc: "",
        etaj: "",
        apartament: ""
    }
    totalOfferWeight = 0;

    canChooseClientType = false;
    canCancelCommand = false;
    canPrintInternallCommandPdf = false;
    showPickedByClient = false;

    showAlternativeDelivery = false;
    cancelCommandFlag = false;
    modifyCommandFlag = false;
    cancelObservations = "";
    username;

    isProduction = false;
    showImage = false;
    image;

    dispecer = false;
    editTransport = false;

    selectedArticle;
    locations;
    f1 = false; f2 = true; f3 = true;
    isFoundSearch = false;
    searchText;
    showPaymentModal = false;
    keyWords = ["SARMA", "PANOU VOPSIT", "PANOU BORDURAT ZINCAT", "OTEL BETON", "OTEL BETON IN", "OTEL BETON IN BARA PC 52", "PLASA SUDATA", "PLASA SUDATA GARD", "TEAVA", "CUIE CONSTRUCTII", "ROTUNDA", "RECTANGULARA"];

    invalidPrice = false;

    selectedCountyName;
    selectedLocalityName;

    selectedLocation;
    selectedLocationString;

    validFiscalCode;
    validatedPartnerData;

    hasDispecerAccess = false;

    nameSearch = false;
    version = environment.version;
    clientSoldData;

    canEditQuantityPrice = false;
    yesterday;

    selectedValueCounty;
    deliverStreetAlternative;

    @ViewChild('fileUpload') fileUpload: any;
    constructor(
        private offerService: OfferService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private notify: NotifyService,
        private _adapter: DateAdapter<any>,
        @Inject('LOCALSTORAGE') private store: any,
        private config: PrimeNGConfig, private translateService: TranslateService,
        private location: LocationStrategy
    ) {

        window.onbeforeunload = function (event) {

            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';
        };

        // preventing back button in browser 
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
            history.pushState(null, null, window.location.href);
        });
    }

    async ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
        if (this.token) {
            this.store.set("localuser", { token: this.token })
        }
        this.offerId = this.route.snapshot.paramMap.get('offerId');
        this.counties = await this.offerService.getCounties();

        let result: any = await this.offerService.getLocations();
        this.locations = result.locations;
        await this.getOfferData(null, this.dispecer);
        this._adapter.setLocale('ro');

        this.onChanges();
        let tokenData = this.decodeToken(this.store.get('localuser').token);
        this.username = tokenData.username;

        if (this.offerData.status == 'COMANDA') {
            this.f1 = true;
            this.f2 = false;
            this.f3 = false;
        }
        this.hasDispecerAccess = await this.checkAccess('DISPECER_MODIFY');
        if (!this.offerData.paymentTerm) {
            this.offerData.paymentTerm = 0;
        }


        if (this.offerData.finishType == "factura") {
            this.paymentTypes = [
                { label: 'OP', value: 'op' },
                { label: 'CEC', value: 'cec' },
                { label: 'BO', value: 'bo' }
            ];
        }
        else if (this.offerData.finishType == "aviz") {
            this.paymentTypes = [{ label: 'Cash', value: 'cash' }];
        }

        if (this.offerData.status == "OFERTA") {
            this.canEditQuantityPrice = true;
        }

        this.yesterday = new Date();
        this.yesterday.setDate(this.yesterday.getDate() - 1);
    }

    translate(lang: string) {
        this.translateService.use(lang);
        this.translateService.get('primeng').subscribe(res =>
            this.config.setTranslation(res)
        );
    }

    decodeToken(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    async checkAccess(actionKey) {
        let res: any = await this.offerService.checkAccess(actionKey).toPromise()
        return res.hasAccess;
    }

    onChanges(): void {
        this.myControl.valueChanges.subscribe(val => {
            if (this.localities && this.localities.rows)
                this.filteredOptions = this.localities.rows.filter(option => option.localityName.toLowerCase().includes(val));
        });
    }

    initLocalities() {
        this.filteredOptions = this.localities.rows;
    }



    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {

        $event.returnValue = true;
        //}
    }

    localityChange() {
        console.log("filter")
    }

    checkPluvialType() {
        if (this.pluvialType == "SL") {
            this.notify.warningMessage("Selectati tip sistem pluvial!");
        }
        else {
            this.showPluvialTypeModal = false;
        }
    }

    //todo
    async loadLocalities(event) {

        this.newClientData.locality = "";
        this.spinner.show();
        if (event.value) {
            this.localities = await this.offerService.getLocalities(event.value.countyId);
        }
        else {
            this.localities = [];
        }

        this.spinner.hide();
        this.filteredOptions = this.localities.rows;
    }

    async getOfferData(locationId, isDispecer) {
        this.spinner.show();
        let res: any = await this.offerService.getOffersInter(this.offerId, locationId, isDispecer).toPromise();

        this.offerData = res;

        if (this.offerData?.client?.internalId) {
            let rr: any = await this.offerService.getSoldDetail(this.offerData.client.internalId);
            this.clientSoldData = rr;
        }

        this.offerData.deliverDate = this.offerData.deliverDate ? new Date(this.offerData.deliverDate) : null;
        this.offerData.categories = this.offerData && this.offerData.categories ? this.offerData.categories : [];
        //this.pluvialType = this.offerData.offerData.pluvialType;
        //preload agents
        this.offerData.invoiceType = "Client";
        if (!this.selectedOfferType) {
            this.selectedOfferType = res.offerData.selectedOfferType;
            this.selectedColor = res.offerData.selectedColor;
            this.selectedMaterial = res.offerData.selectedMaterial;
            this.selectedThickness = res.offerData.selectedThickness;
            this.currentOffer.deliveryDate = res.deliveryDate;
            this.currentOffer.invoiceType = res.invoiceType;
            this.currentOffer.finishType = res.finishType;
            this.currentOffer.paymentType = res.paymentType;
            this.currentOffer.observations = res.observations;
            this.currentOffer.phone = res.phone;
        }
        else {
            this.prefillClientData(this.offerData);

            this.offerData.contact = this.offerData.contact ? JSON.parse(this.offerData.contact) : this.offerData.contact;
            this.offerData.offerData.selectedOfferType = this.selectedOfferType;
            this.offerData.offerData.selectedColor = this.selectedColor;
            this.offerData.offerData.selectedMaterial = this.selectedMaterial;
            this.offerData.offerData.selectedThickness = this.selectedThickness;
            this.offerData.offerData.pluvialType = this.pluvialType;

        }
        //check if saved state

        if (this.temporaryArticles) {
            for (let temporaryArticle of this.temporaryArticles) {
                for (let category of this.offerData.categories) {
                    for (let article of category.articles) {
                        for (let detail of article.data) {
                            if (detail.DENUMIRE.includes(temporaryArticle.name) && detail.D1 == temporaryArticle.D1 && detail.D2 == temporaryArticle.D2 && detail.D3 == temporaryArticle.D3) {
                                detail.quantity = temporaryArticle.quantity;
                            }
                        }
                    }
                }
            }
        }

        if (this.offerData && this.offerData.dealer && this.offerData.client && this.offerData.client.fiscalCode == this.offerData.dealer.fiscalCode) {
            this.differentClient = false;
        }
        this.temporaryArticles = [];
        this.recalculate(true);

        if (!this.offerData.deliverDate) {
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            this.offerData.deliverDate = tomorrow;
        }

        if (!this.offerData.transportType) {
            this.offerData.transportType = "decopertata";
        }


        this.spinner.hide();

    }


    async onPhoneInputChange() {

        if (this.clientPhoneSearch.length >= 3) {
            this.clientFiscalCodeSearch = "";
            this.clientNameSearch = "";
            const data: any = await this.offerService.getClientData(encodeURIComponent(this.clientPhoneSearch), "phone");
            this.clients = data.rows;
        }
    }

    async onNameInputChange() {
        if (this.clientNameSearch.length >= 3) {
            this.nameSearch = true;
            this.clientFiscalCodeSearch = "";
            this.clientPhoneSearch = "";
            const data: any = await this.offerService.getClientData(encodeURIComponent(this.clientNameSearch), "name");
            this.clients = data.rows;
            this.nameSearch = false;
        }
    }

    async onFiscalCodeChange() {
        if (this.clientFiscalCodeSearch.length >= 3) {
            this.clientPhoneSearch = "";
            this.clientNameSearch = "";
            const data: any = await this.offerService.getClientData(encodeURIComponent(this.clientFiscalCodeSearch), "cui");
            this.clients = data.rows;
            return;
        }
        return;
    }


    async onClientSelected(event) {
        this.selectedClient = event.option.value;
        this.offerData.client = {
            fiscalCode: event.option.value.CODFISCAL,
            tradeRegister: event.option.value.REGCOM,
            name: event.option.value.DEN_PART,
            phone: event.option.value.TELEFON,
            internalId: event.option.value.CODPART
        }

        let rezz: any = await this.offerService.getClientData(event.option.value.CODFISCAL, "cui");

        //await this.offerService.getClientDetail(event.option.value.CODPART);

        this.offerData.client["address"] = {
            address: rezz.rows[0].ADRESA,
            city: rezz.rows[0].DEN_LOCALIT,
            conunty: rezz.rows[0].JUDET,
            headquarter: rezz.rows[0].headquarter
        }
    }

    displayPhone(client) {
        return client ? client.phone : '';
    }



    displayClientName(client) {
        return client ? client.name : '';
    }

    displayFiscalCode(client) {
        return client ? client.fiscalCode : '';
    }

    round2Decimals(value) {
        return Math.round(value * 100) / 100;
    }

    hasMultipleArts(item) {

        for (let article of item.articles) {
            if (article.data.length != 1) {
                return true;
            }
        }
        return false;
    }
    async cancelCommand() {
        this.spinner.show();
        let reload = true;
        try {
            await this.offerService.cancelCommand(this.offerData.data[0].commandId, "Anulata " + this.cancelObservations);
        }
        catch (e) {
            reload = false;
            this.spinner.hide();
        }

        if (this.dispecer) {
            //resend command with same number
            await this.finishCommand();
        }
        this.cancelCommandFlag = false;
        this.spinner.hide();
        if (reload) {
            window.location.reload();
        }
    }
    reset() {

        let dialogRef = this.dialog.open(DecisionModalComponent, {
            data: { title: "Salvare cantitati oferta", message: "Doriti salvarea cantitatilor de pe oferta actuala?" }
        });
        dialogRef.afterClosed().subscribe(async reset => {
            if (reset) {
                for (let category of this.offerData.categories) {
                    for (let article of category.articles) {
                        for (let detail of article.data) {
                            if (detail.quantity) {
                                let tempArticle = {
                                    DENUMIRE: detail.DENUMIRE,
                                    D1: detail.D1,
                                    D2: detail.D2,
                                    D3: detail.D3,
                                    quantity: detail.quantity,
                                    selectedColor: this.selectedColor,
                                    selectedThickness: this.selectedThickness,
                                };
                                this.temporaryArticles.push(tempArticle);
                            }
                        }
                    }
                }
                this.store.set("tempPrices", this.temporaryArticles);
            }
        });
    }

    loadPrices() {
        this.temporaryArticles = this.store.get("tempPrices");
        if (this.temporaryArticles) {
            for (let temporaryArticle of this.temporaryArticles) {
                for (let category of this.offerData.categories) {
                    for (let article of category.articles) {
                        for (let detail of article.data) {
                            let articleParsedName = this.getParentName(detail, this.selectedColor, this.selectedThickness);
                            let savedArticleParsedName = this.getParentName(temporaryArticle, temporaryArticle.selectedColor, temporaryArticle.selectedThickness);
                            if (articleParsedName == savedArticleParsedName && detail.D1 == temporaryArticle.D1 && detail.D2 == temporaryArticle.D2 && detail.D3 == temporaryArticle.D3) {
                                detail.quantity = temporaryArticle.quantity;
                            }
                        }
                    }
                }
            }
        }
        this.temporaryArticles = [];
        this.recalculate(true);
        this.notify.successMessage("Cantitati precompletate!")
    }

    getParentName(article, selectedColor, selectedThickness) {
        //get selected data   {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
        let tempDen = article.DENUMIRE;
        tempDen = tempDen.split("PLUS").join("");
        tempDen = tempDen.split("103").join("");
        tempDen = tempDen.split("104").join("");
        tempDen = tempDen.split("105").join("");
        tempDen = tempDen.split("106").join("");
        tempDen = tempDen.split(selectedColor).join("");
        tempDen = tempDen.split(selectedThickness).join("");
        tempDen = tempDen.split("BR").join("");
        tempDen = tempDen.split("MAT").join("");
        tempDen = tempDen.split("SL").join("");
        return tempDen.trim();
    }

    getMaterial(offerType) {
        //this.materials = [];
        this.spinner.show();

        this.offerData.offerData.selectedOfferType = offerType;
        this.selectedOfferType = offerType;
        this.offerService.getMaterials(offerType.key).subscribe(async (materials: any) => {
            this.materials = materials;
            this.spinner.hide();
            if (materials.length == 0) {
                this.spinner.show();
                await this.getOfferData(null, this.dispecer);
                this.offerId = this.tempOfferId;
                this.spinner.hide();
            }
        });
    }

    getColors(material) {
        this.spinner.show();
        this.materials = [];
        this.selectedMaterial = material;
        this.offerData.offerData.selectedMaterial = material;
        if (material != "SL") {
            let tokenData = this.decodeToken(this.store.get('localuser').token);
            if (tokenData.username == "agent1" || tokenData.username == "comenzi" || tokenData.username == "vasile.oslobanu" || tokenData.username == "parteneri.mici") {
                this.showPluvialTypeModal = true;
            }
            else {
                this.pluvialType = "BR";
            }
        }
        this.offerService.getColors(this.selectedOfferType.key, material).subscribe(async (colors: any) => {
            this.spinner.hide();
            this.colors = colors;
            if (colors.length == 0) {
                this.spinner.show();
                await this.getOfferData(null, this.dispecer);
                this.offerId = this.tempOfferId;
                this.spinner.hide();
            }
        });
    }

    getThickness(color) {
        //this.materials = [];
        this.spinner.show();
        this.selectedColor = color;
        this.offerData.offerData.selectedColor = color;
        this.offerService.getThickness(this.selectedOfferType.key, this.selectedMaterial, this.selectedColor).subscribe(async (thickness: any) => {

            this.thickness = thickness;
            this.spinner.hide();
            if (thickness.length == 0) {
                this.spinner.show();
                await this.getOfferData(null, this.dispecer);
                this.offerId = this.tempOfferId;
                this.spinner.hide();
            }

        });


    }

    async selectThickness(thickness) {
        this.spinner.show();
        this.selectedThickness = thickness;
        await this.getOfferData(null, this.dispecer);
        this.offerId = this.tempOfferId;
        this.spinner.hide();
    }

    getTotalQuantity(articleDetails) {
        let sum = 0;
        for (let articleDetail of articleDetails.data) {
            if (articleDetail.quantity && articleDetail.D2) {
                sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
            }
            else if (articleDetail.quantity) {
                sum += articleDetail.D1 * articleDetail.quantity;
            }
        }
        articleDetails.totalQuantity = sum;
        return sum;
    }

    getArticleTotalQuantity(articleDetail) {
        let sum = 0;
        if (articleDetail.quantity && articleDetail.D2) {
            sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
        }
        else if (articleDetail.quantity) {
            sum += articleDetail.D1 * articleDetail.quantity;
        }
        return sum;
    }

    getTotalPrice() {
        this.totalPrice = 0;
        for (let topCategory of this.offerData.topCategories) {
            for (let category of topCategory.categories) {
                for (let article of category.articles) {
                    for (let detail of article.data) {
                        if (detail.quantity) {
                            this.totalPrice += parseFloat(detail.quantity) * parseFloat(detail.agentPrice) * (article.d1 ? parseFloat(article.d1) : 1) * (article.d2 ? parseFloat(article.d2) : 1) * (article.d3 ? parseFloat(article.d3) : 1);
                        }
                    }
                }
            }
        }
        this.totalPrice = this.round2Decimals(this.totalPrice);
        return this.round2Decimals(this.totalPrice);
    }

    getTotalListPrice() {
        this.totalPrice = 0;


        return this.round2Decimals(this.totalPrice);
    }
    //copy price to all details of an article
    propagateQuantity(articles) {
        for (let article of articles) {
            article.agentPrice = articles[0].agentPrice;
        }
    }
    checkk(event) {
        // if (event && event.keyCode == 188) {
        //     this.notify.infoMessage("Folositi punctul ca separator pentru zecimale!")
        //     return;
        // }

        // console.log(event)
        // event.originalEvent.preventDefault();
        // event.originalEvent.stopPropagation();
    }

    async recalculate(event) {
        // if (event && event.keyCode == 188) {
        //     this.notify.infoMessage("Folositi punctul ca separator pentru zecimale!")
        //     return;
        // }

        this.totalPrice = 0;
        this.totalMinimumPrice = 0;
        this.totalWithOutDiscount = 0;
        this.discountValue = 0;
        this.discountPercent = 0;
        this.totalOfferWeight = 0;
        //price withoutDiscount
        this.invalidPrice = false;
        for (let topCategory of this.offerData.topCategories) {
            for (let category of topCategory.categories) {
                for (let article of category.articles) {
                    for (let detail of article.data) {

                        if (detail.quantity > 0 && ((this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.LISTA) || !detail.agentPrice) && detail.PROMOTIE != 1)) {
                            article.invalidPrice = true;
                            this.invalidPrice = true;
                        }
                        else {
                            article.invalidPrice = false;
                        }

                        if (detail.quantity) {
                            detail.quantity = detail.quantity.toString().replace("-", "");
                            detail.quantity = detail.quantity.toString().replace(",", "");
                            this.totalWithOutDiscount += parseFloat(detail.quantity) * parseFloat(detail.LISTA) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
                            this.totalPrice += parseFloat(detail.quantity) * parseFloat(article.data[0].agentPrice) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
                            this.totalMinimumPrice += parseFloat(detail.quantity) * parseFloat(article.data[0].agentMinimumPrice) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
                            if (article.data[0].MASA) {
                                this.totalOfferWeight += parseFloat(detail.quantity) * parseFloat(article.data[0].MASA) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
                            }

                        }

                    }
                }
            }
        }
        this.totalWithOutDiscount = this.round2Decimals(this.totalWithOutDiscount);
        this.totalPrice = this.round2Decimals(this.totalPrice);
        this.discountValue = this.round2Decimals(this.totalWithOutDiscount - this.totalPrice)
        this.discountPercent = this.round2Decimals((this.totalWithOutDiscount - this.totalPrice) / this.totalWithOutDiscount * 100);
        this.adaosValue = this.round2Decimals((this.totalPrice ? this.totalPrice / 1.19 : 0) - (this.totalMinimumPrice ? this.totalMinimumPrice / 1.19 : 0))
        this.adaosPercent = this.round2Decimals((this.totalPrice - this.totalMinimumPrice) / this.totalPrice * 100);

        if ((this.offerData.isAdvancePayment === true || this.offerData.isAdvancePayment === "true") && this.offerData.advancePaymentValue == 0) {
            this.offerData.advancePaymentValue = this.totalPrice;
        }

        this.offerData.totalPrice = this.totalPrice;
        this.offerData.selectedLocation = this.selectedLocation;
    }

    getSubtotal(price, quantity) {
        return this.round2Decimals(quantity ? parseFloat(price) * quantity : 0);
    }

    async saveOffer(status, message) {
        //check if dealer and data is completed
        if (!this.offerData.invoiceType) {
            this.notify.warning(`Selecteaza tip comanda!`);
            return;
        }

        if (this.round2Decimals(this.totalOfferWeight) < 25000) {
            this.notify.warning(`Greutatea comenzii este sub 25000 kg.`);
        }

        if (this.offerData.pickedByClient) {
            this.offerData.coupling = false;
        }
        if (status == "FINANCIAL_VALIDATION") {
            //////////////////////
            if (!this.offerData.client.fiscalCode) {
                this.notify.warning(`Oferta nu contine detalii client!`);
                return;
            }

            if (!this.offerData.deliverDate) {
                this.notify.warningMessage("Selectati data de livrare!");
                return;
            }

            if (!this.offerData.finishType) {
                this.notify.warningMessage("Selectati tip document!");
                return;
            }

            if (!this.offerData.paymentType) {
                this.notify.warningMessage("Selectati modalitatea de plata!");
                return;
            }

            if (!this.offerData.pickedByClient && !this.offerData.transportType) {
                this.notify.warningMessage("Selectati tip transport!");
                return;
            }

            if (this.offerData.invoiceType == 'Client' && (this.offerData.isAdvancePayment === true || this.offerData.isAdvancePayment === "true") && this.offerData.advancePaymentValue <= 0) {
                this.notify.warning(`Precizati suma avans!`);
                return;
            }

            if (this.offerData.pickedByClient) {
                this.offerData.coupling = false;
            }
            //filter categories, only articles with quantity
            let filteredCategories = [];
            let noLocation = false;
            let noPrice = false;
            for (let topCategory of this.offerData.topCategories) {


                topCategory.categories.map(categ => {
                    let filteredCategory = {
                        category: categ.category,
                        articles: []
                    }
                    categ.articles.map(article => {
                        let filteredArticle = {
                            articleName: article.articleName,
                            invalidPrice: article.invalidPrice,
                            totalQuantity: article.totalQuantity,
                            data: []
                        };
                        article.data.map(art => {
                            if (!art.agentPrice && parseFloat(art.quantity) > 0) {
                                noPrice = true;
                            }
                            if (art.stockDetail) {
                                for (let stock of art.stockDetail) {
                                    if (stock.conversionQuantity > 0 && stock.conversionValue == 1) {
                                        noLocation = true;
                                    }
                                }
                            }
                            if (parseFloat(art.quantity) > 0) {
                                filteredArticle.data = [...filteredArticle.data, art]
                            }
                        });
                        if (filteredArticle.data.length > 0) {
                            filteredCategory.articles = [...filteredCategory.articles, filteredArticle]
                        }
                    })
                    if (filteredCategory.articles.length > 0) {
                        filteredCategories = [...filteredCategories, filteredCategory]
                    }
                });

            }

            if (noLocation) {
                this.notify.errorMessage("Oferta contine articole cu cantitati repartizate pe locatie nedefinita sau cu KG/Legatura neprecizat!")
                return;
            }

            if (noPrice) {
                this.notify.errorMessage("Precizati pretul pentru articolele selectate!")
                return;
            }
        }

        /////////////////


        this.offerData.pickedByClient = this.offerData.pickedByClient ? this.offerData.pickedByClient : false;
        this.spinner.show();

        //save filter data in other object
        let fulldata = JSON.parse(JSON.stringify(this.offerData.topCategories));
        this.offerData.categories = [];
        for (let topCategory of this.offerData.topCategories) {

            let tt = topCategory.categories.filter(categ => {
                categ.articles = categ.articles.filter(article => {
                    article.data = article.data.filter(art => {
                        return parseFloat(art.quantity) > 0
                    });
                    return article.data.length > 0;
                })
                return categ.articles.length > 0;
            })
            this.offerData.categories = [...this.offerData.categories, ...tt]
        }
        delete this.offerData.topCategories;
        try {
            this.offerData.loadLocationName = this.selectedLocationString ? this.selectedLocationString : "BUHUSI";
            this.offerData.status = status;
            this.offerData.actions = [{
                action: message ? message : "Oferta a fost schimbata!",
                date: new Date()
            }];

            await this.offerService.saveOfferV2(this.offerData);
            delete this.offerData.categories;
            this.offerData.topCategories = fulldata;
            this.spinner.hide();
        }
        catch (e) {
            this.spinner.hide();
        }
    }


    async finishCommand() {
        if (!this.offerData.client.fiscalCode) {
            this.notify.warning(`Oferta nu contine detalii client!`);
            return;
        }

        if (!this.offerData.deliverDate) {
            this.notify.warningMessage("Selectati data de livrare!");
            return;
        }

        if (!this.offerData.finishType) {
            this.notify.warningMessage("Selectati tip document!");
            return;
        }

        if (!this.offerData.paymentType) {
            this.notify.warningMessage("Selectati modalitatea de plata!");
            return;
        }

        if (!this.offerData.pickedByClient && !this.offerData.transportType) {
            this.notify.warningMessage("Selectati tip transport!");
            return;
        }

        if (this.offerData.invoiceType == 'Client' && (this.offerData.isAdvancePayment === true || this.offerData.isAdvancePayment === "true") && this.offerData.advancePaymentValue <= 0) {
            this.notify.warning(`Precizati suma avans!`);
            return;
        }

        if (this.offerData.pickedByClient) {
            this.offerData.coupling = false;
        }
        //filter categories, only articles with quantity
        let filteredCategories = [];
        let noLocation = false;
        let noPrice = false;
        let noStock = false;
        for (let topCategory of this.offerData.topCategories) {


            topCategory.categories.map(categ => {
                let filteredCategory = {
                    category: categ.category,
                    articles: []
                }
                categ.articles.map(article => {
                    let filteredArticle = {
                        articleName: article.articleName,
                        invalidPrice: article.invalidPrice,
                        totalQuantity: article.totalQuantity,
                        data: []
                    };
                    article.data.map(art => {

                        if (art.quantity && art.quantity > art.STOC) {
                            noStock = true;

                        }
                        if (!art.agentPrice && parseFloat(art.quantity) > 0) {
                            noPrice = true;
                        }
                        if (art.stockDetail) {
                            for (let stock of art.stockDetail) {
                                if (stock.conversionQuantity > 0 && stock.conversionValue == 1) {
                                    noLocation = true;
                                }
                            }
                        }
                        if (parseFloat(art.quantity) > 0) {
                            filteredArticle.data = [...filteredArticle.data, art]
                        }
                    });
                    if (filteredArticle.data.length > 0) {
                        filteredCategory.articles = [...filteredCategory.articles, filteredArticle]
                    }
                })
                if (filteredCategory.articles.length > 0) {
                    filteredCategories = [...filteredCategories, filteredCategory]
                }
            });

        }

        if (noStock) {
            this.notify.errorMessage("Cantitatea comandata depaseste stocul existent!")
            return;
        }

        if (noLocation) {
            this.notify.errorMessage("Oferta contine articole cu cantitati repartizate pe locatie nedefinita sau cu KG/Legatura neprecizat!")
            return;
        }

        if (noPrice) {
            this.notify.errorMessage("Precizati pretul pentru articolele selectate!")
            return;
        }

        let additionalInfo = `Doriti salvarea comenzii in mentor?`;

        if (!this.dispecer) {
            if (this.round2Decimals(this.totalOfferWeight) < 25000) {
                additionalInfo = `Greutatea comenzii este sub 25000 kg. Doriti sa continuati? `
            }

            let dialogRef = this.dialog.open(DecisionModalComponent, {
                data: { title: "Salvare comanda", message: additionalInfo }
            });

            let boolResponse = await dialogRef.afterClosed().toPromise();
            if (!boolResponse) {
                return;
            }


        }
        let serverData = JSON.parse(JSON.stringify(this.offerData));

        serverData.categories = filteredCategories;
        serverData.addedValue = this.adaosValue;

        serverData.operated = this.offerData.client && (this.offerData.client.soldValue + this.totalPrice < this.offerData.client.creditValue) && !this.invalidPrice ? true : false;
        if (serverData.categories.length == 0) {
            this.notify.warningMessage("Oferta nu contine articole!");
            this.spinner.hide();
            return;
        }

        this.spinner.show();
        let res: any = await this.offerService.finishCommandV2inter(serverData);
        this.offerData.data = res.commands
        this.offerData.commandData = res.commands;
        this.offerData.status = "COMANDA";
        this.spinner.hide();
        this.notify.successMessage(`Documentele au fost generate!`);
        return;
    }

    getPriceByCategory(dealer, detail) {
        if (dealer.priceCategorySymbol == "I") {
            return detail.C1;
        }
        else if (dealer.priceCategorySymbol == "II" || dealer.priceCategorySymbol == "IV") {
            return detail.C2;
        }
        else if (dealer.priceCategorySymbol == "III") {
            return detail.C3;
        }
    }

    async generateOfferPdf() {
        // if (!this.offerData.client.fiscalCode) {
        //     this.notify.warning(`Oferta nu contine detalii client!`);
        //     return;
        // }

        let categories = [];
        for (let topCategory of this.offerData.topCategories) {
            for (let category of topCategory.categories) {
                categories.push(category)
            }

        }
        let body = {
            offer: categories,
            offerData: {
                selectedOfferType: this.selectedOfferType,
                selectedColor: this.selectedColor,
                selectedMaterial: this.selectedMaterial,
                selectedThickness: this.selectedThickness
            },
            summary: {
                totalPrice: this.totalPrice,
                totalWithOutDiscount: this.totalWithOutDiscount,
                discountValue: this.discountValue,
                discountPercent: this.discountPercent
            },
            client: this.offerData.client,
            includePrices: this.includePrices,
            id: this.offerData._id,
            commands: this.offerData.data
        }
        this.spinner.show();
        let res: any = await this.offerService.generateOfferInterPdf(body);
        this.spinner.hide();

        this.offerData.actions = [{
            action: "Pdf generat",
            date: new Date()
        }];
        this.offerData.files = [
            {
                name: `${res.pdfName.split("_")[0]}`,
                path: `public/${res.pdfName}`
            }
        ];
        //todo duplicates command nodes
        ///await this.saveOffer(this.offerData.status, "PDF generat!");
        window.open(`${this.serviceUrl}/public/${res.pdfName}`);
    }

    async generateCommandsInternalDocumentsPdf() {
        this.spinner.show();
        this.offerData.summary = {
            totalPrice: this.totalPrice,
            totalWithOutDiscount: this.totalWithOutDiscount,
            discountValue: this.discountValue,
            discountPercent: this.discountPercent
        }
        let res: any = await this.offerService.generateCommandsInternalDocumentsPdf(this.offerData);
        this.spinner.hide();
        window.open(`${this.serviceUrl}/public/${res.pdfName}`);
    }

    async prefillClientData(offer) {
        if (!offer.offerData.selectedOfferType) {
            let contacts = JSON.parse(offer.contact)

            for (let contact of contacts) {
                if (!contact.phone) {
                    break;
                }
                const data: any = await this.offerService.getClientData(contact.phone, "phone");
                if (data.rows.length > 0) {
                    this.onClientSelected({ option: { value: data.rows[0] } })
                    break;
                }
            }
        }
    }
    async prefillEditClientData(client) {
        let filteredCounties = this.counties.rows.filter(county => county.countyName == client.JUDET);
        this.selectedCounty = filteredCounties[0];
        let filteredLocalities;
        if (this.selectedCounty) {
            this.localities = await this.offerService.getLocalities(this.selectedCounty.countyId);
            this.filteredOptions = this.localities.rows;
            filteredLocalities = this.localities.rows.filter(locality => locality.localityName == client.DEN_LOCALIT);
        }



        /////////////////////////
        this.editClientData = {
            isPF: client.PERSOANAFIZICA == 1 ? true : false,
            clientId: client.CODPART,
            name: client.DEN_PART,
            agendaId: client.CODAGENDA,
            fiscalCode: client.CODFISCAL,
            tradeRegister: client.REGCOM,
            phone: client.TELEFON,
            county: this.selectedCounty,
            locality: filteredLocalities ? filteredLocalities[0] : "",
            street: client.ADRESA,
            scara: "",
            number: client.NUMBER,
            bloc: client.BLOC,
            etaj: client.ETAJ,
            apartament: client.APART
        }
        this.editClient = true;
    }


    async prefillSelectedClientData(client) {
        if (!client.CODFISCAL) {
            this.notify.warningMessage("Partenerul selectat nu are completat CUI/CNP !");
            return;
        }

        let rr: any = await this.offerService.getSoldDetail(client.CODPART);
        this.clientSoldData = rr;
        this.differentClient = false;

        this.selectedClient = client;
        this.offerData.client = {
            creditValue: rr.creditValue,
            soldValue: rr.soldValue,
            fiscalCode: client.CODFISCAL,
            tradeRegister: client.REGCOM,
            name: client.DEN_PART,
            phone: client.TELEFON,
            email: client.EMAIL,
            clientLocationId: client.CODAGENDA,
            internalId: client.CODPART,
            address: {
                address: client.ADRESA,
                city: client.DEN_LOCALIT,
                conunty: client.JUDET,
                headquarter: client.headquarter
                //todo add headquarter
            }
        }
        this.offerData.deliverAddress = `${client.JUDET} ${client.DEN_LOCALIT}`
        this.showClientsModal = null;
    }

    async insertNewPartner() {

        if (!this.newClientData.locality.localityId) {
            this.notify.warningMessage("Selectati localitatea!");
            return 0;
        }
        this.spinner.show();
        this.newClientData.name = this.newClientData.name.toUpperCase();
        const insertResult: any = await this.offerService.insertPartner(this.newClientData);
        if (insertResult.Error == "ok") {
            this.spinner.hide();
            this.notify.successMessage("Partener salvat in mentor!");
            this.addNewClient = false;
            this.clientFiscalCodeSearch = this.newClientData.fiscalCode;
            await this.onFiscalCodeChange();
            await this.prefillSelectedClientData(this.clients[0])
            this.showClientsModal = false;

        }
        else {
            this.spinner.hide();
            this.notify.errorMessage(insertResult.Error);
        }
    }

    async editPartner() {

        if (!this.editClientData.locality || !this.editClientData.locality.localityId) {
            this.notify.warningMessage("Selectati localitatea!");
            return 0;
        }
        //filter client
        await this.offerService.editClient(this.editClientData);

        this.clientFiscalCodeSearch = this.editClientData.fiscalCode;
        this.onFiscalCodeChange();
        this.editClient = false;
    }

    async loadDealerAgents(event) {

        /////
        this.spinner.show();
        this.selectedDealer = event.value;

        /////prefill dealer data 
        const data: any = await this.offerService.getClientData(this.selectedDealer.fiscalCode, "cui");
        if (data.rows.length > 0) {
            this.onClientSelected({ option: { value: data.rows[0] } })
        }
        ////
        this.dealerAgents = await this.offerService.loadDealerAgents(this.selectedDealer.partnerId);
        this.spinner.hide();
    }

    async onSelectedDealerAgent(event) {
        this.selectedDealerAgent = event.value;
        this.offerData.client["address"] = {
            address: this.selectedDealerAgent.ADRESA,
            city: this.selectedDealerAgent.DEN_LOCALIT,
            conunty: this.selectedDealerAgent.JUDET,
            headquarter: this.selectedDealerAgent.headquarter
        }
        //this.offerData.dealerAgent = this.selectedDealerAgent;
    }

    loadAgentPrice(P) {
        for (let category of this.offerData.categories || []) {
            for (let article of category.articles) {
                for (let detail of article.data) {

                    if (detail[P] > detail.agentMinimumPrice) {
                        detail.agentPrice = detail[P];

                    }
                }
            }
        }
        this.recalculate(null);
    }

    async getStoc(article) {
        //if dispecer send offer uuid to exclude current commands reserved stock


        let res: any = await this.offerService.getArticleStock(article.CODOBIECT, this.selectedLocation ? this.selectedLocation.locationId : null, this.dispecer ? this.offerData.uuid : null).toPromise();
        this.selectedArticle = article;


        //inactivestock
        let inactiveStock = [];

        if (article.stockDetail) {
            article.stockDetail = article.stockDetail.filter(stockDetailItem => {
                return stockDetailItem.conversionQuantity > 0;
            });
        }

        for (let det of article.stockDetail || []) {
            let offerDetails = res.rows.filter(stockDetailItem => {
                if (det.conversionQuantity && det.supplierId == stockDetailItem.supplierId && det.locationId == stockDetailItem.locationId && det.conversionValue == stockDetailItem.conversionValue) {
                    stockDetailItem.inOrient = true;
                    det.stock = stockDetailItem.stock;
                    return true;
                }
                return false;

            });
            if (offerDetails.length == 0) {
                det.inactive = true;
                det.stock = 0;
            }

        }

        let unusedStock = res.rows.filter(stockDetailItem => {
            if (!stockDetailItem.inOrient) {
                return true;
            }
            return false;
        });

        this.stockDetail = article.stockDetail ? [...article.stockDetail, ...unusedStock] : res.rows;

    }

    commandTypeChanged(event) {
        if (event.value == "Client") {
            delete this.offerData.dealer;
            delete this.offerData.dealerAgent;
        }
    }

    compareDealers(o1, o2) {
        return o1.partnerId == o2.partnerId;
    }
    compareDealerAgents(o1, o2) {
        return o1.agentId == o2.agentId;
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    showColorWarningDialog(data) {
        if (data.length == 1 && this.scannedItems.length == 0) { return; }
        if (data.length > 0) {
            let dialogRef = this.dialog.open(DecisionModalComponent, {
                data: {
                    title: "dialog.title.warning",
                    message: "offers.cart_color_warning",
                    items: data,
                    showClose: false
                }
            });
        }
    }

    round4Decimals(value) {
        return Math.round(value * 10000) / 10000;
    }



    delay(timeout) {
        return new Promise((resolve) => {
            setTimeout(resolve, timeout);
        });
    }

    isTigla(articleName) {
        return articleName.includes("TIGLA METALICA");
    }


    async setCountyDelivery(event) {
        this.selectedCountyName = event.value.countyName;

        this.offerData.deliverAddress = event.value.countyName;

        this.spinner.show();
        this.localities = await this.offerService.getLocalities(event.value.countyId);
        this.filteredOptions = [...this.localities.rows];

        this.spinner.hide();
    }

    async setLocalityDelivery(event) {
        this.selectedLocalityName = event.value.localityName;
        this.offerData.deliverAddress = this.selectedCountyName + " " + this.selectedLocalityName;
    }

    alternativeDeliveryAdress() {
        if (!this.deliverStreetAlternative) {
            this.notify.warningMessage("Precizati strada adresei de livrare!");
            return;
        } else {
            this.offerData.deliverAddress += ' ' + this.deliverStreetAlternative;
        }

        if (!this.offerData.transportType) {
            this.notify.warningMessage("Precizati tip transport!");
            return;
        }

        if (this.offerData.pickedByClient && (!this.offerData.pickedByClientData.driverName || !this.offerData.pickedByClientData.carNumber || !this.offerData.pickedByClientData.trailerNumber || !this.offerData.pickedByClientData.driverPhone || !this.offerData.pickedByClientData.driverID)) {
            this.notify.warningMessage("Completati date sofer!");
            return;
        }
        this.showAlternativeDelivery = false;
    }

    async onBasicUpload(event) {

        const formData = new FormData();
        formData.append("file", event.currentFiles[0]);

        await this.offerService.saveFile(formData);

        this.offerData.files = [...this.offerData.files, {
            name: event.currentFiles[0].name,
            path: `public/agent1/${event.currentFiles[0].name}`,
            user: "agent1"
        }]
        this.fileUpload.clear();

        this.saveOffer(this.offerData.status, null);
    }

    onBasicUploadAuto(e) {
        console.log(e)
    }

    getCalculatedStock(selectedArticle, stockArt) {
        stockArt.conversionValue = stockArt.conversionValue ? stockArt.conversionValue : 1;
        let value = stockArt.stock / stockArt.conversionValue;
        return Math.floor(value);
    }

    onSearchChange(searchValue: string): void {

        if (searchValue.length < 2) {
            return;
        } else {
            for (let topCategory of this.offerData.topCategories) {
                for (let category of topCategory.categories) {
                    for (let article of category.articles) {
                        if (article.articleName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
                            this.isFoundSearch = true;
                            article.found = true;
                        } else {
                            article.found = false;
                        }
                    }
                }
            }
        }
    }

    cutArticleNames(category, articleName) {
        for (let search of this.keyWords) {
            articleName = articleName.split(search).join("");
        }

        if (category == "TEAVA.ROTUNDA" || category == "TEAVA.RECTANGULARA") {
            articleName = articleName.split("Q").join("");
        }
        return articleName;
    }

    changeflag(f1, f2, f3) {
        this.f1 = f1;
        this.f2 = f2;
        this.f3 = f3;
    }
    saveQuantity(selectedArticle, stockItems) {
        //selectedArticle.conversionQuantitySum
        this.selectedLocationString = null;
        //check already set locations
        for (let topCategory of this.offerData.topCategories) {
            for (let category of topCategory.categories) {
                for (let article of category.articles) {
                    for (let detail of article.data) {

                        if (detail.quantity > 0 && detail.CODOBIECT != selectedArticle.CODOBIECT) {
                            if (detail.stockDetail) {
                                for (let stockItem of detail.stockDetail) {
                                    if (stockItem.conversionQuantity && stockItem.locationId != 0) {
                                        this.selectedLocationString = this.getLocationString(stockItem.location);
                                        break;
                                    }
                                }
                            }
                            else {
                                this.selectedLocationString = "BUHUSI";
                                break;
                            }
                        }
                    }
                }
            }
        }



        let quantity = 0;
        let conversionQuantitySum = 0;
        let noLocation = false;
        for (let stockItem of stockItems) {

            if (stockItem.conversionQuantity > stockItem.stock / stockItem.conversionValue && stockItem.locationId != 0) {
                this.notify.warningMessage("Cantitatea ceruta depaseste cantitatea disponibila in locatie!");
                return;
            }
            if (stockItem.conversionQuantity && stockItem.conversionQuantity > 0) {

                let tempLocation = this.getLocationString(stockItem.location);

                if (!this.selectedLocationString && stockItem.locationId != 0) {
                    this.selectedLocationString = tempLocation;
                }
                else if (stockItem.locationId != 0 && tempLocation != this.selectedLocationString) {
                    this.notify.info("Locatie utilizata " + this.selectedLocationString);
                    this.notify.warningMessage("Articolul nu poate fi comandat din locatii multiple!");
                    return;
                }

                //undefined location
                if (stockItem.conversionValue == 1) {
                    if (this.dispecer) {
                        this.notify.warningMessage("Cantitatea nu poate fi repartizata pe o locatie fara conversie!");
                        return;
                    }
                    noLocation = true;
                }
                quantity += (stockItem.conversionQuantity * stockItem.conversionValue);
                conversionQuantitySum += stockItem.conversionQuantity;
            }
        }
        for (let topCategory of this.offerData.topCategories) {
            for (let category of topCategory.categories) {
                for (let article of category.articles) {

                    for (let detail of article.data) {

                        if (detail.CODOBIECT == selectedArticle.CODOBIECT) {
                            if (this.dispecer && selectedArticle.conversionQuantitySum != conversionQuantitySum) {
                                this.notify.warningMessage("Cantitatea repartizata este diferita de cea initiala!");
                                return;
                            }
                            detail.quantity = quantity;
                            detail.stockDetail = stockItems;
                            detail.conversionQuantitySum = conversionQuantitySum;
                            article.noLocation = noLocation;
                        }
                    }
                }
            }
        }
        this.stockDetail = null;
    }

    getLocationString(location) {
        if (location == "...nedefinita..." || location.includes("BUHUSI")) {
            return "BUHUSI";
        }
        return location;
    }

    ckeckLocation(article) {
        if (this.selectedLocationString && this.selectedLocationString != "BUHUSI") {

            this.notify.errorMessage("Oferta contine articole pe locatia " + this.selectedLocationString);
            article.quantity = 0;
        }
    }


    getStockOfLocation(event) {
        this.saveOffer('OFERTA', null)
        this.getOfferData(event.value ? event.value.locationId : null, this.dispecer);
    }

    setAdvancedPayment() {
        if (!(this.offerData.isAdvancePayment === true || this.offerData.isAdvancePayment === "true") && this.offerData.advancePaymentValue == 0) {
            this.offerData.advancePaymentValue = this.totalPrice;
        } else {
            this.offerData.advancePaymentValue = 0;
        }
    }

    onChangePickedClient(event) {
        if (event) {
            this.offerData.coupling = false;
        }
    }

    onChangeCoupling(event) {
        if (event) {
            this.offerData.pickedByClient = false;
        }
    }

    validatePayment() {
        if ((this.offerData.isAdvancePayment === true || this.offerData.isAdvancePayment === "true") && (!this.offerData.advancePaymentValue || this.offerData.advancePaymentValue == 0)) {
            this.notify.warningMessage("Precizati suma avans!");
            return false;
        }

        if (!this.offerData.finishType) {
            this.notify.warningMessage("Precizati modul de finalizare!");
            return false;
        }

        if (!this.offerData.paymentType) {
            this.notify.warningMessage("Precizati modalitatea de plata!");
            return false;
        }

        if (this.offerData.paymentType != "cash" && (!this.offerData.paymentTerm || this.offerData.paymentTerm == 'undefined')) {
            this.notify.warningMessage("Precizati termenul de plata!");
            return false;
        }

        if (this.offerData.paymentTerm && this.offerData.paymentTerm > 90) {
            this.notify.warningMessage("Termenul de plata nu poate depasi 90 de zile!");
            return false;
        }

        return true;
    }


    async validateFiscalCode(fiscalCode) {
        fiscalCode = fiscalCode.replace('RO', '').replace('ro', '').trim();
        if (isNaN(parseInt(fiscalCode))) {
            this.notify.warningMessage('Cod fiscal eronat.');
            return;
        }
        fiscalCode = parseInt(fiscalCode);
        let res: any = await this.offerService
            .validateFiscalCode(fiscalCode)
            .toPromise();
        if (!res.denumire) {
            this.notify.warningMessage(
                'Cod fiscal eronat. Nu a putut fi gasit in baza de date ANAF.'
            );
        } else {
            this.validFiscalCode = true;
            this.validatedPartnerData = res;
            this.newClientData.name = this.validatedPartnerData.denumire;
            this.newClientData.fiscalCode = this.validatedPartnerData.cui;
        }
    }

    showAdressAndTransport() {

        this.showAlternativeDelivery = true;
    }

    setPaymentTypes(event) {
        console.log(event);
        this.offerData.paymentType = "";
        if (event.value == "factura") {
            this.paymentTypes = [
                { label: 'OP', value: 'op' },
                { label: 'CEC', value: 'cec' },
                { label: 'BO', value: 'bo' }
            ];
        }
        else if (event.value == "aviz") {
            this.paymentTypes = [{ label: 'Cash', value: 'cash' }];
        }

    }

    activateDispecer() {
        this.dispecer = true;
        this.getOfferData(null, this.dispecer)
    }

    async changeTransportType() {
        let res: any = await this.offerService.changeTransportType(this.offerId, this.offerData.transportType).toPromise();

        this.editTransport = false;
        this.notify.infoMessage("Tip transport schimbat!");
    }
}