import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class SalesAssistantScanService {
  serviceUrl = environment.serviceUrl;
  constructor(private http: HttpClient, @Inject('LOCALSTORAGE') private store: any) {
  }

  decode(articleCode: string, quantity: number) {
    let client = this.store.get('client');
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/asistvanzari/scan/${client.uid}/${user.username}/${company.uid}/${articleCode}/${quantity}`;
    return this.http.get(url).toPromise();
  }

  decodeFromNart(articleCode: string, quantity: number) {
    let client = this.store.get('client');
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/asistvanzari/scanCommand/${client.uid}/${user.username}/${company.uid}/${articleCode}/${quantity}`;
    return this.http.get(url).toPromise();
  }

  noStockData(client, user, company, codobiect) {
    const url = `${this.serviceUrl}/asistvanzari/confirmScan/${user}/${company}/${client}/${codobiect}`;
    return this.http.get(url);
  }

  getInfoStocData() {
    let client = this.store.get('client');
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/infostoc/${client.uid}/${user.username}/${company.uid}?asist=true`;
    return this.http.get(url);
  }

  getDataForOrder() {
    let client = this.store.get('client');
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/infostoc/comanda/${client.uid}/${user.username}/${company.uid}?asist=true`;
    return this.http.get(url);
  }

  getTempItems() {
    let client = this.store.get('client');
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/asistvanzari/loadScannedItems/${client.uid}/${user.username}/${company.uid}`;
    return this.http.get(url);
  }

  removeTempItem(item) {
    const url = `${this.serviceUrl}/asistvanzari/removeScannedItem/${item._id}`;
    return this.http.get(url);
  }

  removeAllItems() {
    let client = this.store.get('client');
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/asistvanzari/removeScannedItems/${client.uid}/${user.username}/${company.uid}`;
    return this.http.get(url);
  }

  importMonetar() {
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/asistvanzari/finish/monetar/${user.username}/${company.uid}`;
    return this.http.get(url);
  }

  getMonetarItems() {
    let company = this.store.get('company');
    let user = this.store.get('localuser');
    const url = `${this.serviceUrl}/asistvanzari/monetar/items/${user.username}/${company.uid}`;
    return this.http.get(url);
  }

  scanLocalItem(item) {
    let client = this.store.get('client');
    let company = this.store.get('company');
    let user = this.store.get('localuser');

    let body = {
      "article": item
    }
    const url = `${this.serviceUrl}/asistvanzari/scanLocalItem/${client.uid}/${user.username}/${company.uid}`;
    return this.http.post(url, body);
  }


  getConstant(key) {
    const company = this.store.get('company');
    const url = `${this.serviceUrl}/admin/constants/${company.uid}/${key}`;
    return this.http.get(url);
  }

  getConstantValue(constantKey) {
    const company = this.store.get('company');
    const user = this.store.get('localuser').username;
    const url = `${this.serviceUrl}/admin/userconstants/${user}/${company.uid}/${constantKey}`;
    return this.http.get(url).toPromise();
  }

  updateUserSingleConstantValues(body) {
    const url = `${this.serviceUrl}/admin/updateUserSingleConstantValues`;
    return this.http.post(url, body);
  }

  getSupplyList() {
    const url = `${this.serviceUrl}/asistvanzari/supplyList`;
    return this.http.get(url);
  }

  getSupplyArticles(username, gestId) {
    const url = `${this.serviceUrl}/asistvanzari/supplyArticles/${gestId}?username=${username}`;
    return this.http.get(url);
  }

  sendSupplyEmail(store, articles) {

    let body = {
      store, articles
    }
    const url = `${this.serviceUrl}/asistvanzari/sendSupplyEmail`;
    return this.http.post(url, body);
  }


}