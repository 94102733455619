
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { NotifyService } from './notify.service';
import { Router } from '@angular/router';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private notify: NotifyService,
    private $router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token;
    let data = localStorage.getItem('localuser');
    if (data) {
      token = JSON.parse(data).token;
      // Clone the request to add the new header.
      const authReq = req.clone({ headers: req.headers.set('x-access-token', token) });
      return next.handle(authReq).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // process successful responses here
            }
          },
          (errorObject: any) => {
            if (errorObject instanceof HttpErrorResponse && errorObject.status !== 200) {

              //todo handle 500 errors
              // if(errorObject.statusText=="Unknown Error"){

              //   return;
              // }
              this.notify.error(errorObject.error.key);
              window.navigator.vibrate(500);
              if (errorObject.status === 401) {
                // authService.logout();
              }
              if (errorObject.status === 403 && errorObject.error.key == "TOKEN_EXPIRED") {
                if (JSON.parse(data).company.name == "intertranstest" || JSON.parse(data).company.name == "inter") {
                  this.notify.errorMessage("Reconectativa pentru a continua!");
                  return;
                }
                token = JSON.parse(data).company.name;
                this.$router.navigateByUrl(`/login`);
                return;
              }
            }
          })
      );
    }
    else {
      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // process successful responses here
            }
          },
          (errorObject: any) => {
            if (errorObject instanceof HttpErrorResponse) {
              console.log(errorObject.error.key);
              this.notify.error(errorObject.error.key);
              window.navigator.vibrate(500);
              if (errorObject.status === 401) {
                // authService.logout();
              }
            }
          }
        )
      );
    }
  }
}
