import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';

import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';


import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//import { HotkeyModule } from 'angular2-hotkeys';

import { NgVirtualKeyboardModule } from './helpers/virtual-keyboard/virtual-keyboard.module';

import { SharedProvidersModule } from './helpers/shared-providers.module';


import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatCardModule } from '@angular/material/card'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AboutModule } from './about/about.module';



import { ToastrModule } from 'ngx-toastr';
import { MainContainerModule } from './main-container/main-container.module';
import { ServiceWorkerModule } from '@angular/service-worker';

import { InlineSVGModule } from 'ng-inline-svg';

import { LoginComponent } from './login/login/login.component';
import { LoginService } from './login//login/login.service';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { OfferDetailsComponent } from "./offers/components/offer-details-caretta/offer-details.component";
import { OfferDetailsInterComponent } from "./offers/components/offer-details-inter/offer-details.component";
import { OfferDetailsGaitanoComponent } from './offers/components/offer-details-gaitano/offer-details-gaitano.component';
import { OfferDetailsMultidom } from './offers/components/multidom/offer-detail-multidom.component';

import { OfferDetailsDolinexComponent } from './offers/components/offer-details-dolinex/offer-details-dolinex.component';

import { OfferDetailGlobalComponent } from './offers/components/offer-detail-global/offer-detail-global.component';


import { OfferProductionComponent } from "./offers/components/offer-production/offer-production.component";
import { OfferService } from "./offers/components/services/offer.serice";
import { CompanyListModalComponent } from './login/company-list-modal/company-list-modal.component';
import { CompanyListModalService } from './login/company-list-modal/company-list-modal.service';

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'

import { SharedModulesModule } from "./helpers/shared-modules.module";
import { DialogModule } from 'primeng/dialog';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { FileUploadModule } from 'primeng/fileupload';

import { RippleModule } from 'primeng/ripple';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule as PrimeCalendar } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';

import { CheckboxModule } from 'primeng/checkbox';

import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InplaceModule } from 'primeng/inplace';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton'
import { NumberModeDirective } from './helpers/directives/number-mode.directive'

import { ArticleObservationModalComponent } from './offers/components/article-observation-modal/article-observation-modal.component'

import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

registerLocaleData(localeRo);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CompanyListModalComponent,
    OfferDetailsInterComponent, OfferDetailsComponent, OfferDetailsGaitanoComponent,
    OfferProductionComponent, OfferDetailsMultidom, OfferDetailsDolinexComponent, OfferDetailGlobalComponent, NumberModeDirective, ArticleObservationModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModulesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    AboutModule,
    MainContainerModule, MatCardModule,
    MatSelectModule,
    NgVirtualKeyboardModule,
    RippleModule, AccordionModule, DropdownModule, PrimeCalendar, InputTextModule, OverlayPanelModule, CardModule, TabViewModule,
    InputTextareaModule, CheckboxModule, DividerModule, InputNumberModule, InplaceModule, TooltipModule, InputSwitchModule, RadioButtonModule,
    //HotkeyModule.forRoot(),
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatIconModule, MatProgressSpinnerModule, MatToolbarModule, MatSidenavModule, MatListModule, MatGridListModule,
    MatDialogModule, MatButtonModule, MatInputModule, DialogModule,
    SharedProvidersModule.forRoot(),
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    FileUploadModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD-RPalQmuzScGWVIR05PozpS4KNfaWQc8', language: 'ro', region: "RO"
    }),
    AgmDirectionModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    DragDropModule,

    ToastModule
  ],
  providers: [LoginService, CompanyListModalService, OfferService, NgxSpinnerService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
