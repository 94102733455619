import { Component, OnInit, OnDestroy, Inject, HostListener, ViewChild } from "@angular/core";
import { OfferService } from "../services/offer.serice";
import { CartDataModel } from "../../../helpers/datatable/data/cart-data.model";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { NotifyService } from "../../../helpers/notify.service";
import { MatDialog } from "@angular/material/dialog";
import { DecisionModalComponent } from "../../../decision-modal/decision-modal.component";
import { OfferModel } from "../../models/offer.model";
import { environment } from "../../../../environments/environment";
import { DateAdapter } from '@angular/material/core';
import { UntypedFormControl } from '@angular/forms';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { randomColor } from 'randomcolor'; // import the script


@Component({
  selector: 'enode-offergaitano-details',
  templateUrl: './offer-details-gaitano.component.html',
  styleUrls: ['./offer-details-gaitano.component.scss']
})
export class OfferDetailsGaitanoComponent implements OnInit, OnDestroy {
  math: any = Math;
  showZeroQuantityArticles = false;
  firstLoad = true;
  scannedItems: CartDataModel[] = [];
  currentOffer: any = { articles: [], offerData: {} };
  colors;
  materials;
  thickness;
  selectedOfferType;
  selectedColor;
  selectedMaterial;
  selectedThickness;

  totalPrice = 0;
  totalMinimumPrice = 0;
  totalWithOutDiscount = 0;
  discountValue = 0;
  discountPercent = 0;
  adaosValue;
  adaosPercent;
  showAdaos = false;
  includePrices = false;
  generateNewOffer = false;

  temporaryArticles = [];
  clientData;
  offerId;
  tempOfferId;

  clientTypes = [
    { label: 'Persoana fizica', value: 'PF' },
    { label: 'Persoana juridica', value: 'PJ' }
  ];
  clientId;
  serviceUrl = environment.serviceUrl;

  invoiceTypes = OfferModel.InvoiceType;
  minDate = new Date();

  selectedClient: any = {};
  selectedDealer: any = {};
  selectedDealerAgent: any = {};
  dealerAgents: any = [];

  offerData;
  token;
  clients = [];
  dealers: any = [];

  selectedPhone;
  selectedCounty;
  onDestroy$ = new Subject<boolean>();
  pluvialType = "SL";
  showPluvialTypeModal = false;
  debug = false;

  //temporary counties todo
  counties;
  localities;
  myControl = new UntypedFormControl();
  filteredOptions;

  addNewClient = false;
  editClient = false;
  newClientType;
  showClientsModal = false;
  showDocsModal = false;

  clientNameSearch;
  clientPhoneSearch;
  clientFiscalCodeSearch;
  differentClient = true;

  stockDetail;

  newClientData;
  editClientData = {
    isPF: false,
    clientId: "",
    agendaId: "",
    name: "",
    fiscalCode: "",
    tradeRegister: "",
    phone: "",
    county: "",
    locality: null,
    street: "",
    scara: "",
    number: "",
    bloc: "",
    etaj: "",
    apartament: ""
  }
  totalOfferWeight = 0;

  canChooseClientType = false;
  canCancelCommand = false;
  canPrintInternallCommandPdf = false;
  showPickedByClient = false;

  showAlternativeDelivery = false;
  cancelCommandFlag = false;
  cancelObservations = "";
  username;

  isProduction = false;
  showImage = false;
  image;

  selectedArticle;
  locations;
  f1 = false; f2 = true; f3 = true;
  isFoundSearch = false;
  searchText;

  es = {
    firstDayOfWeek: 1,
    dayNames: ["Duminica", "Luni", "Marti", "Miercuri", "Joi", "Vineri", "Sambata"],
    dayNamesShort: ["dum", "lun", "mar", "mie", "joi", "vin", "sam"],
    dayNamesMin: ["D", "L", "Ma", "Mie", "J", "V", "S"],
    monthNames: ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: 'Azi',
    clear: 'Sterge'
  };

  nextData = [];
  selectedPackage;
  layerrr;
  badLayers;

  validFiscalCode;
  validatedPartnerData;

  badPallet;

  colorObj = {};

  version = environment.version;


  stockExceeded = false;

  clientSoldItems = [];

  incorrect = false;

  @ViewChild('fileUpload') fileUpload: any;
  constructor(
    private offerService: OfferService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notify: NotifyService,
    private _adapter: DateAdapter<any>,
    @Inject('LOCALSTORAGE') private store: any
  ) {

    window.onbeforeunload = function (event) {
      console.log(event);
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };
  }

  async ngOnInit() {
    this.setEmptyClientData();
    this.token = this.route.snapshot.paramMap.get('token');
    this.route.data.subscribe(a => {
      console.log(a)

      if (a && a.type == "PRODUCTION") {
        this.isProduction = true;
      }
    })
    if (this.token) {
      this.store.set("localuser", { token: this.token })
    }
    this.offerId = this.route.snapshot.paramMap.get('offerId');
    this.counties = await this.offerService.getCounties();

    let result: any = await this.offerService.getLocations();
    this.locations = result.locations;
    await this.getDealers();

    await this.getOfferData(null);
    if (this.offerData.status == "COMANDA") {
      this.f1 = true; this.f2 = false; this.f3 = false;
    }

    //get sold
    if (this.offerData?.client?.internalId) {
      this.setSoldDetail(this.offerData.client.internalId)
    }


    this._adapter.setLocale('ro');

    this.onChanges();
    let tokenData = this.decodeToken(this.store.get('localuser').token);
    this.username = tokenData.username;
    this.refapaletare();


    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {
            if (detail.quantity > detail.STOC) {
              this.stockExceeded = true;
            }
          }
        }
      }
    }
  }

  decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  onChanges(): void {
    this.myControl.valueChanges.subscribe(val => {
      if (this.localities && this.localities.rows)
        this.filteredOptions = this.localities.rows.filter(option => option.localityName.toLowerCase().includes(val));
    });
  }

  initLocalities() {
    this.filteredOptions = this.localities.rows;
  }



  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {

    $event.returnValue = true;
    //}
  }

  localityChange() {
    console.log("filter")
  }


  //todo
  async loadLocalities(event) {
    console.log(event)
    this.newClientData.county = event.value;
    this.editClientData.county = event.value;
    this.newClientData.locality = "";
    this.spinner.show();
    this.localities = await this.offerService.getLocalities(event.value.countyId);
    this.spinner.hide();
    this.filteredOptions = this.localities.rows;
  }

  async getOfferData(locationId) {
    this.spinner.show();
    let res: any = await this.offerService.getOffersGaitano(this.offerId, locationId).toPromise();



    this.offerData = res;
    this.offerData.categories = this.offerData && this.offerData.categories ? this.offerData.categories : [];
    //this.pluvialType = this.offerData.offerData.pluvialType;
    //preload agents

    this.offerData.invoiceType = "Client";
    await this.prefillClientData(this.offerData);
    if (this.offerData?.client?.internalId) {
      await this.setSoldDetail(this.offerData.client.internalId);
    }


    this.selectedOfferType = res.offerData.selectedOfferType;
    this.selectedColor = res.offerData.selectedColor;
    this.selectedMaterial = res.offerData.selectedMaterial;
    this.selectedThickness = res.offerData.selectedThickness;
    this.currentOffer.deliveryDate = res.deliveryDate;
    this.currentOffer.invoiceType = res.invoiceType;
    this.currentOffer.finishType = res.finishType;
    this.currentOffer.paymentType = res.paymentType;
    this.currentOffer.observations = res.observations;
    this.currentOffer.phone = res.phone;


    //check if saved state

    if (this.temporaryArticles) {
      for (let temporaryArticle of this.temporaryArticles) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              if (detail.DENUMIRE.includes(temporaryArticle.name) && detail.D1 == temporaryArticle.D1 && detail.D2 == temporaryArticle.D2 && detail.D3 == temporaryArticle.D3) {
                detail.quantity = temporaryArticle.quantity;
              }
            }
          }
        }
      }
    }

    if (this.offerData && this.offerData.dealer && this.offerData.client && this.offerData.client.fiscalCode == this.offerData.dealer.fiscalCode) {
      this.differentClient = false;
    }
    this.temporaryArticles = [];
    this.recalculate(true);
    this.spinner.hide();


  }


  async onPhoneInputChange() {

    if (this.clientPhoneSearch.length >= 3) {
      this.clientFiscalCodeSearch = "";
      this.clientNameSearch = "";
      const data: any = await this.offerService.getClientData(this.clientPhoneSearch, "phone");
      this.clients = data.rows;
    }
  }

  async onNameInputChange() {
    if (this.clientNameSearch.length >= 3) {
      this.clientFiscalCodeSearch = "";
      this.clientPhoneSearch = "";
      const data: any = await this.offerService.getClientData(this.clientNameSearch, "name");
      this.clients = data.rows;
    }
  }

  async onFiscalCodeChange() {
    if (this.clientFiscalCodeSearch.length >= 3) {
      this.clientPhoneSearch = "";
      this.clientNameSearch = "";
      const data: any = await this.offerService.getClientData(this.clientFiscalCodeSearch, "cui");
      this.clients = data.rows;
      return;
    }
    return;
  }


  async onClientSelected(event) {
    this.selectedClient = event.option.value;
    this.offerData.client = {
      fiscalCode: event.option.value.CODFISCAL,
      name: event.option.value.DEN_PART,
      phone: event.option.value.TELEFON,
      internalId: event.option.value.CODPART
    }

    let rezz: any = await this.offerService.getClientData(event.option.value.CODFISCAL, "cui");

    //await this.offerService.getClientDetail(event.option.value.CODPART);

    this.offerData.client["address"] = {
      address: rezz.rows[0].ADRESA,
      city: rezz.rows[0].DEN_LOCALIT,
      conunty: rezz.rows[0].JUDET,
      headquarter: rezz.rows[0].headquarter
    }
  }

  displayPhone(client) {
    return client ? client.phone : '';
  }



  displayClientName(client) {
    return client ? client.name : '';
  }

  displayFiscalCode(client) {
    return client ? client.fiscalCode : '';
  }

  round2Decimals(value) {
    return Math.round(value * 100) / 100;
  }

  hasMultipleArts(item) {

    for (let article of item.articles) {
      if (article.data.length != 1) {
        return true;
      }
    }
    return false;
  }
  async cancelCommand() {

    this.spinner.show();
    if (typeof this.offerData.commandData !== 'object') {
      this.offerData.commandData = JSON.parse(this.offerData.commandData);
    }
    try {
      await this.offerService.cancelCommand(this.offerData.commandData.commandId, "Anulata " + this.cancelObservations);
    }
    catch (e) {
      console.log(e);
      this.spinner.hide();
    }
    this.cancelCommandFlag = false;
    this.spinner.hide();
    window.location.reload();

  }
  reset() {

    let dialogRef = this.dialog.open(DecisionModalComponent, {
      data: { title: "Salvare cantitati oferta", message: "Doriti salvarea cantitatilor de pe oferta actuala?" }
    });
    dialogRef.afterClosed().subscribe(async reset => {
      if (reset) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              if (detail.quantity) {
                let tempArticle = {
                  DENUMIRE: detail.DENUMIRE,
                  D1: detail.D1,
                  D2: detail.D2,
                  D3: detail.D3,
                  quantity: detail.quantity,
                  selectedColor: this.selectedColor,
                  selectedThickness: this.selectedThickness,
                };
                this.temporaryArticles.push(tempArticle);
              }
            }
          }
        }
        this.store.set("tempPrices", this.temporaryArticles);
      }
    });
  }

  loadPrices() {
    this.temporaryArticles = this.store.get("tempPrices");
    if (this.temporaryArticles) {
      for (let temporaryArticle of this.temporaryArticles) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              let articleParsedName = this.getParentName(detail, this.selectedColor, this.selectedThickness);
              let savedArticleParsedName = this.getParentName(temporaryArticle, temporaryArticle.selectedColor, temporaryArticle.selectedThickness);
              if (articleParsedName == savedArticleParsedName && detail.D1 == temporaryArticle.D1 && detail.D2 == temporaryArticle.D2 && detail.D3 == temporaryArticle.D3) {
                detail.quantity = temporaryArticle.quantity;
              }
            }
          }
        }
      }
    }
    this.temporaryArticles = [];
    this.recalculate(true);
    this.notify.successMessage("Cantitati precompletate!")
  }

  getParentName(article, selectedColor, selectedThickness) {
    //get selected data   {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
    let tempDen = article.DENUMIRE;
    tempDen = tempDen.split("PLUS").join("");
    tempDen = tempDen.split("103").join("");
    tempDen = tempDen.split("104").join("");
    tempDen = tempDen.split("105").join("");
    tempDen = tempDen.split("106").join("");
    tempDen = tempDen.split(selectedColor).join("");
    tempDen = tempDen.split(selectedThickness).join("");
    tempDen = tempDen.split("BR").join("");
    tempDen = tempDen.split("MAT").join("");
    tempDen = tempDen.split("SL").join("");
    return tempDen.trim();
  }

  getMaterial(offerType) {
    //this.materials = [];
    this.spinner.show();

    this.offerData.offerData.selectedOfferType = offerType;
    this.selectedOfferType = offerType;
    this.offerService.getMaterials(offerType.key).subscribe(async (materials: any) => {
      this.materials = materials;
      this.spinner.hide();
      if (materials.length == 0) {
        this.spinner.show();
        await this.getOfferData(null);
        this.offerId = this.tempOfferId;
        this.spinner.hide();
      }
    });
  }

  getColors(material) {
    this.spinner.show();
    this.materials = [];
    this.selectedMaterial = material;
    this.offerData.offerData.selectedMaterial = material;
    if (material != "SL") {
      let tokenData = this.decodeToken(this.store.get('localuser').token);
      if (tokenData.username == "agent1" || tokenData.username == "comenzi" || tokenData.username == "vasile.oslobanu" || tokenData.username == "parteneri.mici") {
        this.showPluvialTypeModal = true;
      }
      else {
        this.pluvialType = "BR";
      }
    }
    this.offerService.getColors(this.selectedOfferType.key, material).subscribe(async (colors: any) => {
      this.spinner.hide();
      this.colors = colors;
      if (colors.length == 0) {
        this.spinner.show();
        await this.getOfferData(null);
        this.offerId = this.tempOfferId;
        this.spinner.hide();
      }
    });
  }

  getThickness(color) {
    //this.materials = [];
    this.spinner.show();
    this.selectedColor = color;
    this.offerData.offerData.selectedColor = color;
    this.offerService.getThickness(this.selectedOfferType.key, this.selectedMaterial, this.selectedColor).subscribe(async (thickness: any) => {

      this.thickness = thickness;
      this.spinner.hide();
      if (thickness.length == 0) {
        this.spinner.show();
        await this.getOfferData(null);
        this.offerId = this.tempOfferId;
        this.spinner.hide();
      }

    });


  }

  async selectThickness(thickness) {
    this.spinner.show();
    this.selectedThickness = thickness;
    await this.getOfferData(null);
    this.offerId = this.tempOfferId;
    this.spinner.hide();
  }

  getTotalQuantity(articleDetails) {
    let sum = 0;
    for (let articleDetail of articleDetails.data) {
      if (articleDetail.quantity && articleDetail.D2) {
        sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
      }
      else if (articleDetail.quantity) {
        sum += articleDetail.D1 * articleDetail.quantity;
      }
    }
    articleDetails.totalQuantity = sum;
    return sum;
  }

  getArticleTotalQuantity(articleDetail) {
    let sum = 0;
    if (articleDetail.quantity && articleDetail.D2) {
      sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
    }
    else if (articleDetail.quantity) {
      sum += articleDetail.D1 * articleDetail.quantity;
    }
    return sum;
  }

  getTotalPrice() {
    this.totalPrice = 0;
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article?.data ? article.data : []) {
            this.totalPrice += this.getSubtotal(detail);
          }
        }
      }
    }
    return this.round2Decimals(this.totalPrice);
  }

  getTotalListPrice() {
    this.totalPrice = 0;


    return this.round2Decimals(this.totalPrice);
  }
  //copy price to all details of an article
  propagateQuantity(articles) {
    for (let article of articles) {
      article.discount = articles[0].discount;
    }
  }

  async recalculate(event) {
    if (event && event.keyCode == 188) {
      this.notify.infoMessage("Folositi punctul ca separator pentru zecimale!")
      return;
    }

    this.totalPrice = 0;
    this.totalMinimumPrice = 0;
    this.totalWithOutDiscount = 0;
    this.discountValue = 0;
    this.discountPercent = 0;
    this.totalOfferWeight = 0;
    //price withoutDiscount
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {

            if (detail.quantity) {
              detail.quantity = detail.quantity.toString().replace("-", "");
              detail.quantity = detail.quantity.toString().replace(",", "");
              this.totalWithOutDiscount += parseFloat(detail.quantity) * parseFloat(detail.LISTA) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
              this.totalPrice += detail.quantity * detail.LISTA;
              this.totalMinimumPrice += parseFloat(detail.quantity) * parseFloat(article.data[0].agentMinimumPrice) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
              if (article.data[0].boxWeight) {
                this.totalOfferWeight += parseFloat(detail.quantity) * parseFloat(article.data[0].boxWeight);
              }

            }

          }
        }
      }
    }
    this.totalWithOutDiscount = this.round2Decimals(this.totalWithOutDiscount);
    this.totalPrice = this.round2Decimals(this.totalPrice);
    this.discountValue = this.round2Decimals(this.totalWithOutDiscount - this.totalPrice)
    this.discountPercent = this.round2Decimals((this.totalWithOutDiscount - this.totalPrice) / this.totalWithOutDiscount * 100);
    this.adaosValue = this.round2Decimals((this.totalPrice ? this.totalPrice / 1.19 : 0) - (this.totalMinimumPrice ? this.totalMinimumPrice / 1.19 : 0))
    this.adaosPercent = this.round2Decimals((this.totalPrice - this.totalMinimumPrice) / this.totalPrice * 100);
  }

  getSubtotal(article) {
    let totalArticlePrice = 0;
    if (article?.quantity) {
      for (let detail of article.stockDetail) {
        if (detail.conversionQuantity && detail.supplierName != "PROMO") {
          totalArticlePrice += this.round2Decimals((detail.conversionQuantity) * (article.LISTA - (article.LISTA * (article.discount ? article.discount : 0) / 100)));
        }
      }
    }
    return totalArticlePrice;
  }

  async saveOffer(status, message) {
    //check if dealer and data is completed
    if (!this.offerData.invoiceType) {
      this.notify.warning(`Selecteaza tip comanda!`);
      return;
    }
    if (this.offerData.invoiceType == 'Dealer' && Object.keys(this.offerData.dealer).length === 0 && this.offerData.dealer.constructor === Object) {
      this.notify.warning(`Selecteaza dealerul!`);
      return;
    }
    if (this.offerData.invoiceType == 'Dealer' && Object.keys(this.offerData.dealerAgent).length === 0 && this.offerData.dealerAgent.constructor === Object) {
      this.notify.warning(`Selecteaza agentul dealerului!`);
      return;
    }

    if (this.offerData.invoiceType == 'Client' && this.offerData.isAdvancePayment && this.offerData.advancePaymentValue <= 0) {
      this.notify.warning(`Precizati suma avans!`);
      return;
    }

    this.offerData.pickedByClient = this.offerData.pickedByClient ? this.offerData.pickedByClient : false;
    this.spinner.show();

    //save filter data in other object
    let fulldata = JSON.parse(JSON.stringify(this.offerData.topCategories));
    this.offerData.categories = [];
    for (let topCategory of this.offerData.topCategories) {

      let tt = topCategory.categories.filter(categ => {
        categ.articles = categ.articles.filter(article => {
          article.data = article.data.filter(art => {
            return parseFloat(art.quantity) > 0
          });
          return article.data.length > 0;
        })
        return categ.articles.length > 0;
      })
      this.offerData.categories = [...this.offerData.categories, ...tt]
    }
    delete this.offerData.topCategories;
    try {

      this.offerData.status = status;
      this.offerData.actions = [{
        action: message ? message : "Oferta a fost schimbata!",
        date: new Date()
      }];

      await this.offerService.saveOfferV2(this.offerData);
      delete this.offerData.categories;
      this.offerData.topCategories = fulldata;
      this.spinner.hide();
    }
    catch (e) {
      this.spinner.hide();
    }
  }


  async finishCommand() {
    let surface = 0;

    if ((this.badPallet && this.badPallet.layers.length > 0) || this.incorrect) {
      this.notify.warning(`Paletare ineficienta, comanda nu poate fi plasata!`);
      return;
    }


    if (!this.offerData.client.fiscalCode) {
      this.notify.warning(`Oferta nu contine detalii client!`);
      return;
    }

    if (!this.offerData.deliverDate) {
      this.notify.warningMessage("Selectati data de livrare!");
      return;
    }

    if (this.offerData.invoiceType == 'Client' && this.offerData.isAdvancePayment && this.offerData.advancePaymentValue <= 0) {
      this.notify.warning(`Precizati suma avans!`);
      return;
    }

    let dialogRef = this.dialog.open(DecisionModalComponent, {
      data: { title: "Salvare comanda", message: `${this.stockExceeded ? 'Stoc gestiune proprie depasit!' : ''} Doriti salvarea comenzii in mentor?` }
    });

    let boolResponse = await dialogRef.afterClosed().toPromise();
    if (!boolResponse) {
      return;
    }

    let serverData = JSON.parse(JSON.stringify(this.offerData));

    //filter categories, only articles with quantity
    let filteredCategories = [];
    for (let topCategory of this.offerData.topCategories) {


      topCategory.categories.map(categ => {
        let filteredCategory = {
          category: categ.category,
          articles: []
        }
        categ.articles.map(article => {
          let filteredArticle = {
            articleName: article.articleName,
            invalidPrice: article.invalidPrice,
            totalQuantity: article.totalQuantity,
            data: []
          };
          article.data.map(art => {
            if (parseFloat(art.quantity) > 0) {
              filteredArticle.data = [...filteredArticle.data, art]
            }
          });
          if (filteredArticle.data.length > 0) {
            filteredCategory.articles = [...filteredCategory.articles, filteredArticle]
          }
        })
        if (filteredCategory.articles.length > 0) {
          filteredCategories = [...filteredCategories, filteredCategory]
        }
      });

    }

    serverData.categories = filteredCategories;
    serverData.addedValue = this.adaosValue;

    serverData.operated = this.offerData.client && (this.offerData.client.soldValue + this.totalPrice < this.offerData.client.creditValue) ? true : false;
    if (serverData.categories.length == 0) {
      this.notify.warningMessage("Oferta nu contine articole!");
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    let res: any;
    try {
      res = await this.offerService.finishCommandV2gaitano(serverData);
    }
    catch (e) {
      this.spinner.hide();
      return;
    }
    console.log(res);
    this.offerData.data = {
      commandId: res.commandId,
      commandSeries: res.commandSeries,
      commandNumber: res.commandNumber
    }
    this.offerData.commandData = {
      commandId: res.commandId,
      commandSeries: res.commandSeries,
      commandNumber: res.commandNumber
    }
    this.offerData.status = "COMANDA";
    this.spinner.hide();
    this.notify.successMessage(`Comanda ${res.commandNumber} a fost salvata!`);
    return;
  }

  getPriceByCategory(dealer, detail) {
    if (dealer.priceCategorySymbol == "I") {
      return detail.C1;
    }
    else if (dealer.priceCategorySymbol == "II" || dealer.priceCategorySymbol == "IV") {
      return detail.C2;
    }
    else if (dealer.priceCategorySymbol == "III") {
      return detail.C3;
    }
  }

  async generateOfferPdf() {
    // if (!this.offerData.client.fiscalCode) {
    //     this.notify.warning(`Oferta nu contine detalii client!`);
    //     return;
    // }

    let body = {
      offer: this.offerData.categories,
      offerData: {
        selectedOfferType: this.selectedOfferType,
        selectedColor: this.selectedColor,
        selectedMaterial: this.selectedMaterial,
        selectedThickness: this.selectedThickness
      },
      summary: {
        totalPrice: this.totalPrice,
        totalWithOutDiscount: this.totalWithOutDiscount,
        discountValue: this.discountValue,
        discountPercent: this.discountPercent
      },
      client: this.offerData.client,
      includePrices: this.includePrices,
      id: this.offerData._id,
      commandNumber: this.offerData && this.offerData.data ? this.offerData.data.commandNumber : ''
    }
    console.log(this.includePrices)
    this.spinner.show();
    let res: any = await this.offerService.generateOfferPdf(body);
    this.spinner.hide();

    this.offerData.actions = [{
      action: "Pdf generat",
      date: new Date()
    }];
    this.offerData.files = [
      {
        name: `${res.pdfName.split("_")[0]}`,
        path: `public/${res.pdfName}`
      }
    ];
    //todo duplicates command nodes
    ///await this.saveOffer(this.offerData.status, "PDF generat!");
    window.open(`${this.serviceUrl}/public/${res.pdfName}`);
  }

  async generateCommandsInternalDocumentsPdf() {
    this.spinner.show();
    this.offerData.summary = {
      totalPrice: this.totalPrice,
      totalWithOutDiscount: this.totalWithOutDiscount,
      discountValue: this.discountValue,
      discountPercent: this.discountPercent
    }
    let res: any = await this.offerService.generateCommandsInternalDocumentsPdf(this.offerData);
    this.spinner.hide();
    window.open(`${this.serviceUrl}/public/${res.pdfName}`);
  }

  async prefillClientData(offer) {
    if (!offer.offerData.selectedOfferType) {
      let contacts = JSON.parse(offer.contact)

      for (let contact of contacts) {
        if (!contact.phone) {
          break;
        }
        const data: any = await this.offerService.getClientData(contact.phone, "phone");
        if (data.rows.length > 0) {
          this.onClientSelected({ option: { value: data.rows[0] } })
          break;
        }
      }
    }
  }
  async prefillEditClientData(client) {
    console.log(client)
    let filteredCounties = this.counties.rows.filter(county => county.countyName == client.JUDET);
    this.selectedCounty = filteredCounties[0];
    let filteredLocalities;
    if (this.selectedCounty) {
      this.localities = await this.offerService.getLocalities(this.selectedCounty.countyId);
      this.filteredOptions = this.localities.rows;
      filteredLocalities = this.localities.rows.filter(locality => locality.localityName == client.DEN_LOCALIT);
    }



    /////////////////////////
    this.editClientData = {
      isPF: client.PERSOANAFIZICA == 1 ? true : false,
      clientId: client.CODPART,
      name: client.DEN_PART,
      agendaId: client.CODAGENDA,
      fiscalCode: client.CODFISCAL,
      tradeRegister: client.REGCOM,
      phone: client.TELEFON,
      county: this.selectedCounty,
      locality: filteredLocalities ? filteredLocalities[0] : "",
      street: client.ADRESA,
      scara: "",
      number: client.NUMBER,
      bloc: client.BLOC,
      etaj: client.ETAJ,
      apartament: client.APART
    }
    this.editClient = true;
  }

  async setSoldDetail(internalId) {
    let rr: any = await this.offerService.getSoldDetail(internalId);
    this.clientSoldItems = rr.soldData;

    this.offerData.client.creditValue = rr.creditValue;
    this.offerData.client.soldValue = rr.soldValue;
  }


  async prefillSelectedClientData(client) {
    if (!client.CODFISCAL) {
      this.notify.warningMessage("Partenerul selectat nu are completat CUI/CNP !");
      return;
    }
    this.spinner.show();
    let rr: any;
    try {
      rr = await this.offerService.getSoldDetail(client.CODPART);
      this.spinner.hide();
    }
    catch (err) {
      this.spinner.hide();
      return;
    }


    this.differentClient = false;
    this.selectedClient = client;
    this.clientSoldItems = rr.soldData;

    this.offerData.client = {
      creditValue: rr.creditValue,
      soldValue: rr.soldValue,
      fiscalCode: client.CODFISCAL,
      name: client.DEN_PART,
      phone: client.TELEFON,
      internalId: client.CODPART,
      address: {
        address: client.ADRESA,
        city: client.DEN_LOCALIT,
        conunty: client.JUDET,
        headquarter: client.headquarter
        //todo add headquarter
      }
    }
    this.showClientsModal = null;
  }

  async insertNewPartner() {

    if (!this.newClientData.locality.localityId) {
      this.notify.warningMessage("Selectati localitatea!");
      return 0;
    }
    this.spinner.show();
    this.newClientData.name = this.newClientData.name.toUpperCase();
    const insertResult: any = await this.offerService.insertPartner(this.newClientData);
    console.log(insertResult)
    if (insertResult.Error == "ok") {
      this.spinner.hide();
      this.notify.successMessage("Partener salvat in mentor!");
      this.addNewClient = false;
      this.clientFiscalCodeSearch = this.newClientData.fiscalCode;
      await this.onFiscalCodeChange();
      await this.prefillSelectedClientData(this.clients[0])
      this.showClientsModal = false;

      //restore state of client object
      this.setEmptyClientData();

    }
    else {
      this.spinner.hide();
      this.notify.errorMessage(insertResult.Error);
    }
  }

  async editPartner() {

    if (!this.editClientData.locality || !this.editClientData.locality.localityId) {
      this.notify.warningMessage("Selectati localitatea!");
      return 0;
    }
    console.log(this.editClientData)
    //filter client
    await this.offerService.editClient(this.editClientData);

    this.clientFiscalCodeSearch = this.editClientData.fiscalCode;
    this.onFiscalCodeChange();
    this.editClient = false;
  }

  async getDealers() {
    this.dealers = await this.offerService.getDealers();
  }

  async loadDealerAgents(event) {
    //change prices based on client 
    // this.differentClient = false;
    // for (let category of this.offerData.categories || []) {
    //     for (let article of category.articles) {
    //         for (let detail of article.data) {

    //             if (event.value.priceCategorySymbol == "I") {
    //                 detail.agentMinimumPrice = this.round2Decimals(detail.C1);
    //                 detail.agentPrice = this.round2Decimals(detail.C1);
    //             }
    //             else if (event.value.priceCategorySymbol == "II" || event.value.priceCategorySymbol == "IV") {
    //                 detail.agentMinimumPrice = this.round2Decimals(detail.C2);
    //                 detail.agentPrice = this.round2Decimals(detail.C2);
    //             }
    //             else if (event.value.priceCategorySymbol == "III") {
    //                 detail.agentMinimumPrice = this.round2Decimals(detail.C3);
    //                 detail.agentPrice = this.round2Decimals(detail.C3);
    //             }
    //         }
    //     }
    // }
    // this.recalculate(null);



    /////
    this.spinner.show();
    this.selectedDealer = event.value;
    //this.offerData.dealer = this.selectedDealer;

    /////prefill dealer data 
    const data: any = await this.offerService.getClientData(this.selectedDealer.fiscalCode, "cui");
    if (data.rows.length > 0) {
      this.onClientSelected({ option: { value: data.rows[0] } })
    }
    ////
    this.dealerAgents = await this.offerService.loadDealerAgents(this.selectedDealer.partnerId);
    this.spinner.hide();
  }

  async onSelectedDealerAgent(event) {
    this.selectedDealerAgent = event.value;
    this.offerData.client["address"] = {
      address: this.selectedDealerAgent.ADRESA,
      city: this.selectedDealerAgent.DEN_LOCALIT,
      conunty: this.selectedDealerAgent.JUDET,
      headquarter: this.selectedDealerAgent.headquarter
    }
    //this.offerData.dealerAgent = this.selectedDealerAgent;
  }

  loadAgentPrice(P) {
    for (let category of this.offerData.categories || []) {
      for (let article of category.articles) {
        for (let detail of article.data) {

          if (detail[P] > detail.agentMinimumPrice) {
            detail.agentPrice = detail[P];

          }
        }
      }
    }
    this.recalculate(null);
  }

  async getStoc(article) {
    console.log(article.CODOBIECT)
    //this.articleStockByLocation = await this.offerService.loadDealerAgents(this.selectedDealer.partnerId);
    let res: any = await this.offerService.getArticleValability(article.CODOBIECT).toPromise();
    this.selectedArticle = article;
    this.stockDetail = res.rows;

    //if quantity saved on offer put in stockDetail object
    if (article.stockDetail) {
      console.log("HAS DETAIL")
      for (let det of this.stockDetail) {
        let offerDetails = article.stockDetail.filter(stockDetail => {
          return det.supplierId == stockDetail.supplierId && det.valability == stockDetail.valability;
        });
        if (offerDetails.length > 0) {
          det.conversionQuantity = offerDetails[0].conversionQuantity;
        }

      }
    }
  }

  commandTypeChanged(event) {
    if (event.value == "Client") {
      delete this.offerData.dealer;
      delete this.offerData.dealerAgent;
    }
  }

  compareDealers(o1, o2) {
    return o1.partnerId == o2.partnerId;
  }
  compareDealerAgents(o1, o2) {
    return o1.agentId == o2.agentId;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  showColorWarningDialog(data) {
    if (data.length == 1 && this.scannedItems.length == 0) { return; }
    if (data.length > 0) {
      let dialogRef = this.dialog.open(DecisionModalComponent, {
        data: {
          title: "dialog.title.warning",
          message: "offers.cart_color_warning",
          items: data,
          showClose: false
        }
      });
    }
  }

  round4Decimals(value) {
    return Math.round(value * 10000) / 10000;
  }



  delay(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  isTigla(articleName) {
    return articleName.includes("TIGLA METALICA");
  }


  async setCountyDelivery(event) {
    console.log(event.value.countyName)
    this.offerData.deliverAddress = event.value.countyName;

    this.spinner.show();
    this.localities = await this.offerService.getLocalities(event.value.countyId);
    this.filteredOptions = [...this.localities.rows];

    this.spinner.hide();
  }

  async setLocalityDelivery(event) {
    this.offerData.deliverAddress += " " + event.value.localityName
  }

  alternativeDeliveryAdress() {
    this.showAlternativeDelivery = false;
  }

  async onBasicUpload(event) {
    console.log(event)
    const formData = new FormData();
    formData.append("file", event.currentFiles[0]);

    //console.log('boundary:', formData._boundary);
    await this.offerService.saveFile(formData);

    console.log(event.currentFiles[0])
    this.offerData.files = [...this.offerData.files, {
      name: event.currentFiles[0].name,
      path: `public/agent1/${event.currentFiles[0].name}`,
      user: "agent1"
    }]
    this.fileUpload.clear();

    this.saveOffer(this.offerData.status, null);
  }

  onBasicUploadAuto(e) {
    console.log(e)
  }

  getCalculatedStock(selectedArticle, stockArt) {
    stockArt.conversionValue = stockArt.conversionValue ? stockArt.conversionValue : 1;
    let value = stockArt.stock;
    console.log(value)
    return Math.floor(value);
  }

  onSearchChange(searchValue: string): void {

    if (searchValue.length < 2) {
      this.isFoundSearch = false;
      //this.notify.warningMessage("Introduceti minim 2 caractere");
    } else {
      //console.log( this.offerData.categories);
      for (let topCategory of this.offerData.topCategories) {
        for (let category of topCategory.categories) {
          for (let article of category.articles) {
            if (article.articleName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
              this.isFoundSearch = true;
              article.found = true;
            } else {
              article.found = false;
            }
          }
        }
      }
    }
    //console.log(this.searchResultsArticles);
    console.log(this.isFoundSearch);
  }

  changeflag(f1, f2, f3) {
    this.f1 = f1;
    this.f2 = f2;
    this.f3 = f3;
  }
  saveQuantity(selectedArticle, stockItems) {
    let quantity = 0;
    let conversionQuantitySum = 0;
    let promoQuantity = 0;
    for (let stockItem of stockItems) {
      if (stockItem.conversionQuantity && stockItem.conversionQuantity > 0) {
        quantity += stockItem.conversionQuantity;
        if (stockItem.conversionQuantity > stockItem.stock) {
          this.notify.warningMessage("Cantitatea precizata depaseste cantitatea existenta in stoc!");
          return;
        }
        if (stockItem.supplierName == "PROMO") {
          promoQuantity += stockItem.conversionQuantity;
        } else {
          conversionQuantitySum += stockItem.conversionQuantity;
        }

      }
      else if (stockItem.conversionQuantity && stockItem.conversionQuantity < 0) {
        this.notify.warningMessage(`Precizati valori pozitive in inputul cantitate !`);
        return;
      }
    }
    if (selectedArticle.useMinimum && selectedArticle.offerMinimumQuantity > 0 && conversionQuantitySum > 0 && conversionQuantitySum < selectedArticle.offerMinimumQuantity) {
      this.notify.warningMessage(`Cantitate minima ${selectedArticle.offerMinimumQuantity} !`);
      return;
    }
    else if (!selectedArticle.useMinimum && selectedArticle.layerSelected > 0 && conversionQuantitySum > 0 && (conversionQuantitySum < selectedArticle.layerParity || conversionQuantitySum % selectedArticle.layerParity != 0)) {
      this.notify.warningMessage(`Cantitate invalida! Minim ${selectedArticle.layerParity} sau multiplu de aceasta valoare !`);
      return;
    }

    this.stockExceeded = false;
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {

            if (detail.CODOBIECT == selectedArticle.CODOBIECT) {
              detail.quantity = quantity;
              detail.promoQuantity = promoQuantity;
              if (quantity > detail.STOC) {
                this.notify.warningMessage("Cantitatea depaseste stocul din gestiunea proprie!")
              }
              detail.stockDetail = stockItems;
              detail.conversionQuantitySum = conversionQuantitySum + promoQuantity;
            }

            if (detail.quantity > detail.STOC) {
              this.stockExceeded = true;
            }

          }
        }
      }
    }
    this.stockDetail = null;

    this.refapaletare();
    //this.saveOffer('OFERTA', null)
  }

  refapaletare() {
    this.nextData = [];
    this.incorrect = false;
    let newPallet = {
      minimumHeight: 125,
      maximumHeight: 175,
      minimumWeight: 430,
      maximumWeight: 830,
      minimumValue: 2500
    }

    let customArtiles = [];


    //// 1. extract articles to be processed
    let articc = [];
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          //box articles, must be transformed in layer
          if (article.data[0].quantity && article.data[0].quantity > 0) {
            articc = [...articc,
            {
              articleName: article.articleName,
              data: [{ ...article.data[0] }]
            }]
          }
        }
      }
    }

    console.log(articc)
    // if (articc.length == 0) {
    //   this.notify.errorMessage("Oferta nu contine articole!");
    //   return;
    // }
    ////////////// 2.form complete pallets
    for (let art of articc) {
      let articleData = art.data[0];
      while ((articleData.quantity * articleData.boxWeight >= newPallet.maximumWeight || articleData.quantity / articleData.layerParity >= articleData.palletHeight / articleData.boxHeight)) {
        console.log("Pallet Complet");
        let item = {
          layers: [],
          height: 0,
          value: 0,
          maximumWeight: 0,
          maxPriority: 9999
        }
        //add layers till limits reached
        while (item.maximumWeight + (articleData.layerParity * articleData.boxWeight) <= newPallet.maximumWeight && articleData.quantity >= articleData.layerParity && item.height + articleData.boxHeight <= articleData.palletHeight) {
          let fisrtaaa = articleData.quantity;
          articleData.quantity = articleData.layerParity;
          item.layers.push({ mass: 9999, complete: true, layerHeight: articleData.boxHeight, articles: [{ ...articleData }] });

          item.maximumWeight += articleData.quantity * articleData.boxWeight;
          item.height += articleData.boxHeight;
          articleData.quantity = fisrtaaa - articleData.layerParity;

        }
        item.maxPriority = this.getPriority(item.layers[0])
        this.nextData.push(item)
      }
    }
    ////////////////////


    // 3. sort articles by number of full layers that can be formed after full pallets are done
    articc = articc.sort(function (a, b) {
      if (a.data[0].quantity / a.data[0].layerParity > b.data[0].quantity / b.data[0].layerParity) return -1;
      if (a.data[0].quantity / a.data[0].layerParity < b.data[0].quantity / b.data[0].layerParity) return 1;
      return 0;
    });
    ////////////////////////////////////////////

    // 4. get full layers of same article
    let firstLayers = [];
    for (let article of articc) {
      if (article.data[0].quantity && article.data[0].quantity > 0) {
        let art = { ...article.data[0] }
        let nrOfLayersFromBoxes = article.data[0].quantity / article.data[0].layerParity;
        let restQuantity = article.data[0].quantity % article.data[0].layerParity;
        if (nrOfLayersFromBoxes >= 1) {

          art.quantity = article.data[0].layerParity;

          console.log(Math.floor(nrOfLayersFromBoxes), art.layerParity, art.boxWeight)
          let calculatedLayerWeight = Math.floor(nrOfLayersFromBoxes) * art.layerParity * art.boxWeight;
          firstLayers.push({
            maximumWeight: calculatedLayerWeight,
            quantity: Math.floor(nrOfLayersFromBoxes),
            layerHeight: art.boxHeight,
            articles: [{ ...art }]
          });

          if (restQuantity > 0) {
            art.quantity = restQuantity;
            //push remaining articles to array to be processed later in valid layers based on parity
            customArtiles.push(art)
          }
        } else if (restQuantity > 0) {
          art.quantity = restQuantity;
          //push reiming articles to array to be processed later in valid layers based on parity
          customArtiles.push(art)
        }

        article.data[0].quantity = restQuantity;
      }
    }

    firstLayers = firstLayers.sort(function (a, b) {
      if (a.quantity > b.quantity) return -1;
      if (a.quantity < b.quantity) return 1;
      return 0;
    });
    console.log(".................................First layers", firstLayers);


    // 5. make pallet from full layers
    for (let layer of firstLayers) {
      let usedLayer = false;
      for (let pallet of this.nextData) {
        //layer ca be placed on pallet 
        //check if layer priority lower than complete layers
        //maxPriority
        //pallet.layers[pallet.layers.length - 1].complete && this.getPriority(pallet.layers[pallet.layers.length - 1]) <=
        if (!pallet.maxPriority || this.getPriority(layer) <= pallet.maxPriority) {
          console.log("COMPLETE LAYER DETECTED")
          if (pallet.height + (layer.quantity * layer.layerHeight) <= newPallet.maximumHeight && layer.quantity > 0 && pallet.maximumWeight + layer.maximumWeight < newPallet.maximumWeight) {
            pallet.height += layer.quantity * layer.layerHeight;
            pallet.maximumWeight += layer.maximumWeight;

            for (let i = 1; i <= layer.quantity; i++) {
              pallet.layers.push({
                layerHeight: layer.layerHeight,
                maximumWeight: layer.articles[0].boxWeight * layer.articles[0].layerParity,
                quantity: 1,
                articles: [...layer.articles]
              });
            }
            usedLayer = true;
            layer.quantity = 0;
          }
        }

        else if (!pallet.maxPriority) {
          if (pallet.height + (layer.quantity * layer.layerHeight) <= newPallet.maximumHeight && layer.quantity > 0 && pallet.maximumWeight + layer.maximumWeight < newPallet.maximumWeight) {
            pallet.height += layer.quantity * layer.layerHeight;
            pallet.maximumWeight += layer.maximumWeight;

            for (let i = 1; i <= layer.quantity; i++) {
              pallet.layers.push({
                layerHeight: layer.layerHeight,
                maximumWeight: layer.articles[0].boxWeight * layer.articles[0].layerParity,
                quantity: 1,
                articles: [...layer.articles]
              });
            }
            usedLayer = true;
            layer.quantity = 0;
          }
        }

      }
      //push layer to a new pallet
      if (!usedLayer) {

        let newPallet = {
          layers: [],
          height: layer.quantity * layer.layerHeight,
          value: 0,
          maximumWeight: layer.maximumWeight
        }
        for (let i = 1; i <= layer.quantity; i++) {
          newPallet.layers.push({
            layerHeight: layer.layerHeight,
            maximumWeight: layer.articles[0].boxWeight * layer.articles[0].layerParity,
            quantity: 1,
            articles: [...layer.articles]
          });
        }
        this.nextData.push(newPallet)
      }
    }


    // 6. get layers from custom articles
    let articlesGroupedByParity = [];
    for (let article of customArtiles) {
      let foundCustomLayer = articlesGroupedByParity.find(i => {
        return article.boxHeight == i.boxHeight && article.boxLenght == i.boxLenght && article.boxWidth == i.boxWidth;
      });
      if (foundCustomLayer) {
        foundCustomLayer.i++;
        foundCustomLayer.articles.push(article);
      }
      else {
        articlesGroupedByParity.push({ layerParity: article.layerParity, boxHeight: article.boxHeight, boxLenght: article.boxLenght, boxWidth: article.boxWidth, articles: [article] })
      }
    }

    // // 7. make valid layers from customLayers

    // ///////////////////////////////////////////////////////
    let validCustomLayers = [];
    let nerepArticles = []
    for (let customLayer of articlesGroupedByParity) {
      let totalParity = customLayer.articles[0].layerParity;
      let validCustomLayer = { articles: [], layerHeight: 0, maximumWeight: 0, quantity: 1 };
      for (let article of customLayer.articles) {

        if (article.quantity <= totalParity) {
          validCustomLayer.articles.push(article);
          validCustomLayer.maximumWeight += article.quantity * article.boxWeight;
          validCustomLayer.layerHeight = article.boxHeight;
          totalParity -= article.quantity;
          if (totalParity == 0) {
            validCustomLayers.push(validCustomLayer);
            validCustomLayer = { articles: [], layerHeight: 0, maximumWeight: 0, quantity: 1 };
            totalParity = customLayer.articles[0].layerParity;
          }
        }
        else {
          let art: any = { ...article };
          art.quantity = totalParity;
          validCustomLayer.articles.push(art);
          validCustomLayer.maximumWeight += art.quantity * art.boxWeight;
          validCustomLayer.layerHeight = art.boxHeight;
          validCustomLayers.push(validCustomLayer);


          article.quantity -= totalParity;
          //totalParity=
          validCustomLayer = { articles: [article], layerHeight: article.boxHeight, maximumWeight: article.quantity * article.boxWeight, quantity: 1 };
          totalParity = customLayer.articles[0].layerParity - article.quantity;
        }
      }

      nerepArticles = [...nerepArticles, ...validCustomLayer.articles]
    }
    console.log("validCustomLayers ")
    console.log(validCustomLayers)

    //push valid layers to existing pallets
    let item = {
      layers: [],
      height: 0,
      value: 0,
      maximumWeight: 0
    }

    let pushed = true;

    let unrepartizedLayers = [...validCustomLayers]


    // 8. make pallet from valid custom layers 
    for (let layer of unrepartizedLayers) {
      let usedLayer = false;
      for (let pallet of this.nextData) {
        //layer ca be placed on pallet, check if lighter than complete pallet
        if ((!pallet.maxPriority || (layer.maximumWeight / layer.layerHeight) <= pallet.maxPriority) && layer.quantity > 0 && pallet.height + layer.layerHeight <= 175 && pallet.maximumWeight + layer.maximumWeight < newPallet.maximumWeight) {

          pallet.layers = [...pallet.layers, layer]

          pallet.height += layer.layerHeight;
          pallet.maximumWeight += layer.maximumWeight;
          usedLayer = true;
          layer.quantity = 0;
        }
      }
      //push layer to a new pallet
      if (!usedLayer) {

        let newPallet = {
          layers: [layer],
          height: layer.layerHeight,
          value: 0,
          maximumWeight: layer.maximumWeight
        }

        this.nextData.push(newPallet);
        layer.quantity = 0;
      }
    }

    ////9. try to split last pallet if posibble

    //last pallet layers
    if (this.nextData.length > 1) {
      let lastPallet = this.nextData.pop();

      //check if space to redistibute last pallet
      let remainingHeigthSpace = 0;
      for (let [i, pallet] of this.nextData.entries()) {
        //layer ca be placed on pallet
        if (i < this.nextData.length) {
          remainingHeigthSpace += newPallet.maximumHeight - pallet.height;
        }
      }
      console.log(remainingHeigthSpace, lastPallet.height)
      if (remainingHeigthSpace >= lastPallet.height) {

        for (let layer of lastPallet.layers) {
          let usedLayer = false;
          for (let [i, pallet] of this.nextData.entries()) {
            //layer ca be placed on pallet
            if (i < this.nextData.length && layer.quantity != -1 && pallet.height + layer.layerHeight <= 175 && pallet.maximumWeight + layer.maximumWeight < newPallet.maximumWeight) {
              //layer.complete = true;
              pallet.layers.push(layer);
              pallet.height += layer.layerHeight;
              pallet.maximumWeight += layer.maximumWeight;
              usedLayer = true;
              layer.quantity = -1;
            }
          }
          lastPallet.layers = lastPallet.layers.filter(l => {
            return l.quantity >= 0
          })

        }
      }
      if (lastPallet.layers.length > 0) {
        this.nextData.push(lastPallet)
      }
    }

    // ////////////////////
    // 10. generate layers from nonstandard articles
    let layersDetail = {
      width: 800,
      length: 1200
    }
    this.layerrr = { articles: [], surface: 0, partial: true, maximumWeight: 0 }

    this.badLayers = [];
    // 11. order nerep articles by surface

    nerepArticles = nerepArticles.sort(function (a, b) {
      if (a.quantity * a.boxLenght * a.boxWidth > b.quantity * b.boxLenght * b.boxWidth) return -1;
      if (a.quantity * a.boxLenght * a.boxWidth < b.quantity * b.boxLenght * b.boxWidth) return 1;
      return 0;
    });
    console.log(nerepArticles)



    for (let article of nerepArticles) {
      //remaining surface
      let articlesOnRemainingSurface = (960000 - this.layerrr.surface) / (article.boxLenght * article.boxWidth * 100);
      console.log("Incap ", articlesOnRemainingSurface, article.quantity)
      if (this.layerrr.surface + (article.quantity * article.boxLenght * article.boxWidth * 100) <= 960000) {
        this.layerrr.articles.push(article);
        this.layerrr.maximumWeight += article.quantity * article.boxWeight;
        this.layerrr.surface += (article.quantity * article.boxLenght * article.boxWidth * 100);
      }
      else {
        //try to put new layer on existing ones
        let bad = true;
        for (let pallet of this.nextData) {
          if (!pallet.hasCustom && this.layerrr.articles.length > 0 && pallet.height + this.layerrr.articles[0].boxHeight < newPallet.maximumHeight
            && pallet.maximumWeight + this.layerrr.maximumWeight < newPallet.maximumWeight) {
            pallet.layers = [...pallet.layers, this.layerrr];
            bad = false;
            pallet.hasCustom = true;
            break;
          }
        }

        //else push to bad layers
        if (bad && this.layerrr.articles.length > 0) {
          this.badLayers.push(this.layerrr);
        }

        layersDetail = {
          width: 800,
          length: 1200
        }
        this.layerrr = { articles: [], surface: 0, partial: true }
        this.layerrr.articles.push(article);
        this.layerrr.surface += (article.quantity * article.boxLenght * article.boxWidth * 100);
      }
      console.log(article.DENUMIRE, article.boxLenght, article.boxWidth, article.boxHeight, article.quantity * article.boxLenght * article.boxWidth * 10)
    }

    // 12 .order by weight

    for (let pallet of this.nextData) {
      console.log(pallet)
      let that = this;
      pallet.layers = pallet.layers.sort(function (a, b) {
        if (a.partial) {
          return 1;
        }
        if (b.partial) {
          return 1;
        }
        if (a.complete) {
          return 1;
        }
        if (b.complete) {
          return 1;
        }

        // Compare the 2 dates
        if (that.getPriority(a) > that.getPriority(b)) return -1;
        if (that.getPriority(a) < that.getPriority(b)) return 1;
        return 0;
      });

    }


    // 13.place on top if possible bad layers
    console.log(this.layerrr)

    if (this.layerrr.surface > 0) {
      let bad = true;
      for (let pallet of this.nextData) {
        if (this.getPriority(this.layerrr) <= this.getPriority(pallet.layers[pallet.layers.length - 1]) && !pallet.hasCustom && this.layerrr.articles.length > 0 && pallet.height + this.layerrr.articles[0].boxHeight < 175 &&
          pallet.maximumWeight + this.layerrr.maximumWeight < 830) {
          pallet.layers = [...pallet.layers, this.layerrr];
          bad = false;
          break;
        }
      }

      if (bad) {
        this.badLayers.push(this.layerrr);
      }
    }

    this.badPallet = {
      layers: [...this.badLayers],
      height: 0,
      value: 0,
      maximumWeight: 0
    }

    for (let pallet of this.nextData) {

      let height = 0;
      for (let layer of pallet.layers) {
        height += layer.articles[0].boxHeight;
      }

      pallet.height = this.round2Decimals(height);



      let value = 0;
      for (let layer of pallet.layers) {
        for (let article of layer.articles) {
          value += article.quantity * (article.LISTA - (article.LISTA * (article.discount ? article.discount : 0) / 100));
        }
      }

      pallet.value = this.round2Decimals(value);


      let kg = 0;
      for (let layer of pallet.layers) {
        for (let article of layer.articles) {
          kg += article.quantity * article.boxWeight;
        }
      }

      pallet.weight = this.round2Decimals(kg);
    }

    this.offerData.pallets = this.nextData;

    //check if inneficient pallet

    for (let pallet of this.nextData) {
      if (!(pallet.value >= newPallet.minimumValue || pallet.height >= newPallet.minimumHeight || pallet.weight >= newPallet.minimumWeight)) {
        this.incorrect = true;
        pallet.incorrect = true;
      }
      // if (pallet.value < 2500) {
      //   pallet.incorrectValue = true;
      // } else if (pallet.value < 2500 && pallet.height < 140) {
      //   pallet.incorrectHeight = true;
      // }
      // else if (pallet.value < 2500 && pallet.weight < 140) {
      //   pallet.incorrectWeight = true;
      // }
    }
    /////////////  OLDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD


    //console.log(data)




    //console.log(unrepartizedLayers)
  }

  async dragStarted(event) {
    // this.workStations = this.workStations.filter(workStation => {
    //   return workStation.symbol == event.source.data.internalCommand.machineSymbol && workStation.extension == event.source.data.internalCommand.articleLocation;
    // });

    // this.draggedItemData = null;
    // this.draggedItemData = await this.productionSectionAllocationService.getInternalCommand(event.source.data.internalCommand.commandId).toPromise();
    console.log(event);
  }

  async dragEnded(event) {

  }

  drop(event) {
    console.log(event)

    if (event.previousContainer.data.height === event.container.data.height) {
      moveItemInArray(event.container.data.layers, event.previousContainer.data.layers.length - event.previousIndex, event.previousContainer.data.layers.length - event.currentIndex);

      //await this.productionSectionAllocationService.saveWorkStationState(data).toPromise();


    } else {

      transferArrayItem(event.previousContainer.data.layers,
        event.container.data.layers,
        event.previousContainer.data.layers.length - event.previousIndex,
        event.container.data.layers.length - event.currentIndex);

    }


    // for (let pallet of this.nextData) {
    //   console.log(pallet)
    //   pallet.layers = pallet.layers.sort(function (a1, b1) {

    //     if (a1.partial) {
    //       return 1;
    //     }
    //     if (b1.partial) {
    //       return -1;
    //     }


    //     let a = a1.articles[0];
    //     let b = b1.articles[0];

    //     // Compare the 2 dates
    //     if ((a.quantity * a.boxWeight) / a.boxHeight > (b.quantity * b.boxWeight) / b.boxHeight) return -1;
    //     if ((a.quantity * a.boxWeight) / a.boxHeight < (b.quantity * b.boxWeight) / b.boxHeight) return 1;
    //     return 0;
    //   });
    // }
  }

  getPriority(layer) {
    let priority = 0;
    for (let article of layer.articles) {
      // if (layer.complete) {
      //   return 9999;
      // }
      // else {
      priority += ((article.quantity * article.boxWeight) / article.itemHeight);

    }

    return this.round2Decimals(priority);
  }

  getPalletWeight(pallet) {
    let kg = 0;
    for (let layer of pallet.layers) {
      for (let article of layer.articles) {
        kg += article.quantity * article.boxWeight;
      }
    }

    return this.round2Decimals(kg);
  }

  getPalletHeight(pallet) {
    let height = 0;
    for (let layer of pallet.layers) {
      height += layer.articles[0].boxHeight;
    }

    return this.round2Decimals(height);
  }

  getPalletValue(pallet) {
    let value = 0;
    for (let layer of pallet.layers) {
      for (let article of layer.articles) {
        value += article.quantity * (article.LISTA - (article.LISTA * (article.discount ? article.discount : 0) / 100));
      }
    }

    return this.round2Decimals(value);
  }

  getLayerHeight(layer) {
    let max = 0;
    for (let article of layer.articles) {
      if (article.boxHeight > max) {
        max = article.boxHeight
      }
    }
    return this.round2Decimals(max);
  }

  getLayerWeight(layer) {
    let kg = 0;
    for (let article of layer.articles) {
      kg += (article.quantity * article.weight);
    }
    return this.round2Decimals(kg);
  }

  setEmptyClientData() {
    this.newClientData = {
      clientType: "",
      name: "",
      fiscalCode: "",
      tradeRegister: "",
      phone: "",
      county: "",
      locality: null,
      street: "",
      scara: "",
      number: "",
      bloc: "",
      etaj: "",
      apartament: ""
    }
  }

  showDialogMaximized(event, dialog) {
    dialog.maximized = true;
  }

  async validateFiscalCode(fiscalCode) {
    fiscalCode = fiscalCode.replace('RO', '').replace('ro', '').trim();
    if (isNaN(parseInt(fiscalCode))) {
      this.notify.warningMessage('Cod fiscal eronat.');
      return;
    }
    fiscalCode = parseInt(fiscalCode);
    let res: any = await this.offerService
      .validateFiscalCode(fiscalCode)
      .toPromise();
    if (!res.denumire) {
      this.notify.warningMessage(
        'Cod fiscal eronat. Nu a putut fi gasit in baza de date ANAF.'
      );
    } else {
      this.validFiscalCode = true;
      this.validatedPartnerData = res;
      this.newClientData.name = this.validatedPartnerData.denumire;
      this.newClientData.fiscalCode = this.validatedPartnerData.cui;
    }
  }

  getColor(article) {

    if (!this.colorObj[article.CODINTERN]) {
      this.colorObj[article.CODINTERN] = randomColor();
    }

    return this.colorObj[article.CODINTERN];
  }


}
