import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';

@Injectable()
export class NotifyService {
    config = {
        timeOut: 5000,
        progressBar: true
    };

    configError = {
        timeOut: 1000000,
        progressBar: false
    }
    constructor(private toastr: ToastrService, private translate: TranslateService, private messageService: MessageService) {

    }

    success(translationKey) {
        this.translate.get(translationKey).subscribe((translatedMessage: string) => {
            this.toastr.success('', translatedMessage, this.config);
        });
    }

    successMessage(message) {
        this.toastr.success('', message);
    }

    error(translationKey) {
        if (translationKey) {
            this.translate.get(translationKey).subscribe((translatedMessage: string) => {
                this.toastr.error('', translatedMessage, this.configError);
            });
        } else {
            this.toastr.error('', "EROARE", this.configError);
        }
    }

    errorMessage(message) {
        this.toastr.error('', message, this.configError);
    }

    info(translationKey) {
        this.translate.get(translationKey).subscribe((translatedMessage: string) => {
            this.toastr.info('', translatedMessage, this.config);
        });
    }

    infoMessage(message) {
        this.toastr.info('', message);
    }

    infoMessageVersion(message) {
        this.toastr.info('', message, this.configError);
    }

    warning(translationKey) {

        // this.translate.get(translationKey).subscribe((translatedMessage: string) => {
        //     this.messageService.add({severity:'warn',life:100000,sticky:true, summary:translatedMessage})
        // });
        this.translate.get(translationKey).subscribe((translatedMessage: string) => {
            this.toastr.warning('', translatedMessage, this.config);
        });
    }

    warningMessage(message) {

        //this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'})

        this.toastr.warning('', message);
    }


    clear() {
        this.toastr.clear();
    }
}