<mat-card style="margin:5px;">

  <!-- <button style="margin: 3px 5px;" (click)="populatePrice()" pButton type="button" label="Populeaza preturile"
  class="p-button-lg p-button-secondary"></button>

  <button style="margin: 3px 5px;" (click)="fixDeliveryNote()" pButton type="button" label="Fix note predare"
  class="p-button-lg p-button-secondary"></button> -->


  <!-- (onRowSelect)="goToOffer($event)" -->
  <p-table dataKey="packageId" selectionMode="single" styleClass="p-datatable-responsive-demo p-datatable-sm" #dt
    [value]="data" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
    [globalFilterFields]="['commandNumber','observations','finishType', 'paymentType','dealerName','clientName','routeName','clientFiscalCode', 'agentName']"
    currentPageReportTemplate="Afisat de la {first} pana la {last} din {totalRecords} linii"
    [rowsPerPageOptions]="[10,25,50]">

    <ng-template class="tableTitle" pTemplate="caption">
      <div class="hiddenMobile">
        <div style="display: flex;">
          <!-- <div (click)="clearTable();showSearch=!showSearch" style="width:25px;cursor: pointer;margin-top: 5px;"
            [inlineSVG]="'/assets/images/search.svg'"></div> -->

          <i (click)="clearTable();showSearch=!showSearch" class="pi pi-search"
            style="font-size: 2rem;color:#16a085;cursor: pointer;"></i>
          <div style="width:100%;font-size: 30px;color:#536c79;">Dashboard</div>
          <!-- <button (click)="loadAllData()" style="width: 170px;" pButton pRipple type="button" label="Incarca complet"
            class="p-button-outlined p-button-success"></button> -->
          <p-calendar style="margin: 0 5px;" [(ngModel)]="startDate" [showIcon]="true" inputId="icon"></p-calendar>
          <p-calendar style="margin: 0 5px;" [(ngModel)]="stopDate" [showIcon]="true" inputId="icon"></p-calendar>

          <p-button style="margin: 0 5px;" (click)="loadAllData()" label="Reincarca" icon="pi pi-refresh"
            iconPos="left"></p-button>
        </div>

        <div *ngIf="loadingData" style="font-size:20px;margin:5px;color:green;">Se aduc toate comenzile! Vă rugăm
          asteptați!
        </div>
        <!-- Diacritice (ăâîșț -->
        <p-progressBar *ngIf="loadingData" mode="indeterminate" [style]="{'height': '6px'}">
        </p-progressBar>
      </div>
      <div class="hiddenDesktop">

        <div style="width:100%;font-size: 30px;color:#536c79;">Dashboard</div>

        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Cautare..." />
        </span>
        <button style="width: 160px;margin-left: 10px;" pButton pRipple type="button" label="Incarca complet"
          class="p-button-outlined p-button-success"></button>







      </div>

      <!-- <div *ngIf="isLoading" style="font-size:20px;margin:5px;color:green;">Se aduc date!
          </div>
          <div *ngIf="isValidating" style="font-size:20px;margin:5px;color:green;">Validăm datele. Vă rugăm asteptați!
          </div> -->
      <!-- Diacritice (ăâîșț -->
      <!-- <p-progressBar *ngIf="isValidating || isLoading" mode="indeterminate" [style]="{'height': '6px'}">
          </p-progressBar> -->
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{'width':'105px'}">Status</th>
        <th [style]="{'width':'90px'}" pSortableColumn="commandNumber">Comanda
          <p-sortIcon field="commandNumber"></p-sortIcon>
        </th>
        <th [style]="{'width':'90px'}" pSortableColumn="offerData.selectedMaterial">Material
          <p-sortIcon field="offerData.selectedMaterial"></p-sortIcon>
        </th>
        <th [style]="{'width':'90px'}" pSortableColumn="offerData.selectedColor">Culoare
          <p-sortIcon field="offerData.selectedColor"></p-sortIcon>
        </th>
        <th [style]="{'width':'90px'}" pSortableColumn="offerData.selectedThickness">Grosime
          <p-sortIcon field="offerData.selectedThickness"></p-sortIcon>
        </th>
        <th [style]="{'width':'18%'}" pSortableColumn="observations">Observații
          <p-sortIcon field="observations"> </p-sortIcon>
        </th>
        <!-- <th *ngIf="company!='gaitano' && company !='gaitanotest'" [style]="{'width':'100px'}"
          pSortableColumn="finishType">Document
          <p-sortIcon field="finishType"> </p-sortIcon>
        </th> -->
        <th *ngIf="company!='gaitano' && company !='gaitanotest'" [style]="{'width':'100px'}"
          pSortableColumn="paymentType">Plata <p-sortIcon field="finishType">
          </p-sortIcon>
        </th>
        <th [style]="{'width':'10%'}">Agent</th>
        <th *ngIf="company!='gaitano' && company !='gaitanotest'" [style]="{'width':'10%'}">Dealer</th>
        <th [style]="{'width':'15%'}">Client</th>
        <th [style]="{'width':'10%'}">CF/CNP</th>
        <th *ngIf="!ecrm" [style]="{'width':'10%'}">Ruta Producție</th>
        <th [style]="{'width':'145px'}">
          Data plasare producție


          <!-- <p-calendar (onSelect)="onDateSelect($event, 'routeDate','gt')"
                (onClearClick)="dt.filter('', 'routeDate', 'gt')" [showButtonBar]="true" styleClass="p-column-filter"
                [readonlyInput]="true" dateFormat="dd-mm-yy">
              </p-calendar>

              <p-calendar (onSelect)="onDateSelect($event, 'routeDate','lt')"
                (onClearClick)="dt.filter('', 'routeDate', 'lt')" [showButtonBar]="true" styleClass="p-column-filter"
                [readonlyInput]="true" dateFormat="dd-mm-yy">
              </p-calendar> -->

        </th>
        <th [style]="{'width':'10%'}">Traseu creat pentru livrare</th>
        <th [style]="{'width':'145px'}">Data programată pentru încărcare</th>
      </tr>
      <tr *ngIf="showSearch" class="p-inputtext-sm">
        <th>
          <p-dropdown [autoDisplayFirst]="false" [options]="calculatedStatuses"
            (onChange)="dt.filter($event.value, 'calculatedStatus', 'equals')" styleClass="p-column-filter statusFilter"
            [showClear]="true">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
            </ng-template>
          </p-dropdown>
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'commandNumber', 'contains')"
            class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text"
            (input)="dt.filter($event.target.value, 'offerData.selectedMaterial', 'contains')" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'offerData.selectedColor', 'contains')"
            class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text"
            (input)="dt.filter($event.target.value, 'offerData.selectedThickness', 'contains')" class="p-column-filter">
        </th>

        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'observations', 'contains')"
            class="p-column-filter">
        </th>
        <!-- <th *ngIf="company!='gaitano' && company !='gaitanotest'">
          <p-dropdown [autoDisplayFirst]="false" [options]="finishTypes"
            (onChange)="dt.filter($event.value, 'finishType', 'equals')" styleClass="p-column-filter documentFilter"
            [showClear]="true">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
            </ng-template>
          </p-dropdown>
        </th> -->
        <th *ngIf="company!='gaitano' && company !='gaitanotest'">
          <p-dropdown [autoDisplayFirst]="false" [options]="paymentTypes"
            (onChange)="dt.filter($event.value, 'paymentType', 'equals')" styleClass="p-column-filter paymentFilter"
            [showClear]="true">
            <ng-template let-option pTemplate="item">
              <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
            </ng-template>
          </p-dropdown>
        </th>

        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'agentName' , 'contains')"
            class="p-column-filter">
        </th>

        <th *ngIf="company!='gaitano' && company !='gaitanotest'">
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'dealerName', 'contains')"
            class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'clientName', 'contains')"
            class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'clientFiscalCode', 'contains')"
            class="p-column-filter">
        </th>
        <th *ngIf="!ecrm">
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'routeName', 'contains')"
            class="p-column-filter">
        </th>
        <th>
          <p-calendar (onSelect)="onDateSelect($event, 'routeDate','contains')"
            (onClearClick)="dt.filter('', 'routeDate', 'equals')" [showButtonBar]="true" styleClass="p-column-filter"
            [readonlyInput]="true" dateFormat="dd-mm-yy">
          </p-calendar>
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'routeDeliveryName', 'contains')"
            class="p-column-filter">
        </th>
        <th>
          <p-calendar (onSelect)="onDateSelect($event,'routeDeliveryDate','contains')"
            (onClearClick)="dt.filter('', 'routeDeliveryDate', 'equals')" [showButtonBar]="true"
            styleClass="p-column-filter" [readonlyInput]="true" dateFormat="dd-mm-yy">
          </p-calendar>
        </th>

      </tr>

    </ng-template>
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>
          <div (click)="clickTable(product)"
            [ngClass]="(product.calculatedStatus=='INPROGRESS' || product.calculatedStatus == 'COMPLETE'|| product.calculatedStatus == 'INVOICE'|| product.calculatedStatus == 'VERIFY'?'cursorPointer':'')+' status-'+product.calculatedStatus">
            {{ "status."+ product.calculatedStatus | translate}}
          </div>
          <span class="hiddenDesktop p-column-article">Comanda {{product.commandNumber}}</span>
        </td>
        <td>
          <!-- hasDashoardRight && -->
          <div class="hiddenMobile" [ngClass]="'link'" (click)="goToOffer(product)">
            {{product.commandNumber}}
          </div>
        </td>
        <td><span class="p-column-title">Material </span> {{product?.offerData?.selectedMaterial}}</td>
        <td><span class="p-column-title">Culoare </span> {{product?.offerData?.selectedColor}}</td>
        <td><span class="p-column-title">Grosime </span> {{product?.offerData?.selectedThickness}}</td>
        <td><span class="p-column-title">Observatii </span> {{product?.observations}}</td>
        <!-- <td *ngIf="company!='gaitano' && company !='gaitanotest'"> <span class="p-column-title">Document</span>
          {{product.finishType=="undefined"?"-":product.finishType}}
        </td> -->
        <td *ngIf="company!='gaitano' && company !='gaitanotest'"><span class="p-column-title">Tip Plata</span>
          {{product.paymentType=="undefined"?"-":getPaymentString(product.paymentType)}}
        </td>
        <td><span class="p-column-title">Agent</span>
          {{product.agentName}}</td>
        <td *ngIf="company!='gaitano' && company !='gaitanotest'"><span class="p-column-title">Dealer</span>
          {{product.dealerName}}</td>
        <td><span class="p-column-title">Client</span> {{product?.clientName}}</td>
        <td style="    word-break: break-all;"><span class="p-column-title">CF/CNP</span> {{product.clientFiscalCode}}
        </td>
        <td *ngIf="!ecrm"><span class="p-column-title">Ruta Productie</span> {{product?.routeName}}</td>
        <td style="text-align: center;"><span class="p-column-title">Data Productie</span> {{product?.routeDate|
          date:'dd.MM.yyyy HH:mm'}}</td>
        <td><span class="p-column-title">Traseu Livrare</span> {{product?.routeDeliveryName}}</td>
        <td style="text-align: center;"><span class="p-column-title">Data Livrare</span> {{product?.routeDeliveryDate|
          date:'dd.MM.yyyy HH:mm'}}</td>
      </tr>
    </ng-template>

  </p-table>
</mat-card>


<p-dialog *ngIf="internallCommands" [style]="{'max-height': '80%',
'overflow-y': 'auto', 'width':'500px'}" styleClass="dialog-constants" [(visible)]="showDetailModal" [draggable]="false"
  showEffect="fade" [closable]="false" appendTo="body" [modal]="true">
  <p-header>
    <div class="dialog-title">Detalii</div>
    <div class="closeSvgRight" (click)="internallCommands =null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <div *ngFor="let internallCommand of internallCommands">
    <mat-card style=" justify-content: center"
      [ngClass]="'articleMatCard '+ (internallCommand.internalCommandStatus=='COMPLETE'?'greenCard':'redCard')">

      <div *ngIf="internallCommand.machineName" style="display: flex;  align-items: center;">
        <div
          (click)="internallCommand.internalCommandStatus!='COMPLETE'?loadRemainingInternallCommandsArticles(internallCommand.data.commandId):''"
          class="articleTitle" style="font-size: 18px;">
          {{internallCommand.machineName}} - {{internallCommand.data.commandSeries}}
          {{internallCommand.data.commandNumber}}

          <button *ngIf="internallCommand.internalCommandStatus!='COMPLETE'"
            (click)="loadRemainingInternallCommandsArticles(internallCommand.data.commandId)" pButton pRipple
            type="button" icon="pi pi-eye" pTooltip="Articole neambalate/produse"
            class="p-button-rounded p-button-success p-button-text"></button>
        </div>
        <button *ngIf="company== 'gaitanotest' || company == 'gaitano'" (click)="generatePalletPdf(internallCommand)"
          style="margin: 0 10px;" pButton pRipple type="button" icon="pi pi-file-pdf"
          class="p-button-rounded p-button-success"></button>
      </div>
      <!-- <div *ngIf="company== 'gaitanotest' || company == 'gaitano'" style="width:100%;text-align:center;">
        <button (click)="generatePalletPdf(internallCommand)" pButton pRipple type="button" label="GENEREAZA PDF"
          class="p-button-success finishAction"></button>

        <button (click)="generatePalletPdf(internallCommand)" pButton pRipple type="button" icon="pi pi-file-pdf"
          class="p-button-rounded p-button-success"></button>

        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
      </div> -->
      <div *ngIf="company== 'carettatest' || company == 'caretta' || company=='globaltest'|| company=='globaldesign'"
        style="display: flex;flex-wrap: wrap;">
        <ng-container *ngIf="internallCommand.machineName">
          <ng-container *ngFor="let package of internallCommand.packages" style="margin: 0 5px;">
            <div class="p-grid">
              <div class="p-col-2">
                <div style="font-family: 'CarettaOffice';color:white;"> {{package.packageNumber}} </div>
                <div (click)="setPackage(package,internallCommand)"
                  style="  width: 35px;height: 25px; margin-left: 7px;"
                  [ngClass]="{'orangeCardBorder': package.status=='LOADED', '': package.status=== 'CREATED', 'magentaCardBorder': package.status=='INVOICE'}"
                  [inlineSVG]="'/assets/images/box.svg'"></div>
              </div>

            </div>

          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="!internallCommand.machineName && internallCommand.data">

        <div
          (click)="internallCommand.internalCommandStatus!='COMPLETE'?loadRemainingInternallCommandsArticles(internallCommand.data.commandId):''"
          class="articleTitleNerep">Nerepartizata {{internallCommand.data.articleLocation}}
        </div>
      </div>


    </mat-card>
  </div>

  <div *ngIf="showStatus" style="width: 100%;display:flex;">
    <div style="width: 50%;">
      <div>Status comanda</div>
      <div style="display:flex;    align-items: center;">
        <div style="height:15px; width: 15px;background-color: red;">

        </div>
        <div style="margin-left:10px;">IN LUCRU</div>
      </div>
      <div style="display:flex;    align-items: center;">
        <div style="height:15px; width: 15px;background-color: green;">
        </div>
        <div style="margin-left: 10px;">REALIZATA</div>
      </div>
    </div>
    <div>
      <div>Status colet</div>
      <div style="display:flex;    align-items: center;">
        <div style="height:15px; width: 15px;background-color: orange;">
        </div>
        <div style="margin-left:10px;">SCANAT</div>
      </div>
      <div style="display:flex;    align-items: center;">
        <div style="height:15px; width: 15px;background-color: magenta;">
        </div>
        <div style="margin-left: 10px;">FACTURAT</div>
      </div>

    </div>
  </div>
</p-dialog>


<p-dialog *ngIf="articlesFromInternallCommand" [style]="{'max-height': '80%',
'overflow-y': 'auto'}" styleClass="dialog-constants" [draggable]="false" showEffect="fade" [closable]="false"
  appendTo="body" [modal]="true" [(visible)]="showDetailModal">
  <p-header>
    <div class="dialog-title">Articole neambalate/produse</div>
    <div class="closeSvgRight" (click)="articlesFromInternallCommand =null" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <div *ngFor="let article of articlesFromInternallCommand">
    <mat-card style="justify-content: center;display:flex" [ngClass]="'articleMatCard '">
      <div style="color:white">
        <span *ngIf="company=='globaldesign'||company=='globaltest'" style="color:green">{{article.command1Id}} </span>
        {{article.displayName}}
      </div>
    </mat-card>
  </div>
</p-dialog>


<p-dialog *ngIf="selectedPackage" [style]="{'max-height': '80%',
'overflow-y': 'auto'}" styleClass="dialog-constants" [draggable]="false" showEffect="fade" [closable]="false"
  appendTo="body" [modal]="true" [(visible)]="showDetailModal">
  <p-header>
    <div class="dialog-title">Articole din colet</div>
    <div class="closeSvgRight" (click)="selectedPackage =null" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <!-- <button *ngIf="hasReprintRight" (click)="reprintLabel()" pButton pRipple type="button" label="Reprinteaza eticheta"
    class="p-button-info"></button> -->


  <button *ngIf="hasReprintRight" (click)="reprintLabelv2()" pButton pRipple type="button" label="Regenereaza eticheta"
    class="p-button-info"></button>
  <button *ngIf="company=='globaltest' || company =='globaldesign'" (click)="reprintLabelGlobalv2()" pButton pRipple
    type="button" label="Regenereaza eticheta Global" class="p-button-info"></button>



  <div *ngFor="let article of selectedPackage.data.articles">
    <mat-card style="justify-content: center;display:flex" [ngClass]="'articleMatCard '">
      <div style="color:white">
        <span *ngIf="company=='globaldesign'||company=='globaltest'" style="color:green">{{article.command1Id}} </span>
        {{article.displayName}}
      </div>
    </mat-card>
  </div>
</p-dialog>



<p-dialog *ngIf="showCustodyModal" [style]="{'max-height': '80%',
'overflow-y': 'auto'}" styleClass="dialog-constants" [draggable]="false" showEffect="fade" [closable]="false"
  appendTo="body" [modal]="true" [(visible)]="showCustodyModal">
  <p-header>
    <span class="dialog-title">Custodie {{selectedCommand.commandNumber}}</span>
    <div class="closeSvgRight" (click)="showCustodyModal =false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <div style="font-size:20px;">
    Doriti afisarea pe harta a custodiei cu numarul {{selectedCommand.commandNumber}}?
  </div>

  <div (click)="changeStatusCustody()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>
</p-dialog>

<p-dialog *ngIf="showVerifyModal" [style]="{'max-height': '80%',
'overflow-y': 'auto'}" styleClass="dialog-constants" [draggable]="false" showEffect="fade" [closable]="false"
  appendTo="body" [modal]="true" [(visible)]="showVerifyModal">
  <p-header>
    <span class="dialog-title">Validare cantitati comanda {{selectedCommand.commandNumber}}</span>
    <div class="closeSvgRight" (click)="showVerifyModal =false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <div style="font-size:20px;">
    Confirmati cantitatile precizate de client ca fiind corecte?
  </div>

  <div (click)="changeStatusVerify()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>
</p-dialog>

<!-- <p-sidebar [(visible)]="visibleSidebar5" [fullScreen]="true"  [showCloseIcon]="false">
  <div style="    width: 100%;
  margin-top: 300px;
  font-size: 36px;
  color: #536c79;
  text-align: center;
  font-family: 'CarettaOffice';">SE ADUC DATE!</div>
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
</p-sidebar> -->