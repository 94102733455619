<div style="display:flex;justify-content: center; margin-top:10px">
    <div *ngIf="!selectedOfferType && industryCategories" style="flex-direction: column;display: flex;">
        <div style="width:220px;" *ngFor="let provider of industryCategories">
            <button (click)="getMaterial(provider)" pButton pRipple type="button" [label]="provider.name"
                class="p-button-success p-mb-2 typeButton"></button>
        </div>
    </div>
</div>




<div style="display:flex;" *ngIf="offerData && offerData.categories.length>0">
    <div style="    min-width: 600px;
    width: 100%;">
        <span *ngFor="let item of offerData.categories">
            <mat-card style="margin:5px; width:100%" *ngIf="showCategory(item)">

                <div class="categoryTitle" style="text-align: center">{{item.category}}</div>

                <table id="customers" style="width:100%">
                    <tr style="font-size:14px;">
                        <th class="name">Nume</th>
                        <th class="quantity">Cant.</th>
                        <th class="unitMeasure">UM</th>
                        <th class="price">Pret cu discount</th>
                        <th class="listPrice">Subtotal</th>
                        <th *ngIf="debug" class="listPrice">Prod prop</th>
                        <th *ngIf="debug" class="listPrice">Ext</th>
                        <th *ngIf="debug" class="listPrice">Gest</th>
                        <!-- <th>Obs</th> -->
                    </tr>
                    <tr *ngFor="let art of item.articles; let itemI = index">
                        <ng-container
                            *ngIf="!art.data[0].D1 && !art.hideArticle && 
              ((!showZeroQuantityArticles && ( art.data[0].INACTIV==0 ||art.data[0].INACTIV==1 && art.data[0].quantity>0 ) )|| (showZeroQuantityArticles && art.data[0].quantity>0  && art.data[0].INACTIV==0)) ">
                            <td
                                [ngClass]="[art.data[0].quantity?'name selectedItem':'name', art.invalidPrice?'alert':'']">
                                <div>{{art.articleName}}</div>

                                <div *ngIf="art.data[0].observations" class="p-mt-4">
                                    <div class="p-float-label" [style]="{'width':'100%'}">
                                        <textarea [disabled]="true" style="width:100%;" inputId="obs" [rows]="1"
                                            autoResize="autoResize" pInputTextarea
                                            [(ngModel)]="art.data[0].observations"></textarea>
                                        <label for="obs">Observatii</label>
                                    </div>
                                </div>
                                <span style="color:red;">{{art.data[0].INACTIV==1?"INACTIV":""}} </span>
                            </td>

                            <td [ngClass]="offerData.status!='COMANDA'?'quantityDataInput noPading':'noPading'">
                                <input (blur)="checkCredit()"
                                    *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_LEAD' && offerData.status!='REFUZ_CLIENT' && offerData.status!='TRANSFERAT_MULTIDOM' && statusTaskOffer  != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED' "
                                    #quantityInputs (keyup)="recalculate($event)" class="quantity"
                                    [(ngModel)]="art.data[0].quantity" [disabled]="checkIfInactive(art.data[0])"
                                    type="number" lang="en" step="0.01" (wheel)="preventScrool($event)"
                                    [ngStyle]="{'background-color': checkIfInactive(art.data[0])?'rgb(225 225 225)':'#CCffCC'}">

                                <div style="box-sizing: border-box;text-align: center; font-weight: bold; color: #1d941d; font-size: 15px;"
                                    *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && offerData.status == 'OFERTA'">
                                    {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}
                                </div>

                                <div style="box-sizing: border-box;text-align: center; font-weight: bold; color: #1d941d; font-size: 15px;"
                                    *ngIf="offerData.status=='COMANDA' || offerData.status=='REFUZ_LEAD' || offerData.status=='REFUZ_CLIENT' || offerData.status=='TRANSFERAT_MULTIDOM' ">
                                    {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}
                                </div>
                            </td>
                            <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                {{art.data[0].UM}}
                            </td>
                            <td class="price">

                                <input
                                    *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && offerData.status!='REFUZ_CLIENT'"
                                    [disabled]="statusTaskOffer == 'FINANCIAL_VALIDATION'"
                                    [ngClass]="art.invalidPrice?'alert':''" style="width: 100%; "
                                    (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice" type="number"
                                    lang="en" step="0.01" (wheel)="preventScrool($event)">

                                <div style="box-sizing: border-box;text-align: center; font-weight: bold;"
                                    *ngIf="offerData.status=='COMANDA' || offerData.status=='REFUZ_LEAD' || offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_CLIENT'">
                                    {{art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):""}}
                                </div>
                            </td>

                            <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
                            </td>

                            <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                            <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                            <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                            <td>
                                <app-article-observation-modal [(article)]="art.data[0]">
                                </app-article-observation-modal>
                            </td>

                        </ng-container>
                        <ng-container *ngIf="art.data[0].D1 && !art.hideArticle">
                            <td *ngIf="art.data[0].PROMOTIE!=1"
                                [ngClass]="[art.totalQuantity?'name selectedItem':'name',art.invalidPrice?'alert':'']">
                                {{art.articleName}}
                            </td>
                            <td *ngIf="art.data[0].PROMOTIE==1"
                                [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                                {{art.articleName}}
                            </td>

                            <ng-container *ngIf="!art.data[0].showObservations">
                                <td style="    text-align: center;color:#333;font-weight: bold;">
                                    {{round2Decimals(getTotalQuantity(art))}}
                                </td>
                                <td class="unitMeasure">{{art.data[0].UM}}</td>

                                <td class="price">
                                    <input [disabled]="statusTaskOffer == 'FINANCIAL_VALIDATION'"
                                        *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && offerData.status!='REFUZ_CLIENT'"
                                        [ngClass]="art.invalidPrice?'alert':''"
                                        (keyup)="propagateQuantity(art.data);recalculate($event)"
                                        [(ngModel)]="art.data[0].agentPrice" style="width: 100%;" type="number"
                                        lang="en" step="0.01" (wheel)="preventScrool($event)">

                                    <div style="box-sizing: border-box;text-align: center; font-weight: bold;"
                                        *ngIf="offerData.status=='COMANDA' || offerData.status=='REFUZ_LEAD' || offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_CLIENT'">
                                        {{art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):""}}
                                    </div>
                                </td>
                                <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                    {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                                </td>
                            </ng-container>

                            <td *ngIf="art.data[0].showObservations" colspan="5">
                                <input [(ngModel)]="art.data[0].observations" placeholder="Observatii"
                                    style="width: 100%;" lang="en" step="0.01" (wheel)="preventScrool($event)">
                            </td>
                            <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
                            <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
                            <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
                            <td></td>
                        </ng-container>
                    </tr>

                </table>
            </mat-card>
        </span>
    </div>
    <div>
        <span *ngFor="let item of offerData.categories; let i = index">
            <mat-card style="margin: 5px 5px 5px 13px; width:380px" *ngIf="hasMultipleArts(item) && showCategory(item)">
                <div class="categoryTitle" style="text-align: center">{{item.category}}</div>


                <ng-container *ngFor="let art of item.articles;">
                    <ng-container *ngIf="art.data[0].D1">


                        <mat-accordion *ngIf="!art.hideArticle">
                            <mat-expansion-panel (opened)="art.open = true" (closed)="art.open = false"
                                style="margin:5px;">

                                <mat-expansion-panel-header style="padding:0 15px;" [collapsedHeight]="'55px'"
                                    [expandedHeight]="'55px'">
                                    <mat-panel-title>
                                        <div
                                            [ngClass]="checkIfInactive(art.data[0])?'articleTitleInactive':'articleTitle'">
                                            {{art.articleName}}
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>



                                <table id="customers" style="width:100%;">
                                    <tr>
                                        <th>
                                            Tip
                                        </th>
                                        <th *ngIf="!art.data[0].D2" class="unitMeasure center fs12 singleDimension"
                                            style="width: 15%;">
                                            Lung
                                        </th>

                                        <th *ngIf="art.data[0].D2" class="unitMeasure center fs12" style="width: 15%">
                                            Lung</th>

                                        <th *ngIf="art.data[0].D2" class="unitMeasure center fs12" style="width: 10%">
                                            Lat</th>


                                        <th class="detailQuantity center fs12" style="width: 5%">{{art.data[0].UM1}}
                                        </th>
                                        <th class="center fs12" style="width: 5%"> {{art.data[0].UM}}</th>
                                        <th class="center fs12" style="width: 5%"> OBS</th>
                                    </tr>
                                    <ng-container *ngFor="let sub of art.data;let ii = index">


                                        <tr>

                                            <ng-container
                                                *ngIf="(!showZeroQuantityArticles)|| (showZeroQuantityArticles && sub.quantity>0)">
                                                <td>
                                                    {{sub.extensionName}}
                                                </td>
                                                <td>{{round2Decimals(sub.D1)}}</td>


                                                <td *ngIf=" sub.D2">{{sub.D2>0?round2Decimals(sub.D2):''}}</td>
                                                <td
                                                    [ngClass]="offerData.status!='COMANDA'?'quantityDataInput noPading':'noPading'">
                                                    <input [disabled]="checkIfInactive(art.data[0])"
                                                        [ngStyle]="{'background-color': checkIfInactive(art.data[0])?'rgb(225 225 225)':'#CCffCC', width:'100%'}"
                                                        *ngIf="offerData.status!='COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED'"
                                                        (keyup)="recalculate($event)" class="detailQuantity"
                                                        [(ngModel)]="sub.quantity" type="number" lang="en" step="0.01"
                                                        (wheel)="preventScrool($event)">
                                                    <div *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && offerData.status == 'OFERTA'"
                                                        style="box-sizing: border-box;text-align: center; font-weight: bold;color: #1d941d;font-size:
                          14px;">
                                                        {{sub.quantity?round2Decimals(sub.quantity):""}}
                                                    </div>
                                                    <div style="box-sizing: border-box;text-align: center; font-weight: bold;color: #1d941d;font-size: 14px;"
                                                        *ngIf="offerData.status=='COMANDA'">
                                                        {{sub.quantity?round2Decimals(sub.quantity):""}}
                                                    </div>

                                                </td>
                                                <td>{{round2Decimals(getArticleTotalQuantity(sub))}}
                                                </td>
                                                <td>
                                                    <app-article-observation-modal [(article)]="art.data[ii]">
                                                    </app-article-observation-modal>
                                                </td>

                                            </ng-container>
                                        </tr>
                                        <tr *ngIf="sub.observations">
                                            <td colspan="4">
                                                <textarea *ngIf="sub.observations" [disabled]="true" style="width:100%;"
                                                    inputId="obs" [rows]="1" autoResize="autoResize" pInputTextarea
                                                    [(ngModel)]="sub.observations"></textarea>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </ng-container>
                </ng-container>

            </mat-card>
        </span>
    </div>


    <div class="noprint">
        <div style="margin:5px;width:275px;overflow: auto;">
            <mat-card style=" background: #333;font-family: CarettaOffice;">

                <div style="color:#fff;text-align: center;font-size:22px;" *ngIf="offerData">
                    {{offerData.contact.name}}
                </div>
                <div style="color:#fff;text-align: center;font-size:18px; margin-top:5px" *ngIf="offerData">
                    {{offerData.contact.phone}}
                </div>
                <div style="color:#fff;font-size:16px; margin-top:10px">

                    <!-- <div style="color:#e0004d;text-align: center;font-size:18px; margin-top:5px;font-family: 'CarettaOffice';"
                        *ngIf="offerData">
                        <del>Total: {{totalWithOutDiscount}} lei</del>
                    </div>
                    <div
                        style="color:white;text-align: center;font-size:16px; margin-top:5px;font-family: 'CarettaOffice';">
                        Discount valoric: {{discountValue}} lei</div>
                    <div
                        style="color:white;text-align: center;font-size:16px; margin-top:5px;font-family: 'CarettaOffice';">
                        Discount procentual: {{discountPercent}} %</div> -->

                    <div style="color:#44C4A1;text-align: center;font-size:16px; margin-top:5px;font-family: 'CarettaOffice';"
                        *ngIf=" offerData.discountPromo">
                        Discount PROMO {{round2Decimals(offerData.discountPromo)}} lei
                    </div>




                    <div style="color:white;text-align: center;font-size:22px; margin-top:5px;font-family: 'CarettaOffice';"
                        *ngIf="offerData">
                        Total: {{round2Decimals(totalPrice-discountPromo)}} lei
                    </div>

                </div>

                <div style="display:flex">
                    <!-- <div (click)="showAdaos=!showAdaos" class="actionSvg delete" style="margin:auto"
                        [inlineSVG]="'/assets/images/profit.svg'"></div> -->
                    <div style="color:white;margin: auto;"> {{round2Decimals(totalOfferWeight)}} KG</div>
                </div>

                <!-- <div *ngIf="showAdaos" style="color:#27A2DB;text-align: center;font-size:18px; margin-top:5px">
                                    {{adaosValue}} lei </div> -->


            </mat-card>

            <mat-card>
                <!-- <p-divider *ngIf="promoRoofError">
                    <div class=" p-d-inline-flex p-ai-center" style="color: red;">
                        <i class="pi pi-exclamation-triangle p-mr-2"></i>
                        <span>Procentul accesorii nu permite tabla promo</span>
                    </div>
                </p-divider> -->

                <div *ngIf="offerData.status=='COMANDA'">
                    <div style="font-size: 28px;font-weight: bold;text-align: center;color: #D7A853;">
                        COMANDA {{offerData.data && !offerData.returned?offerData.data.commandSeries:'RETUR '}}
                        {{offerData.data?offerData.data.commandNumber:''}}</div>
                    <button *ngIf="canCancelCommand" (click)="cancelCommandFlag=true" pButton pRipple type="button"
                        icon="pi pi-times" label="Anulează comanda"
                        class="p-button-success p-mt-2 customButton"></button>

                </div>

                <div *ngIf="offerData.status=='REFUZ_CLIENT'">
                    <div style="font-size: 28px;font-weight: bold;text-align: center;color: #D7A853;">
                        REFUZ CLIENT</div>
                </div>

                <div *ngIf="offerData.status=='REFUZ_LEAD'">
                    <div style="font-size: 28px;font-weight: bold;text-align: center;color: #D7A853;">
                        OFERTA REFUZATA</div>
                </div>

                <div *ngIf="offerData.status=='NU_ACUM'">
                    <div style="font-size: 28px;font-weight: bold;text-align: center;color: #D7A853;">
                        NU ACUM</div>
                </div>
                <div *ngIf="offerData.status=='TRANSFERAT_MULTIDOM'">
                    <div style="font-size: 28px;font-weight: bold;text-align: center;color: #D7A853;">
                        TRANSFERAT MULTIDOM</div>
                </div>
                <div *ngIf="offerData.status=='SECONDARY_OFFER'">
                    <div style="font-size: 28px;font-weight: bold;text-align: center;color: #D7A853;">
                        OFERTA SECUNDARA</div>
                </div>

                <!-- <mat-form-field
          *ngIf="canChooseClientType && offerData.status!='COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
          class="noLabel" style="width:100%">
          <mat-select [(ngModel)]="offerData.invoiceType" required placeholder="Tip Comanda"
            (selectionChange)="commandTypeChanged($event)">
            <mat-option *ngFor="let invoice of invoiceTypes" [value]="invoice">
              {{invoice}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
                <div *ngIf="canChooseClientType && offerData.status!='COMANDA' &&  offerData.status!='REFUZ_LEAD' && offerData.status!='REFUZ_CLIENT' && offerData.status!='TRANSFERAT_MULTIDOM' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
                    class="p-mt-4">
                    <span class="p-float-label">
                        <p-dropdown (onChange)="commandTypeChanged($event)" [autoDisplayFirst]="false"
                            inputId="invoiceType" appendTo="body" [options]="opType" name="value" [showClear]="true"
                            [style]="{'width':'100%'}" [(ngModel)]="offerData.invoiceType">
                        </p-dropdown>
                        <label for="invoiceType">Tip Comanda</label>
                    </span>
                </div>






                <!-- aaaaaaaaaa -->
                <mat-form-field *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer'" class="noLabel"
                    style="width: 100%;">
                    <mat-label>Nume Dealer</mat-label>
                    <div style="display:flex">
                        <mat-select [compareWith]="compareDealers" style="margin-top:5px; margin-left:5px"
                            [disabled]="offerData.isDealer || offerData.status=='COMANDA' || statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED' ?true:false"
                            [(ngModel)]="offerData.dealer" (selectionChange)="loadDealerAgents($event)">
                            <mat-option *ngFor="let dealer of dealers" [value]="dealer">
                                {{dealer.partnerName}}
                            </mat-option>
                        </mat-select>
                    </div>

                </mat-form-field>

                <p-divider *ngIf=" offerData.status=='COMANDA' && offerData.dealer &&offerData.dealer.partnerName">
                    <div class="p-d-inline-flex p-ai-center grayDivider">
                        <i class="pi pi-user p-mr-2"></i>
                        <span>Dealer</span>
                    </div>
                </p-divider>

                <div *ngIf=" offerData.status=='COMANDA' && offerData.dealer &&offerData.dealer.partnerName"
                    style="color:#333;padding: 5px 0;text-align: center;font-family: 'CarettaOffice';">
                    {{offerData.dealer.partnerName}}</div>

                <p-divider *ngIf=" offerData.status=='COMANDA' && offerData.dealerAgent &&offerData.dealerAgent.name">
                    <div class="p-d-inline-flex p-ai-center grayDivider">
                        <i class="pi pi-user p-mr-2"></i>
                        <span>Agent Dealer</span>
                    </div>
                </p-divider>

                <div *ngIf=" offerData.status=='COMANDA' && offerData.dealerAgent &&offerData.dealerAgent.name"
                    style="color:#333;padding: 5px 0;text-align: center;font-family: 'CarettaOffice';">
                    {{offerData.dealerAgent.name}} {{offerData.dealerAgent.surname}}</div>

                <!-- <div *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer'" class="p-mt-4">
          <span class="p-float-label">
            <p-dropdown [autoDisplayFirst]="false" inputId="dealer" (onChange)="loadDealerAgents($event)"
              [options]="dealers" [(ngModel)]="offerData.dealer" appendTo="body" optionLabel="partnerName"
              [filter]="true" filterBy="partnerName">
              <ng-template pTemplate="selectedItem">
                <div class="country-item country-item-value" *ngIf="offerData.dealer">
                  <div>{{offerData.dealer.partnerName}}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{country.partnerName}}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="dealer">Dealer</label>
          </span>
        </div> -->



                <p-divider
                    *ngIf=" offerData.status!='COMANDA' && offerData.invoiceType=='Dealer' && offerData.dealer  &&isDealerTrusted=='error'">
                    <div class=" p-d-inline-flex p-ai-center" style="color: red;">
                        <i class="pi pi-exclamation-triangle p-mr-2"></i>
                        <span>Limită credit depășită</span>
                    </div>
                </p-divider>

                <p-divider *ngIf=" statusTaskOffer== 'DENIED'">
                    <div class=" p-d-inline-flex p-ai-center" style="color: red;">
                        <i class="pi pi-exclamation-triangle p-mr-2"></i>
                        <span>Ofertă respinsă</span>
                    </div>
                </p-divider>

                <p-divider *ngIf=" statusTaskOffer== 'FINANCIAL_VALIDATION'">
                    <div class=" p-d-inline-flex p-ai-center" style="color: #0097d2;">
                        <i class="pi pi-spinner p-mr-2"></i>
                        <span>Ofertă în verificare</span>
                    </div>
                </p-divider>

                <p-divider *ngIf=" statusTaskOffer== 'APPROVED'">
                    <div class=" p-d-inline-flex p-ai-center" style="color: green;">
                        <i class="pi pi-check p-mr-2"></i>
                        <span>Ofertă Acceptată</span>
                    </div>
                </p-divider>



                <!-- <p-dropdown *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer' && selectedDealer"
          (onChange)="onSelectedDealerAgent($event)" [options]="dealerAgents" [(ngModel)]="offerData.dealerAgent"
          appendTo="body" optionLabel="name" [filter]="true" filterBy="name" placeholder="Agent Dealer">
          <ng-template pTemplate="selectedItem">
            <div class="dealerAgent-item dealerAgent-item-value" *ngIf="offerData.dealerAgent">
              <div>{{offerData.dealerAgent.name}} {{offerData.dealerAgent.surname}}</div>
            </div>
          </ng-template>
          <ng-template let-dealerAgent pTemplate="item">
            <div class="dealerAgent-item">
              <div>{{dealerAgent.name}} {{dealerAgent.surname}}</div>
            </div>
          </ng-template>
        </p-dropdown> -->

                <mat-form-field *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer' && selectedDealer"
                    class="noLabel" style="width: 100%;">
                    <mat-label>Agent Dealer</mat-label>
                    <mat-select [compareWith]="compareDealerAgents"
                        [disabled]="offerData.isDealer || offerData.status=='COMANDA' || statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED' ?true:false"
                        [(ngModel)]="offerData.dealerAgent" (selectionChange)="onSelectedDealerAgent($event)">
                        <mat-option *ngFor="let agent of dealerAgents" [value]="agent">
                            {{agent.name}} {{agent.surname}} ({{agent.headquarter}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- aaa -->


                <div *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_CLIENT' && offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
                    class=" p-mt-2 p-mb-1">
                    <button (click)="showClientsModal=true" pButton pRipple type="button" label="Clienți"
                        class="p-button-success customButton"></button>
                </div>







                <!-- <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-user" style="line-height: 1.25;"></i></span>
          <input disabled="true" type="text" pInputText [placeholder]="offerData.client.name">

        </div>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-map-marker" style="line-height: 1.25;"></i></span>
          <input disabled="true" type="text" pInputText
            [placeholder]="offerData.client.address.address+' '+offerData.client.address.city+' '+offerData.client.address.conunty">

        </div>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-phone" style="line-height: 1.25;"></i></span>
          <input disabled="true" type="text" pInputText [placeholder]="offerData.client.phone">

        </div> -->


                <p-divider *ngIf=" offerData.status=='COMANDA' && offerData.client &&offerData.client.name">
                    <div class="p-d-inline-flex p-ai-center grayDivider">
                        <i class="pi pi-user p-mr-2"></i>
                        <span>Client</span>
                    </div>
                </p-divider>
                <div style="color: #333; font-size: 20px;text-align: center; padding: 10px 0;"
                    *ngIf="offerData.client &&offerData.client.name ">
                    <b>{{offerData.client.name}}</b>
                </div>






                <div *ngIf="offerData.client &&offerData.client.phone" class="p-mt-2">
                    <p-divider>
                        <div class="p-d-inline-flex p-ai-center grayDivider">
                            <i class="pi pi-phone p-mr-2"></i>
                            <span>Telefon</span>
                        </div>
                    </p-divider>
                    <div
                        style="color:#333; font-size: 17px;text-align: center; padding: 3px 0;font-family: 'CarettaOffice';">
                        {{offerData.client.phone}}</div>
                </div>




                <p-divider *ngIf="offerData.client &&offerData.client.name">
                    <div class="p-d-inline-flex p-ai-center grayDivider">
                        <i class="pi pi-map-marker p-mr-2"></i>
                        <span>Adresa</span>
                    </div>
                </p-divider>

                <div *ngIf="offerData.client &&offerData.client.name"
                    style="color:#333;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
                    {{offerData?.client?.address?.address}} {{offerData?.client?.address?.city}}
                    {{offerData?.client?.address?.conunty}}</div>



                <button
                    *ngIf="offerData.status!='COMANDA' && showButtonAlternativeAddress && offerData.client &&offerData.client.name "
                    (click)="openNewAddressAlternative()" pButton pRipple type="button" label="Livrare la alta adresa"
                    class="p-button-success p-mt-1 p-mb-1 customButton"></button>



                <div *ngIf="!showButtonAlternativeAddress">

                    <p-divider *ngIf="offerData.client &&offerData.client.name">
                        <div class="p-d-inline-flex p-ai-center grayDivider">
                            <i class="pi pi-id-card p-mr-2"></i>
                            <span>Livrare</span>
                            <i *ngIf="offerData.status!='COMANDA'" (click)="deleteAlternativeAddress()"
                                class="pi pi-times p-ml-2"></i>
                        </div>
                    </p-divider>
                    <!-- <div>


            <i class="pi pi-id-card" style="font-size: 24px; color: #6c757d;"></i>
           
          </div> -->
                    <div style="color:#333;padding: 3px 0;text-align: center;">
                        {{offerData.deliverAddress}}
                    </div>
                    <!-- <i (click)="deleteAlternativeAddress()" *ngIf="offerData.status!='COMANDA'" class="pi pi-id-card"
            style="font-size: 24px; color: #6c757d;"></i> -->
                    <!-- <div *ngIf="offerData.status!='COMANDA'" (click)="deleteAlternativeAddress()"
            class="customDeleteDeliveryAdressAlternative" [inlineSVG]="'/assets/images/close.svg'"></div> -->

                </div>

                <p-divider>

                </p-divider>

                <div class=" p-mt-2">
                    <div class="p-field-checkbox">
                        <p-checkbox
                            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT' || offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                            [(ngModel)]="offerData.pickedByClient" binary="true" inputId="pickedByClient"></p-checkbox>
                        <label for="pickedByClient"> Ridică Clientul</label>
                    </div>
                </div>

                <div class="p-field-checkbox">
                    <p-checkbox [(ngModel)]="offerData.returned"
                        [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                        binary="true" inputId="returned"></p-checkbox>
                    <label for="returned"> Comanda retur</label>
                </div>

                <div class="p-field-checkbox">
                    <p-checkbox [(ngModel)]="offerData.custody"
                        [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                        (ngModelChange)="setAdvancePaymentValue($event)" binary="true" inputId="custody"></p-checkbox>
                    <label for="custody"> Custodie</label>
                </div>

                <div>
                    <p-fileUpload styleClass="uploadBtn" #fileUpload #uploadFile showUploadButton="true" mode="basic"
                        accept="image/*" maxFileSize="10000000" [auto]="true" chooseLabel="Atașează schița"
                        (onSelect)="onBasicUpload($event)">
                    </p-fileUpload>
                </div>

                <div *ngFor="let file of offerData.files">
                    <!-- <div (click)="image=file;showImage=true"
            style="cursor: pointer;background: #007ad9;color: white;padding: 5px;text-align: center; margin-bottom: 3px;"
            *ngIf="file.name.includes('.jpg')||file.name.includes('.jpeg')||file.name.includes('.gif')||file.name.includes('.png')">
            <a>{{file.name}}</a>
          </div>
          <div>sterge</div> -->

                    <div *ngIf="file.name.includes('.jpg')||file.name.includes('.jpeg')||file.name.includes('.gif')||file.name.includes('.png')"
                        class="p-buttonset p-mt-2">
                        <button (click)="image=file;showImage=true" pButton type="button"
                            style="width: calc(100% - 38px);" [label]="file.name"></button>
                        <button (click)="filterImage(file)" pButton type="button" icon="pi pi-trash"></button>
                    </div>
                </div>

                <!-- <mat-form-field class="noLabel" style="width:100%">
          <input [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.deliverDate" matInput
            [min]="minDate" [matDatepicker]="picker"
            [placeholder]="offerData.returned?'Data estimativa de retur':'Data estimativa de livrare'">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
                <div class=" p-mt-4">
                    <span class="p-float-label">
                        <p-calendar [showIcon]="true" [style]="{'text-align':'left','width':'100%'}" appendTo="body"
                            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                            [firstDayOfWeek]="1" [touchUI]="true" [readonlyInput]="true" dateFormat="dd.mm.yy"
                            inputId="deliverDate" [(ngModel)]="offerData.deliverDate">
                        </p-calendar>
                        <label for="deliverDate">{{offerData.returned?'Data estimativa de retur':'Data estimativa de
                            livrare'}}</label>
                    </span>
                </div>


                <!-- <mat-form-field class="noLabel" style="width:100%">
          <mat-select [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.finishType" required
            placeholder="Finalizare">
            <mat-option *ngFor="let finishType of finishTypes" [value]="finishType">
              {{finishType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="noLabel" style="width:100%">
          <mat-select [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.paymentType" required
            placeholder="Modalitate de plata">
            <mat-option *ngFor="let payment of paymentTypes" [value]="payment">
              {{payment}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

                <div class=" p-mt-4">
                    <span class="p-float-label">
                        <p-dropdown
                            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                            [autoDisplayFirst]="false" inputId="finishTypes" appendTo="body" [options]="finishTypes"
                            name="value" [style]="{'width':'100%'}" [(ngModel)]="offerData.finishType">
                        </p-dropdown>
                        <label for="finishTypes">Finalizare</label>
                    </span>
                </div>

                <div class=" p-mt-4">
                    <span class="p-float-label">
                        <p-dropdown
                            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                            [autoDisplayFirst]="false" inputId="paymentTypes" appendTo="body" [options]="paymentTypes"
                            name="value" [style]="{'width':'100%'}" [(ngModel)]="offerData.paymentType"
                            (onChange)="verifyPayment($event)">
                        </p-dropdown>
                        <label for="paymentTypes">Modalitate de plata</label>
                    </span>
                </div>

                <!-- <mat-checkbox [disabled]="offerData.status=='COMANDA' || this.offerData.custody?true:false"
          [(ngModel)]="offerData.isAdvancePayment"
          *ngIf="(offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0) || offerData.status=='OFERTA' || offerData.status == 'PROSPECTARE'">
          Plata avans</mat-checkbox> -->
                <div *ngIf="offerData && (offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0) || offerData.status=='OFERTA' || offerData.status == 'PROSPECTARE'|| offerData.status=='SECONDARY_OFFER' || offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'"
                    class=" p-mt-2">
                    <div class="p-field-checkbox">
                        <p-checkbox
                            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                            [(ngModel)]="offerData.isAdvancePayment" binary="true" inputId="binary"></p-checkbox>
                        <label for="binary"> Plata avans</label>
                    </div>
                </div>
                <!-- <mat-form-field
          *ngIf="(offerData.isAdvancePayment && offerData.status!='COMANDA') || (offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0)"
          class="noLabel" style="width:100%">
          <input [disabled]="offerData.status=='COMANDA' || this.offerData.custody?true:false" type="number"
            (keyup)="checkAdvancePayment($event)" [(ngModel)]="offerData.advancePaymentValue" matInput
            placeholder="Suma avans">
        </mat-form-field> -->

                <div *ngIf="(offerData.isAdvancePayment && offerData.status!='COMANDA') || (offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0)"
                    class="p-mt-4">
                    <span class="p-float-label">
                        <input
                            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM' "
                            inputId="delivery" [style]="{'text-align':'left','width':'100%'}" type="number" pInputText
                            (keyup)="checkAdvancePayment($event)" [(ngModel)]="offerData.advancePaymentValue" />
                        <label for="delivery">Suma avans</label>
                    </span>
                </div>

                <!-- <mat-form-field class="noLabel" style="width:100%">
          <textarea [disabled]="offerData.status=='COMANDA'?true:false" style="height: 90px;"
            [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.observations" matInput
            placeholder="Observatii"></textarea>
        </mat-form-field> -->

                <div class="p-mt-4">
                    <div class="p-float-label" [style]="{'width':'100%'}">
                        <textarea
                            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
                            inputId="obs" [rows]="2" [cols]="30" autoResize="autoResize" pInputTextarea
                            [(ngModel)]="offerData.observations"></textarea>
                        <label for="obs">Observatii</label>
                    </div>
                </div>
            </mat-card>

            <!-- <mat-card
                *ngIf="offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && offerData.status!='REFUZ_CLIENT'"
                style="margin:10px 10px">
                <div *ngIf="offerData.status!='COMANDA' && offerData.client"
                    style="color: #333;font-family: CarettaOffice, serif;font-size: 25px;text-align:center;">
                    <b>FINALIZARE</b>
                </div>

                <button *ngIf="offerData.status!='COMANDA'" (click)="saveOffer('OFERTA',null)" pButton pRipple
                    type="button" label="SALVEAZĂ OFERTA" class="p-button-success finishAction"></button>

                <button *ngIf="offerData.status!='COMANDA' && offerData.main " (click)="finishCommand()" pButton pRipple
                    type="button" label="PLASEAZĂ COMANDA" class="p-button-success finishAction"></button>


                <button *ngIf="offerData" (click)="generateOfferPdf()" pButton pRipple type="button"
                    icon="pi pi-file-pdf" [label]="offerData.status!='COMANDA'?'PDF Ofertă':'PDF Proformă'"
                    class="p-button-success p-mt-2 customButton"></button>

                <div class=" p-mt-2">
                    <div class="p-field-checkbox">
                        <p-checkbox [(ngModel)]="includePrices" binary="true" inputId="binary"></p-checkbox>
                        <label for="binary"> Include prețuri</label>
                    </div>
                </div>

                <div *ngIf="offerData.status=='COMANDA' && canPrintInternallCommandPdf">

                    <div (click)="generateCommandsInternalDocumentsPdf()"
                        style="display:flex;justify-content: center;align-items:center;padding:10px;color: #fff;font-size: 16px;background:#333">
                        <div class="actionSvg delete" [inlineSVG]="'/assets/images/pdf.svg'"></div>
                        <div>Documente interne</div>
                    </div>

                </div>

                <div *ngIf="offerData && offerData.categories.length>0"
                    style="display:flex;justify-content: center; margin-top:10px">
                    <div (click)="reset()"
                        style="padding:10px;color: #fff;font-size: 16px;background:#9c9c9c;margin-right: 10px;">
                        Copy
                    </div>

                    <div (click)="loadPrices()" style="padding:10px;color: #fff;font-size: 16px;background:#9c9c9c">
                        Paste
                    </div>
                    <div>
                    </div>
                </div>


            </mat-card> -->

            <div class="p-text-bold" style="text-align:center;color:white;font-family: CarettaOffice;font-size: 22px;">V
                {{version}}</div>
            <div (click)="debug=!debug" style="padding: 5px;text-align: center;cursor: pointer;color:transparent;">
                Detalii
            </div>







        </div>
    </div>
</div>

<p-dialog [closable]="false" [positionTop]="20" [draggable]="false" [modal]="true" [(visible)]="showPluvialTypeModal"
    [style]="{'top': '80px'}">
    <p-header>
        <span class="dialog-title">Sistem pluvial!</span>
    </p-header>


    <mat-radio-group [(ngModel)]="pluvialType" aria-label="Tip sistem pluvial" style="text-align: center;">
        <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="MAT">MAT</mat-radio-button>
        <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="BR">BR</mat-radio-button>
    </mat-radio-group>

    <div class="confirmSvg" (click)="checkPluvialType();" [inlineSVG]="'/assets/images/checked.svg'">
    </div>
</p-dialog>



<p-dialog [closable]="false" [style]="{width: '900px',height:'85%'}" [modal]="true" [draggable]="false"
    [(visible)]="showClientsModal">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Clienți!</span>
        <div class="closeSvgRight" (click)="showClientsModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>
    <div style="display:flex">
    </div>

    <table id="customers" style="width:100%">
        <tr style="font-size:14px;">
            <th style="width:230px;padding:0;">
                <!-- <mat-form-field style="width:100%" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientNameSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onNameInputChange()" matInput placeholder="Nume">
        </mat-form-field> -->

                <div class="p-float-label p-input-icon-right" [style]="{'width':'100%'}">
                    <!-- <i *ngIf="nameSearch" class="pi pi-spin pi-spinner"></i> -->
                    <input [debounce]="500" (onDebounce)="onNameInputChange()" class="searchInput" inputId="name"
                        [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                        [(ngModel)]="clientNameSearch" />
                    <label for="name">Nume</label>
                </div>

            </th>
            <th style="width:100px;padding:0;">
                <!-- <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientPhoneSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onPhoneInputChange()" matInput placeholder="Telefon">
        </mat-form-field> -->
                <span class="p-float-label">
                    <input [debounce]="500" (onDebounce)="onPhoneInputChange()" class="searchInput" inputId="phone"
                        [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                        [(ngModel)]="clientPhoneSearch" />
                    <label for="phone">Telefon</label>
                </span>
            </th>
            <th style="width:100px;padding:0;">
                <!-- <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientFiscalCodeSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onFiscalCodeChange()" matInput placeholder="CNP/CUI">
        </mat-form-field> -->
                <span class="p-float-label">
                    <input [debounce]="500" (onDebounce)="onFiscalCodeChange()" class="searchInput" inputId="cui"
                        [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                        [(ngModel)]="clientFiscalCodeSearch" />
                    <label for="cui">CNP/CUI</label>
                </span>
            </th>
            <th style="width:100px;">
                Reg. Com.
            </th>
            <th class="unitMeasure">
                Adresa
            </th>

            <th class="unitMeasure">
                <div (click)="addNewClient=true" matTooltip="Adaugare client nou" class="editSvg"
                    [inlineSVG]="'/assets/images/add.svg'"></div>
            </th>
        </tr>
        <tr *ngFor="let client of clients">
            <td> {{client.DEN_PART}} </td>
            <td> {{client.TELEFON}} </td>
            <td> {{client.CODFISCAL}} </td>
            <td> {{client.REGCOM}} </td>
            <!-- <td> {{client.DEN_LOCALIT}} {{client.JUDET}} </td> -->
            <td *ngIf="client.PERSOANAFIZICA==0">{{client.JUDET}}
                {{client.DEN_LOCALIT&& client.JUDET
                &&client.DEN_LOCALIT.toLowerCase()!=client.JUDET.toLowerCase()?client.DEN_LOCALIT:""}}
                {{client.ADRESA}}</td>
            <td *ngIf="client.PERSOANAFIZICA==1">{{client.JUDET}}
                {{client.DEN_LOCALIT&& client.JUDET
                &&client.JUDET.toLowerCase()!=client.DEN_LOCALIT.toLowerCase()?client.DEN_LOCALIT:""}}
                {{client.ADRESA}}
            </td>
            <td>
                <div style="display:flex">
                    <!-- <div (click)="prefillEditClientData(client)" matTooltip="Adaugare locatie" class="actionSvg"
            [inlineSVG]="'/assets/images/addlocation.svg'"></div> -->
                    <div (click)="prefillEditClientData(client)" matTooltip="Editare locatie" class="actionSvg"
                        [inlineSVG]="'/assets/images/edit.svg'"></div>
                    <div (click)="prefillSelectedClientData(client)" matTooltip="Confirma" style="margin-left: 5px;"
                        class="actionSvg" [inlineSVG]="'/assets/images/confirm.svg'"></div>
                </div>
            </td>
            <!-- <td>{{art.data[0].STOC}}</td> -->
        </tr>

    </table>
</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="addNewClient">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Adaugare client nou!</span>
        <div class="closeSvgRight" (click)="addNewClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>
    <mat-form-field class="noLabel" style="width:100%">
        <mat-select panelClass="clientPanel" [(ngModel)]="newClientData.clientType" required placeholder="Tip Client">
            <mat-option *ngFor="let client of clientTypes" [value]="client">{{client}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <div *ngIf="offerData && newClientData.clientType && !validFiscalCode && newClientData.clientType!='PF'"
        class=" p-mt-1" style="display:flex;">
        <span style="width: calc(100% - 100px);
    margin-right: 10px;" class="p-float-label">
            <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                [(ngModel)]="newClientData.fiscalCode" />
            <label for="street">CUI</label>
        </span>

        <button (click)="validateFiscalCode(newClientData.fiscalCode)" pButton type="button" label="Valideaza"
            class="p-button-outlined"></button>
    </div>



    <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')"
        class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="newClientData.name">
    </mat-form-field>
    <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')"
        class="noLabel" style="width:100%">
        <input [disabled]="validFiscalCode" required type="text" matInput
            [placeholder]="newClientData.clientType=='PF'?'CNP':'CUI'" [(ngModel)]="newClientData.fiscalCode">
    </mat-form-field>



    <mat-form-field *ngIf="offerData&& validFiscalCode && newClientData.clientType!='PF'" class="noLabel"
        style="width:100%">
        <input type="text" matInput placeholder="Reg Com" [(ngModel)]="newClientData.tradeRegister">
    </mat-form-field>
    <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')"
        class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Telefon" [(ngModel)]="newClientData.phone">
    </mat-form-field>

    <div style="color:blue;margin-top:5px;" *ngIf="validatedPartnerData">{{validatedPartnerData.adresa}}</div>

    <mat-form-field *ngIf="counties && ((offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF'))"
        style="width:100%" class="example-full-width noLabel">
        <mat-label>Judet</mat-label>
        <mat-select (selectionChange)='loadLocalities($event)'>
            <mat-option *ngFor="let county of counties.rows" [value]="county">
                {{county.countyName}}
            </mat-option>
        </mat-select>
    </mat-form-field>



    <mat-form-field *ngIf="filteredOptions" style="width:100%" class="example-full-width noLabel">
        <input type="text" [(ngModel)]="newClientData.locality" placeholder="Oraș/Localitate" aria-label="Number"
            matInput [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="newClientData.locality=''">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete (optionSelected)='onSelectedLocality($event.option.value)' [displayWith]="displayLocalityName"
            #matAutocompleteLocality="matAutocomplete">
            <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
                {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village
                    }})</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" style="display:flex">
        <mat-form-field style="width:100%" class="example-full-width noLabel">
            <input matInput placeholder="Strada" [(ngModel)]="newClientData.street">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Nr." [(ngModel)]="newClientData.number">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Scara" [(ngModel)]="newClientData.scara">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Bloc" [(ngModel)]="newClientData.bloc">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Etaj" [(ngModel)]="newClientData.etaj">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Ap." [(ngModel)]="newClientData.apartament">
        </mat-form-field>

    </div>
    <div *ngIf="offerData&& (validFiscalCode || newClientData.clientType=='PF')" (click)="insertNewPartner()"
        class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="editClient">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Editare date client!</span>
        <div class="closeSvgRight" (click)="editClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>

    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="editClientData.name">
    </mat-form-field>
    <mat-form-field *ngIf="offerData && !editClientData.fiscalCode" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
    </mat-form-field>

    <mat-form-field *ngIf="editClientData.isPF" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="CNP" [(ngModel)]="editClientData.fiscalCode">
    </mat-form-field>
    <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Reg Com" [(ngModel)]="editClientData.tradeRegister">
    </mat-form-field>
    <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
    </mat-form-field>
    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Telefon" [(ngModel)]="editClientData.phone">
    </mat-form-field>

    <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
        <mat-label>Judet</mat-label>
        <mat-select [(value)]="selectedCounty" (selectionChange)='loadLocalities($event)'>
            <mat-option *ngFor="let county of counties.rows" [value]="county">
                {{county.countyName}}
            </mat-option>
        </mat-select>
    </mat-form-field>



    <mat-form-field style="width:100%" class="example-full-width noLabel">
        <input type="text" [(ngModel)]="editClientData.locality" placeholder="Oraș/Localitate" aria-label="Number"
            matInput [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="editClientData.locality=''">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #matAutocompleteLocality="matAutocomplete" [displayWith]="displayLocalityName">
            <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
                {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village
                    }})</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div style="display:flex">
        <mat-form-field style="width:100%" class="example-full-width noLabel">
            <input matInput placeholder="Strada" [(ngModel)]="editClientData.street">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Nr." [(ngModel)]="editClientData.number">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Scara" [(ngModel)]="editClientData.scara">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Bloc" [(ngModel)]="editClientData.bloc">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Etaj" [(ngModel)]="editClientData.etaj">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Ap." [(ngModel)]="editClientData.apartament">
        </mat-form-field>

    </div>
    <div (click)="editPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="stockDetail">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Stoc pe gestiuni!</span>
        <div class="closeSvgRight" (click)="stockDetail=null" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>

    <table id="customers" style="width:100%">
        <tr style="font-size:14px;">
            <th class="quantity">Gestiune</th>
            <th class="quantity">Stoc</th>
        </tr>
        <tr *ngFor="let art of stockDetail">
            <ng-container>
                <td>{{art.DEN_GEST}}</td>
                <td>{{art.STOCKQUANTITY}}</td>
            </ng-container>


        </tr>

    </table>

</p-dialog>

<!-- Adresa livrare alternativa -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="showAlternativeDelivery">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Adresa livrare alternativa!</span>
        <div class="closeSvgRight" (click)="closeAlternativeAddress()" [inlineSVG]="'/assets/images/close.svg'">
        </div>
    </p-header>

    <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
        <mat-label>Judet</mat-label>
        <mat-select [(ngModel)]="selectedValueCounty" (selectionChange)='setCountyDelivery($event)'>
            <mat-option *ngFor="let county of counties.rows" [value]="county">
                {{county.countyName}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field *ngIf="filteredOptions" style="width:100%" class="example-full-width noLabel">
        <mat-label>Localitate</mat-label>
        <mat-select (selectionChange)='setLocalityDelivery($event)'>
            <mat-option *ngFor="let locality of localities.rows" [value]="locality">
                {{locality.localityName }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div style="display:flex" *ngIf="filteredOptions">
        <mat-form-field style="width:100%" class="example-full-width noLabel">
            <input [(ngModel)]="deliverStreetAlternative" matInput placeholder="Strada">
        </mat-form-field>


    </div>
    <div (click)="alternativeDeliveryAdress()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

</p-dialog>


<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="cancelCommandFlag">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Anulare comanda
            {{offerData&&offerData.data?offerData.data.commandNumber:''}}</span>
        <div class="closeSvgRight" (click)="cancelCommandFlag=false" [inlineSVG]="'/assets/images/close.svg'">
        </div>
    </p-header>
    <div>Doriti anularea comenzii?</div>
    <mat-form-field class="noLabel" style="width:100%">
        <textarea style="height: 90px;" [(ngModel)]="cancelObservations" matInput
            placeholder="Motiv anulare"></textarea>
    </mat-form-field>
    <div (click)="cancelCommand()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

</p-dialog>


<p-dialog [draggable]="false" showEffect="fade" [closable]="false" [(visible)]="showImage">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Schita</span>
        <div class="closeSvgRight" (click)="showImage=null" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>

    <img *ngIf="image" style="max-width: 800px; margin:10px;"
        [src]="'https://api.e-node.ro/drive/download?user='+image.user+'&filename='+image.name">
</p-dialog>


<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="large" color="#7fc3d6" type="ball-circus"></ngx-spinner>