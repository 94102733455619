<div class="closeDialog">
  <mat-icon (click)="cancel()">highlight_off</mat-icon>
</div>

<div mat-dialog-content>
  <h3 uiSref="app.home" (click)="cancel()" class="about-title" uiSrefActive="active">
      {{ 'menu.production' | translate }} - {{ 'menu.production-upload' | translate }}  
  </h3>
  <br>
  <input type="file" accept=".xlsx, .xls, .csv" (change)="handleFileInput($event.target.files)">
  <button mat-raised-button color="primary" (click)="uploadFile()" class="darkBlue">{{ 'actions.save' | translate }}</button>
</div> 