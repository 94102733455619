import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appNumberMode]'
})
export class NumberModeDirective implements AfterViewInit {

  constructor(private el: ElementRef) {

  }

  ngAfterViewInit(): void {
    this.el.nativeElement.firstElementChild.firstElementChild.setAttribute("inputmode", "decimal");
    this.el.nativeElement.firstElementChild.firstElementChild.setAttribute("pattern", "^[0-9]+(\,)?[0-9]+$");

  }
}
