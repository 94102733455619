import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifyService } from '../helpers/notify.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';

import {
  enable as enableDarkMode,
  disable as disableDarkMode,
} from 'darkreader';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {


  appVersion = environment.version;
  constructor(
    @Inject('LOCALSTORAGE') private store: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notify: NotifyService,
    private router: Router,
    public dialogRef: MatDialogRef<AboutComponent>,
    readonly swPush: SwPush,
    private http: HttpClient) {
  }

  ngOnInit() {
    this.swPush.subscription.subscribe(event => console.log(event));

  }

  cancel(): void {
    this.dialogRef.close();
  }

  darkMode() {
    enableDarkMode({
      brightness: 100,
      contrast: 90,
      sepia: 10,
    });
  }

  accessConstants() {
    var password = prompt("Introduceti Parola", "");

    if (password != null && password != "") {
      if (password == environment.constantPassword) {
        this.router.navigateByUrl("/app/constants");
        this.cancel();
      } else {
        this.notify.error("Parola incorecta!");
      }
    }
  }

  async showNotification() {
    try {
      const subscription = await this.swPush.requestSubscription({
        serverPublicKey: environment.PUBLIC_VAPID_KEY,
      });
      const url = `${environment.serviceUrl}/push/subscribe`;
      let result = await this.http.post(url, subscription).toPromise();
      this.notify.successMessage("Sunteti abonat la notificari!");
      console.log(result)
      // TODO: Send to server.
    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
  }

}
