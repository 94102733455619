<div style="display:flex;justify-content: center; margin-top:10px">
  <div *ngIf="!offerData || offerData.categories.length==0"
    style="display:flex;justify-content: center; margin-top:10px">

    <div *ngIf="offerData?.offerData?.providerName" style="flex-direction: column;display: flex;">
      <div style="width:220px;">
        <div *ngIf="offerData?.offerData?.providerName!='BRAMAC'" [ngClass]="offerData?.offerData?.providerName"
          [inlineSVG]="'/assets/images/'+offerData?.offerData?.providerName+'.svg'">
        </div>
        <div *ngIf="offerData?.offerData?.providerName=='BRAMAC'" [ngClass]="offerData?.offerData?.providerName">
          <img src="./assets/images/BRAMAC.png">
        </div>
      </div>
    </div>

    <div *ngIf="selectedOfferType" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedOfferType.name"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "
        style="font-size: 20px;opacity:1;"></button>
    </div>
    <div *ngIf="selectedMaterial && selectedMaterial!='N'" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedMaterial"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "
        style="font-size: 20px;opacity:1;"></button>
    </div>
    <div *ngIf="selectedColor" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedColor"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "
        style="font-size: 20px;opacity:1;"></button>
    </div>
    <div *ngIf="selectedThickness" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedThickness"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "
        style="font-size: 20px;opacity:1;"></button>
    </div>

    <div *ngIf="showProviders" style="flex-direction: column;display: flex;">
      <!-- <div style="width:220px;">
        <div class="caretta" [inlineSVG]="'/assets/images/caretta.svg'">
        </div>

      </div> -->
      <div style="width:220px;" *ngFor="let provider of providers">
        <div *ngIf="provider.DEN_CLASEA!='BRAMAC'" [ngClass]="provider.DEN_CLASEA"
          (click)="getCategoriesFromProvider(provider)" [inlineSVG]="'/assets/images/'+provider.DEN_CLASEA+'.svg'">
        </div>

        <div *ngIf="provider.DEN_CLASEA=='BRAMAC'" [ngClass]="provider.DEN_CLASEA"
          (click)="getCategoriesFromProvider(provider)">
          <img src="./assets/images/BRAMAC.png">
        </div>

      </div>
    </div>



    <div *ngIf="!selectedOfferType && !showProviders" style="flex-direction: column;display: flex;">
      <button (click)="getMaterial(offerType)" *ngFor="let offerType of offersType" pButton pRipple type="button"
        style="margin-left:10px" [label]="offerType.name"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "></button>

    </div>
    <div *ngIf="!selectedMaterial" style="flex-direction: column;display: flex;">
      <button (click)="getColors(material)" *ngFor="let material of materials" pButton pRipple type="button"
        style="margin-left:10px" [label]="material.name"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "></button>
    </div>
    <div *ngIf="selectedMaterial && !selectedColor" style="width: 270px;">
      <button (click)="getThickness(color)" *ngFor="let color of colors" pButton pRipple type="button"
        [label]="color.name" style="margin-left:10px"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "></button>
    </div>

    <div *ngIf="!selectedThickness">
      <div (click)="selectThickness(item)"
        [ngClass]="'p-button-success p-mb-2 typeButton ' + offerData?.offerData?.providerName "
        *ngFor="let item of thickness">{{item}}</div>
    </div>
  </div>

</div>


<div style="display:flex;" *ngIf="offerData && offerData.categories.length>0">

  <!-- <div style="margin: 0 0 0 auto">
    <div *ngIf="offerData.status!='COMANDA'" (click)="loadAgentPrice('P1')" class="categoryPriceButton">
      P1
    </div>
    <div *ngIf="offerData.status!='COMANDA'" (click)="loadAgentPrice('P2')" class="categoryPriceButton">
      P2
    </div>
    <div *ngIf="offerData.status!='COMANDA'" (click)="loadAgentPrice('P3')" class="categoryPriceButton">
      P3
    </div>
  </div> -->
  <div style="margin: 0 0 0 auto">
    <span *ngFor="let item of offerData.categories">
      <mat-card style="margin:5px; width:650px" *ngIf="showCategory(item)">

        <div [ngClass]="'categoryTitle' + offerData?.offerData?.providerName " style="text-align: center">
          {{item.category}}
          {{item.category=="SISTEM PLUVIAL" ?
          offerData.offerData && offerData.offerData.pluvialType?(offerData.offerData.pluvialType):pluvialType
          :''}}</div>

        <table id="customers" style="width:100%">
          <tr style="font-size:14px;">
            <th class="name">Nume</th>
            <th class="quantity">Cant.</th>
            <th class="unitMeasure">UM</th>
            <th class="price">Pret cu discount</th>
            <th class="listPrice">Pret Lista</th>
            <th class="listPrice">Subtotal</th>
            <th *ngIf="debug" class="listPrice">Prod prop</th>
            <th *ngIf="debug" class="listPrice">Ext</th>
            <th *ngIf="debug" class="listPrice">Gest</th>
          </tr>
          <tr *ngFor="let art of item.articles; let itemI = index">
            <ng-container
              *ngIf="!art.data[0].D1 && !art.hideArticle && 
              ((!showZeroQuantityArticles && ( art.data[0].INACTIV==0 ||art.data[0].INACTIV==1 && art.data[0].quantity>0 ) )|| (showZeroQuantityArticles && art.data[0].quantity>0  && art.data[0].INACTIV==0)) ">
              <td [ngClass]="art.data[0].quantity?'name selectedItem':'name'">
                {{art.articleName}}
                <span style="color:red;">{{art.data[0].INACTIV==1?"INACTIV":""}} </span>
              </td>

              <td [ngClass]="offerData.status!='COMANDA'?'quantityDataInput noPading':'noPading'">
                <input (blur)="checkCredit()"
                  *ngIf="offerData.status!='COMANDA' && statusTaskOffer  != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED' "
                  #quantityInputs (keyup)="recalculate($event)" class="quantity" [(ngModel)]="art.data[0].quantity"
                  [disabled]="checkIfInactive(art.data[0])" type="number" lang="en" step="0.01"
                  (wheel)="preventScrool($event)"
                  [ngStyle]="{'background-color': checkIfInactive(art.data[0])?'rgb(225 225 225)':'#fff'}">

                <div
                  style="box-sizing: border-box;text-align: center; font-weight: bold; color: #1d941d; font-size: 15px;"
                  *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && offerData.status == 'OFERTA'">
                  {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}
                </div>

                <div
                  style="box-sizing: border-box;text-align: center; font-weight: bold; color: #1d941d; font-size: 15px;"
                  *ngIf="offerData.status=='COMANDA'">
                  {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}
                </div>
              </td>
              <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
              </td>
              <td class="price">

                <input *ngIf="offerData.status!='COMANDA'" [disabled]="statusTaskOffer == 'FINANCIAL_VALIDATION'"
                  [ngClass]="art.invalidPrice?'alert':''" style="width: 100%; " (keyup)="recalculate($event)"
                  [(ngModel)]="art.data[0].agentPrice" type="number" lang="en" step="0.01"
                  (wheel)="preventScrool($event)">

                <div style="box-sizing: border-box;text-align: center; font-weight: bold;"
                  *ngIf="offerData.status=='COMANDA'">
                  {{art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):""}}
                </div>
              </td>
              <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">{{round2Decimals(art.data[0].LISTA)}}
              </td>
              <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
              </td>

              <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
              <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
              <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
              <!-- <td>{{art.data[0].STOC}}</td> -->

            </ng-container>

            <ng-container *ngIf="art.data[0].D1 && !art.hideArticle">
              <td *ngIf="art.data[0].PROMOTIE!=1" [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                {{art.articleName}}
              </td>
              <td *ngIf="art.data[0].PROMOTIE==1" [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                {{art.articleName}}
              </td>
              <td style="    text-align: center;color:#003048;font-weight: bold;">
                {{round2Decimals(getTotalQuantity(art))}}
              </td>
              <td class="unitMeasure">{{art.data[0].UM}}</td>

              <td class="price"><input [ngClass]="art.invalidPrice?'alert':''"
                  (keyup)="propagateQuantity(art.data);recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                  style="width: 100%;" type="number" lang="en" step="0.01" (wheel)="preventScrool($event)"></td>
              <td>{{round2Decimals(art.data[0].LISTA)}}</td>
              <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
              </td>


              <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
              <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
              <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>

            </ng-container>
          </tr>

        </table>
      </mat-card>
    </span>
  </div>
  <div>
    <span *ngFor="let item of offerData.categories; let i = index">
      <mat-card style="margin:5px; width:345px" *ngIf="hasMultipleArts(item) && showCategory(item)">
        <div [ngClass]="'categoryTitle' + offerData?.offerData?.providerName " style="text-align: center">
          {{item.category}}</div>


        <ng-container *ngFor="let art of item.articles;">
          <ng-container *ngIf="art.data[0].D1">


            <mat-accordion *ngIf="!art.hideArticle">
              <mat-expansion-panel (opened)="art.open = true" (closed)="art.open = false"
                style="margin:5px;width: 300px !important;">

                <mat-expansion-panel-header style="padding:0 15px;" [collapsedHeight]="'55px'"
                  [expandedHeight]="'55px'">
                  <mat-panel-title>
                    <div [ngClass]="checkIfInactive(art.data[0])?'articleTitleInactive':'articleTitle'">
                      {{art.articleName}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>



                <table id="customers" style="width:100%;">
                  <tr>
                    <th *ngIf="!art.data[0].D2" class="unitMeasure center fs12 singleDimension" style="width: 15%;">
                      Lung
                    </th>

                    <th *ngIf="art.data[0].D2" class="unitMeasure center fs12" style="width: 15%">Lung</th>
                    <th style="width: 15%;" *ngIf="showD1Real(art.articleName)"> Real</th>
                    <th *ngIf="art.data[0].D2" class="unitMeasure center fs12" style="width: 10%">Lat</th>
                    <th
                      *ngIf="selectedOfferType &&(selectedOfferType.key=='CLB' || selectedOfferType.key=='IB'  || selectedOfferType.key=='GO'|| selectedOfferType.key=='BC') && isTigla(art.articleName)"
                      class="unitMeasure center fs12" style="width: 5%"></th>
                    <th class="detailQuantity center fs12" style="width: 5%">{{art.data[0].UM1}}</th>
                    <th class="center fs12" style="width: 5%"> {{art.data[0].UM}}</th>
                  </tr>
                  <tr *ngFor="let sub of art.data;let ii = index">

                    <ng-container *ngIf="(!showZeroQuantityArticles)|| (showZeroQuantityArticles && sub.quantity>0)">
                      <td>{{round2Decimals(sub.D1)}}</td>
                      <td *ngIf="showD1Real(art.articleName)">
                        <input
                          *ngIf="offerData.status != 'COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED'"
                          [disabled]="checkIfInactive(sub)" [(ngModel)]="sub.d1Real" style="width: 100%"
                          (keyup)="checkD1Input($event)" (ngModelChange)="checkValueD1Real($event,sub,ii,art.data)"
                          [ngStyle]="sub.errorD1Real && {'background-color': 'red', 'color':'white'}" />
                        <div
                          *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && offerData.status == 'OFERTA'">
                          {{sub.d1Real? sub.d1Real :""}}
                        </div>
                        <div *ngIf="offerData.status == 'COMANDA'">
                          {{sub.d1Real? sub.d1Real :""}}
                        </div>
                      </td>

                      <td *ngIf="sub.D2">{{sub.D2>0?round2Decimals(sub.D2):''}}</td>
                      <td
                        *ngIf="selectedOfferType && (selectedOfferType.key=='CLB' || selectedOfferType.key=='IB'  || selectedOfferType.key=='GO'|| selectedOfferType.key=='BC') && isTigla(art.articleName)">
                        {{getModule(sub.D1,sub.D2)}}</td>
                      <td [ngClass]="offerData.status!='COMANDA'?'quantityDataInput noPading':'noPading'">
                        <input [disabled]="checkIfInactive(art.data[0])"
                          [ngStyle]="{'background-color': checkIfInactive(art.data[0])?'rgb(225 225 225)':'#fff', width:'100%'}"
                          *ngIf="offerData.status!='COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED'"
                          (keyup)="recalculate($event)" class="detailQuantity" [(ngModel)]="sub.quantity" type="number"
                          lang="en" step="0.01" (wheel)="preventScrool($event)">
                        <div
                          *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && offerData.status == 'OFERTA'"
                          style="box-sizing: border-box;text-align: center; font-weight: bold;color: #1d941d;font-size:
                          14px;">
                          {{sub.quantity?round2Decimals(sub.quantity):""}}
                        </div>
                        <div
                          style="box-sizing: border-box;text-align: center; font-weight: bold;color: #1d941d;font-size: 14px;"
                          *ngIf="offerData.status=='COMANDA'">
                          {{sub.quantity?round2Decimals(sub.quantity):""}}
                        </div>

                      </td>
                      <td>{{round2Decimals(getArticleTotalQuantity(sub))}}
                      </td>

                    </ng-container>
                  </tr>
                </table>
              </mat-expansion-panel>
            </mat-accordion>

          </ng-container>
        </ng-container>

      </mat-card>
    </span>
  </div>


  <div class="noprint" style="width:300px;margin: 0 auto 0 0;">
    <div style="margin:5px;width:275px;overflow: auto;">

      <mat-card [ngClass]="offerData?.offerData?.providerName ">

        <div style="color:#fff;text-align: center;font-size:22px;" *ngIf="offerData">
          {{offerData.contact.name}}
        </div>
        <div style="color:#fff;text-align: center;font-size:18px; margin-top:5px" *ngIf="offerData">
          {{offerData.contact.phone}}
        </div>



        <div *ngIf="offerData?.offerData?.providerName" style="flex-direction: column;display: flex;">
          <div style="width:220px;">
            <div *ngIf="offerData?.offerData?.providerName!='BRAMAC'" [ngClass]="offerData?.offerData?.providerName"
              [inlineSVG]="'/assets/images/'+offerData?.offerData?.providerName+'.svg'">
            </div>
            <div *ngIf="offerData?.offerData?.providerName=='BRAMAC'" [ngClass]="offerData?.offerData?.providerName">
              <img src="./assets/images/BRAMAC.png">
            </div>
          </div>
        </div>

        <div style="color:#fff;font-size:16px; margin-top:10px">
          <div style="text-align: center;font-family: 'MULTIDOM';" *ngIf="selectedOfferType">
            {{selectedOfferType.name}}
          </div>
          <div style="text-align: center;font-family: 'MULTIDOM';" *ngIf="selectedOfferType">
            {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
          </div>
          <div
            *ngIf="selectedOfferType && (selectedOfferType.key=='AA' || selectedOfferType.key=='AP' || selectedOfferType.key=='MM' || selectedOfferType.key=='MP') "
            style="margin-top:10px;">
            <img [src]="'./assets/caretta/'+selectedOfferType.key+'_'+selectedColor+'_'+selectedMaterial+'.png'"
              alt="Imagine indisponibila" width="250" height="175">
          </div>

          <div *ngIf="selectedOfferType && (selectedOfferType.key=='GO') " style="margin-top:10px;text-align: center;">
            <img [src]="'./assets/multidom/'+selectedOfferType.key+'_'+selectedColor+'_'+selectedMaterial+'.jpg'"
              alt="Imagine indisponibila" width="200" height="200">
          </div>

          <div *ngIf="selectedOfferType && (selectedOfferType.key=='OT') " style="margin-top:10px;text-align: center;">
            <img [src]="'./assets/multidom/'+selectedOfferType.key+'_'+selectedColor+'_'+selectedMaterial+'.jpg'"
              alt="Imagine indisponibila" width="200" height="200">
          </div>

          <div
            *ngIf="selectedOfferType && (selectedOfferType.key=='MI' || selectedOfferType.key=='HE' || selectedOfferType.key=='SHA' || selectedOfferType.key=='ELG' || selectedOfferType.key=='ALP' || selectedOfferType.key=='SHN' || selectedOfferType.key=='CLG' || selectedOfferType.key=='DI'  ) "
            style="margin-top:10px;text-align: center;">
            <img style="width:132px;" [src]="'./assets/multidom/'+selectedOfferType.key+'.jpg'"
              alt="Imagine indisponibila">
            <img style="width:133px;" [src]="'./assets/multidom/'+selectedColor+'.jpg'" alt="Imagine indisponibila">
          </div>

          <div *ngIf="selectedOfferType && selectedOfferType.key=='GA' "
            style="display:flex;justify-content: center;margin-top:10px;">
            <img style="margin: 0 10px 0 auto;"
              [src]="'./assets/caretta/'+selectedOfferType.key+'_'+selectedColor+'.png'" alt="..." width="50"
              height="50">
            <img style="margin: 0 auto 0 10px;"
              [src]="'./assets/caretta/'+selectedOfferType.key+'_'+selectedColor+'_2.png'" alt="..." width="50"
              height="50">
          </div>

          <div style="color:#fff;text-align: center;font-size:18px; margin-top:5px;font-family: 'MULTIDOM';"
            *ngIf="offerData">
            <del>Total: {{totalWithOutDiscount}} lei</del>
          </div>
          <div style="color:white;text-align: center;font-size:16px; margin-top:5px;font-family: 'MULTIDOM';"
            *ngIf="offerData">
            Discount valoric: {{discountValue}} lei</div>
          <div style="color:white;text-align: center;font-size:16px; margin-top:5px;font-family: 'MULTIDOM';"
            *ngIf="offerData">
            Discount procentual: {{discountPercent}} %</div>
          <div style="color:white;text-align: center;font-size:22px; margin-top:5px;font-family: 'MULTIDOM';"
            *ngIf="offerData">
            Total: {{totalPrice}} lei
          </div>

        </div>


        <div style="display:flex">
          <div (click)="showAdaos=!showAdaos" class="actionSvg delete" style="margin:auto"
            [inlineSVG]="'/assets/images/profit.svg'"></div>
          <div style="color:white;margin: auto;"> {{round2Decimals(totalOfferWeight)}} KG</div>
          <!-- <div style="margin: auto auto auto 3px;" class="routeInfo kg" [inlineSVG]="'/assets/images/scale.svg'">
          </div> -->
        </div>
        <div *ngIf="showAdaos" style="color:white;text-align: center;font-size:18px; margin-top:5px">
          {{adaosValue}} lei | {{adaosPercent?adaosPercent:0}} %</div>
      </mat-card>
      <mat-card>
        <div *ngIf="offerData.status=='COMANDA'">
          <div style="margin: 0 5px 10px 5px;font-size: 28px;font-weight: bold;text-align: center;color: #3ac47d;">
            {{offerData.data && !offerData.returned?offerData.data.commandSeries:'RETUR '}}
            {{offerData.data?offerData.data.commandNumber:''}}</div>
          <div style="display:flex;justify-content: center;">
            <div *ngIf="canCancelCommand" (click)="cancelCommandFlag=true"
              style="padding:10px;color: #fff;font-size: 16px;background:#e0004d">
              Anuleaza comanda
            </div>
          </div>
          <!-- *ngIf="canCancelCommand" -->
        </div>

        <mat-form-field
          *ngIf="canChooseClientType && offerData.status!='COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
          class="noLabel" style="width:100%">
          <mat-select [(ngModel)]="offerData.invoiceType" required placeholder="Tip Comanda"
            (selectionChange)="commandTypeChanged($event)">
            <mat-option *ngFor="let invoice of invoiceTypes" [value]="invoice">
              {{invoice}}
            </mat-option>
          </mat-select>
        </mat-form-field>




        <!-- aaaaaaaaaa -->
        <mat-form-field *ngIf="offerData.invoiceType=='Dealer'" class="noLabel" style="width: 100%;">
          <mat-label>Nume Dealer</mat-label>
          <div style="display:flex">
            <div *ngIf="isDealerTrusted == 'error' && offerData.dealer" style="width:30px"
              [inlineSVG]="'/assets/images/error.svg'">
            </div>
            <div *ngIf="isDealerTrusted == 'ok' && offerData.dealer" style="width:30px"
              [inlineSVG]="'/assets/images/tick.svg'">
            </div>
            <mat-select [compareWith]="compareDealers" style="margin-top:5px; margin-left:5px"
              [disabled]="offerData.isDealer || offerData.status=='COMANDA' || statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED' ?true:false"
              [(ngModel)]="offerData.dealer" (selectionChange)="loadDealerAgents($event)">
              <mat-option *ngFor="let dealer of dealers" [value]="dealer">
                {{dealer.partnerName}}
              </mat-option>
            </mat-select>
          </div>

        </mat-form-field>
        <mat-form-field *ngIf="offerData.invoiceType=='Dealer' && selectedDealer" class="noLabel" style="width: 100%;">
          <mat-label>Agent Dealer</mat-label>
          <mat-select [compareWith]="compareDealerAgents"
            [disabled]="offerData.isDealer || offerData.status=='COMANDA' || statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED' ?true:false"
            [(ngModel)]="offerData.dealerAgent" (selectionChange)="onSelectedDealerAgent($event)">
            <mat-option *ngFor="let agent of dealerAgents" [value]="agent">
              {{agent.name}} {{agent.surname}} ({{agent.headquarter}})
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-checkbox *ngIf="offerData.invoiceType=='Dealer'" [(ngModel)]="dealerInvoiceOnClient">Factura pe client
        </mat-checkbox> -->
        <!-- aaa -->



        <button
          *ngIf="offerData.status!='COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
          (click)="showClientsModal=true" pButton pRipple type="button" label="Clienti"
          [ngClass]="'p-button-success '+ offerData?.offerData?.providerName"></button>

        <!-- *ngIf="!(offerData.invoiceType=='Dealer')" -->
        <div style="display:flex" *ngIf="statusTaskOffer == 'FINANCIAL_VALIDATION'">
          <div style="width:30px;margin-top:10px" [inlineSVG]="'/assets/images/error.svg'">
          </div>
          <div style="color: #003048; font-size: 20px;text-align: center; padding: 15px 10px 10px 10px;">
            Status: In verificare</div>
        </div>

        <div style="display:flex" *ngIf="statusTaskOffer == 'DENIED'">
          <div style="width:30px;margin-top:10px" [inlineSVG]="'/assets/images/error.svg'">
          </div>
          <div style="color: #003048; font-size: 20px;text-align: center; padding: 15px 10px 10px 10px;">
            Status: Respinsa</div>
        </div>

        <div style="display:flex" *ngIf="statusTaskOffer == 'APPROVED'">
          <div style="width:30px;margin-top:10px" [inlineSVG]="'/assets/images/tick.svg'">
          </div>
          <div style="color: #003048; font-size: 20px;text-align: center; padding: 15px 10px 10px 10px;">
            Status: Acceptata</div>
        </div>
        <div style="color: #003048; font-size: 20px;text-align: center; padding: 15px 10px 10px 10px;"
          *ngIf="offerData.client">
          {{offerData.client.name}}</div>



        <mat-divider></mat-divider>
        <div *ngIf="offerData.client && offerData.client.phone" style="display:flex;padding: 8px 0;">
          <div>
            <div style="width:30px;" [inlineSVG]="'/assets/images/call.svg'"></div>
          </div>
          <div style="color:#003048; font-size: 17px;text-align: center; padding: 5px 7px;">
            {{offerData.client.phone}}</div>
        </div>
        <mat-divider></mat-divider>

        <!-- <div *ngIf="offerData.client &&offerData.client.address " style="display:flex;padding: 8px 0">
          <div>
            <div style="width:30px;" [inlineSVG]="'/assets/images/location.svg'"></div>
          </div>
          <div style="color:#003048; font-size: 17px;padding: 5px 7px;"
            *ngIf="offerData.client && offerData.client.address">
            {{offerData.client.address.address}} {{offerData.client.address.city}}
            {{offerData.client.address.conunty}}</div>
        </div> -->

        <p-divider *ngIf="offerData.client &&offerData.client.name">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-map-marker p-mr-2"></i>
            <span>Adresa</span>
          </div>
        </p-divider>

        <div *ngIf="offerData.client &&offerData.client.name"
          style="color:#003048;padding: 3px 0;text-align: center;font-family: 'MULTIDOM';">
          {{offerData.client.address.address}} {{offerData.client.address.city}}
          {{offerData.client.address.conunty}}</div>


        <mat-divider></mat-divider>

        <button *ngIf="offerData.status!='COMANDA' && showButtonAlternativeAddress"
          (click)="openNewAddressAlternative()" pButton pRipple type="button" label="Livrare la alta adresa"
          [ngClass]="'p-button-success p-mt-1 p-mb-1 '+ offerData?.offerData?.providerName"></button>

        <mat-divider></mat-divider>


        <div style="display:flex;padding: 8px 0; position:relative" *ngIf="!showButtonAlternativeAddress">
          <div>
            <div style="width:30px;" [inlineSVG]="'/assets/images/shipped.svg'"></div>
          </div>
          <div style="color:#003048; font-size: 17px;padding: 5px 7px;">
            {{offerData.deliverAddress}}
          </div>
          <div *ngIf="offerData.status!='COMANDA'" (click)="deleteAlternativeAddress()"
            class="customDeleteDeliveryAdressAlternative" [inlineSVG]="'/assets/images/close.svg'"></div>

        </div>

        <mat-divider></mat-divider>

        <div style="margin:10px;">
          <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.pickedByClient">
            Ridica Clientul
          </mat-checkbox>
        </div>

        <div style="margin:10px;">
          <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.returned">Comanda
            retur</mat-checkbox>
        </div>

        <div style="margin:10px;">
          <mat-checkbox (ngModelChange)="setAdvancePaymentValue($event)"
            [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.custody">
            Custodie
          </mat-checkbox>
        </div>

        <div>
          <p-fileUpload [ngClass]="offerData?.offerData?.providerName+'Upload'" #fileUpload #uploadFile
            showUploadButton="true" mode="basic" accept="image/*" maxFileSize="10000000" [auto]="true"
            chooseLabel="Ataseaza schita" (onSelect)="onBasicUpload($event)">
          </p-fileUpload>
        </div>

        <div *ngFor="let file of offerData.files">
          <div (click)="image=file;showImage=true"
            style="cursor: pointer;background: #007ad9;color: white;padding: 5px;text-align: center; margin-bottom: 3px;"
            *ngIf="file.name.includes('.jpg')||file.name.includes('.jpeg')||file.name.includes('.gif')||file.name.includes('.png')">
            <a>{{file.name}}</a>
          </div>

        </div>

        <mat-form-field class="noLabel" style="width:100%">
          <input [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.deliverDate" matInput
            [min]="minDate" [matDatepicker]="picker"
            [placeholder]="offerData.returned?'Data estimativa de retur':'Data estimativa de livrare'">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>


        <mat-form-field class="noLabel" style="width:100%">
          <mat-select [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.finishType" required
            placeholder="Finalizare">
            <mat-option *ngFor="let finishType of finishTypes" [value]="finishType">
              {{finishType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="noLabel" style="width:100%">
          <mat-select [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.paymentType" required
            placeholder="Modalitate de plata">
            <mat-option *ngFor="let payment of paymentTypes" [value]="payment">
              {{payment}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox [disabled]="offerData.status=='COMANDA' || this.offerData.custody?true:false"
          [(ngModel)]="offerData.isAdvancePayment"
          *ngIf="(offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0) || offerData.status=='OFERTA' || offerData.status == 'PROSPECTARE'">
          Plata avans</mat-checkbox>
        <mat-form-field
          *ngIf="(offerData.isAdvancePayment && offerData.status!='COMANDA') || (offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0)"
          class="noLabel" style="width:100%">
          <input [disabled]="offerData.status=='COMANDA' || this.offerData.custody?true:false" type="number"
            (keyup)="checkAdvancePayment($event)" [(ngModel)]="offerData.advancePaymentValue" matInput
            placeholder="Suma avans">
        </mat-form-field>

        <mat-form-field class="noLabel" style="width:100%">
          <textarea [disabled]="offerData.status=='COMANDA'?true:false" style="height: 90px;"
            [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.observations" matInput
            placeholder="Observatii"></textarea>
        </mat-form-field>

      </mat-card>

      <mat-card style="margin:10px 10px">
        <div *ngIf="offerData.status!='COMANDA' && offerData.client"
          style="color: #003048;font-family: MULTIDOM, serif;font-size: 25px;text-align:center;">
          <b>FINALIZARE</b>
        </div>

        <button *ngIf="offerData.status!='COMANDA'" (click)="saveOffer('OFERTA',null)" pButton pRipple type="button"
          label="SALVEAZA OFERTA"
          [ngClass]="'p-button-success finishAction '+ offerData?.offerData?.providerName"></button>

        <button *ngIf="offerData.status!='COMANDA'" (click)="finishCommand()" pButton pRipple type="button"
          label="PLASEAZA COMANDA"
          [ngClass]="'p-button-success finishAction '+ offerData?.offerData?.providerName"></button>

        <button *ngIf="offerData" (click)="generateOfferPdf()" pButton pRipple type="button" icon="pi pi-file-pdf"
          [label]="offerData.status!='COMANDA'?'PDF Ofertă':'PDF Proformă'"
          class="p-button-success p-mt-2 customButton"></button>
        <mat-checkbox [(ngModel)]="includePrices" style="font-family: 'MULTIDOM';">Include prețuri</mat-checkbox>

        <div *ngIf="offerData.status=='COMANDA' && canPrintInternallCommandPdf">

          <div (click)="generateCommandsInternalDocumentsPdf()"
            style="display:flex;justify-content: center;align-items:center;padding:10px;color: #fff;font-size: 16px;background:#003048">
            <div class="actionSvg delete" [inlineSVG]="'/assets/images/pdf.svg'"></div>
            <div>Documente interne</div>
          </div>

        </div>

        <!-- <div *ngIf="offerData && offerData.categories.length>0"
          style="display:flex;justify-content: center; margin-top:10px">
          <div (click)="reset()"
            style="padding:10px;color: #fff;font-size: 16px;background:#9c9c9c;margin-right: 10px;">
            Copy
          </div>

          <div (click)="loadPrices()" style="padding:10px;color: #fff;font-size: 16px;background:#9c9c9c">
            Paste
          </div>
        </div>

        <div (click)="debug=!debug" style="padding:5px;color: white;font-size: 16px;">
          Detalii
        </div> -->
      </mat-card>



      <div class="p-text-bold" style="text-align:center;color:white;font-family: MULTIDOM;font-size: 22px;">V
        {{version}}</div>





    </div>
  </div>
</div>

<p-dialog [closable]="false" [positionTop]="20" [draggable]="false" [modal]="true" [(visible)]="showPluvialTypeModal"
  [style]="{'top': '80px'}">
  <p-header>
    <span class="dialog-title">Sistem pluvial!</span>
  </p-header>


  <mat-radio-group [(ngModel)]="pluvialType" aria-label="Tip sistem pluvial" style="text-align: center;">
    <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="MAT">MAT</mat-radio-button>
    <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="BR">BR</mat-radio-button>
  </mat-radio-group>

  <div class="confirmSvg" (click)="checkPluvialType();" [inlineSVG]="'/assets/images/checked.svg'">
  </div>
</p-dialog>



<p-dialog [closable]="false" [style]="{width: '900px',height:'85%'}" [modal]="true" [draggable]="false"
  [(visible)]="showClientsModal">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Clienti!</span>
    <div class="closeSvgRight" (click)="showClientsModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>
  <div style="display:flex">
  </div>

  <table id="customers" style="width:100%">
    <tr style="font-size:14px;">
      <th class="name">
        <mat-form-field style="width:100%" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientNameSearch" (onDebounce)="onNameInputChange()" matInput
            placeholder="Nume">
        </mat-form-field>
      </th>
      <th class="quantity">
        <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientPhoneSearch" (onDebounce)="onPhoneInputChange()" matInput
            placeholder="Telefon">
        </mat-form-field>
      </th>
      <th class="unitMeasure">
        <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientFiscalCodeSearch" (onDebounce)="onFiscalCodeChange()" matInput
            placeholder="CNP/CUI">
        </mat-form-field>
      </th>
      <th class="unitMeasure">
        Reg. Com.
      </th>
      <th class="unitMeasure">
        Adresa
      </th>
      <!-- <th class="unitMeasure">
        Localitate
      </th> -->
      <!-- <th class="unitMeasure">
        Judet
      </th>
      <th class="unitMeasure">
        Localitate
      </th> -->
      <th class="unitMeasure">
        <div (click)="addNewClient=true" matTooltip="Adaugare client nou" class="editSvg"
          [inlineSVG]="'/assets/images/add.svg'"></div>
      </th>
    </tr>
    <tr *ngFor="let client of clients">
      <td> {{client.DEN_PART}} </td>
      <td> {{client.TELEFON}} </td>
      <td> {{client.CODFISCAL}} </td>
      <td> {{client.REGCOM}} </td>
      <!-- <td> {{client.DEN_LOCALIT}} {{client.JUDET}} </td> -->
      <td *ngIf="client.PERSOANAFIZICA==0">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.DEN_LOCALIT.toLowerCase()!=client.JUDET.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}</td>
      <td *ngIf="client.PERSOANAFIZICA==1">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.JUDET.toLowerCase()!=client.DEN_LOCALIT.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}
      </td>
      <td>
        <div style="display:flex">
          <!-- <div (click)="prefillEditClientData(client)" matTooltip="Adaugare locatie" class="actionSvg"
            [inlineSVG]="'/assets/images/addlocation.svg'"></div> -->
          <div (click)="prefillEditClientData(client)" matTooltip="Editare locatie" class="actionSvg"
            [inlineSVG]="'/assets/images/edit.svg'"></div>
          <div (click)="prefillSelectedClientData(client)" matTooltip="Confirma" style="margin-left: 5px;"
            class="actionSvg" [inlineSVG]="'/assets/images/confirm.svg'"></div>
        </div>
      </td>
      <!-- <td>{{art.data[0].STOC}}</td> -->
    </tr>

  </table>
</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="addNewClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Adaugare client nou!</span>
    <div class="closeSvgRight" (click)="addNewClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>
  <mat-form-field class="noLabel" style="width:100%">
    <mat-select panelClass="clientPanel" [(ngModel)]="newClientData.clientType" required placeholder="Tip Client">
      <mat-option *ngFor="let client of clientTypes" [value]="client">{{client}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <div *ngIf="offerData && newClientData.clientType && !validFiscalCode && newClientData.clientType!='PF'"
    class=" p-mt-1" style="display:flex;">
    <span style="width: calc(100% - 100px);
    margin-right: 10px;" class="p-float-label">
      <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="newClientData.fiscalCode" />
      <label for="street">CUI</label>
    </span>

    <button (click)="validateFiscalCode(newClientData.fiscalCode)" pButton type="button" label="Valideaza"
      class="p-button-outlined"></button>
  </div>



  <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" class="noLabel"
    style="width:100%">
    <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="newClientData.name">
  </mat-form-field>
  <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" class="noLabel"
    style="width:100%">
    <input [disabled]="validFiscalCode" required type="text" matInput
      [placeholder]="newClientData.clientType=='PF'?'CNP':'CUI'" [(ngModel)]="newClientData.fiscalCode">
  </mat-form-field>



  <mat-form-field *ngIf="offerData&& validFiscalCode && newClientData.clientType!='PF'" class="noLabel"
    style="width:100%">
    <input type="text" matInput placeholder="Reg Com" [(ngModel)]="newClientData.tradeRegister">
  </mat-form-field>
  <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" class="noLabel"
    style="width:100%">
    <input required type="text" matInput placeholder="Telefon" [(ngModel)]="newClientData.phone">
  </mat-form-field>

  <div style="color:blue;margin-top:5px;" *ngIf="validatedPartnerData">{{validatedPartnerData.adresa}}</div>

  <mat-form-field *ngIf="counties && ((offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF'))"
    style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select (selectionChange)='loadLocalities($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-form-field *ngIf="filteredOptions" style="width:100%" class="example-full-width noLabel">
    <input type="text" [(ngModel)]="newClientData.locality" placeholder="Oraș/Localitate" aria-label="Number" matInput
      [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="newClientData.locality=''">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete (optionSelected)='onSelectedLocality($event.option.value)' [displayWith]="displayLocalityName"
      #matAutocompleteLocality="matAutocomplete">
      <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
        {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village }})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" style="display:flex">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input matInput placeholder="Strada" [(ngModel)]="newClientData.street">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Nr." [(ngModel)]="newClientData.number">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Scara" [(ngModel)]="newClientData.scara">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Bloc" [(ngModel)]="newClientData.bloc">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Etaj" [(ngModel)]="newClientData.etaj">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Ap." [(ngModel)]="newClientData.apartament">
    </mat-form-field>

  </div>
  <div *ngIf="offerData&& (validFiscalCode || newClientData.clientType=='PF')" (click)="insertNewPartner()"
    class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="editClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Editare date client!</span>
    <div class="closeSvgRight" (click)="editClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="editClientData.name">
  </mat-form-field>
  <mat-form-field *ngIf="offerData && !editClientData.fiscalCode" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>

  <mat-form-field *ngIf="editClientData.isPF" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="CNP" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>
  <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Reg Com" [(ngModel)]="editClientData.tradeRegister">
  </mat-form-field>
  <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>
  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Telefon" [(ngModel)]="editClientData.phone">
  </mat-form-field>

  <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select [(value)]="selectedCounty" (selectionChange)='loadLocalities($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-form-field style="width:100%" class="example-full-width noLabel">
    <input type="text" [(ngModel)]="editClientData.locality" placeholder="Oraș/Localitate" aria-label="Number" matInput
      [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="editClientData.locality=''">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #matAutocompleteLocality="matAutocomplete" [displayWith]="displayLocalityName">
      <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
        {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village }})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div style="display:flex">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input matInput placeholder="Strada" [(ngModel)]="editClientData.street">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Nr." [(ngModel)]="editClientData.number">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Scara" [(ngModel)]="editClientData.scara">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Bloc" [(ngModel)]="editClientData.bloc">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Etaj" [(ngModel)]="editClientData.etaj">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Ap." [(ngModel)]="editClientData.apartament">
    </mat-form-field>

  </div>
  <div (click)="editPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="stockDetail">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Stoc pe gestiuni!</span>
    <div class="closeSvgRight" (click)="stockDetail=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <table id="customers" style="width:100%">
    <tr style="font-size:14px;">
      <th class="quantity">Gestiune</th>
      <th class="quantity">Stoc</th>
    </tr>
    <tr *ngFor="let art of stockDetail">
      <ng-container>
        <td>{{art.DEN_GEST}}</td>
        <td>{{art.STOCKQUANTITY}}</td>
      </ng-container>


    </tr>

  </table>

</p-dialog>

<!-- Adresa livrare alternativa -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="showAlternativeDelivery">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Adresa livrare alternativa!</span>
    <div class="closeSvgRight" (click)="closeAlternativeAddress()" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select [(ngModel)]="selectedValueCounty" (selectionChange)='setCountyDelivery($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field *ngIf="filteredOptions" style="width:100%" class="example-full-width noLabel">
    <mat-label>Localitate</mat-label>
    <mat-select (selectionChange)='setLocalityDelivery($event)'>
      <mat-option *ngFor="let locality of localities.rows" [value]="locality">
        {{locality.localityName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div style="display:flex" *ngIf="filteredOptions">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input [(ngModel)]="deliverStreetAlternative" matInput placeholder="Strada">
    </mat-form-field>


  </div>
  <div (click)="alternativeDeliveryAdress()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>


<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="cancelCommandFlag">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Anulare comanda
      {{offerData&&offerData.data?offerData.data.commandNumber:''}}</span>
    <div class="closeSvgRight" (click)="cancelCommandFlag=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>
  <div>Doriti anularea comenzii?</div>
  <mat-form-field class="noLabel" style="width:100%">
    <textarea style="height: 90px;" [(ngModel)]="cancelObservations" matInput placeholder="Motiv anulare"></textarea>
  </mat-form-field>
  <div (click)="cancelCommand()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>


<p-dialog [draggable]="false" showEffect="fade" [closable]="false" [(visible)]="showImage">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Schita</span>
    <div class="closeSvgRight" (click)="showImage=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <img *ngIf="image" style="max-width: 800px; margin:10px;"
    [src]="'https://api.e-node.ro/drive/download?user='+image.user+'&filename='+image.name">
</p-dialog>


<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="large" color="#7fc3d6" type="ball-circus"></ngx-spinner>