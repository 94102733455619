import { AboutComponent } from "./about.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModulesModule } from "../helpers/shared-modules.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModulesModule
    ],
    declarations: [AboutComponent],
    providers: []
})
  export class AboutModule { }