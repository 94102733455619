import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


/**
 * File node data with nested structure.
 * Each node has a filename, and a type or a list of children.
 */
export class LinkNode {
  children: LinkNode[];
  filename: string;
  link: any;
}

/** Flat node with expandable and level information */
export class FlatNode {
  constructor(
    public expandable: boolean, public filename: string, public level: number, public link: any) { }
}

/**
 * TreeStructure, it can build a tree structured Json object from string.
 */
@Injectable()
export class TreeStructure {
  dataChange = new BehaviorSubject<LinkNode[]>([]);

  get data(): LinkNode[] { return this.dataChange.value; }

  constructor(@Inject('LOCALSTORAGE') private store: any) {
    this.initialize();
  }

  initialize() {
    // Parse the string to json object.
    const localUser = this.store.get("localuser");
    var dataObject = localUser.menuItems;

    this.addStaticMenuData(dataObject);

    // Build the tree nodes from Json object. The result is a list of `LinkNode` with nested
    //     file node as children.
    const data = this.buildFileTree(dataObject, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  addStaticMenuData(data) {

    let user = this.store.get('localuser');
    for (let menu of user.menu) {
      if (menu == 'ASIST') {
        data["menu.offline|synchronization"] = {
          "menu.local_synchronize|import": "openSynchronizePopup()",
          "menu.online_synchronize|export": "openExportSynchronizePopup()"
        }
      }
      if (menu == 'POS' || menu == 'ASIST') {
        //data["menu.import_monetar|cashier"] = "openMonetarPopup()";

        data["menu.monetar|cashier"] = {
          "menu.import_monetar|cashier": "openMonetarPopup()",
          "menu.generateZ|z": "openZPopup()"
        }
      }
    }
    data["menu.about|about"] = "openAboutPopup()";
    data["menu.logout|logout"] = "/login";
  }


  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `LinkNode`.
   */
  buildFileTree(obj: object, level: number): LinkNode[] {
    return Object.keys(obj).reduce<LinkNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new LinkNode();
      node.filename = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.link = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}