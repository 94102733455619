import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormat'
})
export class PriceFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let price = value.toString().split(".");
    if(price.length > 1) {
      price[price.length - 1] = price[price.length - 1].slice(0, 2);
    }
    return price.join(",");
  }

}