
export enum InvoiceTypeEnum {
    dealer = "Dealer",
    client = "Client"
}

export enum PaymentTypeEnum {
    cash = "cash",
    op = "op",
    casaMarcat = "casaMarcat"
}

export enum FinishTypeEnum {
    factura = "factura",
    aviz = "aviz",
    casaMarcat = "casaMarcat"
}

export enum ClientTypeEnum {
    PF = "PF",
    PJ = "PJ"
}

export enum OfferStatusEnum {
    NEPROCESATA = "NEPROCESATA",
    ACCEPTATA = "ACCEPTATA",
    REFUZATA = "REFUZATA",
    LANSATA = "LANSATA",
    FINALIZATA = "FINALIZATA",
    LIVRATA = "LIVRATA"
}

export class OfferAddress {
    county: string;
    city: string;
    address: string;

    constructor(obj: any) {
        this.county = obj.county;
        this.city = obj.city;
        this.address = obj.address;
    }
}

export class OfferModel {

    static PaymentType: PaymentTypeEnum[] = [PaymentTypeEnum.cash, PaymentTypeEnum.op, PaymentTypeEnum.casaMarcat];
    static InvoiceType: InvoiceTypeEnum[] = [InvoiceTypeEnum.dealer, InvoiceTypeEnum.client];
    static FinishType: FinishTypeEnum[] = [FinishTypeEnum.factura, FinishTypeEnum.aviz, FinishTypeEnum.casaMarcat];
    static ClientType: ClientTypeEnum[] = [ClientTypeEnum.PF, ClientTypeEnum.PJ];

    _id: string;
    invoiceType: InvoiceTypeEnum;
    paymentType: PaymentTypeEnum;
    finishType: FinishTypeEnum;
    clientType: ClientTypeEnum;
    deliveryDate: Date;
    files: any[];
    address: OfferAddress;
    obs: string;
    phone: string;
    cnp: string;
    cui: string;
    status: OfferStatusEnum;
    clientName: string;
    categoryPrice: string;
    totalPrice: string;
    articles: [] | [{
        UM: string;
        ID: string;
        Pret: number;
        Cant: number;
        objectId: string;
    }];

    constructor(obj: any) {
        this._id = obj._id;
        this.invoiceType = obj.invoiceType;
        this.paymentType = obj.paymentType;
        this.finishType = obj.finishType;
        this.clientType = obj.clientType;
        this.files = obj.files ? obj.files : [];
        this.deliveryDate = obj.deliveryDate;
        this.address = obj.address;
        this.obs = obj.obs;
        this.phone = obj.phone ? obj.phone : '';
        this.cnp = obj.cnp;
        this.cui = obj.cui;
        this.clientName = obj.clientName ? obj.clientName : '';
        this.status = obj.status ? obj.status : OfferStatusEnum.NEPROCESATA;
        this.categoryPrice = obj.categoryPrice ? obj.categoryPrice : null;
        this.totalPrice = obj.totalPrice ? obj.totalPrice : 0;
    }
}
