import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  HostListener,
  ViewChild,
} from '@angular/core';
import { OfferService } from '../services/offer.serice';
import { CartDataModel } from '../../../helpers/datatable/data/cart-data.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifyService } from '../../../helpers/notify.service';
import { MatDialog } from '@angular/material/dialog';
import { DecisionModalComponent } from '../../../decision-modal/decision-modal.component';
import { OfferModel } from '../../models/offer.model';
import { environment } from '../../../../environments/environment';
import { DateAdapter } from '@angular/material/core';
import { UntypedFormControl } from '@angular/forms';
import { BilkaService } from '../../../helpers/bilka.helper'

@Component({
  selector: 'app-offer-details-multidom',
  templateUrl: './offer-detail-multidom.component.html',
  styleUrls: ['./offer-detail-multidom.component.scss'],
})
export class OfferDetailsMultidom implements OnInit, OnDestroy {
  providers;
  showProviders = true;
  providerCategories;
  showZeroQuantityArticles = false;
  firstLoad = true;
  scannedItems: CartDataModel[] = [];
  currentOffer: any = { articles: [], offerData: {} };
  colors;
  materials;
  thickness;
  selectedOfferType;
  selectedColor;
  selectedMaterial;
  selectedThickness;

  totalPrice = 0;
  totalMinimumPrice = 0;
  totalWithOutDiscount = 0;
  discountValue = 0;
  discountPercent = 0;
  adaosValue;
  adaosPercent;
  showAdaos = false;
  includePrices = false;
  generateNewOffer = false;

  temporaryArticles = [];
  clientData;
  offerId;
  tempOfferId;
  offersType;
  clientTypes = ['PF', 'PJ'];
  clientId;
  serviceUrl = environment.serviceUrl;

  invoiceTypes = OfferModel.InvoiceType;
  finishTypes = OfferModel.FinishType;
  paymentTypes = OfferModel.PaymentType;
  minDate = new Date();

  selectedClient: any = {};
  selectedDealer: any = {};
  selectedDealerAgent: any = {};
  dealerAgents: any = [];
  //dealerInvoiceOnClient = false;
  deliverStreetAlternative;
  offerData;
  token;
  //select a client
  clients = [];
  dealers: any = [];

  selectedPhone;
  selectedCounty;
  onDestroy$ = new Subject<boolean>();
  pluvialType = 'SL';
  showPluvialTypeModal = false;
  debug = false;

  //temporary counties todo
  selectedValueCounty;
  counties;
  localities;
  myControl = new UntypedFormControl();
  filteredOptions;

  addNewClient = false;
  editClient = false;
  newClientType;
  showClientsModal = false;

  clientNameSearch;
  clientPhoneSearch;
  clientFiscalCodeSearch;

  stockDetail;

  newClientData = {
    clientType: '',
    name: '',
    fiscalCode: '',
    tradeRegister: '',
    phone: '',
    county: '',
    locality: null,
    street: '',
    scara: '',
    number: '',
    bloc: '',
    etaj: '',
    apartament: '',
  };
  editClientData = {
    isPF: false,
    clientId: '',
    agendaId: '',
    name: '',
    fiscalCode: '',
    tradeRegister: '',
    phone: '',
    county: '',
    locality: null,
    street: '',
    scara: '',
    number: '',
    bloc: '',
    etaj: '',
    apartament: ''
  };
  totalOfferWeight = 0;

  canChooseClientType = false;
  canCancelCommand = false;
  canPrintInternallCommandPdf = false;
  showPickedByClient = false;

  showAlternativeDelivery = false;
  showButtonAlternativeAddress = true;
  cancelCommandFlag = false;
  cancelObservations = '';
  username;

  showImage = false;
  image;

  validFiscalCode = false;
  validatedPartnerData;
  statusTaskOffer;
  offerInfo = false;
  isDealerTrusted;
  showSpecialCategory = true;
  inactiveCategory;
  creditValue;
  soldValue;
  isRestrictedArticlePromo;
  version = environment.version;
  @ViewChild('fileUpload') fileUpload: any;
  constructor(
    private offerService: OfferService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notify: NotifyService,
    private _adapter: DateAdapter<any>,
    private bilkaService: BilkaService,
    @Inject('LOCALSTORAGE') private store: any
  ) {
    window.onbeforeunload = function (event) {
      console.log(event);
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };
  }

  async ngOnInit() {


    this.token = this.route.snapshot.paramMap.get('token');
    if (this.token) {
      this.store.set('localuser', { token: this.token });
    }
    this.offerId = this.route.snapshot.paramMap.get('offerId');
    this.providers = await this.offerService.getMultidomProviders();
    this.counties = await this.offerService.getCounties();
    await this.getDealers();

    //for caretta
    await this.getOfferData();
    this._adapter.setLocale('ro');

    this.onChanges();
    let tokenData = this.decodeToken(this.store.get('localuser').token);
    this.username = tokenData.username;

    if (
      tokenData.username == 'schifirnet.daniel'
    ) {
      this.canCancelCommand = true;
    }

    this.canPrintInternallCommandPdf = true;

  }

  async getCategoriesFromProvider(selectedProviderCode) {
    console.log(selectedProviderCode)
    this.offerData.offerData.providerName = selectedProviderCode.DEN_CLASEA;
    this.offerData.offerData.providerId = selectedProviderCode.CODCLASEA;
    let result: any = await this.offerService.getProviderCategories(selectedProviderCode.CODCLASEA);
    this.offersType = result;
    this.showProviders = !this.showProviders;
  }

  decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  onChanges(): void {
    this.myControl.valueChanges.subscribe((val) => {
      if (this.localities && this.localities.rows)
        this.filteredOptions = this.localities.rows.filter((option) =>
          option.localityName.toLowerCase().includes(val)
        );
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue = true;
    //}
  }

  localityChange() {
    console.log('filter');
  }

  checkPluvialType() {
    if (this.pluvialType == 'SL') {
      this.notify.warningMessage('Selectati tip sistem pluvial!');
    } else {
      this.showPluvialTypeModal = false;
    }
  }

  //todo
  async loadLocalities(event) {
    console.log(event);
    this.newClientData.county = event.value;
    this.editClientData.county = event.value;
    this.newClientData.locality = '';
    this.spinner.show();
    this.localities = await this.offerService.getLocalities(
      event.value.countyId
    );
    this.spinner.hide();
    this.filteredOptions = this.localities.rows;
  }

  onSelectedLocality(locality) {
    console.log(locality);
  }

  async getOfferData() {
    let offerData;
    if (this.offerData) {
      offerData = this.offerData.offerData;
    }

    this.spinner.show();
    let res: any = await this.offerService.getOffersCaretta(this.offerId, this.selectedOfferType ? this.selectedOfferType.key : null, this.selectedColor, this.selectedMaterial, this.selectedThickness, this.pluvialType, null).toPromise();
    this.offerData = res;
    this.offerData.categories = this.offerData && this.offerData.categories ? this.offerData.categories : [];
    //this.pluvialType = this.offerData.offerData.pluvialType;
    //preload agents
    if (this.offerData.dealer) {
      this.dealerAgents = await this.offerService.loadDealerAgents(
        this.offerData.dealer.partnerId
      );
      this.offerData.invoiceType = 'Dealer';
    } else {
      this.offerData.invoiceType = 'Client';
    }
    if (!this.selectedOfferType) {
      this.selectedOfferType = res.offerData.selectedOfferType;
      this.selectedColor = res.offerData.selectedColor;
      this.selectedMaterial = res.offerData.selectedMaterial;
      this.selectedThickness = res.offerData.selectedThickness;
      this.currentOffer.deliveryDate = res.deliveryDate;
      this.currentOffer.invoiceType = res.invoiceType;
      this.currentOffer.finishType = res.finishType;
      this.currentOffer.paymentType = res.paymentType;
      this.currentOffer.observations = res.observations;
      this.currentOffer.phone = res.phone;
      if (this.offerData.invoiceType == "Dealer") {
        //prospectare choose type, br ....
        // false = not show in PROSPECTARE
        this.checkDealerStatus(this.offerData.dealer.partnerId, false)
      }
    } else {
      if (this.offerData.invoiceType == "Dealer") {
        //you are already in offer here with just selectedOffer
        if (!this.offerData.client.name) {
          //this.bringPrefillClientDealer(this.offerData.dealer.fiscalCode);
          let event = {
            value: {
              fiscalCode: this.offerData.dealer.fiscalCode,
              partnerId: this.offerData.dealer.partnerId
            }
          }
          this.loadDealerAgents(event)
        }
      }
      this.prefillClientData(this.offerData);

      this.offerData.contact = this.offerData.contact ? JSON.parse(this.offerData.contact) : this.offerData.contact;
      if (offerData) {
        this.offerData.offerData = offerData;
      }

      this.offerData.offerData.selectedOfferType = this.selectedOfferType;
      this.offerData.offerData.selectedColor = this.selectedColor;
      this.offerData.offerData.selectedMaterial = this.selectedMaterial;
      this.offerData.offerData.selectedThickness = this.selectedThickness;
      this.offerData.offerData.pluvialType = this.pluvialType;
    }
    //check if saved state
    if (this.temporaryArticles) {
      for (let temporaryArticle of this.temporaryArticles) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              if (
                detail.DENUMIRE.includes(temporaryArticle.name) &&
                detail.D1 == temporaryArticle.D1 &&
                detail.D2 == temporaryArticle.D2 &&
                detail.D3 == temporaryArticle.D3
              ) {
                detail.quantity = temporaryArticle.quantity;
              }
            }
          }
        }
      }
    }
    if (this.offerData.deliverAddress) {
      this.showButtonAlternativeAddress = false
    }
    this.temporaryArticles = [];
    this.recalculate(true);
    this.statusTaskOffer = this.offerData.statusTaskOffer;
    this.spinner.hide();


  }

  async onPhoneInputChange() {
    if (this.clientPhoneSearch.length >= 3) {
      this.clientFiscalCodeSearch = '';
      this.clientNameSearch = '';
      const data: any = await this.offerService.getClientData(
        this.clientPhoneSearch,
        'phone'
      );
      this.clients = data.rows;
    }
  }

  async onNameInputChange() {
    if (this.clientNameSearch.length >= 3) {
      this.clientFiscalCodeSearch = '';
      this.clientPhoneSearch = '';
      const data: any = await this.offerService.getClientData(
        this.clientNameSearch,
        'name'
      );
      this.clients = data.rows;
    }
  }

  async onFiscalCodeChange() {
    if (this.clientFiscalCodeSearch.length >= 3) {
      this.clientPhoneSearch = '';
      this.clientNameSearch = '';
      const data: any = await this.offerService.getClientData(
        this.clientFiscalCodeSearch,
        'cui'
      );
      this.clients = data.rows;
      return;
    }
    return;
  }

  async onClientSelected(event) {
    this.selectedClient = event.option.value;
    this.offerData.client = {
      fiscalCode: event.option.value.CODFISCAL,
      name: event.option.value.DEN_PART,
      phone: event.option.value.TELEFON,
      internalId: event.option.value.CODPART,
    };


    let rezz: any = await this.offerService.getClientData(
      event.option.value.CODFISCAL,
      'cui'
    );

    this.offerData.client['address'] = {
      address: rezz.rows[0].ADRESA,
      city: rezz.rows[0].DEN_LOCALIT,
      conunty: rezz.rows[0].JUDET,
      headquarter: rezz.rows[0].headquarter,
    };
  }

  displayPhone(client) {
    return client ? client.phone : '';
  }

  displayLocalityName(locality) {
    return locality ? locality.localityName : '';
  }

  displayClientName(client) {
    return client ? client.name : '';
  }

  displayFiscalCode(client) {
    return client ? client.fiscalCode : '';
  }

  round2Decimals(value) {
    return Math.round(value * 100) / 100;
  }

  hasMultipleArts(item) {
    for (let article of item.articles) {
      if (article.data[0].D1) {
        return true;
      }
    }
    return false;
  }
  async cancelCommand() {
    if (this.cancelObservations.length === 0) {
      this.notify.warning('Va rugam sa completati motivul anularii');
      return;
    }
    this.spinner.show();
    if (typeof this.offerData.commandData !== 'object') {
      this.offerData.commandData = JSON.parse(this.offerData.commandData);
    }
    try {
      await this.offerService.cancelCommand(
        this.offerData.commandData.commandId,
        'Anulata ' + this.cancelObservations
      );
      this.cancelCommandFlag = false;

      this.changeStatusTask("CREATED")
      this.spinner.hide();
      window.location.reload();
    } catch (e) {
      console.log(e);
      this.cancelCommandFlag = false;
      this.spinner.hide();
    }
  }
  reset() {
    let dialogRef = this.dialog.open(DecisionModalComponent, {
      data: {
        title: 'Salvare cantitati oferta',
        message: 'Doriti salvarea cantitatilor de pe oferta actuala?',
      },
    });
    dialogRef.afterClosed().subscribe(async (reset) => {
      if (reset) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              if (detail.quantity) {
                let tempArticle = {
                  DENUMIRE: detail.DENUMIRE,
                  D1: detail.D1,
                  D2: detail.D2,
                  D3: detail.D3,
                  quantity: detail.quantity,
                  selectedColor: this.selectedColor,
                  selectedThickness: this.selectedThickness,
                };
                this.temporaryArticles.push(tempArticle);
              }
            }
          }
        }
        this.store.set('tempPrices', this.temporaryArticles);
      }
    });
  }

  loadPrices() {
    this.temporaryArticles = this.store.get('tempPrices');
    if (this.temporaryArticles) {
      for (let temporaryArticle of this.temporaryArticles) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              let articleParsedName = this.getParentName(
                detail,
                this.selectedColor,
                this.selectedThickness
              );
              let savedArticleParsedName = this.getParentName(
                temporaryArticle,
                temporaryArticle.selectedColor,
                temporaryArticle.selectedThickness
              );
              if (
                articleParsedName == savedArticleParsedName &&
                detail.D1 == temporaryArticle.D1 &&
                detail.D2 == temporaryArticle.D2 &&
                detail.D3 == temporaryArticle.D3
              ) {
                detail.quantity = temporaryArticle.quantity;
              }
            }
          }
        }
      }
    }
    this.temporaryArticles = [];
    this.recalculate(true);
    this.notify.successMessage('Cantitati precompletate!');
  }

  getParentName(article, selectedColor, selectedThickness) {
    //get selected data   {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
    let tempDen = article.DENUMIRE;
    tempDen = tempDen.split('PLUS').join('');
    tempDen = tempDen.split('103').join('');
    tempDen = tempDen.split('104').join('');
    tempDen = tempDen.split('105').join('');
    tempDen = tempDen.split('106').join('');
    tempDen = tempDen.split(selectedColor).join('');
    tempDen = tempDen.split(selectedThickness).join('');
    tempDen = tempDen.split('BR').join('');
    tempDen = tempDen.split('MAT').join('');
    tempDen = tempDen.split('SL').join('');
    return tempDen.trim();
  }

  async getMaterial(offerType) {
    //this.materials = [];
    this.spinner.show();

    this.offerData.offerData.selectedOfferType = offerType;
    this.selectedOfferType = offerType;
    let materials: any = await this.offerService.getMaterialsDom(offerType.key, offerType.statisticClassId).toPromise();

    this.materials = materials;
    this.spinner.hide();
    if (materials.length == 0) {
      this.getColors("N")
      // this.spinner.show();
      // await this.getOfferData();
      // this.offerId = this.tempOfferId;
      // this.spinner.hide();
    }
    else if (materials.length == 1) {
      this.getColors(materials[0]);
    }

  }

  async getColors(material) {
    this.spinner.show();
    this.materials = [];
    this.selectedMaterial = material.name;
    this.offerData.offerData.selectedMaterial = material.name;
    let colors: any = await this.offerService.getColorsDom(this.selectedOfferType.key, material.name, material.statisticClassId).toPromise();

    this.spinner.hide();
    this.colors = colors;
    if (colors.length == 0) {
      this.spinner.show();
      await this.getOfferData();
      this.offerId = this.tempOfferId;
      this.spinner.hide();
    }
    else if (colors.length == 1) {
      this.getThickness(colors[0]);
    }

  }

  async getThickness(color) {
    //this.materials = [];
    this.spinner.show();
    this.selectedColor = color.name;
    this.offerData.offerData.selectedColor = color.name;
    let thickness: any = await this.offerService.getThickness(this.selectedOfferType.key, this.selectedMaterial, this.selectedColor).toPromise();

    this.thickness = thickness;
    this.spinner.hide();
    if (thickness.length == 0) {
      this.spinner.show();
      await this.getOfferData();
      this.offerId = this.tempOfferId;
      this.spinner.hide();
    }
    else if (thickness.length == 1) {
      this.selectThickness(thickness[0]);
    }
  }

  async selectThickness(thickness) {
    this.spinner.show();
    this.selectedThickness = thickness;
    await this.getOfferData();
    this.offerId = this.tempOfferId;
    this.spinner.hide();
  }

  getTotalQuantity(articleDetails) {
    let sum = 0;
    for (let articleDetail of articleDetails.data) {
      if (articleDetail.quantity && articleDetail.D2) {
        sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
      } else if (articleDetail.quantity) {
        sum += articleDetail.D1 * articleDetail.quantity;
      }
    }
    articleDetails.totalQuantity = sum;
    return sum;
  }

  getArticleTotalQuantity(articleDetail) {
    let sum = 0;
    if (articleDetail.quantity && articleDetail.D2) {
      sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
    } else if (articleDetail.quantity) {
      sum += articleDetail.D1 * articleDetail.quantity;
    }
    return sum;
  }

  getTotalPrice() {
    this.totalPrice = 0;
    for (let category of this.offerData.categories) {
      for (let article of category.articles) {
        for (let detail of article.data) {
          if (detail.quantity) {
            this.totalPrice +=
              parseFloat(detail.quantity) *
              parseFloat(detail.agentPrice) *
              (article.d1 ? parseFloat(article.d1) : 1) *
              (article.d2 ? parseFloat(article.d2) : 1) *
              (article.d3 ? parseFloat(article.d3) : 1);
          }
        }
      }
    }
    return this.round2Decimals(this.totalPrice);
  }

  getTotalListPrice() {
    this.totalPrice = 0;

    return this.round2Decimals(this.totalPrice);
  }
  //copy price to all details of an article
  propagateQuantity(articles) {
    for (let article of articles) {
      article.agentPrice = articles[0].agentPrice;
    }
  }

  async recalculate(event) {
    if (event && event.keyCode == 188) {
      this.notify.infoMessage('Folositi punctul ca separator pentru zecimale!');
      event.preventDefault();
      return;
    }
    this.totalPrice = 0;
    this.totalMinimumPrice = 0;
    this.totalWithOutDiscount = 0;
    this.discountValue = 0;
    this.discountPercent = 0;
    this.totalOfferWeight = 0;
    //price withoutDiscount
    for (let category of this.offerData.categories) {
      for (let article of category.articles) {
        for (let detail of article.data) {
          //check if price lower than minimum posible, replace , with .
          //detail.agentPrice = parseFloat(detail.agentPrice ? detail.agentPrice.toString().replace(",", "") : 0);
          // if (detail.agentPrice == 0) {
          //     //article.invalidPrice = true;
          //     detail.agentPrice = this.round2Decimals(detail.agentMinimumPrice ? detail.agentMinimumPrice : 0)
          // }

          if (
            detail.quantity > 0 &&
            (this.round2Decimals(detail.agentPrice) <
              this.round2Decimals(detail.agentMinimumPrice) ||
              !detail.agentPrice) &&
            detail.PROMOTIE != 1
          ) {
            article.invalidPrice = true;
            break;
          }

          article.invalidPrice = false;

          if (detail.quantity) {
            detail.quantity = detail.quantity.toString().replace('-', '');
            detail.quantity = detail.quantity.toString().replace(',', '');
            this.totalWithOutDiscount +=
              parseFloat(detail.quantity) *
              parseFloat(detail.LISTA) *
              (detail.D1 ? parseFloat(detail.D1) : 1) *
              (detail.D2 ? parseFloat(detail.D2) : 1) *
              (detail.D3 ? parseFloat(detail.D3) : 1);
            this.totalPrice +=
              parseFloat(detail.quantity) *
              parseFloat(article.data[0].agentPrice) *
              (detail.D1 ? parseFloat(detail.D1) : 1) *
              (detail.D2 ? parseFloat(detail.D2) : 1) *
              (detail.D3 ? parseFloat(detail.D3) : 1);
            this.totalMinimumPrice +=
              parseFloat(detail.quantity) *
              parseFloat(article.data[0].agentMinimumPrice) *
              (detail.D1 ? parseFloat(detail.D1) : 1) *
              (detail.D2 ? parseFloat(detail.D2) : 1) *
              (detail.D3 ? parseFloat(detail.D3) : 1);
            if (article.data[0].MASA) {
              this.totalOfferWeight +=
                parseFloat(detail.quantity) *
                parseFloat(article.data[0].MASA) *
                (detail.D1 ? parseFloat(detail.D1) : 1) *
                (detail.D2 ? parseFloat(detail.D2) : 1) *
                (detail.D3 ? parseFloat(detail.D3) : 1);
            }
          }
        }
      }
    }
    this.totalWithOutDiscount = this.round2Decimals(this.totalWithOutDiscount);
    this.totalPrice = this.round2Decimals(this.totalPrice);
    this.offerData.totalPrice = this.totalPrice;

    if (this.offerData.custody && !this.offerData.advancePaymentValue) {
      this.offerData.advancePaymentValue = this.totalPrice;
    }

    this.discountValue = this.round2Decimals(
      this.totalWithOutDiscount - this.totalPrice
    );
    this.discountPercent = this.round2Decimals(
      ((this.totalWithOutDiscount - this.totalPrice) /
        this.totalWithOutDiscount) *
      100
    );
    this.adaosValue = this.round2Decimals(
      (this.totalPrice ? this.totalPrice / 1.19 : 0) -
      (this.totalMinimumPrice ? this.totalMinimumPrice / 1.19 : 0)
    );
    this.offerData.adaosPrice = this.adaosValue;
    this.adaosPercent = this.round2Decimals(
      ((this.totalPrice - this.totalMinimumPrice) / this.totalPrice) * 100
    );
    if (this.offerData.custody && this.offerData.isAdvancePayment) {
      this.calculateAdvancedPayment(this.offerData)
    }
  }

  getSubtotal(price, quantity) {
    return this.round2Decimals(quantity ? parseFloat(price) * quantity : 0);
  }

  async saveOffer(status, message) {
    let restricted = this.checkCommandProcentual()
    if (restricted) {
      return this.notify.error("Oferta nu a fost salvata")
    }
    if (this.statusTaskOffer == 'FINANCIAL_VALIDATION') {
      return this.notify.warningMessage("Oferta inca nu a fost validata")
    }

    if (!this.offerData.invoiceType) {
      this.notify.warning(`Selecteaza tip comanda!`);
      return;
    }
    if (
      this.offerData.invoiceType == 'Dealer' &&
      Object.keys(this.offerData.dealer).length === 0 &&
      this.offerData.dealer.constructor === Object
    ) {
      this.notify.warning(`Selecteaza dealerul!`);
      return;
    }
    if (
      this.offerData.invoiceType == 'Dealer' &&
      Object.keys(this.offerData.dealerAgent).length === 0 &&
      this.offerData.dealerAgent.constructor === Object
    ) {
      this.notify.warning(`Selecteaza agentul dealerului!`);
      return;
    }

    if (
      this.offerData.invoiceType == 'Client' &&
      this.offerData.isAdvancePayment &&
      this.offerData.advancePaymentValue < 0
    ) {
      this.notify.warning(`Precizati suma avans!`);
      return;
    }


    for (let category of this.offerData.categories) {
      for (let article of category.articles) {
        if (article.invalidPrice) {
          this.notify.warning(
            `Oferta contine preturi sub limita stabilita articol ${article.articleName}!`
          );
          return;
        }
        for (let detail of article.data) {
          if (detail.errorD1Real) {
            this.notify.warning('Dimensiunea reala a articolului incorecta');
            this.spinner.hide();
            return;
          }
          if (detail.quantity && detail.quantity > 0 && detail.INACTIV == 1) {
            this.notify.warning('Oferta contine articole inactive!');
            this.spinner.hide();
            return;
          }
        }
      }
    }
    this.offerInfo = true
    this.offerData.pickedByClient = this.offerData.pickedByClient ? this.offerData.pickedByClient : false;

    this.spinner.show();

    //save filter data in other object
    let fulldata = JSON.parse(JSON.stringify(this.offerData.categories));
    this.offerData.categories = this.offerData.categories.map((categ) => {
      categ.articles = categ.articles.filter((article) => {
        article.data = article.data.filter((art) => {
          return parseFloat(art.quantity) > 0;
        });
        return article.data.length > 0;
      });
      return categ;
    });

    for (let category of this.offerData.categories) {
      for (let article of category.articles) {
        for (let detail of article.data) {
          if (
            this.offerData.offerData.selectedOfferType.key != 'GA' &&
            (detail.DENUMIRE.includes(
              'PROFIL METALIC DE FIXARE PE STALP 192'
            ) ||
              detail.DENUMIRE.includes('RIGLA METALICA DE RIGIDIZARE 193') ||
              detail.DENUMIRE.includes('POP NITURI COLORATE 100 BUC 194') ||
              detail.DENUMIRE.includes('LAMELA GARD ORIZONTAL 191') ||
              detail.DENUMIRE.includes('SURUBURI METAL 4.8*19MM-250/CUT 150') ||
              detail.DENUMIRE.includes('SIPCA GARD 130'))
          ) {
            this.notify.warning(
              `Oferta contine articole din categoria gard! Creati oferta selectand categoria gard.`
            );
            this.offerData.categories = fulldata;
            this.spinner.hide();
            return;
          }

        }
      }
    }

    try {
      this.offerData.status = status;
      this.offerData.actions = [
        {
          action: message ? message : 'Oferta a fost schimbata!',
          date: new Date(),
        },
      ];
      await this.offerService.saveOfferV2(this.offerData);

      this.offerData.categories = fulldata;
      this.spinner.hide();
    } catch (e) {
      this.spinner.hide();
    }

  }
  async changeStatusTask(status) {
    await this.offerService.changeStatusTask(this.offerData.uuid, status)
    this.statusTaskOffer = 'FINANCIAL_VALIDATION';
  }
  async finishCommand() {
    //check required fields before anything
    if (!this.offerData.client.fiscalCode) {
      this.notify.warning(`Oferta nu contine detalii client!`);
      return;
    }

    if (!this.offerData.deliverDate) {
      this.notify.warningMessage('Selectati data de livrare!');
      return;
    }
    if (!this.offerData.finishType || this.offerData.finishType === "undefined") {
      this.notify.warningMessage('Selectati tip document!');
      return;
    }

    if (!this.offerData.paymentType || this.offerData.paymentType === "undefined") {
      this.notify.warningMessage('Selectati modalitatea de plata!');
      return;
    }

    if (
      this.offerData.invoiceType == 'Client' &&
      this.offerData.isAdvancePayment &&
      this.offerData.advancePaymentValue < 0
    ) {
      this.notify.warning(`Precizati suma avans!`);
      return;
    }

    //check promo 40-60
    let restricted = this.checkCommandProcentual()
    if (restricted) {
      return this.notify.error("Oferta nu a fost salvata!")
    }
    //send more quiqly to have commision value
    this.offerData.adaosValue = this.adaosValue;

    //finacial validation
    if (this.statusTaskOffer == 'FINANCIAL_VALIDATION') {
      return this.notify.warningMessage("Oferta inca nu a fost validata")
    }
    if (this.statusTaskOffer != 'APPROVED') {


      //isDealerTrusted ->  error = client facturi scadente > 7 zile
      if (this.isDealerTrusted == "error") {
        this.saveOffer('OFERTA', null);
        if (this.offerInfo) {
          //offerInfo =  true ||  false => true daca s-au facut validarile din oferta 
          let dialogRef = this.dialog.open(DecisionModalComponent, {
            data: {
              title: 'Validare Financiara',
              message: 'Doriti trimiterea ofertei catre validare financiara?',
            },
          });

          let boolResponse = await dialogRef.afterClosed().toPromise();
          if (!boolResponse) {
            return;
          }
          return this.changeStatusTask("FINANCIAL_VALIDATION");
        }
      }
    }
    //continue finishCommand
    for (let category of this.offerData.categories) {
      for (let article of category.articles) {
        for (let detail of article.data) {
          if (detail.quantity && detail.quantity > 0 && detail.INACTIV == 1) {
            this.notify.warning('Oferta contine articole inactive!');
            this.spinner.hide();
            return;
          }
        }
        if (article.data.length > 1) {
          let index = 0;
          for (let detail of article.data) {
            let roundD1SubPrevious;
            if (index > 0) {
              roundD1SubPrevious = this.round2Decimals(article.data[index - 1].D1);
            } else {
              roundD1SubPrevious = 0;
            }


            let roundD1Sub = this.round2Decimals(detail.D1)
            if (!detail.d1Real || detail.d1Real < roundD1Sub && detail.d1Real > roundD1SubPrevious) {
              detail.errorD1Real = false;
            } else {
              this.notify.warning(`Dimensiunea reala a articolului incorecta`)
              detail.errorD1Real = true;
              return;
            }


            index++;
          }

        }
      }
    }



    let dialogRef = this.dialog.open(DecisionModalComponent, {
      data: {
        title: 'Salvare comanda',
        message: 'Doriti salvarea comenzii in mentor?',
      },
    });

    let boolResponse = await dialogRef.afterClosed().toPromise();
    if (!boolResponse) {
      return;
    }



    let serverData = JSON.parse(JSON.stringify(this.offerData));

    //filter categories, only articles with quantity
    let filteredCategories = [];
    this.offerData.categories.map((categ) => {
      let filteredCategory = {
        category: categ.category,
        articles: [],
      };
      categ.articles.map((article) => {
        let filteredArticle = {
          articleName: article.articleName,
          invalidPrice: article.invalidPrice,
          totalQuantity: article.totalQuantity,
          data: [],
        };
        article.data.map((art) => {
          if (parseFloat(art.quantity) > 0) {
            filteredArticle.data = [...filteredArticle.data, art];
          }
        });
        if (filteredArticle.data.length > 0) {
          filteredCategory.articles = [
            ...filteredCategory.articles,
            filteredArticle,
          ];
        }
      });
      if (filteredCategory.articles.length > 0) {
        filteredCategories = [...filteredCategories, filteredCategory];
      }
    });

    serverData.categories = filteredCategories;
    serverData.addedValue = this.adaosValue;
    if (serverData.categories.length == 0) {
      this.notify.warningMessage('Oferta nu contine articole!');
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    let res: any = await this.offerService.finishCommandV2(serverData);
    console.log(res);
    this.offerData.data = {
      commandId: res.commandId,
      commandSeries: res.commandSeries,
      commandNumber: res.commandNumber,
    };
    this.offerData.commandData = {
      commandId: res.commandId,
      commandSeries: res.commandSeries,
      commandNumber: res.commandNumber,
    };
    this.offerData.status = 'COMANDA';
    this.spinner.hide();
    this.notify.successMessage(`Comanda ${res.commandNumber} a fost salvata!`);
    return;
  }

  getPriceByCategory(dealer, detail) {
    if (dealer.priceCategorySymbol == 'I') {
      return detail.C1;
    } else if (
      dealer.priceCategorySymbol == 'II' ||
      dealer.priceCategorySymbol == 'IV'
    ) {
      return detail.C2;
    } else if (dealer.priceCategorySymbol == 'III') {
      return detail.C3;
    }
  }

  async generateOfferPdf() {
    // if (!this.offerData.client.fiscalCode) {
    //     this.notify.warning(`Oferta nu contine detalii client!`);
    //     return;
    // }
    await this.saveOffer(this.offerData.status, null);
    for (let category of this.offerData.categories) {
      for (let article of category.articles) {
        if (article.invalidPrice) {
          this.notify.warning(
            `Oferta contine preturi sub limita stabilita articol ${article.articleName}!`
          );
          return;
        }
      }
    }
    let body = {
      offer: this.offerData.categories,
      dealer: this.offerData.dealer,
      dealerAgent: this.offerData.dealerAgent,
      offerData: this.offerData.offerData,
      summary: {
        totalPrice: this.totalPrice,
        totalWithOutDiscount: this.totalWithOutDiscount,
        discountValue: this.discountValue,
        discountPercent: this.discountPercent,
      },
      client: this.offerData.client,
      includePrices: this.includePrices,
      id: this.offerData._id,
      commandNumber:
        this.offerData && this.offerData.data
          ? this.offerData.data.commandNumber
          : '',
    };
    console.log(this.includePrices);
    this.spinner.show();
    let res: any = await this.offerService.generateOfferPdf(body);
    this.spinner.hide();

    this.offerData.actions = [
      {
        action: 'Pdf generat',
        date: new Date(),
      },
    ];
    this.offerData.files = [
      {
        name: `${res.pdfName.split('_')[0]}`,
        path: `public/${res.pdfName}`,
      },
    ];
    //todo duplicates command nodes
    ///await this.saveOffer(this.offerData.status, "PDF generat!");
    window.open(`${this.serviceUrl}/public/${res.pdfName}`);
  }

  async generateCommandsInternalDocumentsPdf() {
    this.spinner.show();
    this.offerData.summary = {
      totalPrice: this.totalPrice,
      totalWithOutDiscount: this.totalWithOutDiscount,
      discountValue: this.discountValue,
      discountPercent: this.discountPercent,
    };

    let res: any = await this.offerService.generateCommandsInternalDocumentsPdf(
      this.offerData
    );
    this.spinner.hide();
    window.open(`${this.serviceUrl}/public/${res.pdfName}`);
  }

  async prefillClientData(offer) {
    if (!offer.offerData.selectedOfferType) {
      let contacts = JSON.parse(offer.contact);
      for (let contact of contacts) {
        if (!contact.phone) {
          break;
        }
        const data: any = await this.offerService.getClientData(
          contact.phone,
          'phone'
        );
        if (data.rows.length > 0) {
          this.onClientSelected({ option: { value: data.rows[0] } });
          break;
        }
      }
    }
  }
  async prefillEditClientData(client) {
    let filteredCounties = this.counties.rows.filter(
      (county) => county.countyName == client.JUDET
    );
    this.selectedCounty = filteredCounties[0];
    let filteredLocalities;
    if (this.selectedCounty) {
      this.localities = await this.offerService.getLocalities(
        this.selectedCounty.countyId
      );
      this.filteredOptions = this.localities.rows;
      filteredLocalities = this.localities.rows.filter(
        (locality) => locality.localityName == client.DEN_LOCALIT
      );
    }

    /////////////////////////
    this.editClientData = {
      isPF: client.PERSOANAFIZICA == 1 ? true : false,
      clientId: client.CODPART,
      name: client.DEN_PART,
      agendaId: client.CODAGENDA,
      fiscalCode: client.CODFISCAL,
      tradeRegister: client.REGCOM,
      phone: client.TELEFON,
      county: this.selectedCounty,
      locality: filteredLocalities ? filteredLocalities[0] : '',
      street: client.ADRESA,
      scara: '',
      number: client.NUMBER,
      bloc: client.BLOC,
      etaj: client.ETAJ,
      apartament: client.APART
    };
    this.editClient = true;
  }

  async prefillSelectedClientData(client) {
    if (!client.CODFISCAL) {
      this.notify.warningMessage(
        'Partenerul selectat nu are completat CUI/CNP !'
      );
      return;
    }
    this.selectedClient = client;

    this.offerData.client = {
      fiscalCode: client.CODFISCAL,
      name: client.DEN_PART,
      phone: client.TELEFON,
      internalId: client.CODPART,
      address: {
        address: client.ADRESA,
        city: client.DEN_LOCALIT,
        conunty: client.JUDET,
        headquarter: client.headquarter,
        //todo add headquarter
      },
    };

    this.showClientsModal = null;
  }
  async insertNewPartner() {
    if (!this.newClientData?.locality?.localityId) {
      this.notify.warningMessage('Selectati localitatea!');
      return 0;
    }
    this.spinner.show();
    this.newClientData.name = this.newClientData.name.toUpperCase();
    const insertResult: any = await this.offerService.insertPartner(
      this.newClientData
    );
    console.log(insertResult);
    if (insertResult.Error == 'ok') {
      this.spinner.hide();
      this.notify.successMessage('Partener salvat in mentor!');
      this.addNewClient = false;
      this.clientFiscalCodeSearch = this.newClientData.fiscalCode;
      await this.onFiscalCodeChange();
      await this.prefillSelectedClientData(this.clients[0]);
      this.showClientsModal = false;
    } else {
      this.spinner.hide();
      this.notify.errorMessage(insertResult.Error);
    }
  }

  async editPartner() {
    if (
      !this.editClientData?.locality ||
      !this.editClientData?.locality?.localityId
    ) {
      this.notify.warningMessage('Selectati localitatea!');
      return 0;
    }
    console.log(this.editClientData);
    //filter client
    await this.offerService.editClient(this.editClientData);

    this.clientFiscalCodeSearch = this.editClientData.fiscalCode;
    this.onFiscalCodeChange();
    this.editClient = false;
  }

  async getDealers() {
    this.dealers = await this.offerService.getDealers();
  }

  async loadDealerAgents(event) {

    this.spinner.show();
    this.selectedDealer = event.value;

    const data: any = await this.offerService.getClientData(
      this.selectedDealer.fiscalCode,
      'cui'
    );
    if (data.rows.length > 0) {
      this.onClientSelected({ option: { value: data.rows[0] } });
    }
    ////
    this.dealerAgents = await this.offerService.loadDealerAgents(
      this.selectedDealer.partnerId
    );
    this.checkDealerStatus(this.selectedDealer.partnerId, true)
    this.recalculate(true)
    this.spinner.hide();
  }

  async onSelectedDealerAgent(event) {
    this.selectedDealerAgent = event.value;
    this.offerData.client['address'] = {
      address: this.selectedDealerAgent.ADRESA,
      city: this.selectedDealerAgent.DEN_LOCALIT,
      conunty: this.selectedDealerAgent.JUDET,
      headquarter: this.selectedDealerAgent.headquarter,
    };
  }

  loadAgentPrice(P) {
    for (let category of this.offerData.categories || []) {
      for (let article of category.articles) {
        for (let detail of article.data) {
          if (detail[P] > detail.agentMinimumPrice) {
            detail.agentPrice = detail[P];
          }
        }
      }
    }
    this.recalculate(null);
  }

  commandTypeChanged(event) {
    if (event.value == 'Client') {
      this.isDealerTrusted = 'ok'
      delete this.offerData.dealer;
      delete this.offerData.dealerAgent;
    } else {
      this.isDealerTrusted = 'ok';
    }
  }

  compareDealers(o1, o2) {
    return o1.partnerId == o2.partnerId;
  }
  compareDealerAgents(o1, o2) {
    return o1.agentId == o2.agentId;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  showColorWarningDialog(data) {
    if (data.length == 1 && this.scannedItems.length == 0) {
      return;
    }
    if (data.length > 0) {
      let dialogRef = this.dialog.open(DecisionModalComponent, {
        data: {
          title: 'dialog.title.warning',
          message: 'offers.cart_color_warning',
          items: data,
          showClose: false,
        },
      });
    }
  }

  round4Decimals(value) {
    return Math.round(value * 10000) / 10000;
  }

  delay(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  isTigla(articleName) {
    return articleName.includes('TIGLA METALICA');
  }

  async setCountyDelivery(event) {
    this.offerData.deliverAddress = event.value.countyName;
    this.spinner.show();
    this.localities = await this.offerService.getLocalities(
      event.value.countyId
    );
    this.filteredOptions = this.localities.rows;
    this.spinner.hide();
  }

  async setLocalityDelivery(event) {
    this.offerData.deliverAddress += ' ' + event.value.localityName;
  }

  alternativeDeliveryAdress() {
    if (!this.deliverStreetAlternative) {
      this.offerData.deliverAddress += ' ' + "";
    } else {
      this.offerData.deliverAddress += ' ' + this.deliverStreetAlternative;
    }
    this.showAlternativeDelivery = false;
    this.showButtonAlternativeAddress = false;
  }

  deleteAlternativeAddress() {
    this.filteredOptions = undefined;
    this.offerData.deliverAddress = '';
    this.showButtonAlternativeAddress = true;
    this.deliverStreetAlternative = '';
    this.selectedValueCounty = this.counties;
  }

  closeAlternativeAddress() {
    this.showAlternativeDelivery = false;
    this.showButtonAlternativeAddress = true;
    this.deliverStreetAlternative = '';
  }

  openNewAddressAlternative() {
    this.showAlternativeDelivery = true;
  }
  checkValueD1Real(d1Real, sub, index, items) {
    var regExp = /[a-zA-Z]/g;
    if (regExp.test(d1Real)) {
      this.notify.warning('Valoarea nu trebuie sa contina litere sau caractere speciale');
      sub.errorD1Real = true;
      return;
    }
    let roundD1SubPrevious;
    if (index > 0) {
      roundD1SubPrevious = this.round2Decimals(items[index - 1].D1);
    } else {
      roundD1SubPrevious = 0;
    }

    let roundD1Sub = this.round2Decimals(sub.D1)
    if (d1Real.length <= 4) {

      if (d1Real < roundD1Sub && d1Real > roundD1SubPrevious || d1Real.length === 0) {
        sub.errorD1Real = false;
      } else {
        sub.errorD1Real = true;
      }

    }
  }

  checkD1Input(event) {
    if (event && event.keyCode == 188) {
      this.notify.infoMessage('Folositi punctul ca separator pentru zecimale!');
      event.preventDefault();
      return;
    }
  }

  showD1Real(item) {
    if (item.includes("SIPCA GARD 130") ||
      item.includes("LAMELA GARD ORIZONTAL 191") ||
      item.includes("PROFIL CAPAC INCHIDERE GARD ORIZONTAL 205") ||
      item.includes("LAMBRIU METALIC 122") ||
      item.includes("TIGLA METALICA ALPIN 103") ||
      item.includes("TIGLA METALICA ALPIN 3D PLUS 104") ||
      item.includes("TIGLA METALICA MEDITERAN 105") ||
      item.includes("TIGLA METALICA MEDITERAN PLUS 106") ||
      item.includes("TABLA CLICK 101") ||
      item.includes("TABLA CLICK PLUS 102")) {
      return true
    }
    return false
  }

  //todo remove
  // getD1RealErrorStyles(d1RealBool) {
  //     let errorD1Real = {
  //         'color': d1RealBool ? 'white' : 'black',
  //         'background-color': d1RealBool ? 'red' : 'white'
  //     };
  //     return errorD1Real;
  // }

  async onBasicUpload(event) {
    console.log(event);
    const formData = new FormData();
    formData.append('file', event.currentFiles[0]);

    //console.log('boundary:', formData._boundary);
    await this.offerService.saveFile(formData);

    console.log(event.currentFiles[0]);
    this.offerData.files = [
      ...this.offerData.files,
      {
        name: event.currentFiles[0].name,
        path: `public/agent1/${event.currentFiles[0].name}`,
        user: 'agent1',
      },
    ];
    this.fileUpload.clear();

    this.saveOffer(this.offerData.status, null);
  }

  preventScrool(event) {
    event.preventDefault();
  }

  async validateFiscalCode(fiscalCode) {
    fiscalCode = fiscalCode.replace('RO', '').replace('ro', '').trim();
    if (isNaN(parseInt(fiscalCode))) {
      this.notify.warningMessage('Cod fiscal eronat.');
      return;
    }
    fiscalCode = parseInt(fiscalCode);
    let res: any = await this.offerService
      .validateFiscalCode(fiscalCode)
      .toPromise();
    if (!res.denumire) {
      this.notify.warningMessage(
        'Cod fiscal eronat. Nu a putut fi gasit in baza de date ANAF.'
      );
    } else {
      this.validFiscalCode = true;
      this.validatedPartnerData = res;
      this.newClientData.name = this.validatedPartnerData.denumire;
      this.newClientData.fiscalCode = this.validatedPartnerData.cui;
    }
  }

  checkIfInactive(detail) {

    if (
      this.offerData.offerData.selectedOfferType.key != 'GA' && this.offerData.offerData.selectedOfferType.key != 'VLX' && this.offerData.offerData.selectedOfferType.key != 'OP' &&
      (detail.DENUMIRE.includes('PROFIL METALIC DE FIXARE PE STALP 192') ||
        detail.DENUMIRE.includes('RIGLA METALICA DE RIGIDIZARE 193') ||
        detail.DENUMIRE.includes('POP NITURI COLORATE 100 BUC 194') ||
        detail.DENUMIRE.includes('LAMELA GARD ORIZONTAL 191') ||
        detail.DENUMIRE.includes('SURUBURI METAL 4.8*19MM-250/CUT 150') ||
        detail.DENUMIRE.includes('VELUX') ||
        detail.DENUMIRE.includes('SIPCA GARD 130') ||
        detail.DENUMIRE.includes('APARAT NITUIT') ||
        detail.DENUMIRE.includes('CIOCAN TEFLON') ||
        detail.DENUMIRE.includes('CIOCAN ROTUND') ||
        detail.DENUMIRE.includes('CLESTE FALTUIT') ||
        detail.DENUMIRE.includes('CLESTE PLIERE') ||
        detail.DENUMIRE.includes('FOARFECA TABLA') ||
        detail.DENUMIRE.includes('FOARFECA CIRCULARA') ||
        detail.DENUMIRE.includes('FOARFECA COMBINATA') ||
        detail.DENUMIRE.includes('SCARA DE ACCES') ||
        detail.DENUMIRE.includes('PRISMA TEFLON') ||
        detail.DENUMIRE.includes('CLESTE FALCI') ||
        detail.DENUMIRE.includes('TRASATOR GRADAT') ||
        detail.DENUMIRE.includes('GARD ORIZONTAL') ||
        detail.DENUMIRE.includes('SURUB TABLA COLORATA CARETTA') ||
        detail.DENUMIRE.includes('MANSON ETANSARE') ||
        detail.DENUMIRE.includes('CUIE 8') ||
        detail.DENUMIRE.includes('HOL SURUB')

      )) {
      return true;
    }
    return false;
  }

  setAdvancePaymentValue(isCustody) {

    if (isCustody) {
      this.offerData.isAdvancePayment = true;
      this.calculateAdvancedPayment(this.offerData)
    } else {
      this.offerData.isAdvancePayment = false;
      this.offerData.advancePaymentValue = 0;
    }
  }
  showCategory(item) {
    let show = false;

    if (this.offerData.offerData.selectedOfferType.key == 'GA') {
      if (item.category == 'ELEMENTE OPTIONALE' || item.category == 'VELUX') {
        return false;
      }
      for (let article of item.articles) {
        if (article.data[0].INACTIV === 1) {
          article.hideArticle = true;
        } else {
          article.hideArticle = false;
          show = true
        }
      }
      return show;
    }
    if (this.offerData.offerData.selectedOfferType.key == 'VLX') {
      if (item.category == 'ELEMENTE OPTIONALE') {
        return false;
      }
      return true;
    }
    if (this.offerData.offerData.selectedOfferType.key == 'OP') {
      if (item.category == 'VELUX' || item.category == 'TABLA CUTATA') {
        return false
      }
      return true
    }
    if (this.offerData.offerData.selectedOfferType.key == 'AA' ||
      this.offerData.offerData.selectedOfferType.key == 'AP' ||
      this.offerData.offerData.selectedOfferType.key == 'MM' ||
      this.offerData.offerData.selectedOfferType.key == 'MP' ||
      this.offerData.offerData.selectedOfferType.key == 'CC' ||
      this.offerData.offerData.selectedOfferType.key == 'CP'
    ) {
      if (item.category == 'ELEMENTE OPTIONALE') {
        return false;
      }
    }
    for (let article of item.articles) {

      if ((article.articleName.includes('PROFIL METALIC DE FIXARE PE STALP 192') ||
        article.articleName.includes('RIGLA METALICA DE RIGIDIZARE 193') ||
        article.articleName.includes('POP NITURI COLORATE 100 BUC 194') ||
        article.articleName.includes('LAMELA GARD ORIZONTAL 191') ||
        article.articleName.includes('SURUBURI METAL 4.8*19MM-250/CUT 150') ||
        article.articleName.includes('VELUX') ||
        article.articleName.includes('SIPCA GARD 130') ||
        article.articleName.includes('APARAT NITUIT') ||
        article.articleName.includes('CIOCAN TEFLON') ||
        article.articleName.includes('CIOCAN ROTUND') ||
        article.articleName.includes('CLESTE FALTUIT') ||
        article.articleName.includes('CLESTE PLIERE') ||
        article.articleName.includes('FOARFECA TABLA') ||
        article.articleName.includes('FOARFECA CIRCULARA') ||
        article.articleName.includes('FOARFECA COMBINATA') ||
        article.articleName.includes('SCARA DE ACCES') ||
        article.articleName.includes('PRISMA TEFLON') ||
        article.articleName.includes('CLESTE FALCI') ||
        article.articleName.includes('BURLAN RECTANGULAR PROMO') ||
        article.articleName.includes('TRASATOR GRADAT') ||
        article.articleName.includes('GARD ORIZONTAL') ||
        article.articleName.includes('SURUB TABLA COLORATA CARETTA') ||
        article.articleName.includes('MANSON ETANSARE') ||
        article.articleName.includes('CUIE 8') ||
        article.articleName.includes('HOL SURUB')
      ) && !article.quantity) {
        article.hideArticle = true;
      }
      else if (!article.quantity && article.data[0].INACTIV === 1) {
        article.hideArticle = true;
      }
      else {
        article.hideArticle = false;
        show = true;
      }
    }
    return show;
  }

  calculateAdvancedPayment(data) {
    if (data.dealer && data.dealer.partnerTypeId != 16) {
      data.advancePaymentValue = this.round2Decimals(this.totalPrice - this.adaosValue)
    } else {
      data.advancePaymentValue = this.totalPrice;
    }
  }

  async checkDealerStatus(dealerId, showWarning) {
    let response = await this.offerService.getSoldDetail(dealerId)
    let docLength = response["documents"].length

    this.creditValue = response["creditValue"];
    this.soldValue = response["soldValue"]
    if (docLength > 0) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      //let lastInvoicePayment = response["documents"][docLength - 1].invoicePaymentDeadline;
      let lastInvoicePayment;
      for (let invoice of response['documents']) {

        if (invoice.invoiceRest >= 1 && invoice.type == "FOUT") {
          lastInvoicePayment = invoice.invoicePaymentDeadline;
          break;
        }

      }
      let invoicePaymentDeadline = Math.ceil((today.getTime() - (new Date(lastInvoicePayment).getTime())) / (1000 * 60 * 60 * 24))
      if (invoicePaymentDeadline >= 7) {
        this.isDealerTrusted = 'error';
        if (showWarning) {
          this.notify.error("Dealerul are facturi scadente mai vechi de 7 zile")
        }
      } else if (invoicePaymentDeadline < 7 && invoicePaymentDeadline > 2) {
        if (showWarning) {
          this.notify.warning("Dealerul are facturi scadente intre 3 si 7 zile")
        }
        this.isDealerTrusted = 'ok';
      } else {
        this.isDealerTrusted = 'ok';
      }
    } else {
      this.isDealerTrusted = 'ok';
    }
    if (response["creditValue"] < response["soldValue"] + this.totalPrice) {
      this.notify.warning("Limita Credit depasita");
      this.isDealerTrusted = 'error';
    }
  }
  checkCredit() {
    if (this.offerData.invoiceType == "Dealer") {
      if (this.creditValue < this.soldValue + this.totalPrice) {
        this.notify.warning("Limita Credit depasita");
        this.isDealerTrusted = 'error';
      }
    }
  }
  checkAdvancePayment(event) {
    if (!this.offerData.advancePaymentValue) {
      //69=point 190 = e 187=add 189=substrat 107 = add + num lk 109 = substract + num lk
      if (event && (event.keyCode == 69 || event.keyCode == 190 || event.keyCode == 187 || event.keyCode == 189 || event.keyCode == 107 || event.keyCode == 109)) {
        this.notify.warning('Caracter neacceptat')
        this.offerData.advancePaymentValue = '';

        event.preventDefault();
        return;
      }
    }
  }
  checkCommandProcentual() {
    let priceRoofingSheets = 0;
    let percentRoofingSheets;
    for (let category of this.offerData.categories) {
      for (let article of category.articles) {
        if (article.totalQuantity && article.data[0].PROMOTIE == 1 && (article.articleName.includes("TIGLA METALICA") || article.articleName.includes("TABLA CLICK"))) {
          priceRoofingSheets += article.totalQuantity * article.data[0].agentPrice;
        }
      }
    }
    percentRoofingSheets = this.round2Decimals((priceRoofingSheets * 100) / this.totalPrice);
    if (percentRoofingSheets < 60 && percentRoofingSheets > 0) {

      console.log()
      // nu se poate da comanda/ nu se salveaza oferta
      this.notify.error('Oferta contine mai putin de 60% tigla/tabla!')
      this.notify.error('Repartizati cantitatea pe artiolul nepromotional!')
      return true;
    } else if (priceRoofingSheets == 0) {
      // se poate da comanda/ se salveaza oferta
      return false;
    } else if (percentRoofingSheets > 60) {
      // se poate da comanda/ se salveaza oferta
      //this.notify.info('Oferta');
      return false;
    }
  }

  getModule(d1, d2) {
    return this.bilkaService.getModule(d1, d2);
  }
}

