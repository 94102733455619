<div class="signin-content">
  <mat-card>
    <mat-card-content>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" id="loginForm">
        <div>
          <!-- <mat-spinner></mat-spinner> -->
          <div class="appTitle">ENODE <span [style.color]="color">{{type}}</span> </div>

        </div>


        <!-- <span class="p-input-icon-left p-float-label">
          <svg style="width:30px" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 464.001 464.001"
            style="enable-background:new 0 0 464.001 464.001;" xml:space="preserve">
            <g>
              <path style="fill:#FFD7A3;" d="M388.628,437.023l-10.126-81.012c-1.529-12.228-9.943-22.492-21.633-26.389l-39.047-13.016
             c-5.362-1.788-11.244-0.649-15.379,2.874l-11.502-3.834c-6.533-2.178-10.94-8.292-10.94-15.179v-25.802l34.461-14.359
             C332.349,252.855,344,235.378,344,216v-8c13.255,0,24-10.745,24-24c0-5.979-2.191-11.443-5.808-15.645
             C368.983,143.637,388.07,65.449,368,32l8-32c0,0-84,2-128,16l16-16c0,0-112.5,0-135.993,39.993L128,40c0,0-40-0.333-40,48
             c0,17.69,9.867,61.862,14.103,80.015C98.31,172.259,96,177.859,96,184c0,13.255,10.745,24,24,24v8
             c0,19.377,11.651,36.854,29.538,44.308L184,274.667v25.801c0,6.887-4.407,13.001-10.94,15.179l-11.502,3.834
             c-4.135-3.523-10.017-4.662-15.379-2.874l-39.047,13.016c-11.691,3.897-20.105,14.161-21.633,26.389l-10.126,81.012
             C73.582,451.348,84.751,464,99.187,464h0.225h265.176h0.225C379.249,464,390.419,451.348,388.628,437.023z" />
              <g>
                <path style="fill:#FDC88E;" d="M280,274.667l-96,0.001v25.801c0,5.759-3.186,10.831-8.017,13.659
               C243.077,325.871,280,274.667,280,274.667z" />
              </g>
              <g>
                <path style="fill:#734A3E;" d="M104,176c0,0-16-65.474-16-88c0-48.333,40-48,40-48l0.007-0.007C151.5,0,264,0,264,0l-16,16
               C292,2,376,0,376,0l-8,32c24,40-8,144-8,144H104z" />
              </g>
              <g>
                <path style="fill:#623F33;" d="M264,0c0,0-112.5,0-135.993,39.993L128,40c0,0-40-0.333-40,48c0,22.526,16,88,16,88
               S270.334,101.667,376,0c0,0-84,2-128,16L264,0z" />
              </g>
              <path style="fill:#FFE1B2;" d="M344,160v-16c0-22.091-17.909-40-40-40H160c-22.091,0-40,17.909-40,40v16c-13.255,0-24,10.745-24,24
             s10.745,24,24,24v8c0,19.377,11.651,36.854,29.538,44.308l51.691,21.538c9.75,4.063,20.208,6.154,30.77,6.154l0,0
             c10.562,0,21.019-2.092,30.769-6.154l51.694-21.539C332.349,252.855,344,235.378,344,216v-8c13.255,0,24-10.745,24-24
             S357.255,160,344,160z" />
              <g>
                <path style="fill:#623F33;" d="M176,192L176,192c-4.4,0-8-3.6-8-8v-8c0-4.4,3.6-8,8-8l0,0c4.4,0,8,3.6,8,8v8
               C184,188.4,180.4,192,176,192z" />
              </g>
              <g>
                <path style="fill:#623F33;" d="M288,192L288,192c-4.4,0-8-3.6-8-8v-8c0-4.4,3.6-8,8-8l0,0c4.4,0,8,3.6,8,8v8
               C296,188.4,292.4,192,288,192z" />
              </g>
              <g>
                <g>
                  <path style="fill:#E4B07B;"
                    d="M232,248.219c-14.223,0-27.527-3.5-36.5-9.605c-3.652-2.484-4.602-7.461-2.113-11.113
                 c2.48-3.648,7.461-4.598,11.113-2.113c6.289,4.277,16.57,6.832,27.5,6.832s21.211-2.555,27.5-6.832
                 c3.66-2.492,8.629-1.539,11.113,2.113c2.488,3.652,1.539,8.629-2.113,11.113C259.528,244.719,246.223,248.219,232,248.219z" />
                </g>
              </g>
              <g>
                <path style="fill:#00BEA4;" d="M388.628,437.024l-10.126-81.012c-1.528-12.228-9.943-22.492-21.633-26.389l-39.047-13.016
               c-6.8-2.267-14.45,0.154-18.291,6.206C285.349,345.157,260.424,360,232,360s-53.349-14.844-67.53-37.187
               c-3.841-6.052-11.491-8.473-18.291-6.206l-39.047,13.016c-11.691,3.897-20.105,14.161-21.633,26.389l-10.126,81.012
               c-1.791,14.324,9.379,26.977,23.815,26.977h265.626C379.249,464.001,390.419,451.348,388.628,437.024z" />
              </g>
              <g>
                <path style="fill:#00AB9A;" d="M92.168,340.168c-3.552,4.497-5.93,9.93-6.669,15.843l-10.126,81.012
               C73.582,451.348,84.752,464,99.188,464H144v-52.067c0-7.697-2.774-15.135-7.813-20.953L92.168,340.168z" />
              </g>
              <g>
                <path style="fill:#00AB9A;"
                  d="M371.832,340.168c3.552,4.497,5.93,9.93,6.669,15.843l10.126,81.012
               c1.791,14.325-9.379,26.977-23.815,26.977H320v-52.067c0-7.697,2.774-15.135,7.813-20.953L371.832,340.168z" />
              </g>
              <path style="fill:#FFD7A3;" d="M161.996,243.723c-6.482-6.95-9.995-16.121-9.995-25.625v-90.66
             c25.463-1.909,105.744-8.852,152-23.438H160c-2.754,0-5.421,0.321-8,0.881v-0.075c-18.257,3.707-32,19.843-32,39.194v16
             c-13.255,0-24,10.745-24,24s10.745,24,24,24v8c0,19.378,11.651,36.855,29.538,44.308l51.69,21.538
             c2.53,1.054,5.112,1.962,7.727,2.749C186.111,267.883,170.905,253.274,161.996,243.723z" />
            </g>
          </svg>
          <input inputId="autocomplete" type="text" pInputText placeholder="Search">
          <label for="autocomplete">AutoComplete</label>
        </span> -->


        <mat-form-field class="full-width-input">
          <div matPrefix class="loginFormSvg">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 464.001 464.001" style="enable-background:new 0 0 464.001 464.001;"
              xml:space="preserve">
              <g>
                <path style="fill:#FFD7A3;" d="M388.628,437.023l-10.126-81.012c-1.529-12.228-9.943-22.492-21.633-26.389l-39.047-13.016
             c-5.362-1.788-11.244-0.649-15.379,2.874l-11.502-3.834c-6.533-2.178-10.94-8.292-10.94-15.179v-25.802l34.461-14.359
             C332.349,252.855,344,235.378,344,216v-8c13.255,0,24-10.745,24-24c0-5.979-2.191-11.443-5.808-15.645
             C368.983,143.637,388.07,65.449,368,32l8-32c0,0-84,2-128,16l16-16c0,0-112.5,0-135.993,39.993L128,40c0,0-40-0.333-40,48
             c0,17.69,9.867,61.862,14.103,80.015C98.31,172.259,96,177.859,96,184c0,13.255,10.745,24,24,24v8
             c0,19.377,11.651,36.854,29.538,44.308L184,274.667v25.801c0,6.887-4.407,13.001-10.94,15.179l-11.502,3.834
             c-4.135-3.523-10.017-4.662-15.379-2.874l-39.047,13.016c-11.691,3.897-20.105,14.161-21.633,26.389l-10.126,81.012
             C73.582,451.348,84.751,464,99.187,464h0.225h265.176h0.225C379.249,464,390.419,451.348,388.628,437.023z" />
                <g>
                  <path style="fill:#FDC88E;" d="M280,274.667l-96,0.001v25.801c0,5.759-3.186,10.831-8.017,13.659
               C243.077,325.871,280,274.667,280,274.667z" />
                </g>
                <g>
                  <path style="fill:#734A3E;" d="M104,176c0,0-16-65.474-16-88c0-48.333,40-48,40-48l0.007-0.007C151.5,0,264,0,264,0l-16,16
               C292,2,376,0,376,0l-8,32c24,40-8,144-8,144H104z" />
                </g>
                <g>
                  <path style="fill:#623F33;" d="M264,0c0,0-112.5,0-135.993,39.993L128,40c0,0-40-0.333-40,48c0,22.526,16,88,16,88
               S270.334,101.667,376,0c0,0-84,2-128,16L264,0z" />
                </g>
                <path style="fill:#FFE1B2;" d="M344,160v-16c0-22.091-17.909-40-40-40H160c-22.091,0-40,17.909-40,40v16c-13.255,0-24,10.745-24,24
             s10.745,24,24,24v8c0,19.377,11.651,36.854,29.538,44.308l51.691,21.538c9.75,4.063,20.208,6.154,30.77,6.154l0,0
             c10.562,0,21.019-2.092,30.769-6.154l51.694-21.539C332.349,252.855,344,235.378,344,216v-8c13.255,0,24-10.745,24-24
             S357.255,160,344,160z" />
                <g>
                  <path style="fill:#623F33;" d="M176,192L176,192c-4.4,0-8-3.6-8-8v-8c0-4.4,3.6-8,8-8l0,0c4.4,0,8,3.6,8,8v8
               C184,188.4,180.4,192,176,192z" />
                </g>
                <g>
                  <path style="fill:#623F33;" d="M288,192L288,192c-4.4,0-8-3.6-8-8v-8c0-4.4,3.6-8,8-8l0,0c4.4,0,8,3.6,8,8v8
               C296,188.4,292.4,192,288,192z" />
                </g>
                <g>
                  <g>
                    <path style="fill:#E4B07B;"
                      d="M232,248.219c-14.223,0-27.527-3.5-36.5-9.605c-3.652-2.484-4.602-7.461-2.113-11.113
                 c2.48-3.648,7.461-4.598,11.113-2.113c6.289,4.277,16.57,6.832,27.5,6.832s21.211-2.555,27.5-6.832
                 c3.66-2.492,8.629-1.539,11.113,2.113c2.488,3.652,1.539,8.629-2.113,11.113C259.528,244.719,246.223,248.219,232,248.219z" />
                  </g>
                </g>
                <g>
                  <path style="fill:#00BEA4;" d="M388.628,437.024l-10.126-81.012c-1.528-12.228-9.943-22.492-21.633-26.389l-39.047-13.016
               c-6.8-2.267-14.45,0.154-18.291,6.206C285.349,345.157,260.424,360,232,360s-53.349-14.844-67.53-37.187
               c-3.841-6.052-11.491-8.473-18.291-6.206l-39.047,13.016c-11.691,3.897-20.105,14.161-21.633,26.389l-10.126,81.012
               c-1.791,14.324,9.379,26.977,23.815,26.977h265.626C379.249,464.001,390.419,451.348,388.628,437.024z" />
                </g>
                <g>
                  <path style="fill:#00AB9A;" d="M92.168,340.168c-3.552,4.497-5.93,9.93-6.669,15.843l-10.126,81.012
               C73.582,451.348,84.752,464,99.188,464H144v-52.067c0-7.697-2.774-15.135-7.813-20.953L92.168,340.168z" />
                </g>
                <g>
                  <path style="fill:#00AB9A;"
                    d="M371.832,340.168c3.552,4.497,5.93,9.93,6.669,15.843l10.126,81.012
               c1.791,14.325-9.379,26.977-23.815,26.977H320v-52.067c0-7.697,2.774-15.135,7.813-20.953L371.832,340.168z" />
                </g>
                <path style="fill:#FFD7A3;" d="M161.996,243.723c-6.482-6.95-9.995-16.121-9.995-25.625v-90.66
             c25.463-1.909,105.744-8.852,152-23.438H160c-2.754,0-5.421,0.321-8,0.881v-0.075c-18.257,3.707-32,19.843-32,39.194v16
             c-13.255,0-24,10.745-24,24s10.745,24,24,24v8c0,19.378,11.651,36.855,29.538,44.308l51.69,21.538
             c2.53,1.054,5.112,1.962,7.727,2.749C186.111,267.883,170.905,253.274,161.996,243.723z" />
              </g>
            </svg>
          </div>
          <input *ngIf="!keyboardActive" matInput placeholder="username@client" formControlName="username" required>
          <input *ngIf="keyboardActive" ng-virtual-keyboard ng-virtual-keyboard-layout="extended"
            ng-virtual-keyboard-placeholder="username@client" matInput placeholder="username@client"
            formControlName="username" required>
          <mat-error *ngIf="isFieldInvalid('username')">
            username@client {{'app.not-valid' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <div matPrefix class="loginFormSvg">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <path style="fill:#C8C6CD;" d="M343.75,87.75v67.63h-30V87.75C313.75,55.91,287.84,30,256,30s-57.75,25.91-57.75,57.75v67.63h-30
           V87.75C168.25,39.36,207.61,0,256,0S343.75,39.36,343.75,87.75z" />
              <path style="fill:#AEADB3;"
                d="M256,30V0c48.39,0,87.75,39.36,87.75,87.75v67.63h-30V87.75C313.75,55.91,287.84,30,256,30z" />
              <rect x="59.33" y="383" style="fill:#00D9E0;" width="393.34" height="129" />
              <rect x="256" y="383" style="fill:#00B7BE;" width="196.67" height="129" />
              <rect x="138.23" y="140.38" style="fill:#FFB74F;" width="235.54" height="203.22" />
              <g>
                <rect x="120.5" y="433.4" style="fill:#008878;" width="31" height="31" />
                <rect x="180.5" y="433.4" style="fill:#008878;" width="31" height="31" />
                <rect x="240.5" y="433.4" style="fill:#008878;" width="31" height="31" />
                <rect x="300.5" y="433.4" style="fill:#008878;" width="31" height="31" />
                <rect x="360.5" y="433.4" style="fill:#008878;" width="31" height="31" />
              </g>
              <g>
                <rect x="256" y="433.4" style="fill:#006659;" width="15.5" height="31" />
                <rect x="300.5" y="433.4" style="fill:#006659;" width="31" height="31" />
              </g>
              <rect x="256" y="140.38" style="fill:#FF9A00;" width="117.77" height="203.22" />
              <rect x="360.5" y="433.4" style="fill:#006659;" width="31" height="31" />
              <path style="fill:#008878;" d="M278.5,226.5c0,6.66-2.9,12.64-7.5,16.76v35.41h-30v-35.41c-4.6-4.12-7.5-10.1-7.5-16.76
           c0-12.43,10.08-22.5,22.5-22.5C268.42,204,278.5,214.07,278.5,226.5z" />
              <path style="fill:#006659;"
                d="M271,278.67h-15V204c12.42,0,22.5,10.07,22.5,22.5c0,6.66-2.9,12.64-7.5,16.76V278.67z" />
            </svg>
          </div>
          <input *ngIf="keyboardActive" ng-virtual-keyboard ng-virtual-keyboard-layout="extended"
            ng-virtual-keyboard-placeholder="{{'app.password' | translate}}" matInput type="password"
            placeholder="{{'app.password' | translate}}" formControlName="password" required>
          <input *ngIf="!keyboardActive" matInput type="password" placeholder="{{'app.password' | translate}}"
            formControlName="password" required>


          <mat-error *ngIf="isFieldInvalid('password')">
            {{'app.enter-password' | translate}}
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" class="login darkBlue" type="submit" id="btnOnSubmit">Login</button>
      </form>
    </mat-card-content>
  </mat-card>

  <div *ngIf="showKeyboard() && keyboardActive" (click)="keyboardActive=!keyboardActive" class="keyboardSvg">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
      <path style="fill:#4A5A5E;" d="M255.457,269.8c-4.215,0-7.63-3.416-7.63-7.63v-55.118c0-20.376,16.578-36.954,36.954-36.954h13.585
c11.962,0,21.694-9.732,21.694-21.694v-20.707c0-11.962-9.732-21.694-21.694-21.694h-12.651c-20.376,0-36.954-16.578-36.954-36.954
V9.506c0-4.215,3.416-7.63,7.63-7.63c4.215,0,7.63,3.416,7.63,7.63V69.05c0,11.962,9.732,21.694,21.694,21.694h12.651
c20.376,0,36.954,16.578,36.954,36.954v20.707c0,20.376-16.578,36.954-36.954,36.954h-13.585c-11.962,0-21.694,9.732-21.694,21.694
v55.118C263.087,266.384,259.672,269.8,255.457,269.8z" />
      <path style="fill:#1BCECE;" d="M273.845,220.08h-36.038c-4.706,0-8.521,3.815-8.521,8.521v31.4c0,4.706,3.815,8.521,8.521,8.521
h36.038c4.706,0,8.521-3.815,8.521-8.521v-31.4C282.366,223.895,278.551,220.08,273.845,220.08z" />
      <path style="fill:#1BCECE;" d="M491.061,510.124H20.939C9.374,510.124,0,500.75,0,489.186V275.724
c0-11.564,9.374-20.939,20.939-20.939h470.123c11.564,0,20.939,9.374,20.939,20.939v213.462
C512,500.75,502.625,510.124,491.061,510.124z" />
      <path style="fill:#1BCECE;" d="M491.061,254.786h-34.544c1.081,2.526,1.682,5.305,1.682,8.227v213.462
c0,11.564-9.375,20.938-20.939,20.938H1.682c3.197,7.473,10.613,12.712,19.256,12.712h470.123c11.564,0,20.939-9.375,20.939-20.939
V275.724C512,264.16,502.625,254.786,491.061,254.786z" />
      <g>
        <path style="fill:#FFFFFF;" d="M72.4,332.158H38.27c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
H72.4c2.638,0,4.777,2.139,4.777,4.777v31.409C77.177,330.019,75.038,332.158,72.4,332.158z" />
        <path style="fill:#FFFFFF;" d="M139.288,332.158h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C144.065,330.019,141.927,332.158,139.288,332.158z" />
        <path style="fill:#FFFFFF;" d="M206.177,332.158h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C210.954,330.019,208.815,332.158,206.177,332.158z" />
        <path style="fill:#FFFFFF;" d="M273.065,332.158h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C277.842,330.019,275.703,332.158,273.065,332.158z" />
        <path style="fill:#FFFFFF;" d="M339.954,332.158h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C344.731,330.019,342.592,332.158,339.954,332.158z" />
        <path style="fill:#FFFFFF;" d="M406.842,332.158h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C411.619,330.019,409.48,332.158,406.842,332.158z" />
        <path style="fill:#FFFFFF;" d="M473.73,332.158H439.6c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C478.508,330.019,476.369,332.158,473.73,332.158z" />
        <path style="fill:#FFFFFF;" d="M101.635,406.373H38.27c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h63.366c2.638,0,4.777,2.139,4.777,4.777v31.409C106.412,404.234,104.274,406.373,101.635,406.373z" />
        <path style="fill:#FFFFFF;" d="M473.73,406.373h-63.366c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h63.366c2.638,0,4.777,2.139,4.777,4.777v31.409C478.508,404.234,476.369,406.373,473.73,406.373z" />
        <path style="fill:#FFFFFF;" d="M169.801,406.373h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C174.578,404.234,172.44,406.373,169.801,406.373z" />
        <path style="fill:#FFFFFF;" d="M238.644,406.373h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C243.421,404.234,241.282,406.373,238.644,406.373z" />
        <path style="fill:#FFFFFF;" d="M307.486,406.373h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C312.264,404.234,310.125,406.373,307.486,406.373z" />
        <path style="fill:#FFFFFF;" d="M376.329,406.373h-34.13c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C381.106,404.234,378.967,406.373,376.329,406.373z" />
        <path style="fill:#FFFFFF;" d="M400.81,480.588H111.19c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h289.62c2.638,0,4.777,2.139,4.777,4.777v31.409C405.587,478.449,403.449,480.588,400.81,480.588z" />
        <path style="fill:#FFFFFF;" d="M72.4,480.588H38.27c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
H72.4c2.638,0,4.777,2.139,4.777,4.777v31.409C77.177,478.449,75.038,480.588,72.4,480.588z" />
        <path style="fill:#FFFFFF;" d="M473.73,480.588H439.6c-2.638,0-4.777-2.139-4.777-4.777v-31.409c0-2.638,2.139-4.777,4.777-4.777
h34.13c2.638,0,4.777,2.139,4.777,4.777v31.409C478.508,478.449,476.369,480.588,473.73,480.588z" />
      </g>
    </svg>
  </div>
  <div *ngIf="showKeyboard() && !keyboardActive" (click)="keyboardActive=!keyboardActive" class="keyboardSvg">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 409.6008 409.6008"
      version="1.1">
      <g id="surface1">
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(34%,34%,34%);fill-opacity:1;"
          d="M 204.367188 215.839844 C 200.992188 215.839844 198.261719 213.105469 198.261719 209.734375 L 198.261719 165.640625 C 198.261719 149.339844 211.523438 136.078125 227.824219 136.078125 L 238.691406 136.078125 C 248.261719 136.078125 256.046875 128.292969 256.046875 118.722656 L 256.046875 102.15625 C 256.046875 92.589844 248.261719 84.800781 238.691406 84.800781 L 228.570312 84.800781 C 212.269531 84.800781 199.007812 71.539062 199.007812 55.238281 L 199.007812 7.605469 C 199.007812 4.234375 201.742188 1.5 205.113281 1.5 C 208.484375 1.5 211.214844 4.234375 211.214844 7.605469 L 211.214844 55.238281 C 211.214844 64.808594 219.003906 72.59375 228.570312 72.59375 L 238.691406 72.59375 C 254.992188 72.59375 268.257812 85.859375 268.257812 102.160156 L 268.257812 118.722656 C 268.257812 135.023438 254.992188 148.289062 238.691406 148.289062 L 227.824219 148.289062 C 218.253906 148.289062 210.46875 156.074219 210.46875 165.640625 L 210.46875 209.738281 C 210.46875 213.105469 207.738281 215.839844 204.367188 215.839844 Z M 204.367188 215.839844 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,60%);fill-opacity:1;"
          d="M 219.074219 176.0625 L 190.246094 176.0625 C 186.480469 176.0625 183.429688 179.117188 183.429688 182.878906 L 183.429688 208 C 183.429688 211.765625 186.480469 214.816406 190.246094 214.816406 L 219.074219 214.816406 C 222.839844 214.816406 225.894531 211.765625 225.894531 208 L 225.894531 182.878906 C 225.894531 179.117188 222.839844 176.0625 219.074219 176.0625 Z M 219.074219 176.0625 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(91%,91%,91%);fill-opacity:1;"
          d="M 392.847656 408.097656 L 16.75 408.097656 C 7.5 408.097656 0 400.601562 0 391.347656 L 0 220.578125 C 0 211.328125 7.5 203.828125 16.75 203.828125 L 392.847656 203.828125 C 402.101562 203.828125 409.601562 211.328125 409.601562 220.578125 L 409.601562 391.347656 C 409.601562 400.601562 402.101562 408.097656 392.847656 408.097656 Z M 392.847656 408.097656 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,80%,80%);fill-opacity:1;"
          d="M 392.847656 203.828125 L 365.214844 203.828125 C 366.078125 205.847656 366.558594 208.074219 366.558594 210.410156 L 366.558594 381.179688 C 366.558594 390.429688 359.058594 397.929688 349.808594 397.929688 L 1.34375 397.929688 C 3.902344 403.910156 9.835938 408.101562 16.75 408.101562 L 392.847656 408.101562 C 402.101562 408.101562 409.601562 400.601562 409.601562 391.347656 L 409.601562 220.578125 C 409.601562 211.328125 402.101562 203.828125 392.847656 203.828125 Z M 392.847656 203.828125 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 57.921875 265.726562 L 30.617188 265.726562 C 28.503906 265.726562 26.792969 264.015625 26.792969 261.90625 L 26.792969 236.777344 C 26.792969 234.667969 28.503906 232.957031 30.617188 232.957031 L 57.921875 232.957031 C 60.03125 232.957031 61.742188 234.667969 61.742188 236.777344 L 61.742188 261.90625 C 61.742188 264.015625 60.03125 265.726562 57.921875 265.726562 Z M 57.921875 265.726562 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 111.429688 265.726562 L 84.125 265.726562 C 82.015625 265.726562 80.304688 264.015625 80.304688 261.90625 L 80.304688 236.777344 C 80.304688 234.667969 82.015625 232.957031 84.125 232.957031 L 111.429688 232.957031 C 113.539062 232.957031 115.253906 234.667969 115.253906 236.777344 L 115.253906 261.90625 C 115.253906 264.015625 113.542969 265.726562 111.429688 265.726562 Z M 111.429688 265.726562 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 164.941406 265.726562 L 137.636719 265.726562 C 135.527344 265.726562 133.816406 264.015625 133.816406 261.90625 L 133.816406 236.777344 C 133.816406 234.667969 135.527344 232.957031 137.636719 232.957031 L 164.941406 232.957031 C 167.050781 232.957031 168.761719 234.667969 168.761719 236.777344 L 168.761719 261.90625 C 168.761719 264.015625 167.050781 265.726562 164.941406 265.726562 Z M 164.941406 265.726562 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 218.453125 265.726562 L 191.148438 265.726562 C 189.039062 265.726562 187.328125 264.015625 187.328125 261.90625 L 187.328125 236.777344 C 187.328125 234.667969 189.039062 232.957031 191.148438 232.957031 L 218.453125 232.957031 C 220.5625 232.957031 222.273438 234.667969 222.273438 236.777344 L 222.273438 261.90625 C 222.273438 264.015625 220.5625 265.726562 218.453125 265.726562 Z M 218.453125 265.726562 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 271.964844 265.726562 L 244.660156 265.726562 C 242.546875 265.726562 240.835938 264.015625 240.835938 261.90625 L 240.835938 236.777344 C 240.835938 234.667969 242.546875 232.957031 244.660156 232.957031 L 271.964844 232.957031 C 274.074219 232.957031 275.785156 234.667969 275.785156 236.777344 L 275.785156 261.90625 C 275.785156 264.015625 274.074219 265.726562 271.964844 265.726562 Z M 271.964844 265.726562 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 325.472656 265.726562 L 298.167969 265.726562 C 296.058594 265.726562 294.347656 264.015625 294.347656 261.90625 L 294.347656 236.777344 C 294.347656 234.667969 296.058594 232.957031 298.167969 232.957031 L 325.472656 232.957031 C 327.585938 232.957031 329.296875 234.667969 329.296875 236.777344 L 329.296875 261.90625 C 329.296875 264.015625 327.585938 265.726562 325.472656 265.726562 Z M 325.472656 265.726562 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 378.984375 265.726562 L 351.679688 265.726562 C 349.570312 265.726562 347.859375 264.015625 347.859375 261.90625 L 347.859375 236.777344 C 347.859375 234.667969 349.570312 232.957031 351.679688 232.957031 L 378.984375 232.957031 C 381.09375 232.957031 382.804688 234.667969 382.804688 236.777344 L 382.804688 261.90625 C 382.804688 264.015625 381.09375 265.726562 378.984375 265.726562 Z M 378.984375 265.726562 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 81.308594 325.097656 L 30.617188 325.097656 C 28.503906 325.097656 26.792969 323.386719 26.792969 321.277344 L 26.792969 296.148438 C 26.792969 294.039062 28.503906 292.328125 30.617188 292.328125 L 81.308594 292.328125 C 83.417969 292.328125 85.128906 294.039062 85.128906 296.148438 L 85.128906 321.277344 C 85.128906 323.386719 83.417969 325.097656 81.308594 325.097656 Z M 81.308594 325.097656 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 378.984375 325.097656 L 328.292969 325.097656 C 326.179688 325.097656 324.46875 323.386719 324.46875 321.277344 L 324.46875 296.148438 C 324.46875 294.039062 326.179688 292.328125 328.292969 292.328125 L 378.984375 292.328125 C 381.09375 292.328125 382.804688 294.039062 382.804688 296.148438 L 382.804688 321.277344 C 382.804688 323.386719 381.09375 325.097656 378.984375 325.097656 Z M 378.984375 325.097656 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 135.839844 325.097656 L 108.535156 325.097656 C 106.425781 325.097656 104.714844 323.386719 104.714844 321.277344 L 104.714844 296.148438 C 104.714844 294.039062 106.425781 292.328125 108.535156 292.328125 L 135.839844 292.328125 C 137.953125 292.328125 139.664062 294.039062 139.664062 296.148438 L 139.664062 321.277344 C 139.664062 323.386719 137.953125 325.097656 135.839844 325.097656 Z M 135.839844 325.097656 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 190.914062 325.097656 L 163.609375 325.097656 C 161.5 325.097656 159.789062 323.386719 159.789062 321.277344 L 159.789062 296.148438 C 159.789062 294.039062 161.5 292.328125 163.609375 292.328125 L 190.914062 292.328125 C 193.027344 292.328125 194.738281 294.039062 194.738281 296.148438 L 194.738281 321.277344 C 194.738281 323.386719 193.027344 325.097656 190.914062 325.097656 Z M 190.914062 325.097656 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 245.988281 325.097656 L 218.683594 325.097656 C 216.574219 325.097656 214.863281 323.386719 214.863281 321.277344 L 214.863281 296.148438 C 214.863281 294.039062 216.574219 292.328125 218.683594 292.328125 L 245.988281 292.328125 C 248.097656 292.328125 249.808594 294.039062 249.808594 296.148438 L 249.808594 321.277344 C 249.8125 323.386719 248.101562 325.097656 245.988281 325.097656 Z M 245.988281 325.097656 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 301.0625 325.097656 L 273.757812 325.097656 C 271.648438 325.097656 269.9375 323.386719 269.9375 321.277344 L 269.9375 296.148438 C 269.9375 294.039062 271.648438 292.328125 273.757812 292.328125 L 301.0625 292.328125 C 303.171875 292.328125 304.886719 294.039062 304.886719 296.148438 L 304.886719 321.277344 C 304.886719 323.386719 303.171875 325.097656 301.0625 325.097656 Z M 301.0625 325.097656 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 320.648438 384.46875 L 88.953125 384.46875 C 86.839844 384.46875 85.128906 382.757812 85.128906 380.648438 L 85.128906 355.523438 C 85.128906 353.410156 86.839844 351.699219 88.953125 351.699219 L 320.648438 351.699219 C 322.757812 351.699219 324.46875 353.410156 324.46875 355.523438 L 324.46875 380.648438 C 324.46875 382.757812 322.757812 384.46875 320.648438 384.46875 Z M 320.648438 384.46875 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 57.921875 384.46875 L 30.617188 384.46875 C 28.503906 384.46875 26.792969 382.757812 26.792969 380.648438 L 26.792969 355.523438 C 26.792969 353.410156 28.503906 351.699219 30.617188 351.699219 L 57.921875 351.699219 C 60.03125 351.699219 61.742188 353.410156 61.742188 355.523438 L 61.742188 380.648438 C 61.742188 382.757812 60.03125 384.46875 57.921875 384.46875 Z M 57.921875 384.46875 " />
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
          d="M 378.984375 384.46875 L 351.679688 384.46875 C 349.570312 384.46875 347.859375 382.757812 347.859375 380.648438 L 347.859375 355.523438 C 347.859375 353.410156 349.570312 351.699219 351.679688 351.699219 L 378.984375 351.699219 C 381.09375 351.699219 382.804688 353.410156 382.804688 355.523438 L 382.804688 380.648438 C 382.804688 382.757812 381.09375 384.46875 378.984375 384.46875 Z M 378.984375 384.46875 " />
      </g>
    </svg>


  </div>
  <div class="refresh">
    <p-button style="margin: 0 5px;" (click)="refr()" icon="pi pi-refresh" iconPos="left"></p-button>
  </div>

  <div class="loginFooter">
    V {{version}} © {{madeBy}}
    <!-- <button style="width:200px;" mat-raised-button color="primary" class="login darkBlue" type="submit" id="btnOnSubmit"
      (click)="refr()">Refresh</button> -->

  </div>


  <div *ngIf="loading" id="preloader">
    <div id="loader"></div>
    <div id="message">SE ADUC DATE. VA RUGAM ASTEPTATI!</div>

    <style type="text/css">
      #preloader {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: white;
      }

      #loader {
        display: block;
        position: relative;
        left: 50%;
        top: 45%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #9370DB;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }

      #message {
        font-size: 30px;
        display: block;
        position: relative;
        text-align: center;
        margin: auto;
        top: 50%;
        color: #536c79;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      }

      #loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #BA55D3;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
      }

      #loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #FF00FF;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
      }

      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    </style>
  </div>
</div>