import { Injectable, Inject } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { NotifyService } from '../helpers/notify.service';


@Injectable({
    providedIn: 'root'
})
export class OfferGuard implements CanActivate {

    constructor(public router: Router, @Inject('LOCALSTORAGE') private store: any, private notify: NotifyService) { }

    canActivate(route: ActivatedRouteSnapshot): any {
        let user = this.store.get('localuser');
        let decoded;

        if (route.params.token) {
            //from ecrm
            decoded = this.decodeToken(route.params.token);
        }
        else {
            decoded = this.decodeToken(user.token);
        }
        if (decoded.company == "caretta" || decoded.company == "carettatest") {
            this.router.navigate(['offerCaretta', route.params]);
            return true;
        }
        if (decoded.company == "dolinex" || decoded.company == "dolitest") {
            this.router.navigate(['offerDolinex', route.params]);
            return true;
        }
        if (decoded.company == "inter" || decoded.company == "intertranstest") {
            this.router.navigate(['offerInter', route.params]);
            return true;
        }
        if (decoded.company == "gaitano" || decoded.company == "gaitanotest") {
            this.router.navigate(['offerGaitano', route.params]);
            return true;
        }
        if (decoded.company == "domtest" || decoded.company == "multidom") {
            this.router.navigate(['offerMultidom', route.params]);
            return true;
        }

        if (decoded.company == "globaltest" || decoded.company == "globaldesign") {
            this.router.navigate(['offerGlobal', route.params]);
            return true;
        }


        this.notify.errorMessage("Eroare selectie componenta oferta!")
        return false;
    }

    decodeToken(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
}