import { Directive, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject, fromEvent } from 'rxjs';
import { map, debounceTime, takeUntil, distinctUntilChanged } from 'rxjs/operators';
@Directive({
  selector: '[ngModel][debounce]',
})
export class DebounceDirective implements OnDestroy {
  @Output()
  public onDebounce = new EventEmitter<any>();

  @Input('debounce')
  public debounceTime: number = 500;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(public model: NgControl, private elementRef: ElementRef) {
  }

  ngOnInit() {

    fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
      map(() => this.model.value),
      takeUntil(this.ngUnsubscribe),
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(modelValue => {
      if (modelValue && modelValue.length > 2) {
        this.onDebounce.emit(modelValue)
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
