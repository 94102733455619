<div (click)="showModal=true">OBS</div>
<p-dialog *ngIf="showModal" [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false"
    [modal]="true" [(visible)]="showModal">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">{{article.DENUMIRE}}</span>
        <div class="closeSvgRight" (click)="showModal=false" [inlineSVG]="'/assets/images/close.svg'">
        </div>
    </p-header>
    <mat-form-field class="noLabel" style="width:100%">
        <textarea style="height: 90px;" [(ngModel)]="article.observations" matInput
            placeholder="Observatii articol!"></textarea>
    </mat-form-field>

    <!-- <div class="p-mt-4">
        <div class="p-float-label" [style]="{'width':'100%'}">
            <textarea
                inputId="obs" [rows]="2" [cols]="30" autoResize="autoResize" pInputTextarea
                [(ngModel)]="article.observations"></textarea>
            <label for="obs">Observatii</label>
        </div>
    </div> -->
    <div (click)="showModal=false;saveObs()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>
</p-dialog>