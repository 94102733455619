<div class="keyboard-container">
    <ul id="keyboard">

        <li (click)="handleKey(7)" class="letter clearl">7</li>
        <li (click)="handleKey(8)" class="letter ">8</li>
        <li (click)="handleKey(9)" class="letter">9</li>

        <li class="return" (click)="handleKey('remove')">
            <div class="actionSvg" [inlineSVG]="'/assets/images/backspace.svg'"></div>
        </li>

        <li (click)="handleKey(4)" class="letter clearl">4</li>
        <li (click)="handleKey(5)" class="letter">5</li>
        <li (click)="handleKey(6)" class="letter">6</li>

        <li class="return" (click)="handleKey('clear')">Sterge</li>

        <li (click)="handleKey(1)" class="letter clearl">1</li>
        <li (click)="handleKey(2)" class="letter">2</li>
        <li (click)="handleKey(3)" class="letter">3</li>

        <li *ngIf="type=='number'" (click)="handleKey('scale')" class="return">
            <div class="actionSvg" [inlineSVG]="'/assets/images/scale.svg'"></div>
        </li>
        <li *ngIf="type=='letter'" (click)="handleKey('RO')" class="return">RO</li>

        <!-- <li *ngIf="type=='password'" (click)="handleKey('')" class="return clearl"></li> -->

        <li *ngIf="type=='number' || type=='letter'" (click)="handleKey('.')" class="letter clearl">.</li>

        <li *ngIf="type=='number' || type=='letter'" (click)="handleKey(0)" class="letter big">0</li>
        <li (click)="handleKey(0)" class="letter big clearl">0</li>
        <li (click)="handleKey('')" class="letter"></li>

        <li class="return" (click)="finish()">
            <div class="actionSvg" [inlineSVG]="'/assets/images/checked.svg'"></div>
        </li>


    </ul>
</div>