import { Component, OnInit, Inject } from '@angular/core';
import { CompanyListModalService } from './company-list-modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './company-list-modal.component.html',
  styleUrls: ['./company-list-modal.component.css']
})
export class CompanyListModalComponent {
  companies;
  selectedItem;
  constructor(public companyListService: CompanyListModalService,
    @Inject('LOCALSTORAGE') private store: any,
    private router: Router
  ) {
    companyListService.getCompanyList().subscribe(result => {
      this.companies = result;
    });
  }

  saveCompany() {
    this.store.set('company', this.selectedItem);
    if(!this.store.get('userConstants') && this.store.get('userConstants').length <= 0 ) {
      this.router.navigateByUrl('app/constants/addConstants');
    } else {
      this.router.navigateByUrl(`/app/home`);
    }
  }

}

