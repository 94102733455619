<button *ngIf="promokey=='4e544728-96e4-4773-a52f-a22962b548c4'" pButton pRipple type="button" label="Accces promooo"
  class="p-button-success p-mb-2 customButton" style="font-size: 20px;opacity:1;"></button>

<div style="display:flex;justify-content: center; margin-top:10px">

  <div *ngIf="!offerData?.offerData?.providerName" style="flex-direction: column;display: flex;">

    <div style="width:220px;" *ngFor="let provider of providers">
      <div *ngIf="provider=='CARETTA'" (click)="selectProvider(provider)">
        <div class="CARETTA" [inlineSVG]="'/assets/images/carettawhite.svg'">
        </div>

      </div>

      <!-- <div *ngIf="provider=='STILETO'" (click)="selectProvider(provider)">
   
gsesdgsdgsdg
      </div> -->

      <!-- <div *ngIf="provider!='BRAMAC' && provider!='CARETTA'" (click)="selectProvider(provider)" [ngClass]="provider"
              [inlineSVG]="'/assets/images/'+provider+'.svg'">
            </div>
      
            <div *ngIf="provider=='BRAMAC'" (click)="selectProvider(provider)" [ngClass]="'BRAMAC'">
              <img src="./assets/images/BRAMAC.png">
            </div> -->

    </div>
  </div>


  <div *ngIf="!offerData || offerData.categories.length==0"
    style="display:flex;justify-content: center; margin-top:10px">

    <div *ngIf="selectedOfferType" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedOfferType.name"
        class="p-button-success p-mb-2 customButton" style="font-size: 20px;opacity:1;"></button>
    </div>
    <div *ngIf="selectedColor" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedColor"
        class="p-button-success p-mb-2 customButton" style="font-size: 20px;opacity:1;"></button>
    </div>
    <div *ngIf="selectedMaterial" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedMaterial"
        class="p-button-success p-mb-2 customButton" style="font-size: 20px;opacity:1;"></button>
    </div>
    <div *ngIf="selectedThickness" style="margin: 0 5px;">
      <button disabled pButton pRipple type="button" [label]="selectedThickness"
        class="p-button-success p-mb-2 customButton" style="font-size: 20px;opacity:1;"></button>
    </div>

    <div *ngIf="!selectedOfferType && offerData?.offerData?.providerName" style="flex-direction: column;display: flex;">
      <ng-container *ngFor="let offerType of offersType">
        <button *ngIf="offerType.key!='IND'" (click)="getMaterial(offerType)" pButton pRipple type="button"
          [label]="offerType.name" class="p-button-success p-mb-2 typeButton"></button>
        <button *ngIf="offerType.key=='IND'" (click)="getIndustrySucategories()" pButton pRipple type="button"
          [label]="offerType.name" class="p-button-success p-mb-2 typeButton"></button>
      </ng-container>

    </div>
    <div *ngIf="!selectedMaterial" style="flex-direction: column;display: flex;">
      <button (click)="getColors(material)" *ngFor="let material of materials" pButton pRipple type="button"
        [label]="material" [ngClass]=" 'p-button-success p-mb-2 typeButton'"></button>
    </div>
    <div *ngIf="colors && !selectedColor" style="width: 270px;">
      <button (click)="getThickness(color)" *ngFor="let color of colors" pButton pRipple type="button" [label]="color"
        [ngClass]=" 'p-button-success p-mb-2 typeButton color'+color"></button>
    </div>

    <div *ngIf="!selectedThickness">
      <div (click)="selectThickness(item)" class="offerDetail" *ngFor="let item of thickness">{{item}}</div>
    </div>
  </div>

  <div *ngIf="!selectedOfferType && industryCategories" style="flex-direction: column;display: flex;">
    <div style="width:220px;" *ngFor="let provider of industryCategories">
      <button (click)="getMaterial(provider)" pButton pRipple type="button" [label]="provider.name"
        class="p-button-success p-mb-2 typeButton"></button>
    </div>
  </div>



</div>




<div style="display:flex;" *ngIf="offerData && offerData.categories.length>0">

  <div style="margin: 0 0 0 auto">
    <div *ngFor="let priceVariation of priceVariations">
      <div *ngIf="offerData.status!='COMANDA'" (click)="loadAgentPrice(priceVariation.key)"
        [ngClass]="priceVariation.key==offerData.priceVariationKey?'categoryPriceButtonSelected':'categoryPriceButton'">
        {{priceVariation.name}}
      </div>
    </div>

    <div style="font-size: 18px;
    font-weight: bold;margin-top:35px;
    font-family: 'CarettaOffice';">DARK</div>
    <div style="font-size: 18px;
    font-weight: bold;
    margin-bottom:5px;
    font-family: 'CarettaOffice';">MODE</div>
    <p-inputSwitch (click)="darkMode()" [(ngModel)]="darkModeEnabled"></p-inputSwitch>

    <div *ngIf="canSimulator && selectedOfferType && selectedOfferType.key=='GAZ'" (click)="conf()"
      class="categoryPriceButtonSelected">
      SIMULATOR
    </div>
  </div>
  <div>
    <!-- <div style="     display: flex;
      justify-content: center;
      background: white;
      align-items: center;
      margin-top: 5px;
      padding: 5px;">
      <div style="text-align: right;">
        <img style="width: 205px !important;" src="https://caretta.e-node.ro/assets/images/caretta.svg" alt="Logo">
      </div>
    </div> -->

    <span *ngFor="let item of offerData.categories">
      <mat-card style="margin:5px; width:650px" *ngIf="showCategory(item)">

        <div [ngClass]="'categoryTitle '+offerData?.offerData?.selectedOfferType?.key" style="text-align: center">
          {{item.category}}
          {{item.category=="SISTEM PLUVIAL" ?offerData.offerData &&
          offerData.offerData.pluvialType?(offerData.offerData.pluvialType):pluvialType:''}}</div>

        <table id="customers" style="width:100%">
          <tr style="font-size:14px;">
            <th class="name">Nume</th>
            <th class="quantity">Cant.</th>
            <th class="unitMeasure">UM</th>
            <th class="price">Pret cu discount</th>
            <th *ngIf="!selectedOfferType?.key?.startsWith('IND.')" class="listPrice">Pret Lista</th>
            <th class="listPrice">Subtotal</th>
            <th *ngIf="debug" class="listPrice">Prod prop</th>
            <th *ngIf="debug" class="listPrice">Ext</th>
            <th *ngIf="debug" class="listPrice">Gest</th>
            <!-- <th>Obs</th> -->
          </tr>
          <tr *ngFor="let art of item.articles; let itemI = index">
            <ng-container
              *ngIf="!art.data[0].D1 && !art.hideArticle && 
              ((!showZeroQuantityArticles && ( art.data[0].INACTIV==0 ||art.data[0].INACTIV==1 && art.data[0].quantity>0 ) )|| (showZeroQuantityArticles && art.data[0].quantity>0  && art.data[0].INACTIV==0)) ">
              <td [ngClass]="[art.data[0].quantity?'name selectedItem':'name', art.invalidPrice?'alert':'']">
                <div>{{art.articleName}} </div>
                <div
                  *ngIf="canDrawTechnicalDrawing  && checkFiles()&& art?.articleName?.includes('TABLA LISA COLORATA PRELUCRATA')">
                  <button *ngIf="offerData && offerData.status!='COMANDA'" (click)="setDrawArticle(art);showDraw=true;"
                    pButton type="button" pTooltip="Schita" tooltipPosition="top" icon="pi pi-image"></button>


                  <button *ngIf="offerData && offerData.status=='COMANDA' && art.data[0].drawings"
                    (click)="showDraw=true;setDraw(art.data[0].drawings)" pButton type="button" pTooltip="Vezi schita/e"
                    tooltipPosition="top" icon="pi pi-image"></button>

                </div>
                <div *ngIf="art.data[0].observations" class="p-mt-4">
                  <div class="p-float-label" [style]="{'width':'100%'}">
                    <textarea [disabled]="true" style="width:100%;" inputId="obs" [rows]="1" autoResize="autoResize"
                      pInputTextarea [(ngModel)]="art.data[0].observations"></textarea>
                    <label for="obs">Observatii</label>
                  </div>
                </div>
                <span style="color:red;">{{art.data[0].INACTIV==1?"INACTIV":""}} </span>
              </td>

              <td [ngClass]="offerData.status!='COMANDA'?'quantityDataInput noPading':'noPading'">
                <input (blur)="checkCredit()"
                  *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_LEAD' && offerData.status!='REFUZ_CLIENT' && offerData.status!='TRANSFERAT_MULTIDOM' && statusTaskOffer  != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED' "
                  #quantityInputs (keyup)="recalculate($event)" class="quantity" [(ngModel)]="art.data[0].quantity"
                  [disabled]="checkIfInactive(art.data[0])" type="number" lang="en" step="0.01"
                  (wheel)="preventScrool($event)"
                  [ngStyle]="{'background-color': checkIfInactive(art.data[0])?'rgb(225 225 225)':'#e9f2ff'}">

                <div
                  style="box-sizing: border-box;text-align: center; font-weight: bold; color: #1d941d; font-size: 15px;"
                  *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && (offerData.status == 'OFERTA' || offerData.status =='SECONDARY_OFFER')">
                  {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}
                </div>

                <div
                  style="box-sizing: border-box;text-align: center; font-weight: bold; color: #1d941d; font-size: 15px;"
                  *ngIf="offerData.status=='COMANDA' || offerData.status=='REFUZ_LEAD' || offerData.status=='REFUZ_CLIENT' || offerData.status=='TRANSFERAT_MULTIDOM' ">
                  {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}
                </div>
              </td>
              <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">{{art.data[0].UM}}
              </td>
              <td class="price2">

                <input
                  *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && offerData.status!='REFUZ_CLIENT'"
                  [disabled]="statusTaskOffer == 'FINANCIAL_VALIDATION'" [ngClass]="art.invalidPrice?'alert':''"
                  style="width: calc(100% - 16px);" (keyup)="recalculate($event)" [(ngModel)]="art.data[0].agentPrice"
                  type="number" lang="en" step="0.01" (wheel)="preventScrool($event)">
                <span style="color:red"
                  *ngIf="offerData.status!='COMANDA' && art.data[0].agentPrice==art.data[0].agentMinimumPrice">R</span>
                <span style="color:blue"
                  *ngIf="offerData.status!='COMANDA' && !(art.data[0].agentPrice==art.data[0].agentMinimumPrice) && art.data[0].agentPrice==art.data[0].P1">P1</span>
                <span style="color:blue"
                  *ngIf="offerData.status!='COMANDA' && !(art.data[0].agentPrice==art.data[0].agentMinimumPrice) && !(art.data[0].agentPrice==art.data[0].P1) && art.data[0].agentPrice==art.data[0].P2">P2</span>
                <span style="color:blue"
                  *ngIf="offerData.status!='COMANDA' && !(art.data[0].agentPrice==art.data[0].agentMinimumPrice) && !(art.data[0].agentPrice==art.data[0].P1) && !(art.data[0].agentPrice==art.data[0].P2)&&art.data[0].agentPrice==art.data[0].P3">P3</span>
                <!-- <span style="color:blue" *ngIf="art.data[0].agentPrice==art.data[0].agent">P3</span> -->


                <div style="box-sizing: border-box;text-align: center; font-weight: bold;"
                  *ngIf="offerData.status=='COMANDA' || offerData.status=='REFUZ_LEAD' || offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_CLIENT'">
                  {{art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):""}}
                </div>
              </td>
              <td *ngIf="!selectedOfferType?.key?.startsWith('IND.')"
                [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">{{round2Decimals(art.data[0].LISTA)}}
              </td>
              <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                {{getSubtotal(art.data[0].agentPrice,art.data[0].quantity)}}
              </td>

              <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
              <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
              <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
              <td>
                <app-article-observation-modal [(article)]="art.data[0]"></app-article-observation-modal>
              </td>

            </ng-container>
            <ng-container *ngIf="art.data[0].D1 && !art.hideArticle">
              <td *ngIf="art.data[0].PROMOTIE!=1"
                [ngClass]="[art.totalQuantity?'name selectedItem':'name',art.invalidPrice?'alert':'']">
                {{art.articleName}}
              </td>
              <td *ngIf="art.data[0].PROMOTIE==1" [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                {{art.articleName}}
              </td>

              <ng-container *ngIf="!art.data[0].showObservations">
                <td style="    text-align: center;color:#003048;font-weight: bold;">
                  {{round2Decimals(getTotalQuantity(art))}}
                </td>
                <td class="unitMeasure">{{art.data[0].UM}}</td>

                <td class="price2">
                  <input [disabled]="statusTaskOffer == 'FINANCIAL_VALIDATION'"
                    *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && offerData.status!='REFUZ_CLIENT'"
                    [ngClass]="art.invalidPrice?'alert':''" (keyup)="propagateQuantity(art.data);recalculate($event)"
                    [(ngModel)]="art.data[0].agentPrice" style="width: calc(100% - 16px);" type="number" lang="en"
                    step="0.01" (wheel)="preventScrool($event)">
                  <span style="color:red"
                    *ngIf="offerData.status!='COMANDA' && art.data[0].agentPrice==art.data[0].agentMinimumPrice">R</span>
                  <span style="color:blue"
                    *ngIf="offerData.status!='COMANDA' && art.data[0].agentPrice==art.data[0].P1">P1</span>
                  <span style="color:blue"
                    *ngIf="offerData.status!='COMANDA' && art.data[0].agentPrice==art.data[0].P2">P2</span>
                  <span style="color:blue"
                    *ngIf="offerData.status!='COMANDA' && art.data[0].agentPrice==art.data[0].P3">P3</span>
                  <div style="box-sizing: border-box;text-align: center; font-weight: bold;"
                    *ngIf="offerData.status=='COMANDA' || offerData.status=='REFUZ_LEAD' || offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_CLIENT'">
                    {{art.data[0].agentPrice?round2Decimals(art.data[0].agentPrice):""}}
                  </div>
                </td>
                <td *ngIf="!selectedOfferType?.key?.startsWith('IND.')">{{round2Decimals(art.data[0].LISTA)}}</td>
                <td [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                  {{getSubtotal(art.data[0].agentPrice,getTotalQuantity(art))}}
                </td>


              </ng-container>

              <td *ngIf="art.data[0].showObservations" colspan="5">
                <input [(ngModel)]="art.data[0].observations" placeholder="Observatii" style="width: 100%;" lang="en"
                  step="0.01" (wheel)="preventScrool($event)">
              </td>

              <td *ngIf="debug"> {{art.data[0].PRODUSPROPRIU}} </td>
              <td *ngIf="debug"> {{art.data[0].articleLocation}} </td>
              <td *ngIf="debug"> {{art.data[0].simbolGestiune}} </td>
              <td>


              </td>


            </ng-container>
            <!-- <div>
              <input type="text" value="PrimeNG" pInputText>
            </div> -->
          </tr>

        </table>
      </mat-card>
    </span>
  </div>
  <div>
    <!-- <p-button label="Sincronizeaza" (click)="syncOffer()"></p-button> -->
    <span *ngFor="let item of offerData.categories; let i = index">
      <mat-card style="margin:5px; width:345px" *ngIf="hasMultipleArts(item) && showCategory(item)">
        <div [ngClass]="'categoryTitle '+offerData?.offerData?.selectedOfferType?.key" style="text-align: center">
          {{item.category}}
        </div>


        <ng-container *ngFor="let art of item.articles;">
          <ng-container *ngIf="art.data[0].D1">
            <p-accordion *ngIf="!art.hideArticle">
              <p-accordionTab [transitionOptions]="'0ms'" [header]="art.articleName">


                <div *ngIf="art.data[0].LMIN" class="dimnesionInfo">Lungime minimă {{round2Decimals(art.data[0].LMIN)}}
                  m</div>
                <div *ngIf="art.data[0].LMIN" class="dimnesionInfo">Lungime maximă {{round2Decimals(art.data[0].LMAX)}}
                  m</div>

                <table id="customers" class="dimensionTable" style="width:100%;">
                  <tr>
                    <th *ngIf="!art.data[0].D2" class="unitMeasure center fs12 singleDimension" style="width: 5%;">
                      Lung
                    </th>

                    <th *ngIf="art.data[0].D2" class="unitMeasure center fs12" style="width: 5%">Lung</th>
                    <th style="width: 15%;" *ngIf="showD1Real(art.articleName)"> Real</th>
                    <th *ngIf="art.data[0].D2" class="unitMeasure center fs12" style="width: 5%">Lat</th>

                    <th
                      *ngIf="selectedOfferType &&(selectedOfferType.key=='AA' || selectedOfferType.key=='AP' || selectedOfferType.key=='MM' || selectedOfferType.key=='MP' || selectedOfferType.key=='NO' || selectedOfferType.key=='NS' || selectedOfferType.key=='DA' || selectedOfferType.key=='DS') && isTigla(art.articleName)"
                      class="unitMeasure center fs12" style="width: 5%"></th>
                    <th class="detailQuantity center fs12" style="width: 5%">{{art.data[0].UM1}}</th>
                    <th class="center fs12" style="width: 5%"> {{art.data[0].UM}}</th>
                    <th class="center fs12" style="width: 5%"> OBS</th>
                  </tr>
                  <ng-container *ngFor="let sub of art.data;let ii = index">
                    <tr>
                      <ng-container
                        *ngIf="sub.D1!=0.01 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && sub.quantity>0) )">
                        <td>
                          <span
                            *ngIf="!sub.LMIN || offerData.status== 'COMANDA' || statusTaskOffer == 'FINANCIAL_VALIDATION'||statusTaskOffer == 'APPROVED'">{{round2Decimals(sub.D1)}}
                          </span>
                          <input (keyup)="recalculate($event)"
                            *ngIf="sub.LMIN && offerData.status != 'COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED'"
                            type="number" step="0.01" [disabled]="checkIfInactive(sub)" [(ngModel)]="sub.D1"
                            (keyup)="checkD1Input($event)" (ngModelChange)="checkValueD1($event,sub)"
                            [ngStyle]="sub.errorD1 && {'background-color': 'red', 'color':'white'}"
                            style="width: 100%" />
                        </td>
                        <td *ngIf="showD1Real(art.articleName)">
                          <input
                            *ngIf="offerData.status != 'COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED'"
                            [disabled]="checkIfInactive(sub)" [(ngModel)]="sub.d1Real" style="width: 100%"
                            (keyup)="checkD1Input($event)" (ngModelChange)="checkValueD1Real($event,sub,ii,art.data)"
                            [ngStyle]="sub.errorD1Real && {'background-color': 'red', 'color':'white'}" />
                          <div
                            *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && offerData.status == 'OFERTA'">
                            {{sub.d1Real? sub.d1Real :""}}
                          </div>
                          <div *ngIf="offerData.status == 'COMANDA'">
                            {{sub.d1Real? sub.d1Real :""}}
                          </div>
                        </td>

                        <td *ngIf="sub.D2">{{sub.D2>0?round2Decimals(sub.D2):''}}</td>
                        <td
                          *ngIf="selectedOfferType &&(selectedOfferType.key=='AA' || selectedOfferType.key=='AP' || selectedOfferType.key=='MM' || selectedOfferType.key=='MP' || selectedOfferType.key=='NO' || selectedOfferType.key=='NS' || selectedOfferType.key=='DA' || selectedOfferType.key=='DS') && isTigla(art.articleName)">
                          {{getModule(sub.D1,sub.D2)}}</td>
                        <td [ngClass]="offerData.status!='COMANDA'?'quantityDataInput noPading':'noPading'">
                          <input [disabled]="checkIfInactive(art.data[0])"
                            [ngStyle]="{'background-color': checkIfInactive(art.data[0])?'rgb(225 225 225)':'#e9f2ff', width:'100%'}"
                            *ngIf="offerData.status!='COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'APPROVED'"
                            (keyup)="recalculate($event)" class="detailQuantity" [(ngModel)]="sub.quantity"
                            type="number" lang="en" step="0.01" (wheel)="preventScrool($event)">
                          <div
                            *ngIf="(statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED') && offerData.status == 'OFERTA'"
                            style="box-sizing: border-box;text-align: center; font-weight: bold;color: #1d941d;font-size:
                          14px;">
                            {{sub.quantity?round2Decimals(sub.quantity):""}}
                          </div>
                          <div
                            style="box-sizing: border-box;text-align: center; font-weight: bold;color: #1d941d;font-size: 14px;"
                            *ngIf="offerData.status=='COMANDA'">
                            {{sub.quantity?round2Decimals(sub.quantity):""}}
                          </div>

                        </td>
                        <td>{{round2Decimals(getArticleTotalQuantity(sub))}}
                        </td>
                        <td>
                          <app-article-observation-modal [(article)]="art.data[ii]"></app-article-observation-modal>
                        </td>

                      </ng-container>
                    </tr>
                    <tr *ngIf="sub.observations">
                      <td colspan="4">
                        <textarea *ngIf="sub.observations" [disabled]="true" style="width:100%;" inputId="obs"
                          [rows]="1" autoResize="autoResize" pInputTextarea [(ngModel)]="sub.observations"></textarea>
                      </td>
                    </tr>
                  </ng-container>
                </table>

                <button *ngIf="offerData && art.data[0].LMIN" style="margin-bottom: 10px;"
                  (click)="addArtileDimension(art.data)" pButton pRipple type="button" icon="pi pi-plus"
                  label="Adaugă dimensiune" class="p-button-success p-mt-2 customButton"></button>
              </p-accordionTab>
            </p-accordion>

          </ng-container>
        </ng-container>

      </mat-card>
    </span>
  </div>


  <div class="noprint" style="margin: 0 auto 0 0;">

    <div *ngIf="offerData?.offerData &&offerData?.offerData?.selectedOfferType?.key=='AT'" style="     display: flex;
    justify-content: center;
    background: white;
    align-items: center;
    margin-top: 5px;">
      <div style="text-align: right;">
        <img style="width: 190px !important;" src="https://caretta.e-node.ro/assets/images/attica.png" alt="Logo">
      </div>
    </div>

    <div style="text-align: center;">
      <div *ngIf="offerData?.offerData?.providerName=='CARETTA'">
        <div class="CARETTA" [inlineSVG]="'/assets/images/carettawhite.svg'">
        </div>

      </div>

      <div *ngIf="offerData?.offerData?.providerName!='BRAMAC' && offerData?.offerData?.providerName!='CARETTA'"
        [ngClass]="offerData?.offerData?.providerName"
        [inlineSVG]="'/assets/images/'+offerData?.offerData?.providerName+'.svg'">
      </div>

      <div *ngIf="offerData?.offerData?.providerName=='BRAMAC'" [ngClass]="offerData?.offerData?.providerName">
        <img src="./assets/images/BRAMAC.png">
      </div>

    </div>


    <div *ngIf="offerData && offerData.categories.length>0" style="margin: 0 5px;">
      <button *ngIf="offerData.offerData.selectedOfferType?.key?.startsWith('IND.')" (click)="resetSelect()" pButton
        pRipple type="button" label="Adauga articole" class="p-button-success p-mb-2 customButton"
        style="font-size: 20px;opacity:1;"></button>
    </div>
    <div style="margin:5px;width:275px;overflow: auto;">

      <mat-card [ngClass]="'mainCard '+offerData?.offerData?.selectedOfferType?.key">

        <div style="color:#fff;text-align: center;font-size:22px;" *ngIf="offerData">
          {{offerData.contact.name}}
        </div>
        <div style="color:#fff;text-align: center;font-size:18px; margin-top:5px" *ngIf="offerData">
          {{offerData.contact.phone}}
        </div>
        <div style="color:#fff;font-size:16px; margin-top:10px">
          <div style="text-align: center;font-family: 'CarettaOffice';font-size: 24px;" *ngIf="selectedOfferType">
            {{selectedOfferType.name}}
          </div>
          <div style="text-align: center;font-family: 'CarettaOffice';" *ngIf="selectedOfferType">
            {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
          </div>
          <div
            *ngIf="selectedOfferType && (selectedOfferType.key=='AT' ||selectedOfferType.key=='DA' || selectedOfferType.key=='DS' || selectedOfferType.key=='NO' || selectedOfferType.key=='NS'|| selectedOfferType.key=='CA' || selectedOfferType.key=='CS') "
            style="margin-top:10px;">
            <img [src]="'./assets/caretta/'+selectedOfferType.key+'_'+selectedColor+'_'+selectedMaterial+'.png'"
              onerror="this.style.display='none'" alt="Imagine indisponibila" width="250" height="175">
          </div>

          <div *ngIf="selectedOfferType && selectedOfferType.key=='GAZ' "
            style="display:flex;justify-content: center;margin-top:10px;">
            <img style="margin: 0 10px 0 auto;"
              [src]="'./assets/caretta/'+selectedOfferType.key+'_'+selectedColor+'.png'" alt="..." width="50"
              height="50">
            <img style="margin: 0 auto 0 10px;"
              [src]="'./assets/caretta/'+selectedOfferType.key+'_'+selectedColor+'_2.png'" alt="..." width="50"
              height="50">
          </div>

          <div style="text-align: center;font-size:18px; margin-top:5px;font-family: 'CarettaOffice';"
            *ngIf="offerData">
            <del>Total: {{totalWithOutDiscount}} lei</del>
          </div>
          <div *ngIf="offerData && !selectedOfferType?.key?.startsWith('IND.')"
            style="color:white;text-align: center;font-size:16px; margin-top:5px;font-family: 'CarettaOffice';">
            Discount valoric: {{discountValue}} lei</div>
          <div *ngIf="offerData && !selectedOfferType?.key?.startsWith('IND.')"
            style="color:white;text-align: center;font-size:16px; margin-top:5px;font-family: 'CarettaOffice';">
            Discount procentual: {{discountPercent}} %</div>

          <div style="color:#44C4A1;text-align: center;font-size:16px; margin-top:5px;font-family: 'CarettaOffice';"
            *ngIf=" offerData.discountPromo">
            Discount PROMO {{round2Decimals(offerData.discountPromo)}} lei
          </div>




          <div style="color:white;text-align: center;font-size:22px; margin-top:5px;font-family: 'CarettaOffice';"
            *ngIf="offerData">
            Total: {{round2Decimals(totalPrice-discountPromo)}} lei
          </div>

        </div>

        <div style="display:flex">
          <div (click)="showAdaos=!showAdaos" class="actionSvg delete" style="margin:auto"
            [inlineSVG]="'/assets/images/profit.svg'"></div>
          <div style="color:white;margin: auto;"> {{round2Decimals(totalOfferWeight)}} KG</div>
          <!-- <div style="margin: auto auto auto 3px;" class="routeInfo kg" [inlineSVG]="'/assets/images/scale.svg'">
          </div> -->
        </div>
        <!-- <div style="display:flex">
          <div (click)="showAdaos=!showAdaos" class="actionSvg delete" style="margin:auto"
            [inlineSVG]="'/assets/images/cash_earned.svg'"></div>
          <div style="color:white;margin: auto 3px auto auto;"> {{round2Decimals(totalOfferWeight)}}</div>
          <div style="margin: auto auto auto 3px;" class="routeInfo kg" [inlineSVG]="'/assets/images/scale.svg'">
          </div>
        </div> -->
        <div *ngIf="showAdaos" style="color:#27A2DB;text-align: center;font-size:18px; margin-top:5px">
          {{adaosValue}} lei </div>
        <!-- <div>| {{adaosPercent}} %</div> -->
        <div style="color:white;padding: 5px 0;text-align: center;font-family: 'CarettaOffice';">
          Procent accesorii {{accesoryProcent}} %</div>

      </mat-card>
      <mat-card>
        <p-divider *ngIf="promoRoofError">
          <div class=" p-d-inline-flex p-ai-center" style="color: red;">
            <i class="pi pi-exclamation-triangle p-mr-2"></i>
            <span>Procentul accesorii nu permite tabla promo</span>
          </div>
        </p-divider>

        <div *ngIf="offerData.status=='COMANDA'">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            {{offerData.data && !offerData.returned?offerData.data.commandSeries:'RETUR '}}
            {{offerData.data?offerData.data.commandNumber:''}}</div>
          <button *ngIf="canCancelCommand" (click)="cancelCommandFlag=true" pButton pRipple type="button"
            icon="pi pi-times" label="Anulează comanda" class="p-button-success p-mt-2 customButton"></button>

        </div>

        <div *ngIf="offerData.status=='OFERTA'   &&offerData.data">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            OFERTA {{offerData.data.offerNumber}}/{{offerData.indexlocal}}</div>

        </div>

        <div *ngIf="offerData.status=='REFUZ_CLIENT'">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            REFUZ CLIENT</div>
        </div>

        <div *ngIf="offerData.status=='REFUZ_LEAD'">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            OFERTA REFUZATA</div>
        </div>

        <div *ngIf="offerData.status=='REFUZ_VALIDARE'">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            VALIDARE REFUZ</div>
        </div>

        <div *ngIf="offerData.status=='NU_ACUM'">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            NU ACUM</div>
        </div>
        <div *ngIf="offerData.status=='TRANSFERAT_MULTIDOM'">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            TRANSFERAT MULTIDOM</div>
        </div>
        <div *ngIf="offerData.status=='SECONDARY_OFFER'">
          <div style="font-size: 28px;font-weight: bold;text-align: center;color: #e0004d;">
            OFERTA SECUNDARA {{offerData.data.offerNumber}}/{{offerData.indexlocal}}</div>
        </div>

        <!-- <mat-form-field
          *ngIf="canChooseClientType && offerData.status!='COMANDA' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
          class="noLabel" style="width:100%">
          <mat-select [(ngModel)]="offerData.invoiceType" required placeholder="Tip Comanda"
            (selectionChange)="commandTypeChanged($event)">
            <mat-option *ngFor="let invoice of invoiceTypes" [value]="invoice">
              {{invoice}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <div
          *ngIf="canChooseClientType && offerData.status!='COMANDA' &&  offerData.status!='REFUZ_LEAD' && offerData.status!='REFUZ_CLIENT' && offerData.status!='TRANSFERAT_MULTIDOM' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
          class="p-mt-4">
          <span class="p-float-label">
            <p-dropdown (onChange)="commandTypeChanged($event)" [autoDisplayFirst]="false" inputId="invoiceType"
              appendTo="body" [options]="opType" name="value" [showClear]="true" [style]="{'width':'100%'}"
              [(ngModel)]="offerData.invoiceType">
            </p-dropdown>
            <label for="invoiceType">Tip Comanda</label>
          </span>
        </div>






        <!-- aaaaaaaaaa -->
        <mat-form-field *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer'" class="noLabel"
          style="width: 100%;">
          <mat-label>Nume Dealer</mat-label>
          <div style="display:flex">
            <mat-select [compareWith]="compareDealers" style="margin-top:5px; margin-left:5px"
              [disabled]="offerData.isDealer || offerData.status=='COMANDA' || statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED' ?true:false"
              [(ngModel)]="offerData.dealer" (selectionChange)="loadDealerAgents($event)">
              <mat-option *ngFor="let dealer of dealers" [value]="dealer">
                {{dealer.partnerName}}
              </mat-option>
            </mat-select>
          </div>

        </mat-form-field>

        <p-divider *ngIf=" offerData.status=='COMANDA' && offerData.dealer &&offerData.dealer.partnerName">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-user p-mr-2"></i>
            <span>Dealer</span>
          </div>
        </p-divider>

        <div *ngIf=" offerData.status=='COMANDA' && offerData.dealer &&offerData.dealer.partnerName"
          style="color:#003048;padding: 5px 0;text-align: center;font-family: 'CarettaOffice';">
          {{offerData.dealer.partnerName}}</div>

        <p-divider *ngIf=" offerData.status=='COMANDA' && offerData.dealerAgent &&offerData.dealerAgent.name">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-user p-mr-2"></i>
            <span>Agent Dealer</span>
          </div>
        </p-divider>

        <div *ngIf=" offerData.status=='COMANDA' && offerData.dealerAgent &&offerData.dealerAgent.name"
          style="color:#003048;padding: 5px 0;text-align: center;font-family: 'CarettaOffice';">
          {{offerData.dealerAgent.name}} {{offerData.dealerAgent.surname}}</div>

        <!-- <div *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer'" class="p-mt-4">
          <span class="p-float-label">
            <p-dropdown [autoDisplayFirst]="false" inputId="dealer" (onChange)="loadDealerAgents($event)"
              [options]="dealers" [(ngModel)]="offerData.dealer" appendTo="body" optionLabel="partnerName"
              [filter]="true" filterBy="partnerName">
              <ng-template pTemplate="selectedItem">
                <div class="country-item country-item-value" *ngIf="offerData.dealer">
                  <div>{{offerData.dealer.partnerName}}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <div>{{country.partnerName}}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="dealer">Dealer</label>
          </span>
        </div> -->



        <p-divider
          *ngIf=" offerData.status!='COMANDA' && offerData.invoiceType=='Dealer' && offerData.dealer  &&isDealerTrusted=='error'">
          <div class=" p-d-inline-flex p-ai-center" style="color: red;">
            <i class="pi pi-exclamation-triangle p-mr-2"></i>
            <span>Limită credit depășită</span>
          </div>
        </p-divider>

        <p-divider *ngIf=" statusTaskOffer== 'DENIED'">
          <div class=" p-d-inline-flex p-ai-center" style="color: red;">
            <i class="pi pi-exclamation-triangle p-mr-2"></i>
            <span>Ofertă respinsă</span>
          </div>
        </p-divider>

        <p-divider *ngIf=" statusTaskOffer== 'FINANCIAL_VALIDATION'">
          <div class=" p-d-inline-flex p-ai-center" style="color: #0097d2;">
            <i class="pi pi-spinner p-mr-2"></i>
            <span>Ofertă în verificare</span>
          </div>
        </p-divider>

        <p-divider *ngIf=" statusTaskOffer== 'APPROVED'">
          <div class=" p-d-inline-flex p-ai-center" style="color: green;">
            <i class="pi pi-check p-mr-2"></i>
            <span>Ofertă Acceptată</span>
          </div>
        </p-divider>



        <!-- <p-dropdown *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer' && selectedDealer"
          (onChange)="onSelectedDealerAgent($event)" [options]="dealerAgents" [(ngModel)]="offerData.dealerAgent"
          appendTo="body" optionLabel="name" [filter]="true" filterBy="name" placeholder="Agent Dealer">
          <ng-template pTemplate="selectedItem">
            <div class="dealerAgent-item dealerAgent-item-value" *ngIf="offerData.dealerAgent">
              <div>{{offerData.dealerAgent.name}} {{offerData.dealerAgent.surname}}</div>
            </div>
          </ng-template>
          <ng-template let-dealerAgent pTemplate="item">
            <div class="dealerAgent-item">
              <div>{{dealerAgent.name}} {{dealerAgent.surname}}</div>
            </div>
          </ng-template>
        </p-dropdown> -->

        <mat-form-field *ngIf="offerData.status!='COMANDA' && offerData.invoiceType=='Dealer' && selectedDealer"
          class="noLabel" style="width: 100%;">
          <mat-label>Agent Dealer</mat-label>
          <mat-select [compareWith]="compareDealerAgents"
            [disabled]="offerData.isDealer || offerData.status=='COMANDA' || statusTaskOffer == 'FINANCIAL_VALIDATION' || statusTaskOffer == 'APPROVED' ?true:false"
            [(ngModel)]="offerData.dealerAgent" (selectionChange)="onSelectedDealerAgent($event)">
            <mat-option *ngFor="let agent of dealerAgents" [value]="agent">
              {{agent.name}} {{agent.surname}} ({{agent.headquarter}})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- aaa -->


        <div
          *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_CLIENT' && offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && statusTaskOffer != 'FINANCIAL_VALIDATION' && statusTaskOffer != 'DENIED' && statusTaskOffer != 'APPROVED'"
          class=" p-mt-2 p-mb-1">
          <button (click)="showClientsModal=true" pButton pRipple type="button" label="Clienți"
            class="p-button-success customButton"></button>
        </div>







        <!-- <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-user" style="line-height: 1.25;"></i></span>
          <input disabled="true" type="text" pInputText [placeholder]="offerData.client.name">

        </div>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-map-marker" style="line-height: 1.25;"></i></span>
          <input disabled="true" type="text" pInputText
            [placeholder]="offerData.client.address.address+' '+offerData.client.address.city+' '+offerData.client.address.conunty">

        </div>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-phone" style="line-height: 1.25;"></i></span>
          <input disabled="true" type="text" pInputText [placeholder]="offerData.client.phone">

        </div> -->


        <p-divider *ngIf=" offerData.status=='COMANDA' && offerData.client &&offerData.client.name">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-user p-mr-2"></i>
            <span>Client</span>
          </div>
        </p-divider>
        <div style="color: #003048; font-size: 20px;text-align: center; padding: 10px 0;"
          *ngIf="offerData.client &&offerData.client.name ">
          <b>{{offerData.client.name}}</b>
        </div>






        <div *ngIf="offerData.client &&offerData.client.phone" class="p-mt-2">
          <p-divider>
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-phone p-mr-2"></i>
              <span>Telefon</span>
            </div>
          </p-divider>
          <div style="color:#003048; font-size: 17px;text-align: center; padding: 3px 0;font-family: 'CarettaOffice';">
            {{offerData.client.phone}}</div>
        </div>




        <p-divider *ngIf="offerData.client &&offerData.client.name">
          <div class="p-d-inline-flex p-ai-center grayDivider">
            <i class="pi pi-map-marker p-mr-2"></i>
            <span>Adresa</span>
          </div>
        </p-divider>

        <div *ngIf="offerData.client &&offerData.client.name"
          style="color:#003048;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
          {{offerData?.client?.address?.address}} {{offerData?.client?.address?.city}}
          {{offerData?.client?.address?.conunty}}</div>



        <button
          *ngIf="offerData.status!='COMANDA' && showButtonAlternativeAddress && offerData.client &&offerData.client.name "
          (click)="openNewAddressAlternative()" pButton pRipple type="button" label="Livrare la alta adresa"
          class="p-button-success p-mt-1 p-mb-1 customButton"></button>



        <div *ngIf="!showButtonAlternativeAddress">

          <p-divider *ngIf="offerData.client &&offerData.client.name">
            <div class="p-d-inline-flex p-ai-center grayDivider">
              <i class="pi pi-id-card p-mr-2"></i>
              <span>Livrare</span>
              <i *ngIf="offerData.status!='COMANDA'" (click)="deleteAlternativeAddress()"
                class="pi pi-times p-ml-2"></i>
            </div>
          </p-divider>
          <!-- <div>


            <i class="pi pi-id-card" style="font-size: 24px; color: #6c757d;"></i>
           
          </div> -->
          <div style="color:#003048;padding: 3px 0;text-align: center;">
            {{offerData.deliverAddress}}
          </div>
          <!-- <i (click)="deleteAlternativeAddress()" *ngIf="offerData.status!='COMANDA'" class="pi pi-id-card"
            style="font-size: 24px; color: #6c757d;"></i> -->
          <!-- <div *ngIf="offerData.status!='COMANDA'" (click)="deleteAlternativeAddress()"
            class="customDeleteDeliveryAdressAlternative" [inlineSVG]="'/assets/images/close.svg'"></div> -->

        </div>

        <p-divider>

        </p-divider>

        <div *ngIf="canPickedByClient || !(offerData.invoiceType=='Dealer')" class=" p-mt-2">
          <div class="p-field-checkbox">
            <p-checkbox
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT' || offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
              [(ngModel)]="offerData.pickedByClient" binary="true" inputId="pickedByClient"></p-checkbox>
            <label for="pickedByClient"> Ridică Clientul</label>
          </div>
        </div>

        <!-- <div style="margin:10px;">
          <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.returned">Comanda
            retur</mat-checkbox>
        </div> -->

        <div class="p-field-checkbox">
          <p-checkbox [(ngModel)]="offerData.returned"
            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
            binary="true" inputId="returned"></p-checkbox>
          <label for="returned"> Comanda retur</label>
        </div>

        <!-- <div style="margin:10px;">
          <mat-checkbox (ngModelChange)="setAdvancePaymentValue($event)"
            [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.custody">
            Custodie
          </mat-checkbox>
        </div> -->

        <div class="p-field-checkbox">
          <p-checkbox [(ngModel)]="offerData.custody"
            [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
            (ngModelChange)="setAdvancePaymentValue($event)" binary="true" inputId="custody"></p-checkbox>
          <label for="custody"> Custodie</label>
        </div>

        <div *ngIf="this.getTotalSurface()==0">
          <p-fileUpload styleClass="uploadBtn" #fileUpload #uploadFile showUploadButton="true" mode="basic"
            accept="image/*" maxFileSize="10000000" [auto]="true" chooseLabel="Atașează schița"
            (onSelect)="onBasicUpload($event)">
          </p-fileUpload>
        </div>

        <div *ngFor="let file of offerData.files">
          <!-- <div (click)="image=file;showImage=true"
            style="cursor: pointer;background: #007ad9;color: white;padding: 5px;text-align: center; margin-bottom: 3px;"
            *ngIf="file.name.includes('.jpg')||file.name.includes('.jpeg')||file.name.includes('.gif')||file.name.includes('.png')">
            <a>{{file.name}}</a>
          </div>
          <div>sterge</div> -->

          <div
            *ngIf="file.name.includes('.jpg')||file.name.includes('.jpeg')||file.name.includes('.gif')||file.name.includes('.png')"
            class="p-buttonset p-mt-2">
            <button (click)="image=file;showImage=true" pButton type="button" style="width: calc(100% - 38px);"
              [label]="file.name"></button>
            <button (click)="filterImage(file)" pButton type="button" icon="pi pi-trash"></button>
          </div>
        </div>

        <!-- <mat-form-field class="noLabel" style="width:100%">
          <input [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.deliverDate" matInput
            [min]="minDate" [matDatepicker]="picker"
            [placeholder]="offerData.returned?'Data estimativa de retur':'Data estimativa de livrare'">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
        <div class=" p-mt-4">
          <span class="p-float-label">
            <p-calendar [minDate]="yesterday" [showIcon]="true" [style]="{'text-align':'left','width':'100%'}"
              [panelStyle]="{'width':'30%','min-width': '35vw'}" appendTo="body"
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
              [firstDayOfWeek]="1" [touchUI]="true" [readonlyInput]="true" dateFormat="dd.mm.yy" inputId="deliverDate"
              [(ngModel)]="offerData.deliverDate">
            </p-calendar>
            <label for="deliverDate">{{offerData.returned?'Data estimativa de retur':'Data estimativa de
              livrare'}}</label>
          </span>
        </div>


        <!-- <mat-form-field class="noLabel" style="width:100%">
          <mat-select [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.finishType" required
            placeholder="Finalizare">
            <mat-option *ngFor="let finishType of finishTypes" [value]="finishType">
              {{finishType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="noLabel" style="width:100%">
          <mat-select [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.paymentType" required
            placeholder="Modalitate de plata">
            <mat-option *ngFor="let payment of paymentTypes" [value]="payment">
              {{payment}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <div class=" p-mt-4">
          <span class="p-float-label">
            <p-dropdown
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
              [autoDisplayFirst]="false" inputId="finishTypes" appendTo="body" [options]="finishTypes" name="value"
              [style]="{'width':'100%'}" [(ngModel)]="offerData.finishType">
            </p-dropdown>
            <label for="finishTypes">Finalizare</label>
          </span>
        </div>

        <div class=" p-mt-4">
          <span class="p-float-label">
            <p-dropdown
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
              [autoDisplayFirst]="false" inputId="paymentTypes" appendTo="body" [options]="paymentTypes" name="value"
              [style]="{'width':'100%'}" [(ngModel)]="offerData.paymentType" (onChange)="verifyPayment($event)">
            </p-dropdown>
            <label for="paymentTypes">Mod plata principal</label>
          </span>
        </div>

        <div *ngIf=" offerData.paymentType && (offerData.status!='COMANDA' ||offerData.status=='COMANDA' )"
          class="p-mt-4">
          <span class="p-float-label">
            <input id="principalPaymentValue"
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM' "
              inputId="delivery" [style]="{'text-align':'left','width':'100%'}" type="number" pInputText
              (keyup)="checkAdvancePayment($event)" [(ngModel)]="offerData.principalPaymentValue" />
            <label for="delivery">Suma plata metoda principala</label>


          </span>
        </div>

        <div *ngIf=" offerData.paymentType=='cashbon'" style="color:red;padding: 3px;text-align: center;">Maxim 10000
          lei !!!</div>

        <div class=" p-mt-4">
          <span class="p-float-label">
            <p-dropdown
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
              [autoDisplayFirst]="false" inputId="paymentTypes" appendTo="body" [options]="paymentTypes" name="value"
              [style]="{'width':'100%'}" [(ngModel)]="offerData.secondaryPaymentType"
              (onChange)="verifyPayment($event)">
            </p-dropdown>
            <label for="paymentTypes">Mod plata secundar</label>
          </span>
        </div>

        <div *ngIf=" offerData.secondaryPaymentType && (offerData.status!='COMANDA' ||offerData.status=='COMANDA' )"
          class="p-mt-4">
          <span class="p-float-label">
            <input
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM' "
              inputId="delivery" [style]="{'text-align':'left','width':'100%'}" type="number" pInputText
              (keyup)="checkAdvancePayment($event)" [(ngModel)]="offerData.secondaryPaymentValue" />
            <label for="delivery">Suma plata metoda secundara</label>
          </span>
        </div>

        <div *ngIf=" offerData.secondaryPaymentType=='cashbon'" style="color:red;padding: 3px;text-align: center;">
          Maxim 10000 lei !!!</div>

        <!-- <mat-checkbox [disabled]="offerData.status=='COMANDA' || this.offerData.custody?true:false"
          [(ngModel)]="offerData.isAdvancePayment"
          *ngIf="(offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0) || offerData.status=='OFERTA' || offerData.status == 'PROSPECTARE'">
          Plata avans</mat-checkbox> -->
        <div
          *ngIf="offerData && (offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0) || offerData.status=='OFERTA' || offerData.status == 'PROSPECTARE'|| offerData.status=='SECONDARY_OFFER' || offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'"
          class=" p-mt-2">
          <div class="p-field-checkbox">
            <p-checkbox
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
              [(ngModel)]="offerData.isAdvancePayment" binary="true" inputId="binary"></p-checkbox>
            <label for="binary"> Plata avans</label>
          </div>
        </div>
        <!-- <mat-form-field
          *ngIf="(offerData.isAdvancePayment && offerData.status!='COMANDA') || (offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0)"
          class="noLabel" style="width:100%">
          <input [disabled]="offerData.status=='COMANDA' || this.offerData.custody?true:false" type="number"
            (keyup)="checkAdvancePayment($event)" [(ngModel)]="offerData.advancePaymentValue" matInput
            placeholder="Suma avans">
        </mat-form-field> -->

        <div
          *ngIf="(offerData.isAdvancePayment && offerData.status!='COMANDA') || (offerData.status=='COMANDA' && offerData.isAdvancePayment && offerData.advancePaymentValue && offerData.advancePaymentValue>0)"
          class="p-mt-4">
          <span class="p-float-label">
            <input
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM' "
              inputId="delivery" [style]="{'text-align':'left','width':'100%'}" type="number" pInputText
              (keyup)="checkAdvancePayment($event)" [(ngModel)]="offerData.advancePaymentValue" />
            <label for="delivery">Suma avans</label>
          </span>
        </div>

        <!-- <mat-form-field class="noLabel" style="width:100%">
          <textarea [disabled]="offerData.status=='COMANDA'?true:false" style="height: 90px;"
            [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.observations" matInput
            placeholder="Observatii"></textarea>
        </mat-form-field> -->

        <div class="p-mt-4">
          <div class="p-float-label" [style]="{'width':'100%'}">
            <textarea
              [disabled]="offerData.status=='COMANDA'|| offerData.status=='REFUZ_LEAD'|| offerData.status=='REFUZ_CLIENT'|| offerData.status=='TRANSFERAT_MULTIDOM'?true:false"
              inputId="obs" [rows]="2" [cols]="30" autoResize="autoResize" pInputTextarea
              [(ngModel)]="offerData.observations"></textarea>
            <label for="obs">Observatii</label>
          </div>
        </div>
      </mat-card>

      <mat-card
        *ngIf="offerData.status!='REFUZ_LEAD' && offerData.status!='TRANSFERAT_MULTIDOM' && offerData.status!='REFUZ_CLIENT'"
        style="margin:10px 10px">
        <div *ngIf="offerData.status!='COMANDA' && offerData.client"
          style="color: #003048;font-family: CarettaOffice, serif;font-size: 25px;text-align:center;">
          <b>FINALIZARE</b>
        </div>

        <button *ngIf="offerData.status!='COMANDA'" (click)="saveOffer('OFERTA',null)" pButton pRipple type="button"
          label="SALVEAZĂ OFERTA" class="p-button-success finishAction"></button>

        <button *ngIf="offerData.status!='COMANDA' && offerData.status!='REFUZ_VALIDARE' && offerData.main "
          (click)="finishCommand()" pButton pRipple type="button" label="PLASEAZĂ COMANDA"
          class="p-button-success finishAction"></button>


        <button *ngIf="offerData" (click)="generateOfferPdf()" pButton pRipple type="button" icon="pi pi-file-pdf"
          [label]="offerData.status!='COMANDA'?'PDF Ofertă':'PDF Proformă'"
          class="p-button-success p-mt-2 customButton"></button>

        <div class=" p-mt-2">
          <div class="p-field-checkbox">
            <p-checkbox [(ngModel)]="includePrices" binary="true" inputId="binary"></p-checkbox>
            <label for="binary"> Include prețuri</label>
          </div>
        </div>

        <div *ngIf="offerData.status=='COMANDA' && canPrintInternallCommandPdf">

          <div (click)="generateCommandsInternalDocumentsPdf()"
            style="display:flex;justify-content: center;align-items:center;padding:10px;color: #fff;font-size: 16px;background:#003048">
            <div class="actionSvg delete" [inlineSVG]="'/assets/images/pdf.svg'"></div>
            <div>Documente interne</div>
          </div>

        </div>

        <div *ngIf="offerData && offerData.categories.length>0"
          style="display:flex;justify-content: center; margin-top:10px">
          <div (click)="reset()"
            style="padding:10px;color: #fff;font-size: 16px;background:#9c9c9c;margin-right: 10px;">
            Copy
          </div>

          <div (click)="loadPrices()" style="padding:10px;color: #fff;font-size: 16px;background:#9c9c9c">
            Paste
          </div>
          <div>
          </div>
        </div>


      </mat-card>

      <div class="p-text-bold" style="text-align:center;color:white;font-family: CarettaOffice;font-size: 22px;">V
        {{version}}</div>
      <div (click)="debug=!debug" style="padding: 5px;text-align: center;cursor: pointer;color:transparent;">
        Detalii
      </div>







    </div>
  </div>
</div>

<p-dialog [closable]="false" [positionTop]="20" [draggable]="false" [modal]="true" [(visible)]="showPluvialTypeModal"
  [style]="{'top': '80px'}">
  <p-header>
    <span class="dialog-title">Sistem pluvial!</span>
  </p-header>


  <mat-radio-group [(ngModel)]="pluvialType" aria-label="Tip sistem pluvial" style="text-align: center;">
    <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="MAT">MAT</mat-radio-button>
    <mat-radio-button style="margin:10px;font-size: 22px;color: #536c79;" value="BR">BR</mat-radio-button>
  </mat-radio-group>

  <div class="confirmSvg" (click)="checkPluvialType();" [inlineSVG]="'/assets/images/checked.svg'">
  </div>
</p-dialog>



<p-dialog [closable]="false" [style]="{width: '900px',height:'85%'}" [modal]="true" [draggable]="false"
  [(visible)]="showClientsModal">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Clienți!</span>
    <div class="closeSvgRight" (click)="showClientsModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>
  <div style="display:flex">
  </div>

  <table id="customers" style="width:100%">
    <tr style="font-size:14px;">
      <th style="width:230px;padding:0;">
        <!-- <mat-form-field style="width:100%" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientNameSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onNameInputChange()" matInput placeholder="Nume">
        </mat-form-field> -->

        <div class="p-float-label p-input-icon-right" [style]="{'width':'100%'}">
          <!-- <i *ngIf="nameSearch" class="pi pi-spin pi-spinner"></i> -->
          <input [debounce]="500" (onDebounce)="onNameInputChange()" class="searchInput" inputId="name"
            [style]="{'text-align':'left','width':'100%'}" type="text" pInputText [(ngModel)]="clientNameSearch" />
          <label for="name">Nume</label>
        </div>

      </th>
      <th style="width:100px;padding:0;">
        <!-- <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientPhoneSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onPhoneInputChange()" matInput placeholder="Telefon">
        </mat-form-field> -->
        <span class="p-float-label">
          <input [debounce]="500" (onDebounce)="onPhoneInputChange()" class="searchInput" inputId="phone"
            [style]="{'text-align':'left','width':'100%'}" type="text" pInputText [(ngModel)]="clientPhoneSearch" />
          <label for="phone">Telefon</label>
        </span>
      </th>
      <th style="width:100px;padding:0;">
        <!-- <mat-form-field style="width:80px" class="example-full-width noLabel">
          <input [debounce]="500" [(ngModel)]="clientFiscalCodeSearch" (keydown.enter)="$event.target.blur();false"
            (onDebounce)="onFiscalCodeChange()" matInput placeholder="CNP/CUI">
        </mat-form-field> -->
        <span class="p-float-label">
          <input [debounce]="500" (onDebounce)="onFiscalCodeChange()" class="searchInput" inputId="cui"
            [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
            [(ngModel)]="clientFiscalCodeSearch" />
          <label for="cui">CNP/CUI</label>
        </span>
      </th>
      <th style="width:100px;">
        Reg. Com.
      </th>
      <th class="unitMeasure">
        Adresa
      </th>

      <th class="unitMeasure">
        <div (click)="addNewClient=true" matTooltip="Adaugare client nou" class="editSvg"
          [inlineSVG]="'/assets/images/add.svg'"></div>
      </th>
    </tr>
    <tr *ngFor="let client of clients">
      <td> {{client.DEN_PART}} </td>
      <td> ****{{client?.TELEFON?.slice(client.TELEFON.length -4)}} </td>
      <td> ****{{client?.CODFISCAL?.slice(client.CODFISCAL.length - 4)}} </td>
      <td> {{client.REGCOM}} </td>
      <!-- <td> {{client.DEN_LOCALIT}} {{client.JUDET}} </td> -->
      <td *ngIf="client.PERSOANAFIZICA==0">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.DEN_LOCALIT.toLowerCase()!=client.JUDET.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}</td>
      <td *ngIf="client.PERSOANAFIZICA==1">{{client.JUDET}}
        {{client.DEN_LOCALIT&& client.JUDET
        &&client.JUDET.toLowerCase()!=client.DEN_LOCALIT.toLowerCase()?client.DEN_LOCALIT:""}}
        {{client.ADRESA}}
      </td>
      <td>
        <div style="display:flex">
          <!-- <div (click)="prefillEditClientData(client)" matTooltip="Adaugare locatie" class="actionSvg"
            [inlineSVG]="'/assets/images/addlocation.svg'"></div> -->
          <div (click)="prefillEditClientData(client)" matTooltip="Editare locatie" class="actionSvg"
            [inlineSVG]="'/assets/images/edit.svg'"></div>
          <div (click)="prefillSelectedClientData(client)" matTooltip="Confirma" style="margin-left: 5px;"
            class="actionSvg" [inlineSVG]="'/assets/images/confirm.svg'"></div>
        </div>
      </td>
      <!-- <td>{{art.data[0].STOC}}</td> -->
    </tr>

  </table>
</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="addNewClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Adaugare client nou!</span>
    <div class="closeSvgRight" (click)="addNewClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>
  <mat-form-field class="noLabel" style="width:100%">
    <mat-select panelClass="clientPanel" [(ngModel)]="newClientData.clientType" required placeholder="Tip Client">
      <mat-option *ngFor="let client of clientTypes" [value]="client">{{client}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <div *ngIf="offerData && newClientData.clientType && !validFiscalCode && newClientData.clientType!='PF'"
    class=" p-mt-1" style="display:flex;">
    <span style="width: calc(100% - 100px);
    margin-right: 10px;" class="p-float-label">
      <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="newClientData.fiscalCode" />
      <label for="street">CUI</label>
    </span>

    <button (click)="validateFiscalCode(newClientData.fiscalCode)" pButton type="button" label="Valideaza"
      class="p-button-outlined"></button>
  </div>



  <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" class="noLabel"
    style="width:100%">
    <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="newClientData.name">
  </mat-form-field>
  <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" class="noLabel"
    style="width:100%">
    <input [disabled]="validFiscalCode" required type="text" matInput
      [placeholder]="newClientData.clientType=='PF'?'CNP':'CUI'" [(ngModel)]="newClientData.fiscalCode">
  </mat-form-field>



  <mat-form-field *ngIf="offerData&& validFiscalCode && newClientData.clientType!='PF'" class="noLabel"
    style="width:100%">
    <input type="text" matInput placeholder="Reg Com" [(ngModel)]="newClientData.tradeRegister">
  </mat-form-field>
  <mat-form-field *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" class="noLabel"
    style="width:100%">
    <input required type="text" matInput placeholder="Telefon" [(ngModel)]="newClientData.phone">
  </mat-form-field>

  <div style="color:blue;margin-top:5px;" *ngIf="validatedPartnerData">{{validatedPartnerData.adresa}}</div>

  <mat-form-field *ngIf="counties && ((offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF'))"
    style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select (selectionChange)='loadLocalities($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-form-field *ngIf="filteredOptions" style="width:100%" class="example-full-width noLabel">
    <input type="text" [(ngModel)]="newClientData.locality" placeholder="Oraș/Localitate" aria-label="Number" matInput
      [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="newClientData.locality=''">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete (optionSelected)='onSelectedLocality($event.option.value)' [displayWith]="displayLocalityName"
      #matAutocompleteLocality="matAutocomplete">
      <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
        {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village
          }})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div *ngIf="(offerData&& validFiscalCode) || (offerData&& newClientData.clientType=='PF')" style="display:flex">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input matInput placeholder="Strada" [(ngModel)]="newClientData.street">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Nr." [(ngModel)]="newClientData.number">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Scara" [(ngModel)]="newClientData.scara">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Bloc" [(ngModel)]="newClientData.bloc">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Etaj" [(ngModel)]="newClientData.etaj">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Ap." [(ngModel)]="newClientData.apartament">
    </mat-form-field>

  </div>
  <div *ngIf="offerData&& (validFiscalCode || newClientData.clientType=='PF')" (click)="insertNewPartner()"
    class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>


  <!-- <div class="p-mt-4">
    <span class="p-float-label">
      <p-dropdown appendTo="body" [autoDisplayFirst]="false" inputId="clientType" name="value" [options]="clientTypes"
        [showClear]="true" [style]="{'width':'100%'}" [(ngModel)]="newClientData.clientType">
      </p-dropdown>
      <label for="clientType">Tip client</label>
    </span>
  </div>

  <div *ngIf="offerData && newClientData.clientType && !validFiscalCode && newClientData.clientType!='PF'"
    class=" p-mt-4" style="display:flex;">
    <span style="width: calc(100% - 100px);margin-right: 10px;" class="p-float-label">
      <input required inputId="CUI" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
        [(ngModel)]="newClientData.fiscalCode" />
      <label for="CUI">CUI</label>
    </span>

    <button (click)="validateFiscalCode(newClientData.fiscalCode)" pButton type="button" label="Valideaza"
      class="p-button-outlined"></button>
  </div>




  <div *ngIf="newClientData.clientType=='PF' || validFiscalCode">

    <div class="p-mt-4">
      <span class="p-float-label">
        <input [disabled]="validFiscalCode" inputId="clientName" [style]="{'text-align':'left','width':'100%'}"
          type="text" pInputText [(ngModel)]="newClientData.name" />
        <label for="clientName">Nume Client</label>
      </span>
    </div>

    <div class="p-mt-4">
      <span class="p-float-label">
        <input [disabled]="validFiscalCode" required inputId="typeClient" [style]="{'text-align':'left','width':'100%'}"
          type="text" pInputText [(ngModel)]="newClientData.fiscalCode" />
        <label for="typeClient">{{newClientData.clientType=='PF'?'CNP':'CUI'}}</label>
      </span>
    </div>
    <div *ngIf="offerData && newClientData.clientType!='PF'" class="p-mt-4">
      <span class="p-float-label">
        <input required inputId="reg" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="newClientData.tradeRegister" />
        <label for="reg">Reg Com</label>
      </span>
    </div>
    <div class="p-mt-4">
      <span class="p-float-label">
        <input required inputId="phone" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="newClientData.phone" />
        <label for="phone">Telefon</label>
      </span>
    </div> -->



  <!-- <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="newClientData.name">
    </mat-form-field>

    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput [placeholder]="newClientData.clientType=='PF'?'CNP':'CUI'"
        [(ngModel)]="newClientData.fiscalCode">
    </mat-form-field>
    <mat-form-field *ngIf="offerData && newClientData.clientType!='PF'" class="noLabel" style="width:100%">
      <input type="text" matInput placeholder="Reg Com" [(ngModel)]="newClientData.tradeRegister">
    </mat-form-field>
    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput placeholder="Telefon" [(ngModel)]="newClientData.phone">
    </mat-form-field> -->


  <!-- <div class=" p-mt-4" *ngIf="counties">
      <span class="p-float-label">
        <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownCountyClient" [options]="counties.rows"
          [filter]="true" [showClear]="true" [style]="{'width':'100%'}" optionLabel="countyName"
          emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="countyName" (onChange)="loadLocalities($event)">
        </p-dropdown>
        <label for="dropdownCountyClient">Judet</label>
      </span>
    </div>

    <div class=" p-mt-4" *ngIf="filteredOptions">
      <span class="p-float-label">
        <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownLocality"
          [(ngModel)]="newClientData.locality" [options]="filteredOptions" [filter]="true" [showClear]="true"
          (onClick)="initLocalities()" [style]="{'width':'100%'}" emptyFilterMessage="Nu au fost gasite rezultate!"
          optionLabel="localityName" filterBy="localityName">
          <ng-template let-loc pTemplate="item">
            <div style="font-size:14px;margin-top:4px"> {{loc.localityName}} <span
                *ngIf="loc && loc.localityName!=loc.village">({{loc.village }})</span></div>
          </ng-template>
        </p-dropdown>
        <label for="dropdownLocality">Oraș/Localitate</label>
      </span>
    </div>



    <div style="display:flex">
      <mat-form-field style="width:100%" class="example-full-width noLabel">
        <input matInput placeholder="Strada" [(ngModel)]="newClientData.street">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Nr." [(ngModel)]="newClientData.number">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Scara" [(ngModel)]="newClientData.scara">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Bloc" [(ngModel)]="newClientData.bloc">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Etaj" [(ngModel)]="newClientData.etaj">
      </mat-form-field>
      <mat-form-field style="width:50px" class="example-full-width noLabel">
        <input matInput placeholder="Ap." [(ngModel)]="newClientData.apartament">
      </mat-form-field>

    </div>
    <div (click)="insertNewPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

  </div> -->

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="editClient">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Editare date client!</span>
    <div class="closeSvgRight" (click)="editClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="editClientData.name">
  </mat-form-field>
  <mat-form-field *ngIf="offerData && !editClientData.fiscalCode" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>

  <mat-form-field *ngIf="editClientData.isPF" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="CNP" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>
  <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Reg Com" [(ngModel)]="editClientData.tradeRegister">
  </mat-form-field>
  <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
  </mat-form-field>
  <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
    <input required type="text" matInput placeholder="Telefon" [(ngModel)]="editClientData.phone">
  </mat-form-field>

  <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select [(value)]="selectedCounty" (selectionChange)='loadLocalities($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-form-field style="width:100%" class="example-full-width noLabel">
    <input type="text" [(ngModel)]="editClientData.locality" placeholder="Oraș/Localitate" aria-label="Number" matInput
      [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="editClientData.locality=''">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #matAutocompleteLocality="matAutocomplete" [displayWith]="displayLocalityName">
      <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
        {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village }})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div style="display:flex">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input matInput placeholder="Strada" [(ngModel)]="editClientData.street">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Nr." [(ngModel)]="editClientData.number">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Scara" [(ngModel)]="editClientData.scara">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Bloc" [(ngModel)]="editClientData.bloc">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Etaj" [(ngModel)]="editClientData.etaj">
    </mat-form-field>
    <mat-form-field style="width:50px" class="example-full-width noLabel">
      <input matInput placeholder="Ap." [(ngModel)]="editClientData.apartament">
    </mat-form-field>

  </div>
  <div (click)="editPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="stockDetail">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Stoc pe gestiuni!</span>
    <div class="closeSvgRight" (click)="stockDetail=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <table id="customers" style="width:100%">
    <tr style="font-size:14px;">
      <th class="quantity">Gestiune</th>
      <th class="quantity">Stoc</th>
    </tr>
    <tr *ngFor="let art of stockDetail">
      <ng-container>
        <td>{{art.DEN_GEST}}</td>
        <td>{{art.STOCKQUANTITY}}</td>
      </ng-container>


    </tr>

  </table>

</p-dialog>

<!-- Adresa livrare alternativa -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="showAlternativeDelivery">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Adresa livrare alternativa!</span>
    <div class="closeSvgRight" (click)="closeAlternativeAddress()" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>

  <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
    <mat-label>Judet</mat-label>
    <mat-select [(ngModel)]="selectedValueCounty" (selectionChange)='setCountyDelivery($event)'>
      <mat-option *ngFor="let county of counties.rows" [value]="county">
        {{county.countyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field *ngIf="filteredOptions" style="width:100%" class="example-full-width noLabel">
    <mat-label>Localitate</mat-label>
    <mat-select (selectionChange)='setLocalityDelivery($event)'>
      <mat-option *ngFor="let locality of localities.rows" [value]="locality">
        {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village }})</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <div class="p-mt-4 p-mb-2" *ngIf="counties">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownCountyClient" [options]="counties.rows"
        [(ngModel)]="editClientData.county" [filter]="true" [showClear]="true" [style]="{'width':'100%'}"
        optionLabel="countyName" emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="countyName"
        (onChange)="loadLocalities($event)">
      </p-dropdown>
      <label for="dropdownCountyClient">Judet</label>
    </span>
  </div>

  <div class="p-mt-4 p-mb-3" *ngIf="filteredOptions">
    <span class="p-float-label">
      <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownLocality"
        [(ngModel)]="editClientData.locality" [options]="filteredOptions" [filter]="true" [showClear]="true"
        [style]="{'width':'100%'}" emptyFilterMessage="Nu au fost gasite rezultate!" optionLabel="localityName"
        filterBy="localityName">
        <ng-template let-loc pTemplate="item">
          <div style="font-size:14px;margin-top:4px"> {{loc.localityName}} <span
              *ngIf="loc && loc.localityName!=loc.village">({{loc.village }})</span></div>
        </ng-template>
      </p-dropdown>
      <label for="dropdownLocality">Oraș/Localitate</label>
    </span>
  </div> -->

  <div style="display:flex" *ngIf="filteredOptions">
    <mat-form-field style="width:100%" class="example-full-width noLabel">
      <input [(ngModel)]="deliverStreetAlternative" matInput placeholder="Strada">
    </mat-form-field>


  </div>
  <div (click)="alternativeDeliveryAdress()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>


<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
  [(visible)]="cancelCommandFlag">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Anulare comanda
      {{offerData&&offerData.data?offerData.data.commandNumber:''}}</span>
    <div class="closeSvgRight" (click)="cancelCommandFlag=false" [inlineSVG]="'/assets/images/close.svg'">
    </div>
  </p-header>
  <div>Doriti anularea comenzii?</div>
  <mat-form-field class="noLabel" style="width:100%">
    <textarea style="height: 90px;" [(ngModel)]="cancelObservations" matInput placeholder="Motiv anulare"></textarea>
  </mat-form-field>
  <div (click)="cancelCommand()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
  </div>

</p-dialog>


<p-dialog [draggable]="false" showEffect="fade" [closable]="false" [(visible)]="showImage">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">SCHITE </span>
    <div class="closeSvgRight" (click)="showImage=null" [inlineSVG]="'/assets/images/close.svg'"></div>
  </p-header>

  <img *ngIf="image" style="max-width: 800px; margin:10px;"
    [src]="'https://api.e-node.ro/drive/download?user='+image.user+'&filename='+image.name">
</p-dialog>


<p-dialog [header]="getTotalSurface() >0?'TOTAL SCHITE : '+round2Decimals(getTotalSurface())+'m2':'SCHITE'"
  (onShow)="showDialogMaximized($event)" #dialDetail [(visible)]="showDraw" [modal]="true" [style]="{width: '50vw'}"
  [maximizable]="true" [draggable]="false" [resizable]="false">

  <p-tabView [(activeIndex)]="activeIndex1" (onChange)="handleChange($event)" [scrollable]="true">

    <ng-container *ngFor="let draw of drawArticle?.data[0]?.drawings ;index as i;">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-eye"></i>
          <span>Schita {{i+1}}</span>
        </ng-template>
      </p-tabPanel>


    </ng-container>
    <p-tabPanel *ngIf="offerData && offerData.status!='COMANDA'">
      <ng-template pTemplate="header">
        <i class="pi pi-image"></i>
        <span>Desenare</span>
      </ng-template>
    </p-tabPanel>

  </p-tabView>


  <div style="background-color: white;display:flex;    justify-content: center;">
    <div>
      <canvas (click)="drawCanvas($event)" #myCanvas width="960" height="600"
        style="border:1px solid #d3d3d3;"></canvas>
    </div>
    <div style="width:270px;    margin: 10px;" *ngIf="offerData">
      <div *ngFor="let point of drawingObject.points; index as i" style="margin:7px;">
        <div *ngIf="i>0" class="p-inputgroup">
          <div style="color: #003048; padding: 3 px 0; text-align: center;
            font-size: 20px; margin: 6px 3px 0 3px;font-family: CarettaOffice;width: 42px;"> M{{i}}</div>
          <p-inputNumber [disabled]="offerData.status=='COMANDA'" [inputStyle]="{'text-align': 'right'}"
            [(ngModel)]="point.value" [useGrouping]="false" mode="decimal" [placeholder]="'Muchia '+i"
            [minFractionDigits]="0" [maxFractionDigits]="1">
          </p-inputNumber>
          <span class="p-inputgroup-addon">cm</span>
        </div>
        <div *ngIf="i>0 && i<drawingObject.points.length-1" class="p-inputgroup">
          <div style="color: #003048; padding: 3 px 0; text-align: center;
            font-size: 20px; margin: 6px 3px 0 3px;font-family: CarettaOffice;width: 42px;"> U{{i}}</div>
          <p-inputNumber [disabled]="offerData.status=='COMANDA'" [inputStyle]="{'text-align': 'right'}"
            [(ngModel)]="point.angle" [useGrouping]="false" mode="decimal" [placeholder]="'Unghiul '+i"
            [minFractionDigits]="0" [maxFractionDigits]="1">
          </p-inputNumber>
          <span class="p-inputgroup-addon"> °</span>
        </div>
      </div>

      <div *ngIf="drawingObject.points.length>1" style="display:flex;" style="margin:7px;">
        <div style="color: #003048; padding: 3 px 0; text-align: center;
              font-size: 20px; margin: 6px 3px 0 3px;font-family: CarettaOffice;"> Lungime</div>
        <div class="p-inputgroup">
          <p-inputNumber [disabled]="offerData.status=='COMANDA'" [inputStyle]="{'text-align': 'right'}"
            [(ngModel)]="drawingObject.customLength" [useGrouping]="false" mode="decimal" placeholder="Lungime"
            [minFractionDigits]="0" [maxFractionDigits]="1">
          </p-inputNumber>
          <span class="p-inputgroup-addon">cm</span>
        </div>
      </div>

      <div *ngIf="drawingObject.points.length>1" style="display:flex;" style="margin:7px;">
        <div style="color: #003048; padding: 3 px 0; text-align: center;
              font-size: 20px; margin: 6px 3px 0 3px;font-family: CarettaOffice;"> Cantitate</div>
        <div class="p-inputgroup">


          <p-inputNumber [disabled]="offerData.status=='COMANDA'" [inputStyle]="{'text-align': 'right'}"
            [(ngModel)]="drawingObject.customQuantity" [useGrouping]="false" mode="decimal" placeholder="Cantitate">
          </p-inputNumber>
          <span class="p-inputgroup-addon">buc</span>
        </div>
      </div>

      <div *ngIf="drawingObject.points.length>1" style="display:flex;" style="margin:7px;">
        <div style="color: #003048; padding: 3 px 0; text-align: center;
              font-size: 20px; margin: 6px 3px 0 3px;font-family: CarettaOffice;"> Observatii schita</div>
        <div><input [disabled]="offerData.status=='COMANDA'" placeholder="Observatii schita" inputId="street"
            [style]="{'text-align':'left','width':'100%'}" type="text" pInputText [(ngModel)]="observation" /></div>
      </div>

      <button *ngIf="offerData.status!='COMANDA' && drawingObject.points.length>1" style="margin:7px;"
        (click)="redrawCanvas(null)" pButton pRipple type="button" label="Calculeaza"
        class="p-button-secondary "></button>

      <!-- <button *ngIf="offerData.status!='COMANDA' && drawingObject.points.length>1" style="margin:7px;"
        (click)="undoPoint()" pButton pRipple type="button" label="Undo" class="p-button-secondary "></button> -->

      <button *ngIf="offerData.status!='COMANDA' && showRAL && !drawingObject.arrow.p1 && !drawingObject.arrow.p2"
        style="margin:7px;" (click)="activateRAL()" pButton pRipple type="button" label="RAL"
        class="p-button-secondary "></button>

      <button *ngIf="offerData.status!='COMANDA' && drawingObject.arrow.p1 && drawingObject.arrow.p2"
        style="margin:7px;" (click)="deleteRAL()" pButton pRipple type="button" label="Sterge RAL"
        class="p-button-secondary "></button>

      <div *ngIf="customTotal >0" style="color: #003048; padding: 3 px 0; text-align: center;
              font-size: 20px; margin: 6px 3px 0 3px;font-family: CarettaOffice;"> TOTAL SCHITA :
        {{round2Decimals(customTotal)}} m<sup>2</sup>
      </div>

      <button *ngIf="offerData.status!='COMANDA'" style="margin:7px;" (click)="clearCanvas();customTotal=0;" pButton
        pRipple type="button" label="Renunta" class="p-button-secondary "></button>

      <button *ngIf="offerData.status!='COMANDA' && drawingObject.points.length>1" style="margin:7px;"
        (click)="saveCanvas()" pButton pRipple type="button" label="Salveaza" class="p-button-secondary "></button>
      <button *ngIf="offerData.status!='COMANDA' && drawingObject.points.length>1" style="margin:7px;"
        (click)="removeDraw(drawingObject)" pButton pRipple type="button" label="Sterge schita"
        class="p-button-secondary "></button>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="showDraw=false;saveCumulateQuantity()" label="Ok" styleClass="p-button-text">
    </p-button>
  </ng-template>
</p-dialog>


<p-dialog (onShow)="showFenceMaximized($event)" #fenceDetail [closable]="false"
  [style]="{width: '1200px','max-height': '95%'}" [draggable]="false" [modal]="true" [(visible)]="confEnabled">
  <p-header>
    <span *ngIf="selectedFenceType" class="dialog-title" style="width:80%;margin-top:0;">
      {{selectedFenceType.name}}
    </span>
    <div *ngIf="offerData?.status=='COMANDA' || !selectedFenceCategory" class="closeSvgRight"
      (click)="confEnabled=false;" [inlineSVG]="'/assets/images/close.svg'">
    </div>

    <button *ngIf="selectedFenceType && offerData.status!='COMANDA'" (click)="changeFenceModal=true" pButton pRipple
      type="button" label="Schimba tip gard" class="p-button-success"
      style="font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
    margin: auto;    margin-left: 10px !important; cursor: pointer; position: absolute;right: 20px; top: 5px;"></button>
  </p-header>

  <p-tabView [(activeIndex)]="activeFenceIndex" (onChange)="activateFenceTab($event)" *ngIf="selectedFenceType">
    <p-tabPanel *ngIf="offerData.status!='COMANDA'">
      <ng-template pTemplate="header">

        <i class="pi pi-check"></i>
        <span>Configuratie noua</span>
      </ng-template>
      <div *ngIf="selectedFenceCategory">
        <p-divider align="center" type="dashed">
          <b>Cote generale</b>
        </p-divider>
        <div style="display:flex;align-content: center;
        justify-content: space-evenly;">
          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">Numar panouri</div>
            <div
              *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
              {{numarPanouri}}</div>
            <input
              *ngIf="!(selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115')"
              class="fenceInput" (keyup)="recalculateDistStalpi($event)" [(ngModel)]="numarPanouri" type="number"
              lang="en" step="0.01">


          </div>
          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                Lungime gard</div>
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                (m) </div>

              <span
                *ngIf="selectedFenceType.name!='ZEBRA.VERTICAL Y 118' && selectedFenceType.name!='ZEBRA.VERTICAL Y 115'">Distanta
                stalpi (0.5-4 m)</span>


            </div>
            <input class="fenceInput" (keyup)="recalculateDistStalpi($event)" [(ngModel)]="distantaStalpi" type="number"
              lang="en" step="0.01">
          </div>
          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
          font-size: 24px;
          font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                Distanta intre sipci</div>
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                (cm)
              </div>
            </div>
            <span
              *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
              <input class="fenceInput" (keyup)="changeInaltimeStalpi($event)" [(ngModel)]=" distantaSipci"
                type="number" lang="en" step="0.01">
            </span>
          </div>


          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                Inaltime sipca</div>

              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                (0.5-4 m)</div>
              <span
                *ngIf="selectedFenceType.name!='ZEBRA.ORIZONTAL CASETA X 140' && selectedFenceType.name!='ZEBRA.VERTICAL Y 118' && selectedFenceType.name!='ZEBRA.VERTICAL Y 115'">Inaltime
                stalp (m)</span>
              <span *ngIf="selectedFenceType.name=='ZEBRA.ORIZONTAL CASETA X 140'">Distanta intre casete (cm)</span>

            </div>
            <span *ngIf="selectedFenceType.name=='ZEBRA.ORIZONTAL CASETA X 140'">
              <input class="fenceInput" (keyup)="changeInaltimeStalpi($event)" [(ngModel)]="distantaSipci" type="number"
                lang="en" step="0.01">
            </span>
            <span
              *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
              <input class="fenceInput" (keyup)="changeInaltimeStalpi($event)" [(ngModel)]=" inaltimeStalpi"
                type="number" lang="en" step="0.01">
            </span>
            <span
              *ngIf="selectedFenceType.name!='ZEBRA.ORIZONTAL CASETA X 140' && selectedFenceType.name!='ZEBRA.VERTICAL Y 118' && selectedFenceType.name!='ZEBRA.VERTICAL Y 115'">
              <p-dropdown (onChange)="changeInaltimeStalpi($event)" [options]="selectedFenceType.heights"
                [(ngModel)]="inaltimeStalpi" placeholder="Inaltime stalpi" optionLabel="name" optionValue="value"
                [showClear]="true">
                <ng-template let-country pTemplate="item">
                  <div class="country-item">

                    <div>{{country.name}}m ({{getSipci(country.name)}} lamele)</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </span>
          </div>

          <div *ngIf="selectedFenceType.name=='ZEBRA.ORIZONTAL CASETA X 140'" style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <span>Numar casete per panou</span>
            </div>
            <input class="fenceInput" (keyup)="recalculateDistStalpi($event)" [(ngModel)]="sipci" type="number"
              lang="en" step="0.01">
          </div>

          <div *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'"
            style="text-align: center;">
            <div class="field-radiobutton">
              <p-radioButton [disabled]="this.offerData?.fences && this.offerData.fences.length>0" name="city"
                value="dreapta" [(ngModel)]="tipSipca" inputId="city1">
              </p-radioButton>
              <label for="city1">Sipca DREAPTA</label>
            </div>
            <div class="field-radiobutton">
              <p-radioButton [disabled]="this.offerData?.fences && this.offerData.fences.length>0" name="city"
                value="rotunda" [(ngModel)]="tipSipca" inputId="city2">
              </p-radioButton>
              <label for="city2">Sipca ROTUNDA</label>
            </div>
          </div>





        </div>
        <p-divider align="center" type="dashed">
          <b>Calcul</b>
        </p-divider>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 121'" style="display:flex;">
          <div style="width:100%">

            <div>
              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{sipci>0?sipci:0}} X </div>
                <div style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';">
                  LAMELA FRONT 30 X121 367</div>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
                <div *ngIf="sipciObs" style="margin-left: 5px;font-size:15px;
              color: #0072ff;">Observatii {{sipciObs}}</div>
              </div>

              <p-divider></p-divider>
              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{profil>0?profil:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                  PROFIL METALIC DE FIXARE PE STALP 35 X121 368
                </div>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeProfil>0?lungimeProfil:0}} </div>
                <div>Lungime profil</div>
              </div>
              <p-divider></p-divider>
              <div style=" display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{rigle>0?rigle:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  RIGLA
                  DE RIGIDIZARE X 121 391
                </div>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeRigle>0?lungimeRigle:0}} </div>
                <div>Lungime rigla</div>
              </div>


              <p-divider></p-divider>
              <div style="    display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{rigle>0?rigle:0}} X </div>
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  RIGLA DE RIGIDIZARE X 121 396
                </span>
              </div>

              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeRigle>0?lungimeRigle:0}} </div>
                <div>Lungime capac rigla</div>
              </div>
              <p-divider></p-divider>
              <div style="   display:flex; margin: 15px 5px 10px 5px">
                <!-- <input class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01"> -->
                <div class="fenceQuantity">
                  {{capace>0?capace:0}} X </div>
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 35 X 121 369
                </span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeCapaceinchidere>0?lungimeCapaceinchidere:0}} </div>
                <div>Lungime capac inchidere</div>
              </div>


              <p-divider></p-divider>
              <div style="   display:flex; margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{popnituri>0?popnituri:0}}</div>
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  POP NITURI COLORATE 100 BUC 194 </span>
              </div>
              <div *ngIf="offerData.status!='COMANDA'"
                style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
                <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                  class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
                <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                  class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
              </div>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <img style="width:190px" src="./assets/caretta/schitaX121.png">
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 121 2F'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{sipci>0?sipci:0}} X </div>
                <div style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';">
                  LAMELA FRONT 30 X121 2F 392</div>
              </div>

              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
              </div>

              <p-divider></p-divider>

              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{profil>0?profil:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                  PROFIL METALIC DE FIXARE PE STALP 70 X121 2F 388
                </div>
              </div>

              <div
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 70 X121 2F
                </span>
              </div>
            </div>
            <div
              style="    color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>

            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>
            <div *ngIf="offerData.status!='COMANDA'"
              style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
              <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
              <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135'" style="display:flex;">
          <div style="width:100%">

            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  LAMELA FRONT 65 X135 365</span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
              </div>
              <div *ngIf="sipciObs">Observatii {{sipciObs}}</div>
              <p-divider></p-divider>
              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{profil>0?profil:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                  PROFIL METALIC DE FIXARE PE STALP 25 X135 366
                </div>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style=" margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">RIGLA
                  DE RIGIDIZARE X135 390
                </span>
              </div>
              <div
                style="color: rgb(0, 48, 72);font-size: 20px; font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  RIGLA DE RIGIDIZARE X135 397 6005
                </span>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime capac rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 25 X135 370
                </span>
              </div>
            </div>
            <div
              style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>
            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>
            <div *ngIf="offerData.status!='COMANDA'"
              style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
              <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
              <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135 2F'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  LAMELA FRONT 65 X135 2F 404
                </span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
              </div>
              <p-divider></p-divider>

              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{profil>0?profil:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                  PROFIL METALIC DE FIXARE PE STALP 50 X135 2F 405
                </div>
              </div>


              <div
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 50 X135 2F 406
                </span>
              </div>
            </div>
            <div
              style="    color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>

            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>
            <div *ngIf="offerData.status!='COMANDA'"
              style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
              <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
              <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 174'" style="display:flex;">
          <div style="width:100%">

            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  LAMELA 170MM X174 360</span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
              </div>
              <div *ngIf="sipciObs">Observatii {{sipciObs}}</div>
              <p-divider></p-divider>

              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{profil>0?profil:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                  PROFIL METALIC DE FIXARE PE STALP X174 361
                </div>
              </div>

              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style=" margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">RIGLA
                  DE RIGIDIZARE X174 362
                </span>
              </div>
              <div
                style="color: rgb(0, 48, 72);font-size: 20px; font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  RIGLA DE RIGIDIZARE X174 395
                </span>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime capac rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE X174 363
                </span>
              </div>
            </div>
            <div
              style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>
            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>
            <div *ngIf="offerData.status!='COMANDA'"
              style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
              <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
              <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL CASETA X 140'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  CASETA INTERIOR/EXTERIOR 140 GARD ORIZONTAL
                </span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime caseta</div>
              </div>

              <p-divider></p-divider>

              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{profil>0?profil:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                  PROFIL METALIC DE FIXARE PE STALP 25 CASETA X140 398
                </div>
              </div>

              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
            </div>

            <p-divider></p-divider>

            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194</span>
            </div>
            <div *ngIf="offerData.status!='COMANDA'"
              style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
              <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
              <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.VERTICAL Y 115'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div *ngIf="tipSipca=='rotunda'" style="    margin: 15px 5px 10px 5px">
                <input class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD BIVALENTA Y115 364
                </span>
              </div>
              <div *ngIf="tipSipca=='rotunda'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeSipci" type="number" lang="en" step="0.01">
                Lungime lamela
              </div>

              <p-divider></p-divider>
              <div *ngIf="tipSipca=='dreapta'" style="    margin: 15px 5px 10px 5px">
                <input class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD BIVALENTA CAPAT DREPT Y115 377
                </span>
              </div>
              <div *ngIf="tipSipca=='dreapta'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeSipci" type="number" lang="en" step="0.01">
                Lungime lamela
              </div>
              <p-divider></p-divider>

            </div>

            <p-divider></p-divider>

            <!-- <div style="    margin: 15px 5px 10px 5px">
              <input class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en" step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150</span>
            </div> -->
            <div style="display:flex;margin: 15px 5px 10px 5px">
              <div class="fenceQuantity">
                {{popnituri>0?popnituri:0}}</div>
              <div
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150
              </div>
            </div>
            <div *ngIf="offerData.status!='COMANDA'"
              style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
              <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
              <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.VERTICAL Y 118'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div *ngIf="tipSipca=='rotunda'" style="    margin: 15px 5px 10px 5px">
                <input class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD Y118 358
                </span>
              </div>
              <div *ngIf="tipSipca=='rotunda'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="inaltimeStalpi" type="number" lang="en" step="0.01">
                Lungime lamela
              </div>

              <p-divider></p-divider>
              <div *ngIf="tipSipca=='dreapta'" style="    margin: 15px 5px 10px 5px">
                <input class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD CAPAT DREPT Y118 359
                </span>
              </div>
              <div *ngIf="tipSipca=='dreapta'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="inaltimeStalpi" type="number" lang="en" step="0.01">
                Lungime lamela
              </div>

              <p-divider></p-divider>

            </div>

            <p-divider></p-divider>

            <!-- <div style="    margin: 15px 5px 10px 5px">
              <input class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en" step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150</span>
            </div> -->

            <div style="display:flex;margin: 15px 5px 10px 5px">
              <div class="fenceQuantity">
                {{popnituri>0?popnituri:0}}</div>
              <div
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150
              </div>
            </div>

            <div *ngIf="offerData.status!='COMANDA'"
              style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
              <button (click)="adaugaPanou()" pButton pRipple type="button" label="ADAUGA PANOU"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
  margin: auto;"></button>
              <button (click)="populateQuantities()" pButton pRipple type="button" label="SALVEAZA"
                class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
            margin: auto;"></button>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

      </div>
    </p-tabPanel>
    <p-tabPanel *ngFor="let fence of offerData.fences">
      <ng-template pTemplate="header">

        <i class="pi pi-check"></i>
        <span>Panou</span>

        (<i class="pi pi-chevron-right"></i>
        {{fence.distantaStalpi}}X
        <i class="pi pi-chevron-up"></i>
        {{fence.inaltimeStalpi}}m)
      </ng-template>
      <div *ngIf="selectedFenceCategory">
        <p-divider align="center" type="dashed">
          <b>Cote generale</b>
        </p-divider>
        <div style="display:flex;align-content: center;
        justify-content: space-evenly;">
          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">Numar panouri</div>
            <div
              *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
              {{numarPanouri}}</div>
            <input disabled
              *ngIf="!(selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115')"
              class="fenceInput" (keyup)="recalculateDistStalpi($event)" [(ngModel)]="numarPanouri" type="number"
              lang="en" step="0.01">


          </div>
          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                Lungime gard</div>
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                (m) </div>

              <span
                *ngIf="selectedFenceType.name!='ZEBRA.VERTICAL Y 118' && selectedFenceType.name!='ZEBRA.VERTICAL Y 115'">Distanta
                stalpi (0.5-4 m)</span>


            </div>
            <input disabled class="fenceInput" (keyup)="recalculateDistStalpi($event)" [(ngModel)]="distantaStalpi"
              type="number" lang="en" step="0.01">
          </div>
          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
          font-size: 24px;
          font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                Distanta intre sipci</div>
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                (cm)
              </div>
            </div>
            <span
              *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
              <input disabled class="fenceInput" (keyup)="changeInaltimeStalpi($event)" [(ngModel)]="distantaSipci"
                type="number" lang="en" step="0.01">
            </span>
          </div>

          <div *ngIf="selectedFenceType.name=='ZEBRA.ORIZONTAL CASETA X 140'" style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <span>Distanta intre casete (cm)</span>
            </div>
            <input disabled class="fenceInput" (keyup)="recalculateDistStalpi($event)" [(ngModel)]="distantaSipci"
              type="number" lang="en" step="0.01">
          </div>

          <div *ngIf="selectedFenceType.name=='ZEBRA.ORIZONTAL CASETA X 140'" style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <span>Numar casete per panou</span>
            </div>
            <input disabled class="fenceInput" (keyup)="recalculateDistStalpi($event)" [(ngModel)]="sipci" type="number"
              lang="en" step="0.01">
          </div>


          <div style="text-align: center;">
            <div style="color: rgb(0, 48, 72);
        font-size: 24px;
        font-weight: bold;    font-family: 'CarettaOffice';margin: 15px 5px;">
              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                Inaltime sipca</div>

              <div
                *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
                (0.5-4 m)</div>
              <span
                *ngIf="selectedFenceType.name!='ZEBRA.ORIZONTAL CASETA X 140' && selectedFenceType.name!='ZEBRA.VERTICAL Y 118' && selectedFenceType.name!='ZEBRA.VERTICAL Y 115'">Inaltime
                stalp (m)</span>


            </div>

            <span
              *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'">
              <input disabled class="fenceInput" (keyup)="changeInaltimeStalpi($event)" [(ngModel)]=" inaltimeStalpi"
                type="number" lang="en" step="0.01">
            </span>
            <span
              *ngIf="selectedFenceType.name!='ZEBRA.ORIZONTAL CASETA X 140' && selectedFenceType.name!='ZEBRA.VERTICAL Y 118' && selectedFenceType.name!='ZEBRA.VERTICAL Y 115'">
              <p-dropdown [disabled]="'true'" (onChange)="changeInaltimeStalpi($event)"
                [options]="selectedFenceType.heights" [(ngModel)]="inaltimeStalpi" placeholder="Inaltime stalpi"
                optionLabel="name" optionValue="value" [showClear]="true">
                <ng-template let-country pTemplate="item">
                  <div class="country-item">

                    <div>{{country.name}}m ({{getSipci(country.name)}} lamele)</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </span>
          </div>
          <div *ngIf="selectedFenceType.name=='ZEBRA.VERTICAL Y 118' || selectedFenceType.name=='ZEBRA.VERTICAL Y 115'"
            style="text-align: center;">
            <div class="field-radiobutton">
              <p-radioButton [disabled]="this.offerData?.fences && this.offerData.fences.length>0" name="city"
                value="dreapta" [(ngModel)]="tipSipca" inputId="city1">
              </p-radioButton>
              <label for="city1">Sipca DREAPTA</label>
            </div>
            <div class="field-radiobutton">
              <p-radioButton [disabled]="this.offerData?.fences && this.offerData.fences.length>0" name="city"
                value="rotunda" [(ngModel)]="tipSipca" inputId="city2">
              </p-radioButton>
              <label for="city2">Sipca ROTUNDA</label>
            </div>
          </div>





        </div>
        <p-divider align="center" type="dashed">
          <b>Calcul</b>
        </p-divider>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 121'" style="display:flex;">
          <div style="width:100%">

            <div>
              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{sipci>0?sipci:0}} X </div>
                <div style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';">
                  LAMELA FRONT 30 X121 367</div>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
                <div *ngIf="sipciObs" style="margin-left: 5px;font-size:15px;
              color: #0072ff;">Observatii {{sipciObs}}</div>
              </div>
              <p-divider></p-divider>
              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{profil>0?profil:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                  PROFIL METALIC DE FIXARE PE STALP 35 X121 368
                </div>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeProfil>0?lungimeProfil:0}} </div>
                <div>Lungime profil</div>
              </div>
              <p-divider></p-divider>
              <div style=" display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{rigle>0?rigle:0}} X </div>
                <div
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  RIGLA
                  DE RIGIDIZARE X 121 391
                </div>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeRigle>0?lungimeRigle:0}} </div>
                <div>Lungime rigla</div>
              </div>


              <p-divider></p-divider>
              <div style="    display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{rigle>0?rigle:0}} X </div>
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  RIGLA DE RIGIDIZARE X 121 396
                </span>
              </div>

              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeRigle>0?lungimeRigle:0}} </div>
                <div>Lungime capac rigla</div>
              </div>
              <p-divider></p-divider>
              <div style="   display:flex; margin: 15px 5px 10px 5px">
                <!-- <input class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01"> -->
                <div class="fenceQuantity">
                  {{capace>0?capace:0}} X </div>
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 35 X 121 369
                </span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeCapaceinchidere>0?lungimeCapaceinchidere:0}} </div>
                <div>Lungime capac inchidere</div>
              </div>


              <p-divider></p-divider>
              <div style="   display:flex; margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{popnituri>0?popnituri:0}}</div>
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  POP NITURI COLORATE 100 BUC 194 </span>
              </div>
            </div>
          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <img style="width:190px" src="./assets/caretta/schitaX121.png">
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 121 2F'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div style="display:flex;margin: 15px 5px 10px 5px">
                <div class="fenceQuantity">
                  {{sipci>0?sipci:0}} X </div>
                <div style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';">
                  LAMELA FRONT 30 X121 2F 392</div>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
              </div>
              <p-divider></p-divider>

              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="profil" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">PROFIL
                  METALIC DE FIXARE PE STALP 70 X121 2F 388</span>
              </div>
              <div
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 70 X121 2F
                </span>
              </div>
            </div>
            <div
              style="    color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>

            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>

          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135'" style="display:flex;">
          <div style="width:100%">

            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  LAMELA FRONT 65 X135 365</span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
              </div>
              <div *ngIf="sipciObs">Observatii {{sipciObs}}</div>
              <p-divider></p-divider>
              <div style="margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="profil" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">PROFIL
                  METALIC DE FIXARE PE STALP 25 X135 366 </span>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style=" margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">RIGLA
                  DE RIGIDIZARE X135 390
                </span>
              </div>
              <div
                style="color: rgb(0, 48, 72);font-size: 20px; font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  RIGLA DE RIGIDIZARE X135 397 6005
                </span>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime capac rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 25 X135 370
                </span>
              </div>
            </div>
            <div
              style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>
            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>

          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 135 2F'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  LAMELA FRONT 65 X135 2F 404
                </span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime lamela</div>
              </div>

              <p-divider></p-divider>

              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="profil" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">PROFIL
                  METALIC DE FIXARE PE STALP 50 X135 2F 405</span>
              </div>
              <div
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE 50 X135 2F 406
                </span>
              </div>
            </div>
            <div
              style="    color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>

            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>

          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL X 174'" style="display:flex;">
          <div style="width:100%">

            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  LAMELA 170MM X174 360</span>
              </div>
              <div
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeSipci" type="number" lang="en" step="0.01">
                Lungime lamela
              </div>
              <div *ngIf="sipciObs">Observatii {{sipciObs}}</div>
              <p-divider></p-divider>
              <div style="margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="profil" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">PROFIL
                  METALIC DE FIXARE PE STALP X174 361</span>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
              <div style=" margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">RIGLA
                  DE RIGIDIZARE X174 362
                </span>
              </div>
              <div
                style="color: rgb(0, 48, 72);font-size: 20px; font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="rigle" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  RIGLA DE RIGIDIZARE X174 395
                </span>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="lungimeRigle" type="number" lang="en" step="0.01">
                Lungime capac rigla
              </div>
              <p-divider></p-divider>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="capace" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">CAPAC
                  INCHIDERE X174 363
                </span>
              </div>
            </div>
            <div
              style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
              <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                [(ngModel)]="lungimeCapaceinchidere" type="number" lang="en" step="0.01">
              Lungime capac
            </div>
            <p-divider></p-divider>
            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194 </span>
            </div>

          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.ORIZONTAL CASETA X 140'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  CASETA INTERIOR/EXTERIOR 140 GARD ORIZONTAL
                </span>
              </div>
              <div
                style="display:flex;color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <div class="fenceItemDimension">
                  {{lungimeSipci-0.01>0?round2Decimals(lungimeSipci-0.01):0}} </div>
                <div>Lungime caseta</div>
              </div>
              <p-divider></p-divider>
              <div style="margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="profil" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">PROFIL
                  METALIC DE FIXARE PE STALP 25 CASETA X140 398</span>
              </div>
              <div style="    color: rgb(0, 48, 72);
            font-size: 20px;
            /* font-weight: bold; */
            font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateDistStalpi($event)"
                  [(ngModel)]="lungimeProfil" type="number" lang="en" step="0.01">
                Lungime profil
              </div>
              <p-divider></p-divider>
            </div>

            <p-divider></p-divider>

            <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                POP NITURI COLORATE 100 BUC 194</span>
            </div>

          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.VERTICAL Y 115'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div *ngIf="tipSipca=='rotunda'" style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD BIVALENTA Y115 364
                </span>
              </div>
              <div *ngIf="tipSipca=='rotunda'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="inaltimeStalpi" type="number" lang="en" step="0.01">
                Inaltime sipca
              </div>

              <p-divider></p-divider>
              <div *ngIf="tipSipca=='dreapta'" style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD BIVALENTA CAPAT DREPT Y115 377
                </span>
              </div>
              <div *ngIf="tipSipca=='dreapta'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="inaltimeStalpi" type="number" lang="en" step="0.01">
                Inaltime sipca
              </div>
              <p-divider></p-divider>

            </div>

            <p-divider></p-divider>

            <!-- <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150</span>
            </div> -->

            <div style="display:flex;margin: 15px 5px 10px 5px">
              <div class="fenceQuantity">
                {{popnituri>0?popnituri:0}}</div>
              <div
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150
              </div>
            </div>

          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedFenceType.name == 'ZEBRA.VERTICAL Y 118'" style="display:flex;">
          <div style="width:100%">
            <div>
              <div *ngIf="tipSipca=='rotunda'" style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD Y118 358
                </span>
              </div>
              <div *ngIf="tipSipca=='rotunda'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="inaltimeStalpi" type="number" lang="en" step="0.01">
                Inaltime sipca
              </div>
              <p-divider></p-divider>
              <div *ngIf="tipSipca=='dreapta'" style="    margin: 15px 5px 10px 5px">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="sipci" type="number" lang="en" step="0.01">
                <span
                  style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                  SIPCA GARD CAPAT DREPT Y118 359
                </span>
              </div>
              <div *ngIf="tipSipca=='dreapta'"
                style="color: rgb(0, 48, 72);font-size: 20px;font-family: CarettaOffice;    margin: 10px 5px 10px 5px;">
                <input disabled class="fenceInput" style="width:55px;" (keyup)="recalculateInaltimeStalpi($event)"
                  [(ngModel)]="inaltimeStalpi" type="number" lang="en" step="0.01">
                Inaltime sipca
              </div>
              <p-divider></p-divider>

            </div>

            <p-divider></p-divider>

            <!-- <div style="    margin: 15px 5px 10px 5px">
              <input disabled class="fenceInput" style="width:55px;" [(ngModel)]="popnituri" type="number" lang="en"
                step="0.01">
              <span
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;    font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150</span>
            </div> -->

            <div style="display:flex;margin: 15px 5px 10px 5px">
              <div class="fenceQuantity">
                {{popnituri>0?popnituri:0}}</div>
              <div
                style="color: rgb(0, 48, 72);font-size: 24px;font-weight: bold;font-family: 'CarettaOffice';margin: 0 7px;">
                SURUBURI METAL 4.8*19MM-250/CUT 150
              </div>
            </div>

          </div>
          <div>
            <div style="text-align: center;">
              <img style="width:290px" [src]="selectedFenceType.imagePath">
            </div>
            <div style="display: flex;">
              <div>
                <!-- <img style="width:190px" src="./assets/caretta/schitaX121.png"> -->
              </div>
              <div>
                <img style="width:157px" [src]="selectedFenceType.sectionPath">
              </div>
            </div>
          </div>
        </div>

      </div>
    </p-tabPanel>

  </p-tabView>

  <div *ngIf="!selectedFenceCategory" style="text-align: center;" class="p-grid" style="margin: 0 10px;">
    <div style="text-align: center;" class="p-col-12 p-md-6 p-lg-3" *ngFor="let fence of fences">
      <p-card (click)="selectFence(fence)" class="p-col-12 p-md-6 p-lg-3" [header]="fence.name">
        <div>
          <img width="100%" [src]="fence.imagePath">
        </div>
      </p-card>
    </div>
  </div>


</p-dialog>


<p-dialog [draggable]="false" showEffect="fade" [modal]="true" [closable]="false" [(visible)]="changeFenceModal">
  <p-header>
    <span class="dialog-title" style="width:80%;margin-top:0;">Doriti schimbare tip gard in simulator? </span>
    <!-- <div class="closeSvgRight" (click)="changeFenceModal=null" [inlineSVG]="'/assets/images/close.svg'"></div> -->
  </p-header>
  Schimbarea tipului de gard duce la pierderea panourilor deja definite.
  <div style="width: 100%;text-align: center; margin: 25px 0;display:flex;">
    <button (click)="changeFenceModal=false" pButton pRipple type="button" label="RENUNTA" class="p-button-success"
      style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
margin: auto;"></button>
    <button (click)="changeFence();changeFenceModal=false" pButton pRipple type="button" label="CONTINUA"
      class="p-button-success" style="    font-size: 26px;background: #e0004d;border: 1px solid #e0004d;font-family: 'CarettaOffice';TEXT-ALIGN: CENTER;
margin: auto;"></button>
  </div>
</p-dialog>

<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="large" color="#7fc3d6" type="ball-circus"></ngx-spinner>