export function getLocalStorage() {
  let localStorageManagement: any = {};
  localStorageManagement.get = function (key: string) {
    let value = window.localStorage.getItem(key);
    try {
      return JSON.parse(value);
    }
    catch (error) {
      return value;
    }

  };
  localStorageManagement.set = function (key, value) {
    if (typeof (value) !== 'object') {
      window.localStorage.setItem(key, value);
    }
    else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  };

  localStorageManagement.clear = function () {
    window.localStorage.clear();
  };

  localStorageManagement.remove = function(key) {
    localStorage.removeItem(key);
  }

  return localStorageManagement;
}
