// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


import packagejson from '../../package.json';

// export const environment = {
//   production: false,
//   serviceUrl: "https://api.e-node.ro/adi/v1",
//   type: "LOCAL",
//   color: "#7400ac",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-node.ro/crmadi"
// };



//  API ADI DEV
// let apiadi = "https://api.e-node.ro/v2adi";
// let apiadicrm = "https://api.e-crm.ro/v3test";
// export const environment = {
//   production: true,
//   serviceUrl: apiadi,
//   type: "DEV",
//   color: "red",
//   constantPassword: "1",
//   ecrmApi: apiadicrm,
//   version: packagejson.version,
//   fileExplorerAPI: "https://api.e-node.ro/drive",
//   PUBLIC_VAPID_KEY: "BInjQn5qQHGnAT5GqW4w8II3S2Kxv2A6IL9hOUZYFqOq3NGUY5cUgYZsKkyuGgvqSXPWCb_APNMYuslLRVObg7I",
//   subscriptionKey: "xP_N1duK4k0dcQJ7QPCJEKs3ALELisbSsi1ROvjfZdY",
//   madeBy: "ZITRO TECH SOLUTIONS"
// }


//enode v2
// let apia = "https://api.e-node.ro/v2";
// export const environment = {
//   production: true,
//   serviceUrl: apia,
//   type: "",
//   color: "#04c2c9",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-node.ro/crmcaretta",
//   version,
//   fileExplorerAPI: ""
// }


//enode v2 DEV
// let apia = "https://api.e-node.ro/v2dev";
// export const environment = {
//   production: true,
//   serviceUrl: apia,
//   type: "",
//   color: "#04c2c9",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-node.ro/crmcaretta",
//   version: packagejson.version,
//   fileExplorerAPI: "https://api.e-node.ro/drive",
//   PUBLIC_VAPID_KEY: "BInjQn5qQHGnAT5GqW4w8II3S2Kxv2A6IL9hOUZYFqOq3NGUY5cUgYZsKkyuGgvqSXPWCb_APNMYuslLRVObg7I",
//   subscriptionKey: "xP_N1duK4k0dcQJ7QPCJEKs3ALELisbSsi1ROvjfZdY"
// }

//new demoo
// export const environment = {
//   production: true,
//   serviceUrl: "https://api.e-node.ro/demo",
//   type: "",
//   color: "#04c2c9",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-crm.ro/v2",
//   version,
//   fileExplorerAPI: "https://api.e-node.ro/drive",
//   PUBLIC_VAPID_KEY: ""
// }


//new dev V2
// export const environment = {
//   production: true,
//   serviceUrl: "https://api.e-node.ro/v2dev",
//   type: "dev",
//   color: "red",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-crm.ro/v2",
//   version,
//   fileExplorerAPI: "",
//   PUBLIC_VAPID_KEY: ""
// }

// export const environment = {
//   production: true,
//   serviceUrl: "https://api.e-node.ro/v2demo",
//   type: "",
//   color: "red",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-crm.ro/v2",
//   version: packagejson.version,
//   fileExplorerAPI: "",
//   PUBLIC_VAPID_KEY: "",
//   subscriptionKey: "xP_N1duK4k0dcQJ7QPCJEKs3ALELisbSsi1ROvjfZdY",
//   madeBy: "ZITRO TECH SOLUTIONS"
// }

//production

// export const environment = {
//   production: true,
//   serviceUrl: "https://api.e-node.ro/prod/v1",
//   type: "",
//   color: "#04c2c9",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-node.ro/crm/v1",
//   version,
//   fileExplorerAPI: "",
//   PUBLIC_VAPID_KEY: "BInjQn5qQHGnAT5GqW4w8II3S2Kxv2A6IL9hOUZYFqOq3NGUY5cUgYZsKkyuGgvqSXPWCb_APNMYuslLRVObg7I"
// }


// export const environment = {
//   production: true,
//   serviceUrl: "https://api.e-node.ro/prodtest/v1",
//   type: "",
//   color: "#04c2c9",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-node.ro/crm/v1",
//   version: require('../../package.json').version
// }


// export const environment = {
//   production: true,
//   serviceUrl: "https://api.e-node.ro/v2",
//   type: "",
//   color: "#04c2c9",
//   constantPassword: "1",
//   ecrmApi: "https://api.e-crm.ro/v2",
//   version: packagejson.version,
//   fileExplorerAPI: "https://api.e-node.ro/drive",
//   PUBLIC_VAPID_KEY: "BInjQn5qQHGnAT5GqW4w8II3S2Kxv2A6IL9hOUZYFqOq3NGUY5cUgYZsKkyuGgvqSXPWCb_APNMYuslLRVObg7I",
//   subscriptionKey: "xP_N1duK4k0dcQJ7QPCJEKs3ALELisbSsi1ROvjfZdY",
//   madeBy: "ZITRO TECH SOLUTIONS"
// }

// export const environment = {
//   production: true,
//   serviceUrl: "https://api.e-node.ro/v2prod",
//   type: "",
//   color: "#04c2c9",
//   constantPassword: "1235weyw3j4j34t434er34",
//   ecrmApi: "https://api.e-crm.ro/v2",
//   version: packagejson.version,
//   fileExplorerAPI: "https://api.e-node.ro/drive",
//   PUBLIC_VAPID_KEY: "BInjQn5qQHGnAT5GqW4w8II3S2Kxv2A6IL9hOUZYFqOq3NGUY5cUgYZsKkyuGgvqSXPWCb_APNMYuslLRVObg7I",
//   subscriptionKey: "xP_N1duK4k0dcQJ7QPCJEKs3ALELisbSsi1ROvjfZdY",
//   madeBy: "ZITRO TECH SOLUTIONS"
// }


export const environment = {
  production: true,
  serviceUrl: "https://api.e-node.ro/v2prod",
  type: "",
  color: "#04c2c9",
  constantPassword: "1werwerwerwer",
  ecrmApi: "https://api.e-crm.ro/v2",
  version: packagejson.version,
  fileExplorerAPI: "https://api.e-node.ro/drive",
  PUBLIC_VAPID_KEY: "BInjQn5qQHGnAT5GqW4w8II3S2Kxv2A6IL9hOUZYFqOq3NGUY5cUgYZsKkyuGgvqSXPWCb_APNMYuslLRVObg7I",
  subscriptionKey: "xP_N1duK4k0dcQJ7QPCJEKs3ALELisbSsi1ROvjfZdY",
  madeBy: "ZITRO TECH SOLUTIONS"
}