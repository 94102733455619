import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login/login.component';

import { OfferDetailsComponent } from "./offers/components/offer-details-caretta/offer-details.component";
import { OfferDetailsInterComponent } from "./offers/components/offer-details-inter/offer-details.component";
import { OfferDetailsDolinexComponent } from "./offers/components/offer-details-dolinex/offer-details-dolinex.component";
import { OfferDetailsGaitanoComponent } from './offers/components/offer-details-gaitano/offer-details-gaitano.component';
import { OfferDetailsMultidom } from './offers/components/multidom/offer-detail-multidom.component';
import { OfferDetailGlobalComponent } from './offers/components/offer-detail-global/offer-detail-global.component';

import { OfferProductionComponent } from "./offers/components/offer-production/offer-production.component";

import { DashboardComponent } from "./offers/components/dashboard/dashboard.component"
import { AppGuard } from './app-guards/app.guard';
import { OfferGuard } from './app-guards/offer.guard';
import { MainContainerComponent } from './main-container/main-container.component';
import { DashboardResolve } from './offers/components/dashboard/dashboard.resolve';

const routes: Routes = [
  {
    path: 'app',
    component: MainContainerComponent,
    children: [
      // {
      //   path: 'home',
      //   loadChildren: './home/home.module#HomeModule'
      // },
      // {
      //   path: 'infostoc',
      //   data: {
      //     roles: [
      //       'INFO',
      //     ],
      //   },
      //   canActivate: [AppGuard],
      //   loadChildren: './infostoc/infostoc.module#InfostocModule'
      // },
      // {
      //   path: 'commands',
      //   data: {
      //     roles: [
      //       'COMMANDS',
      //     ],
      //   },
      //   canActivate: [AppGuard],
      //   loadChildren: './commands/commands.module#CommandsModule'
      // },



      { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      {
        path: 'infostoc', loadChildren: () => import('./infostoc/infostoc.module').then(m => m.InfostocModule),
        canActivate: [AppGuard],
        data: { roles: ['INFO'] }
      },
      {
        path: 'commands', loadChildren: () => import('./commands/commands.module').then(m => m.CommandsModule),
        canActivate: [AppGuard],
        data: { roles: ['COMMANDS'] }
      },
      {
        path: 'invoice', loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule),
        canActivate: [AppGuard],
        data: { roles: ['COMMANDS'] }
      },
      {
        path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AppGuard],
        data: { roles: ['INV', 'ALL'] }
      },
      {
        path: 'constants', loadChildren: () => import('./constants/constants.module').then(m => m.ConstantsModule)
      },
      {
        path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
        canActivate: [AppGuard],
        data: { roles: ['ADM', 'ALL'] }
      },
      {
        path: 'salesAssistant', loadChildren: () => import('./sales-assistant/sales-assistant.module').then(m => m.SalesAssistantModule),
        canActivate: [AppGuard],
        data: { roles: ['ASIST'] }
      },
      {
        path: 'companies', loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [AppGuard],
        data: { roles: ['ADM', 'ALL'] }
      },
      {
        path: 'logger', loadChildren: () => import('./logger/logger.module').then(m => m.LoggerModule),
        canActivate: [AppGuard],
        data: { roles: ['ADM', 'ALL'] }
      },
      {
        path: 'roles', loadChildren: () => import('./user-roles/user-roles.module').then(m => m.UserRolesModule),
        canActivate: [AppGuard],
        data: { roles: ['ALL'] }
      },
      {
        path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AppGuard],
        data: { roles: ['ADM', 'ALL'] }
      },
      {
        path: 'production', loadChildren: () => import('./production/production.module').then(m => m.ProductionModule)
      },
      {
        path: 'prod-orders', loadChildren: () => import('./prod-orders/prod-orders.module').then(m => m.ProdOrdersModule),
        canActivate: [AppGuard],
        data: { roles: ['PROD-COM'] }
      },
      {
        path: 'partners', loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule),
        canActivate: [AppGuard],
        data: { roles: ['PART'] }
      },
      {
        path: 'raport', loadChildren: () => import('./activity-raport/activity-raport.module').then(m => m.ActivityRaportModule),
        canActivate: [AppGuard],
        data: { roles: ['LIST'] }
      },
      {
        path: 'pos', loadChildren: () => import('./pos/pos.module').then(m => m.PosModule),
        canActivate: [AppGuard],
        data: { roles: ['POS'] }
      },
      {
        path: 'logistic', loadChildren: () => import('./logistic/logistic.module').then(m => m.LogisticModule),
        canActivate: [AppGuard],
        data: { roles: ['LOGI'] }
      },
      {
        path: 'importArticles', loadChildren: () => import('./import-articles/import-articles.module').then(m => m.ImportArticlesModule),
        canActivate: [AppGuard],
        data: { roles: ['ASIST'] }
      },
      {
        path: 'offers', loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule),
      },
      {
        path: 'delivery', loadChildren: () => import('./delivery/delivery.module').then(m => m.DeliveryModule),
      },
      {
        path: 'simulator', loadChildren: () => import('./simulator/simulator.module').then(m => m.SimulatorModule),
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'offer',
    canActivate: [OfferGuard],
    component: OfferDetailsComponent
  },
  {
    path: 'offerCaretta',
    component: OfferDetailsComponent
  }
  ,
  {
    path: 'offerInter',
    component: OfferDetailsInterComponent
  }
  ,
  {
    path: 'offerDolinex',
    component: OfferDetailsDolinexComponent
  }
  ,
  {
    path: 'offerGaitano',
    component: OfferDetailsGaitanoComponent
  },

  {
    path: 'offer/production',
    component: OfferProductionComponent,
  },
  {
    path: 'offerMultidom',
    component: OfferDetailsMultidom,
  },
  {
    path: 'offerGlobal',
    component: OfferDetailGlobalComponent,
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      type: "FULL"
    },
    resolve: {
      dashboardData: DashboardResolve
    }
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: `reload`, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
