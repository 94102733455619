import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ConstantsService {
    userConstants;
    serviceUrl = environment.serviceUrl;
    ecrmApi = environment.ecrmApi;
    constructor(private http: HttpClient, @Inject('LOCALSTORAGE') private store: any) {
    }

    getUserConstants() {
        const company = this.store.get('company');
        const localuser = this.store.get('localuser');
        const url = `${this.serviceUrl}/admin/userconstants/${localuser.username}/${company.uid}`;
        return this.http.get(url);
    }

    getConstantsCategory() {
        const company = this.store.get('company');
        const url = `${this.serviceUrl}/admin/constants/${company.uid}`;
        return this.http.get(url);
    }

    getCompanyConstants() {
        const company = this.store.get('company');
        const url = `${this.serviceUrl}/admin/constants/${company.uid}`;
        return this.http.get(url);
    }

    getConstantValues(constant) {
        const company = this.store.get('company');
        const url = `${this.serviceUrl}/admin/constants/${company.uid}/${constant.key}`;
        return this.http.get(url);
    }

    getSpecificContantValues(constant) {
        const company = this.store.get('company');
        const localuser = this.store.get('localuser');
        const url = `${this.serviceUrl}/admin/userconstants/${localuser.username}/${company.uid}/${constant}`;
        return this.http.get(url);
    }

    getPromiseSpecificContantValues(constant) {
        const company = this.store.get('company');
        const localuser = this.store.get('localuser');
        const url = `${this.serviceUrl}/admin/userconstants/${localuser.username}/${company.uid}/${constant}`;
        return this.http.get(url).toPromise();
    }


    saveUserConstants(selectedConstants, userConstants) {
        const company = this.store.get('company');
        const user = this.store.get("localuser").username;
        const url = `${this.serviceUrl}/admin/addConstants`;
        let constants = [];
        for (let constant of selectedConstants) {

            if (!constant) continue;

            let tempConstant: any = {};
            tempConstant.values = [];

            console.log(constant.selectedItem, constant.values);
            if (constant.values) {
                tempConstant.values = constant.selectedItem;
            }
            else {
                if (constant.multiplevalues) {
                    let values = constant.selectedItem.split(",");
                    for (let qty of values) {
                        tempConstant.values.push(qty);
                    }
                } else {
                    tempConstant.values.push(constant.selectedItem);
                }
            }

            tempConstant.multiplevalues = constant.multiplevalues;
            tempConstant.key = constant.key;
            tempConstant.name = constant.name;
            tempConstant.single = constant.single;
            tempConstant.category = constant.category;
            constants.push(tempConstant);
        }
        console.log("constants");
        console.log(constants);
        console.log("Uconstants");
        console.log(userConstants);

        if (userConstants) {
            for (let userConstant of userConstants) {
                constants.push(userConstant);
            }
        }
        let body = {
            "user": user,
            "constants": {
                "company": company.uid,
                "constants": constants
            }
        };

        console.log(body);

        return this.http.post(url, body);
    }

    updateSingleConstants(selectedConstants) {
        const company = this.store.get('company');
        const user = this.store.get("localuser").username;
        const url = `${this.serviceUrl}/admin/updateSingleConstants`;

        let body = {
            "user": user,
            "constants": {
                "company": company.uid,
                "constants": selectedConstants
            }
        };
        return this.http.post(url, body);
    }

    async updateSingelConstantOrient(selectedConstants) {
        let orientConstant = {
            single: selectedConstants.single,
            values: selectedConstants.values,
            key: selectedConstants.key,
            name: selectedConstants.name,
            category: selectedConstants.category
        }

        console.log(orientConstant)
        let crmurl = `${this.ecrmApi}/constantByUsername`;
        let orientSaveResponse = await this.http.post(crmurl, orientConstant).toPromise();
        return orientSaveResponse;
    }

    updateUserConstants(selectedConstants) {
        const company = this.store.get('company');
        const user = this.store.get("localuser").username;
        const url = `${this.serviceUrl}/admin/updateConstants`;
        let constants = [];
        for (let constant of selectedConstants) {
            let tempConstant: any = {};

            tempConstant.values = [];
            if (constant.multiplevalues == undefined) {
                if (constant.selectedValues) {
                    tempConstant.values = constant.selectedValues;
                }
                else {
                    tempConstant.values = constant.values;
                }
            }
            else {
                if (constant.multiplevalues) {
                    let values = constant.selectValues.split(",");
                    for (let qty of values) {
                        tempConstant.values.push(qty);
                    }
                } else {
                    constant.selectValues = constant.values[0];
                    tempConstant.values.push(constant.selectValues);
                }
            }

            tempConstant.multiplevalues = constant.multiplevalues;

            tempConstant.key = constant.key;
            tempConstant.name = constant.name;
            tempConstant.single = constant.single;
            tempConstant.category = constant.category;
            constants.push(tempConstant);
        }


        let body = {
            "user": user,
            "constants": {
                "company": company.uid,
                "constants": constants
            }
        };

        return this.http.post(url, body);
    }

    removeConstant(key) {
        const user = this.store.get("localuser").username;
        const url = `${this.serviceUrl}/admin/remove-constant/${user}/${key}`;
        return this.http.get(url)
    }

    removeConstantCategory(key) {
        const user = this.store.get("localuser").username;
        const url = `${this.serviceUrl}/admin/remove-constant-category/${user}/${key}`;
        return this.http.get(url)
    }

}
