import { Injectable, Inject } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { NotifyService } from '../helpers/notify.service';


@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor(public router: Router, @Inject('LOCALSTORAGE') private store: any, private notify: NotifyService) { }

  canActivate(route: ActivatedRouteSnapshot): any {
    let user = this.store.get('localuser');
    for (let item in user.menu) {
      let canAccess = route.data.roles.find(role => role === user.menu[item]);
      if (canAccess) {
        return true;
      }
    }
    return true;
    this.notify.errorMessage("Nu aveti acces la acest modul!")
    this.router.navigateByUrl("app/home");
    return false;
  }
}


// return role === user.menu[item]