import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { OfferModel } from "../../models/offer.model";
import { CartDataModel } from "../../../helpers/datatable/data/cart-data.model";
import { CartServiceAdapter } from "../../../helpers/datatable/data/cart-service.adapter";
import { OfferPriceModel } from "../../models/offer-price.model";
import moment from "moment";

@Injectable()
export class OfferService implements CartServiceAdapter {

    private editOffer$: Observable<any>;
    private editOfferSubject: BehaviorSubject<any> = new BehaviorSubject(false);

    private showSelecton$: Observable<any>
    private showSelectonSubject: BehaviorSubject<any> = new BehaviorSubject(false);

    private removeOffer$: Observable<any>;
    private removeOfferSubject: BehaviorSubject<any> = new BehaviorSubject(false);

    serviceUrl = environment.serviceUrl;
    ecrmApi = environment.ecrmApi;
    fileExplorerAPI = environment.fileExplorerAPI;

    constructor(private http: HttpClient) {
        this.editOffer$ = this.editOfferSubject.asObservable();
        this.showSelecton$ = this.showSelectonSubject.asObservable();
        this.removeOffer$ = this.removeOfferSubject.asObservable();
    }

    setEditOffer(edit: boolean) {
        this.editOfferSubject.next(edit);
    }

    setRemoveOffer(flag: boolean) {
        this.removeOfferSubject.next(flag);
    }

    setSelection(flag: boolean) {
        this.showSelectonSubject.next(flag);
    }

    showSelection(flag: boolean) {
        this.showSelectonSubject.next(flag);
    }

    getSelection() {
        return this.showSelecton$;
    }

    getEditOffer() {
        return this.editOffer$;
    }

    getRemoveOffer() {
        return this.removeOffer$;
    }

    getAllOffers(): Promise<any> {
        const url = `${this.serviceUrl}/offers/list`;
        //const url = `${this.ecrmApi}/offers/v3`;
        return this.http.get(url).toPromise();
    }

    getClasses() {
        let url = `${this.serviceUrl}/offers/classes`;
        return this.http.get(url).toPromise();
    }

    getSingleOffer(clientId, offerId): Promise<any> {
        //${this.serviceUrl}/offers/offerId/${offerId}/clientId/${clientId}`;
        const url = `${this.serviceUrl}/offer/${offerId}`;
        return this.http.get(url).toPromise();
    }

    removeOffers(offers: OfferModel[]) {
        const url = `${this.serviceUrl}/offers/remove`;
        const body = {
            offers: offers
        }
        return this.http.post(url, body).toPromise();
    }



    uploadFile(offer, files: any) {
        const url = `${this.serviceUrl}/offers/upload/${offer._id}`;
        let formData: FormData = new FormData();
        for (let index in files) {
            formData.append(index, files[index]);
        }
        return this.http.post(url, formData).toPromise();
    }

    async downloadFile(offerId: string, fileName: string) {
        const url = `${this.serviceUrl}/offers/file/${offerId}/${fileName}`;
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json' }).toPromise();
    }

    getClientData(data: string, type) {
        const url = `${this.serviceUrl}/partners/partnerAdress?${type}=${data}`;
        return this.http.get(url).toPromise();
    }

    getOfferColors() {
        const url = `${this.serviceUrl}/offers/color`;
        return this.http.get(url).toPromise();
    }

    getOfferThickness() {
        const url = `${this.serviceUrl}/offers/thickness`;
        return this.http.get(url).toPromise();
    }

    getOfferDetailsData(filter: any) {
        const url = `${this.serviceUrl}/offers/details/data/`;
        const body = { params: filter };
        return this.http.post(url, body).toPromise();
    }

    saveCartItem(item: CartDataModel) {
        const url = `${this.serviceUrl}/offers/details/cart/data/${item.categoryId}`;
        const body = { offer: item };
        return this.http.post(url, body).toPromise();
    }

    getOfferCartItem(categoryId: string) {
        const url = `${this.serviceUrl}/offers/details/cart/data/${categoryId}`;
        return this.http.get(url).toPromise();
    }

    removeAllCartData(categoryId: string) {
        const url = `${this.serviceUrl}/offers/details/cart/data/${categoryId}`;
        return this.http.delete(url).toPromise();
    }

    removeCartData(id) {
        const url = `${this.serviceUrl}/offers/details/cart/single/data/${id}`;
        return this.http.delete(url).toPromise();
    }

    savePriceList(priceList: OfferPriceModel[]) {
        const url = `${this.serviceUrl}/offers/details/price`;
        const body = {
            priceList: priceList
        }
        return this.http.post(url, body).toPromise();
    }

    getPriceList() {
        const url = `${this.serviceUrl}/offers/details/price`;
        return this.http.get(url).toPromise();
    }

    //todo deprecated
    finishCommand(body) {
        let company = JSON.parse(localStorage.getItem('company'));
        let user = JSON.parse(localStorage.getItem('localuser'));
        //const url = `${this.serviceUrl}/asistvanzari/finish/${user.username}/${company.uid}`;
        const url = `${this.serviceUrl}/offers/finishCommand`;
        return this.http.post(url, body).toPromise();
    }

    finishCommandV2(body) {
        const url = `${this.serviceUrl}/offers/finishCommandV2`;
        return this.http.post(url, body).toPromise();
    }

    finishCommandV2inter(body) {
        const url = `${this.serviceUrl}/offers/finishCommandV2inter`;
        return this.http.post(url, body).toPromise();
    }

    finishCommandV2gaitano(body) {
        const url = `${this.serviceUrl}/offers/finishCommandV2gaitano`;
        return this.http.post(url, body).toPromise();
    }
    //adi
    getOffersCaretta(offerId, selectedOfferType, selectedColor, selectedMaterial, selectedThickness, pluvialType, promokey) {
        let url = `${this.serviceUrl}/offers/${offerId}?selectedOfferType=${selectedOfferType}&selectedColor=${selectedColor}&selectedMaterial=${selectedMaterial}&selectedThickness=${selectedThickness}&pluvialType=${pluvialType}&promokey=${promokey}&version=190`;
        return this.http.get(url);
    }



    syncOfferData() {
        let url = `${this.serviceUrl}/offers/caretta/syncOfferData`;
        return this.http.get(url);
    }

    getProductionOfferCaretta(offerId, selectedOfferType, selectedColor, selectedMaterial, selectedThickness, pluvialType) {

        let url = `${this.serviceUrl}/offers/production/${offerId}?selectedOfferType=${selectedOfferType}&selectedColor=${selectedColor}&selectedMaterial=${selectedMaterial}&selectedThickness=${selectedThickness}&pluvialType=${pluvialType}`;
        return this.http.get(url);
    }

    generateInternalProductionCommand(data, offerUuid) {
        let url = `${this.serviceUrl}/productie/generateInternalProductionCommand/${offerUuid}`;
        return this.http.post(url, data);
    }

    getOffersInter(offerId, locationId,isDispecer) {
        const url = `${this.serviceUrl}/offers/inter/${offerId}`;
        let params: any = {

        }
        if (locationId) {
            params.locationId = locationId;
        }
        if (isDispecer) {
            params.isDispecer = isDispecer;
        }
        return this.http.get(url, { params });
    }

    getOfferDolinex(offerId, locationId) {
        const url = `${this.serviceUrl}/offers/dolinex/${offerId}`; //${locationId ? "?locationId=" + locationId : ""}
        return this.http.get(url);
    }

    getOffersGaitano(offerId, locationId) {
        const url = `${this.serviceUrl}/offers/gaitano/${offerId}`;
        return this.http.get(url);
    }

    getOffersGlobal(offerId, classId, materialId, colorId, selectedThickness) {
        const url = `${this.serviceUrl}/offers/global/${offerId}`;
        let options: any = { params: {} }

        classId && (options.params.classId = classId)
        materialId && (options.params.materialId = materialId)
        colorId && (options.params.colorId = colorId)
        selectedThickness && (options.params.thickness = selectedThickness)
        return this.http.get(url, options);
    }

    getClass(parentClassId) {
        const url = `${this.serviceUrl}/offers/data/class${parentClassId ? "?parentClassId=" + parentClassId : ""}`;
        return this.http.get(url);
    }

    getArticleStock(articleObjId, locationId, offerUuid) {
        const url = `${this.serviceUrl}/offers/articleStock/${articleObjId}`;
        let params: any = {

        }
        if (locationId) {
            params.locationId = locationId;
        }
        if (offerUuid) {
            params.offerUuid = offerUuid;
        }
        return this.http.get(url, { params });
    }

    getArticleManagementStock(articleObjId) {
        const url = `${this.serviceUrl}/offers/articleManagementStock/${articleObjId}`;
        return this.http.get(url);
    }

    getArticleValability(articleObjId) {
        const url = `${this.serviceUrl}/offers/articleValability/${articleObjId}`;
        return this.http.get(url);
    }
    //adi

    getColors(offerType, material) {
        const url = `${this.serviceUrl}/offers/offerCarreta/${offerType}/materials/${material}/colors`;
        return this.http.get(url);
    }

    getColorsDom(offerType, material, statisticClassId) {
        const url = `${this.serviceUrl}/offers/offerDom/${offerType}/materials/${material}/colors?statisticClassId=${statisticClassId}`;
        return this.http.get(url);
    }

    getMaterials(offerType) {
        const url = `${this.serviceUrl}/offers/offerCarreta/${offerType}/materials`;
        return this.http.get(url);
    }

    getMaterialsDom(offerType, statisticClassId) {
        const url = `${this.serviceUrl}/offers/offerDom/${offerType}/materials?statisticClassId=${statisticClassId}`;
        return this.http.get(url);
    }

    getThickness(offerType, material, color) {
        const url = `${this.serviceUrl}/offers/offerCarreta/${offerType}/colors/${color}/materials/${material}/thickness`;
        return this.http.get(url);
    }

    insertPartner(body) {
        const url = `${this.serviceUrl}/partners`;
        return this.http.post(url, body).toPromise();
    }

    generateOfferPdf(body) {
        const url = `${this.serviceUrl}/offers/generateOfferPdf`;
        return this.http.post(url, body).toPromise();
    }

    generateOfferInterPdf(body) {
        const url = `${this.serviceUrl}/offers/generateOfferInterPdf`;
        return this.http.post(url, body).toPromise();
    }

    generateOfferEmailPdf(body) {
        const url = `${this.serviceUrl}/offers/generateOfferEmailPdf`;
        return this.http.post(url, body).toPromise();
    }

    generateCommandsInternalDocumentsPdf(offer) {
        const url = `${this.serviceUrl}/offers/generateCommandsInternalDocumentsPdf`;
        return this.http.post(url, offer).toPromise();
    }

    saveClient(client) {
        const url = `${this.serviceUrl}/offers/client`;
        return this.http.post(url, client).toPromise();
    }
    cancelCommand(commandId, cancelObservations) {
        const url = `${this.serviceUrl}/offers/cancelCommand/${commandId}`;
        return this.http.post(url, { cancelObservations }).toPromise();
    }

    saveOffer(offer) {
        const url = `${this.serviceUrl}/offers`;
        return this.http.post(url, offer).toPromise();
    }

    saveOfferV2(offer) {
        const url = `${this.serviceUrl}/offers/saveOfferV2`;
        return this.http.post(url, offer).toPromise();
    }

    updateOffer(clientId, offerId, offer) {
        const url = `${this.serviceUrl}/offers?clientId=${clientId}&offerId=${offerId}`;
        return this.http.put(url, offer).toPromise();
    }

    getSavedClient(clientId) {
        const url = `${this.serviceUrl}/offers/clientData/${clientId}`;
        return this.http.get(url).toPromise();
    }

    getClientDetail(partnerId) {
        let company = JSON.parse(localStorage.getItem('company'));
        const url = `${this.serviceUrl}/partners/partner/${partnerId}/${company.uid}`;
        return this.http.get(url).toPromise();
    }

    getCounties() {
        const url = `${this.serviceUrl}/partners/counties`;
        return this.http.get(url).toPromise();
    }

    getLocalities(countyId) {
        const url = `${this.serviceUrl}/partners/localities?countyId=${countyId}`;
        return this.http.get(url).toPromise();
    }

    getDealers() {
        const url = `${this.serviceUrl}/partners/dealer`;
        return this.http.get(url).toPromise();
    }

    loadDealerAgents(partnerId) {
        const url = `${this.serviceUrl}/partners/dealer/${partnerId}/agents`;
        return this.http.get(url).toPromise();
    }

    saveCommission(body) {
        const url = `${this.serviceUrl}/offers/saveCommission`;
        return this.http.post(url, body).toPromise();
    }

    saveAdvancePaymentValue(body) {
        const url = `${this.serviceUrl}/offers/saveAdvancePaymentValue`;
        return this.http.post(url, body).toPromise();
    }

    saveShowRoomRouteData(routeData) {
        const url = `${this.serviceUrl}/logistic/route?pickedByClient=true`;
        return this.http.post(url, routeData).toPromise();
    }

    editClient(clientData) {
        const url = `${this.serviceUrl}/partners/editClientData`;
        return this.http.post(url, clientData).toPromise();
    }

    loadDashboardData(type, allData, startDate, endDate) {
        const url = `${this.serviceUrl}/commands/dashboard?type=${type}&allData=${allData}&startDate=${moment(startDate).format("YYYY-MM-DD 00:00:00")}&endDate=${moment(endDate).format("YYYY-MM-DD 23:59:59")}`;
        return this.http.get(url).toPromise();
    }

    loadCommandData(commandNumber) {
        const url = `${this.serviceUrl}/commands/${commandNumber}/dashboard`;
        return this.http.get(url).toPromise();
    }

    loadRemainingInternallCommandsArticles(internallCommandNumber) {
        const url = `${this.serviceUrl}/commands/internalCommands/${internallCommandNumber}`;
        return this.http.get(url).toPromise();
    }


    checkIfBroker(partnerId) {
        const url = `${this.serviceUrl}/partners/checkIfBroker/${partnerId}`;
        return this.http.get(url).toPromise();
    }

    saveConversion(conversionData) {
        const url = `${this.serviceUrl}/offers/saveConversion`;
        return this.http.post(url, conversionData).toPromise();
    }

    getSoldDetail(partnerId) {
        const url = `${this.serviceUrl}/partners/soldDetail/${partnerId}`;
        return this.http.get(url).toPromise();
    }

    changeStatus(commandId, status) {
        const url = `${this.serviceUrl}/commands/${commandId}/changeStatus/${status}`;
        return this.http.get(url).toPromise();
    }

    saveFile(file) {
        const url = `${this.fileExplorerAPI}/upload?user=agent1`;
        return this.http.post(url, file).toPromise();
    }

    getLocations() {
        const url = `${this.serviceUrl}/offers/company/location`;
        return this.http.get(url).toPromise();
    }

    validateFiscalCode(fiscalCode) {
        const url = `${this.serviceUrl}/partners/fiscalValidation/${fiscalCode}`;
        return this.http.get(url);
    }

    reprintLabelCaretta(body) {
        const url = `${this.serviceUrl}/print/reprintLabelCaretta`;
        return this.http.post(url, body).toPromise();
    }

    reprintLabelCarettav2(packageUuid) {
        const url = `${this.serviceUrl}/print/reprintLabelCarettav2?packageUuid=${packageUuid}`;
        return this.http.get(url).toPromise();
    }


    reprintLabelGlobalv2(packageUuid) {
        const url = `${this.serviceUrl}/print/reprintLabelGlobalv2?packageUuid=${packageUuid}`;
        return this.http.get(url).toPromise();
    }

    reprintStockLabelCaretta(body) {
        const url = `${this.serviceUrl}/print/reprintStockLabelCaretta`;
        return this.http.post(url, body).toPromise();
    }

    loadDashboardStockCommandsData() {

        const url = `${this.serviceUrl}/commands/dashboardInternallStockCommands`;
        return this.http.get(url);
    }

    loadDashboardStockCommandDetailData(commandId) {
        const url = `${this.serviceUrl}/commands/${commandId}/dashboardStock`;
        return this.http.get(url);
    }

    loadCanceledInternallCommands() {
        const url = `${this.serviceUrl}/commands/canceledInternallCommands`;
        return this.http.get(url);
    }
    saveRouteData(routeData, pickedClient) {
        const url = `${this.serviceUrl}/logistic/route?pickedByClient=${pickedClient}`;
        return this.http.post(url, routeData).toPromise();
    }
    checkClient(codpart) {
        const url = `${this.serviceUrl}/partners/checkClient/${codpart}`;
        return this.http.get(url).toPromise();
    }
    changeStatusTask(uuid, status) {
        let data = {
            'uuid': uuid,
            'status': status
        }
        const url = `${this.serviceUrl}/offers/changeStatusTask`;
        return this.http.post(url, data).toPromise();
    }
    getMultidomProviders() {
        const url = `${this.serviceUrl}/offers/offerProviders/providers`;
        return this.http.get(url).toPromise();
    }
    getProviderCategories(providerCode) {
        const url = `${this.serviceUrl}/offers/providerCategories/categories/${providerCode}`;
        return this.http.get(url).toPromise();
    }
    getIndustryCategories() {
        const url = `${this.serviceUrl}/offers/industryCategories/values`;
        return this.http.get(url).toPromise();
    }
    getMaterialsMultidom(offerType) {
        const url = `${this.serviceUrl}/offers/offerMultidom/${offerType}/materials`;
        return this.http.get(url);
    }
    getColorsMultidom(offerType, material) {
        const url = `${this.serviceUrl}/offers/offerMultidom/${offerType}/materials/${material}/colors`;
        return this.http.get(url);
    }
    getThicknessMultidom(offerType, material, color) {
        const url = `${this.serviceUrl}/offers/offerMultidom/${offerType}/colors/${color}/materials/${material}/thickness`;
        return this.http.get(url);
    }

    checkAccess(actionKey) {
        const url = `${this.serviceUrl}/admin/checkAccess/${actionKey}`;
        return this.http.get(url);
    }

    generatePalletPdf(internalCommandUuid) {
        const url = `${this.serviceUrl}/productie/generatePalletPdf?internalCommandUuid=${internalCommandUuid}`;
        return this.http.get(url);
    }

    changeTransportType(offerId, transportType) {
        const url = `${this.serviceUrl}/offers/changeTransportType?offerId=${offerId}`;
        return this.http.post(url, { offerId, transportType });
    }

    getMaterialsGlobal(classId, materialId, colorId) {
        const url = `${this.serviceUrl}/offers/offerGlobal/attribute`;
        let options: any = {
            params: {
            }
        };
        classId && (options.params.classId = classId)
        materialId && (options.params.materialId = materialId)
        colorId && (options.params.colorId = colorId)

        return this.http.get(url, options);
    }

    populatePrice() {
        const url = `${this.serviceUrl}/offers/populateExtensionPriceCaretta/start?nr=60000&stopnr=80000`;
        return this.http.get(url).toPromise();
    }

    fixDeliveryNote() {
        const url = `${this.serviceUrl}/offers/fixDeliveryNote/start`;
        return this.http.get(url).toPromise();
    }

    async printLocal(label) {
        return await this.http.post("http://localhost:21000/printLabel", { labelTxt: label }).toPromise();
    }


}