<div class="container">
  <h2>
    {{ 'login.selectCompany' | translate }}
  </h2>
  <mat-form-field class="app-mat-form" *ngIf="companies && companies.length>0">
    <mat-select [(ngModel)]="selectedItem" placeholder="{{'app.company' | translate}}">
      <mat-option *ngFor="let item of companies" [value]="item">
        {{item.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button [disabled]="!selectedItem" color="primary" class="darkBlue submit" mat-raised-button [mat-dialog-close] (click)="saveCompany()" color="primary">Salveaza</button>
</div>
