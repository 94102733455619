import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BilkaService {
    constructor() {
    }

    getModule(d1, d2) {

        if (d1 && d2) {
            if (d2.toFixed(2) == "1.20" || d2.toFixed(2) == "1.18") {
                if (d1.toFixed(2) == "0.50") {
                    return "M1";
                }
                else if (d1.toFixed(2) == "0.85") {
                    return "M2";
                }
                else if (d1.toFixed(2) == "1.20") {
                    return "M3";
                }
                else if (d1.toFixed(2) == "1.55") {
                    return "M4";
                }
                else if (d1.toFixed(2) == "1.90") {
                    return "M5";
                }
                else if (d1.toFixed(2) == "2.25") {
                    return "M6";
                }
                else if (d1.toFixed(2) == "2.60") {
                    return "M7";
                }
                else if (d1.toFixed(2) == "2.95") {
                    return "M8";
                }
                else if (d1.toFixed(2) == "3.30") {
                    return "M9";
                }
                else if (d1.toFixed(2) == "3.65") {
                    return "M10";
                }
                else if (d1.toFixed(2) == "4.00") {
                    return "M11";
                }
                else if (d1.toFixed(2) == "4.35") {
                    return "M12";
                }
                else if (d1.toFixed(2) == "4.70") {
                    return "M13";
                }
                else if (d1.toFixed(2) == "5.05") {
                    return "M14";
                }
            }
        }
        return null;
    }
}