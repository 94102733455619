import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NotifyService } from './helpers/notify.service';

import { environment } from './../environments/environment';

import { PrimeNGConfig } from 'primeng/api';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {



  constructor(private translateService: TranslateService, updates: SwUpdate, notify: NotifyService, private primengConfig: PrimeNGConfig) {
    updates.available.subscribe((event: any) => {
      notify.infoMessageVersion("Este necesar refresh-ul paginii pentru a activa noua versiune!");
      notify.infoMessageVersion("S-a instalat o noua versiune! ");
    });

    const updatesAvailable = updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));
    if (updatesAvailable) {
      console.log("new version")
    }
//asfasf
    updates.activated.subscribe(event => {
      console.log('oldf version was', event.previous);
      console.log('newf version is', event.current);
    });
    primengConfig.ripple = true;
  }

  ngOnInit() {
    this.translateService.setDefaultLang('ro');
    this.translateService.use('ro');
    this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
  }

}
