import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let time = value.toString();
    return this.formatDate(time);
  }

  formatDate(time) {
    var date = new Date(time);
    var monthNames = [
      "Ianuarie", "Februarie", "Martie",
      "Aprilie", "Mai", "Iunie", "Iulie",
      "August", "Septembrie", "Octombrie",
      "Noiembrie", "Decembrie"
    ];
  
    var day = date.getDate().toString().length == 1 ? '0' + date.getDate() : date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    var minutes = date.getMinutes().toString().length == 1 ? '0' + date.getMinutes() : date.getMinutes();
    var hours = date.getHours().toString().length == 1 ? '0' + date.getHours() : date.getHours();
  
    return day + ' ' + monthNames[monthIndex] + ' ' + year + ' ' + hours + ':' + minutes;
  }

}