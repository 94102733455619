
import { Component, OnInit, OnDestroy, Inject, HostListener, ViewChild } from "@angular/core";
import { OfferService } from "../services/offer.serice";
import { CartDataModel } from "../../../helpers/datatable/data/cart-data.model";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { NotifyService } from "../../../helpers/notify.service";
import { MatDialog } from "@angular/material/dialog";
import { DecisionModalComponent } from "../../../decision-modal/decision-modal.component";
import { OfferModel } from "../../models/offer.model";
import { environment } from "../../../../environments/environment";
import { DateAdapter } from '@angular/material/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-offer-details-dolinex',
  templateUrl: './offer-details-dolinex.component.html',
  styleUrls: ['./offer-details-dolinex.component.scss']
})
export class OfferDetailsDolinexComponent implements OnInit {


  showZeroQuantityArticles = false;
  firstLoad = true;
  scannedItems: CartDataModel[] = [];
  currentOffer: any = { articles: [], offerData: {} };
  colors;
  materials;
  thickness;
  selectedOfferType;
  selectedColor;
  selectedMaterial;
  selectedThickness;

  totalPrice = 0;
  totalMinimumPrice = 0;
  totalWithOutDiscount = 0;
  discountValue = 0;
  discountPercent = 0;
  adaosValue;
  adaosPercent;
  showAdaos = false;
  includePrices = false;
  generateNewOffer = false;

  temporaryArticles = [];
  clientData;
  offerId;
  tempOfferId;

  finishTypes = [
    { label: 'Factura', value: 'factura' },
    { label: 'Aviz', value: 'aviz' }
  ]
  paymentTypes = [
    { label: 'Cash', value: 'cash' },
    { label: 'OP', value: 'op' },
    { label: 'CEC', value: 'cec' },
    { label: 'Bilet la ordin', value: 'bo' }
  ];

  transportTypes = [
    { label: 'Macara', value: 'macara' },
    { label: 'Prelata', value: 'prelata' },
    { label: 'Decopertata', value: 'decopertata' }
  ]

  clientTypes = [
    { label: 'Persoana fizica', value: 'PF' },
    { label: 'Persoana juridica', value: 'PJ' }
  ];
  clientId;
  serviceUrl = environment.serviceUrl;

  invoiceTypes = OfferModel.InvoiceType;
  minDate = new Date();

  selectedClient: any = {};
  selectedDealer: any = {};
  selectedDealerAgent: any = {};
  dealerAgents: any = [];

  offerData;
  token;
  clients = [];
  dealers: any = [];

  selectedPhone;
  selectedCounty;
  onDestroy$ = new Subject<boolean>();
  pluvialType = "SL";
  showPluvialTypeModal = false;
  debug = false;

  //temporary counties todo
  counties;
  localities;
  myControl = new UntypedFormControl();
  filteredOptions;

  addNewClient = false;
  editClient = false;
  newClientType;
  showClientsModal = false;

  clientNameSearch;
  clientPhoneSearch;
  clientFiscalCodeSearch;
  differentClient = true;

  stockDetail;

  newClientData = {
    clientType: "",
    name: "",
    fiscalCode: "",
    tradeRegister: "",
    phone: "",
    county: "",
    locality: null,
    street: "",
    scara: "",
    number: "",
    bloc: "",
    etaj: "",
    apartament: ""
  }
  editClientData = {
    isPF: false,
    clientId: "",
    agendaId: "",
    name: "",
    fiscalCode: "",
    tradeRegister: "",
    phone: "",
    county: "",
    locality: null,
    street: "",
    scara: "",
    number: "",
    bloc: "",
    etaj: "",
    apartament: ""
  }
  totalOfferWeight = 0;

  canChooseClientType = false;
  canCancelCommand = false;
  canPrintInternallCommandPdf = false;
  showPickedByClient = false;

  showAlternativeDelivery = false;
  cancelCommandFlag = false;
  cancelObservations = "";
  username;

  isProduction = false;
  showImage = false;
  image;

  selectedArticle;
  locations;
  f1 = false; f2 = true; f3 = true;
  searchText;
  showPaymentModal = false;


  es = {
    firstDayOfWeek: 1,
    dayNames: ["Duminica", "Luni", "Marti", "Miercuri", "Joi", "Vineri", "Sambata"],
    dayNamesShort: ["dum", "lun", "mar", "mie", "joi", "vin", "sam"],
    dayNamesMin: ["D", "L", "Ma", "Mie", "J", "V", "S"],
    monthNames: ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: 'Azi',
    clear: 'Sterge'
  };

  invalidPrice = false;
  version = environment.version;

  foundArticles = [];


  @ViewChild('fileUpload') fileUpload: any;
  constructor(
    private offerService: OfferService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notify: NotifyService,
    private _adapter: DateAdapter<any>,
    @Inject('LOCALSTORAGE') private store: any
  ) {

    window.onbeforeunload = function (event) {
      console.log(event);
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };
  }

  async ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.route.data.subscribe(a => {
      console.log(a)

      if (a && a.type == "PRODUCTION") {
        this.isProduction = true;
      }
    })
    if (this.token) {
      this.store.set("localuser", { token: this.token })
    }
    this.offerId = this.route.snapshot.paramMap.get('offerId');
    this.counties = await this.offerService.getCounties();

    let result: any = await this.offerService.getLocations();
    this.locations = result.locations;
    await this.getDealers();
    //for caretta
    this.getOfferData(null);
    //for inter
    //await this.f();
    this._adapter.setLocale('ro');

    this.onChanges();
    let tokenData = this.decodeToken(this.store.get('localuser').token);
    this.username = tokenData.username;
    if (tokenData.username == "agent1" || tokenData.username == "comenzi" || tokenData.username == "parteneri.mici") {
      this.canChooseClientType = true;
    }

    if (tokenData.username == "catalin.dartu" || tokenData.username == "ovidiu.dragomir" || tokenData.username == "agent1") {
      this.canCancelCommand = true;
    }
    if (tokenData.username == "daniel.schifirnet" || tokenData.username == "agent007" || tokenData.username == "cpa" || tokenData.username == "agent1") {
      this.canPrintInternallCommandPdf = true;
    }
    // if (tokenData.username == "lupu.razvan" || tokenData.username == "surpanu.vasile" || !(this.offerData.invoiceType == 'Dealer')) {
    //     this.showPickedByClient = true;
    // }
  }

  decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  onChanges(): void {
    this.myControl.valueChanges.subscribe(val => {
      if (this.localities && this.localities.rows)
        this.filteredOptions = this.localities.rows.filter(option => option.localityName.toLowerCase().includes(val));
    });
  }

  initLocalities() {
    this.filteredOptions = this.localities.rows;
  }



  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {

    $event.returnValue = true;
    //}
  }

  localityChange() {
    console.log("filter")
  }

  checkPluvialType() {
    if (this.pluvialType == "SL") {
      this.notify.warningMessage("Selectati tip sistem pluvial!");
    }
    else {
      this.showPluvialTypeModal = false;
    }
  }

  //todo
  async loadLocalities(event) {
    console.log(event)
    this.newClientData.county = event.value;
    this.editClientData.county = event.value;
    this.newClientData.locality = "";
    this.spinner.show();
    this.localities = await this.offerService.getLocalities(event.value.countyId);
    this.spinner.hide();
    this.filteredOptions = this.localities.rows;
  }

  async getOfferData(locationId) {
    this.spinner.show();
    this.offerService.getOfferDolinex(this.offerId, locationId).subscribe(async (res: any) => {
      this.offerData = res;
      this.offerData.categories = this.offerData && this.offerData.categories ? this.offerData.categories : [];
      //this.pluvialType = this.offerData.offerData.pluvialType;
      //preload agents
      if (this.offerData.dealer) {
        this.dealerAgents = await this.offerService.loadDealerAgents(this.offerData.dealer.partnerId);
        this.offerData.invoiceType = "Dealer";
      }
      else {
        this.offerData.invoiceType = "Client";
      }
      if (!this.selectedOfferType) {
        this.selectedOfferType = res.offerData.selectedOfferType;
        this.selectedColor = res.offerData.selectedColor;
        this.selectedMaterial = res.offerData.selectedMaterial;
        this.selectedThickness = res.offerData.selectedThickness;
        this.currentOffer.deliveryDate = res.deliveryDate;
        this.currentOffer.invoiceType = res.invoiceType;
        this.currentOffer.finishType = res.finishType;
        this.currentOffer.paymentType = res.paymentType;
        this.currentOffer.observations = res.observations;
        this.currentOffer.phone = res.phone;
      }
      else {
        this.prefillClientData(this.offerData);

        this.offerData.contact = this.offerData.contact ? JSON.parse(this.offerData.contact) : this.offerData.contact;
        this.offerData.offerData.selectedOfferType = this.selectedOfferType;
        this.offerData.offerData.selectedColor = this.selectedColor;
        this.offerData.offerData.selectedMaterial = this.selectedMaterial;
        this.offerData.offerData.selectedThickness = this.selectedThickness;
        this.offerData.offerData.pluvialType = this.pluvialType;

      }
      //check if saved state

      if (this.temporaryArticles) {
        for (let temporaryArticle of this.temporaryArticles) {
          for (let category of this.offerData.categories) {
            for (let article of category.articles) {
              for (let detail of article.data) {
                if (detail.DENUMIRE.includes(temporaryArticle.name) && detail.D1 == temporaryArticle.D1 && detail.D2 == temporaryArticle.D2 && detail.D3 == temporaryArticle.D3) {
                  detail.quantity = temporaryArticle.quantity;
                }

                if (detail.quantity > 0 && (this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.agentMinimumPrice))) {
                  article.invalidPrice = true;

                }
              }
            }
          }
        }
      }

      if (this.offerData && this.offerData.dealer && this.offerData.client && this.offerData.client.fiscalCode == this.offerData.dealer.fiscalCode) {
        this.differentClient = false;
      }
      this.temporaryArticles = [];
      this.recalculate(true);
      this.spinner.hide();
    });

  }


  async onPhoneInputChange() {

    if (this.clientPhoneSearch.length >= 3) {
      this.clientFiscalCodeSearch = "";
      this.clientNameSearch = "";
      const data: any = await this.offerService.getClientData(this.clientPhoneSearch, "phone");
      this.clients = data.rows;
    }
  }

  async onNameInputChange() {
    if (this.clientNameSearch.length >= 3) {
      this.clientFiscalCodeSearch = "";
      this.clientPhoneSearch = "";
      const data: any = await this.offerService.getClientData(this.clientNameSearch, "name");
      this.clients = data.rows;
    }
  }

  async onFiscalCodeChange() {
    if (this.clientFiscalCodeSearch.length >= 3) {
      this.clientPhoneSearch = "";
      this.clientNameSearch = "";
      const data: any = await this.offerService.getClientData(this.clientFiscalCodeSearch, "cui");
      this.clients = data.rows;
      return;
    }
    return;
  }


  async onClientSelected(event) {
    this.selectedClient = event.option.value;
    this.offerData.client = {
      fiscalCode: event.option.value.CODFISCAL,
      name: event.option.value.DEN_PART,
      phone: event.option.value.TELEFON,
      internalId: event.option.value.CODPART
    }

    let rezz: any = await this.offerService.getClientData(event.option.value.CODFISCAL, "cui");

    //await this.offerService.getClientDetail(event.option.value.CODPART);

    this.offerData.client["address"] = {
      address: rezz.rows[0].ADRESA,
      city: rezz.rows[0].DEN_LOCALIT,
      conunty: rezz.rows[0].JUDET,
      headquarter: rezz.rows[0].headquarter
    }
  }

  displayPhone(client) {
    return client ? client.phone : '';
  }



  displayClientName(client) {
    return client ? client.name : '';
  }

  displayFiscalCode(client) {
    return client ? client.fiscalCode : '';
  }

  round2Decimals(value) {
    return Math.round(value * 100) / 100;
  }

  hasMultipleArts(item) {

    for (let article of item.articles) {
      if (article.data.length != 1) {
        return true;
      }
    }
    return false;
  }
  async cancelCommand() {

    this.spinner.show();
    if (typeof this.offerData.commandData !== 'object') {
      this.offerData.commandData = JSON.parse(this.offerData.commandData);
    }
    try {
      await this.offerService.cancelCommand(this.offerData.commandData.commandId, "Anulata " + this.cancelObservations);
    }
    catch (e) {
      console.log(e);
      this.spinner.hide();
    }
    this.cancelCommandFlag = false;
    this.spinner.hide();
    window.location.reload();

  }
  reset() {

    let dialogRef = this.dialog.open(DecisionModalComponent, {
      data: { title: "Salvare cantitati oferta", message: "Doriti salvarea cantitatilor de pe oferta actuala?" }
    });
    dialogRef.afterClosed().subscribe(async reset => {
      if (reset) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              if (detail.quantity) {
                let tempArticle = {
                  DENUMIRE: detail.DENUMIRE,
                  D1: detail.D1,
                  D2: detail.D2,
                  D3: detail.D3,
                  quantity: detail.quantity,
                  selectedColor: this.selectedColor,
                  selectedThickness: this.selectedThickness,
                };
                this.temporaryArticles.push(tempArticle);
              }
            }
          }
        }
        this.store.set("tempPrices", this.temporaryArticles);
      }
    });
  }

  loadPrices() {
    this.temporaryArticles = this.store.get("tempPrices");
    if (this.temporaryArticles) {
      for (let temporaryArticle of this.temporaryArticles) {
        for (let category of this.offerData.categories) {
          for (let article of category.articles) {
            for (let detail of article.data) {
              let articleParsedName = this.getParentName(detail, this.selectedColor, this.selectedThickness);
              let savedArticleParsedName = this.getParentName(temporaryArticle, temporaryArticle.selectedColor, temporaryArticle.selectedThickness);
              if (articleParsedName == savedArticleParsedName && detail.D1 == temporaryArticle.D1 && detail.D2 == temporaryArticle.D2 && detail.D3 == temporaryArticle.D3) {
                detail.quantity = temporaryArticle.quantity;
              }
            }
          }
        }
      }
    }
    this.temporaryArticles = [];
    this.recalculate(true);
    this.notify.successMessage("Cantitati precompletate!")
  }

  getParentName(article, selectedColor, selectedThickness) {
    //get selected data   {{selectedColor}} {{selectedMaterial}} {{selectedThickness}}
    let tempDen = article.DENUMIRE;
    tempDen = tempDen.split("PLUS").join("");
    tempDen = tempDen.split("103").join("");
    tempDen = tempDen.split("104").join("");
    tempDen = tempDen.split("105").join("");
    tempDen = tempDen.split("106").join("");
    tempDen = tempDen.split(selectedColor).join("");
    tempDen = tempDen.split(selectedThickness).join("");
    tempDen = tempDen.split("BR").join("");
    tempDen = tempDen.split("MAT").join("");
    tempDen = tempDen.split("SL").join("");
    return tempDen.trim();
  }

  getMaterial(offerType) {
    //this.materials = [];
    this.spinner.show();

    this.offerData.offerData.selectedOfferType = offerType;
    this.selectedOfferType = offerType;
    this.offerService.getMaterials(offerType.key).subscribe(async (materials: any) => {
      this.materials = materials;
      this.spinner.hide();
      if (materials.length == 0) {
        this.spinner.show();
        await this.getOfferData(null);
        this.offerId = this.tempOfferId;
        this.spinner.hide();
      }
    });
  }

  getColors(material) {
    this.spinner.show();
    this.materials = [];
    this.selectedMaterial = material;
    this.offerData.offerData.selectedMaterial = material;
    if (material != "SL") {
      let tokenData = this.decodeToken(this.store.get('localuser').token);
      if (tokenData.username == "agent1" || tokenData.username == "comenzi" || tokenData.username == "vasile.oslobanu" || tokenData.username == "parteneri.mici") {
        this.showPluvialTypeModal = true;
      }
      else {
        this.pluvialType = "BR";
      }
    }
    this.offerService.getColors(this.selectedOfferType.key, material).subscribe(async (colors: any) => {
      this.spinner.hide();
      this.colors = colors;
      if (colors.length == 0) {
        this.spinner.show();
        await this.getOfferData(null);
        this.offerId = this.tempOfferId;
        this.spinner.hide();
      }
    });
  }

  getThickness(color) {
    //this.materials = [];
    this.spinner.show();
    this.selectedColor = color;
    this.offerData.offerData.selectedColor = color;
    this.offerService.getThickness(this.selectedOfferType.key, this.selectedMaterial, this.selectedColor).subscribe(async (thickness: any) => {

      this.thickness = thickness;
      this.spinner.hide();
      if (thickness.length == 0) {
        this.spinner.show();
        await this.getOfferData(null);
        this.offerId = this.tempOfferId;
        this.spinner.hide();
      }

    });


  }

  async selectThickness(thickness) {
    this.spinner.show();
    this.selectedThickness = thickness;
    await this.getOfferData(null);
    this.offerId = this.tempOfferId;
    this.spinner.hide();
  }

  getTotalQuantity(articleDetails) {
    let sum = 0;
    for (let articleDetail of articleDetails.data) {
      if (articleDetail.quantity && articleDetail.D2) {
        sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
      }
      else if (articleDetail.quantity) {
        sum += articleDetail.D1 * articleDetail.quantity;
      }
    }
    articleDetails.totalQuantity = sum;
    return sum;
  }

  getArticleTotalQuantity(articleDetail) {
    let sum = 0;
    if (articleDetail.quantity && articleDetail.D2) {
      sum += articleDetail.D1 * articleDetail.D2 * articleDetail.quantity;
    }
    else if (articleDetail.quantity) {
      sum += articleDetail.D1 * articleDetail.quantity;
    }
    return sum;
  }

  getTotalPrice() {
    this.totalPrice = 0;
    this.totalOfferWeight = 0;
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {
            if (detail.quantity) {
              this.totalPrice += parseFloat(detail.quantity) * parseFloat(detail.agentPrice);
            }
            if (detail.quantity && detail.MASA) {
              this.totalOfferWeight += parseFloat(detail.quantity) * parseFloat(detail.MASA);
            }
          }
        }
      }
    }
    this.totalPrice = this.round2Decimals(this.totalPrice);
    this.totalOfferWeight = this.round2Decimals(this.totalOfferWeight);
  }

  getTotalListPrice() {
    this.totalPrice = 0;


    return this.round2Decimals(this.totalPrice);
  }
  //copy price to all details of an article
  propagateQuantity(articles) {
    for (let article of articles) {
      article.agentPrice = articles[0].agentPrice;
    }
  }

  async recalculate(event) {
    if (event && event.keyCode == 188) {
      this.notify.infoMessage("Folositi punctul ca separator pentru zecimale!")
      return;
    }

    this.totalPrice = 0;
    this.totalMinimumPrice = 0;
    this.totalWithOutDiscount = 0;
    this.discountValue = 0;
    this.discountPercent = 0;
    this.totalOfferWeight = 0;
    //price withoutDiscount
    this.invalidPrice = false;
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {
            if (detail.quantity > 0 && ((this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.agentMinimumPrice) || !detail.agentPrice) && detail.PROMOTIE != 1)) {
              article.invalidPrice = true;
              this.invalidPrice = true;
            }
            else {
              article.invalidPrice = false;
            }

            if (detail.quantity) {
              detail.quantity = detail.quantity.toString().replace("-", "");
              detail.quantity = detail.quantity.toString().replace(",", "");
              this.totalWithOutDiscount += parseFloat(detail.quantity) * parseFloat(detail.LISTA) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
              this.totalPrice += parseFloat(detail.quantity) * parseFloat(article.data[0].agentPrice) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
              this.totalMinimumPrice += parseFloat(detail.quantity) * parseFloat(article.data[0].agentMinimumPrice) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
              if (article.data[0].MASA) {
                this.totalOfferWeight += parseFloat(detail.quantity) * parseFloat(article.data[0].MASA) * (detail.D1 ? parseFloat(detail.D1) : 1) * (detail.D2 ? parseFloat(detail.D2) : 1) * (detail.D3 ? parseFloat(detail.D3) : 1);
              }

            }

          }
        }
      }
    }
    this.totalWithOutDiscount = this.round2Decimals(this.totalWithOutDiscount);
    this.totalPrice = this.round2Decimals(this.totalPrice);
    this.discountValue = this.round2Decimals(this.totalWithOutDiscount - this.totalPrice)
    this.discountPercent = this.round2Decimals((this.totalWithOutDiscount - this.totalPrice) / this.totalWithOutDiscount * 100);
    this.adaosValue = this.round2Decimals((this.totalPrice ? this.totalPrice / 1.19 : 0) - (this.totalMinimumPrice ? this.totalMinimumPrice / 1.19 : 0))
    this.adaosPercent = this.round2Decimals((this.totalPrice - this.totalMinimumPrice) / this.totalPrice * 100);
  }

  getSubtotal(price, quantity) {
    return this.round2Decimals(quantity ? parseFloat(price) * quantity : 0);
  }

  async saveOffer(status, message) {
    //check if dealer and data is completed
    if (!this.offerData.invoiceType) {
      this.notify.warning(`Selecteaza tip comanda!`);
      return;
    }
    if (this.offerData.invoiceType == 'Dealer' && Object.keys(this.offerData.dealer).length === 0 && this.offerData.dealer.constructor === Object) {
      this.notify.warning(`Selecteaza dealerul!`);
      return;
    }
    if (this.offerData.invoiceType == 'Dealer' && Object.keys(this.offerData.dealerAgent).length === 0 && this.offerData.dealerAgent.constructor === Object) {
      this.notify.warning(`Selecteaza agentul dealerului!`);
      return;
    }

    if (this.offerData.invoiceType == 'Client' && this.offerData.isAdvancePayment && this.offerData.advancePaymentValue <= 0) {
      this.notify.warning(`Precizati suma avans!`);
      return;
    }

    let hasArticle = false;
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {
            if (detail.quantity > 0) {
              hasArticle = true;
            }
            if (detail.quantity > 0 && (this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.agentMinimumPrice))) {
              this.notify.warning(`Oferta contine preturi sub limita stabilita articol ${article.articleName}!`);
              return;

            }
          }
        }
      }
    }

    if (!hasArticle) {
      this.notify.errorMessage("Oferta nu contine articole!");
      this.spinner.hide();
      return;
    }


    this.offerData.pickedByClient = this.offerData.pickedByClient ? this.offerData.pickedByClient : false;
    this.spinner.show();

    //save filter data in other object
    let fulldata = JSON.parse(JSON.stringify(this.offerData.topCategories));
    this.offerData.categories = [];
    for (let topCategory of this.offerData.topCategories) {

      let tt = topCategory.categories.filter(categ => {
        categ.articles = categ.articles.filter(article => {
          article.data = article.data.filter(art => {
            return parseFloat(art.quantity) > 0
          });
          return article.data.length > 0;
        })
        return categ.articles.length > 0;
      })
      this.offerData.categories = [...this.offerData.categories, ...tt]
    }



    delete this.offerData.topCategories;
    try {

      this.offerData.status = status;
      this.offerData.actions = [{
        action: message ? message : "Oferta a fost schimbata!",
        date: new Date()
      }];

      await this.offerService.saveOfferV2(this.offerData);
      delete this.offerData.categories;
      this.offerData.topCategories = fulldata;
      this.spinner.hide();
    }
    catch (e) {
      this.spinner.hide();
    }
  }


  async finishCommand() {
    let surface = 0;

    let hasArticle = false;
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {
            if (detail.quantity > 0) {
              hasArticle = true;
            }
            if (detail.quantity > 0 && (this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.agentMinimumPrice))) {
              this.notify.warning(`Oferta contine preturi sub limita stabilita articol ${article.articleName}!`);
              return;

            }
          }
        }
      }
    }

    if (!hasArticle) {
      this.notify.errorMessage("Oferta nu contine articole!");
      this.spinner.hide();
      return;
    }


    if (!this.offerData.client.fiscalCode) {
      this.notify.warning(`Oferta nu contine detalii client!`);
      return;
    }

    if (!this.offerData.deliverDate) {
      this.notify.warningMessage("Selectati data de livrare!");
      return;
    }

    if (!this.offerData.finishType) {
      this.notify.warningMessage("Selectati tip document!");
      return;
    }

    if (!this.offerData.paymentType) {
      this.notify.warningMessage("Selectati modalitatea de plata!");
      return;
    }

    if (this.offerData.invoiceType == 'Client' && this.offerData.isAdvancePayment && this.offerData.advancePaymentValue <= 0) {
      this.notify.warning(`Precizati suma avans!`);
      return;
    }

    let dialogRef = this.dialog.open(DecisionModalComponent, {
      data: { title: "Salvare comanda", message: "Doriti salvarea comenzii in mentor?" }
    });

    let boolResponse = await dialogRef.afterClosed().toPromise();
    if (!boolResponse) {
      return;
    }

    let serverData = JSON.parse(JSON.stringify(this.offerData));

    //filter categories, only articles with quantity
    let filteredCategories = [];
    for (let topCategory of this.offerData.topCategories) {


      topCategory.categories.map(categ => {
        let filteredCategory = {
          category: categ.category,
          articles: []
        }
        categ.articles.map(article => {
          let filteredArticle = {
            articleName: article.articleName,
            invalidPrice: article.invalidPrice,
            totalQuantity: article.totalQuantity,
            data: []
          };
          article.data.map(art => {
            if (parseFloat(art.quantity) > 0) {
              filteredArticle.data = [...filteredArticle.data, art]
            }
          });
          if (filteredArticle.data.length > 0) {
            filteredCategory.articles = [...filteredCategory.articles, filteredArticle]
          }
        })
        if (filteredCategory.articles.length > 0) {
          filteredCategories = [...filteredCategories, filteredCategory]
        }
      });

    }

    serverData.categories = filteredCategories;
    serverData.addedValue = this.adaosValue;

    serverData.operated = this.offerData.client && (this.offerData.client.soldValue + this.totalPrice < this.offerData.client.creditValue) && !this.invalidPrice ? true : false;
    if (serverData.categories.length == 0) {
      this.notify.warningMessage("Oferta nu contine articole!");
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    let res: any = await this.offerService.finishCommandV2(serverData);
    console.log(res);
    this.offerData.data = {
      commandId: res.commandId,
      commandSeries: res.commandSeries,
      commandNumber: res.commandNumber
    }
    this.offerData.commandData = {
      commandId: res.commandId,
      commandSeries: res.commandSeries,
      commandNumber: res.commandNumber
    }
    this.offerData.status = "COMANDA";
    this.spinner.hide();
    this.notify.successMessage(`Comanda ${res.commandNumber} a fost salvata!`);
    return;
  }

  getPriceByCategory(dealer, detail) {
    if (dealer.priceCategorySymbol == "I") {
      return detail.C1;
    }
    else if (dealer.priceCategorySymbol == "II" || dealer.priceCategorySymbol == "IV") {
      return detail.C2;
    }
    else if (dealer.priceCategorySymbol == "III") {
      return detail.C3;
    }
  }

  async generateOfferPdf() {
    // if (!this.offerData.client.fiscalCode) {
    //     this.notify.warning(`Oferta nu contine detalii client!`);
    //     return;
    // }
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {
            if (detail.quantity > 0 && (this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.agentMinimumPrice))) {
              this.notify.warning(`Oferta contine preturi sub limita stabilita articol ${article.articleName}!`);
              return;

            }
          }
        }
      }
    }

    let categories = [];
    for (let topCategory of this.offerData.topCategories) {
      categories = [...categories, ...topCategory.categories]
    }

    let body = {
      offer: categories,
      offerData: {
        selectedOfferType: this.selectedOfferType,
        selectedColor: this.selectedColor,
        selectedMaterial: this.selectedMaterial,
        selectedThickness: this.selectedThickness
      },
      summary: {
        totalPrice: this.totalPrice,
        totalWithOutDiscount: this.totalWithOutDiscount,
        discountValue: this.discountValue,
        discountPercent: this.discountPercent
      },
      client: this.offerData.client,
      includePrices: this.includePrices,
      id: this.offerData._id,
      commandNumber: this.offerData && this.offerData.data ? this.offerData.data.commandNumber : ''
    }
    console.log(this.includePrices)
    this.spinner.show();
    let res: any = await this.offerService.generateOfferPdf(body);
    this.spinner.hide();

    this.offerData.actions = [{
      action: "Pdf generat",
      date: new Date()
    }];
    this.offerData.files = [
      {
        name: `${res.pdfName.split("_")[0]}`,
        path: `public/${res.pdfName}`
      }
    ];
    //todo duplicates command nodes
    ///await this.saveOffer(this.offerData.status, "PDF generat!");
    window.open(`${this.serviceUrl}/public/${res.pdfName}`);
  }

  async sendOfferEmail() {
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {
            if (detail.quantity > 0 && (this.round2Decimals(detail.agentPrice) < this.round2Decimals(detail.agentMinimumPrice))) {
              this.notify.warning(`Oferta contine preturi sub limita stabilita articol ${article.articleName}!`);
              return;

            }
          }
        }
      }
    }

    let categories = [];
    for (let topCategory of this.offerData.topCategories) {
      categories = [...categories, ...topCategory.categories]
    }

    let body = {
      offer: categories,
      offerData: {
        selectedOfferType: this.selectedOfferType,
        selectedColor: this.selectedColor,
        selectedMaterial: this.selectedMaterial,
        selectedThickness: this.selectedThickness
      },
      summary: {
        totalPrice: this.totalPrice,
        totalWithOutDiscount: this.totalWithOutDiscount,
        discountValue: this.discountValue,
        discountPercent: this.discountPercent
      },
      client: this.offerData.client,
      includePrices: this.includePrices,
      id: this.offerData._id,
      commandNumber: this.offerData && this.offerData.data ? this.offerData.data.commandNumber : ''
    }
    console.log(this.includePrices)
    this.spinner.show();
    let res: any = await this.offerService.generateOfferEmailPdf(body);
    this.spinner.hide();

    // this.offerData.actions = [{
    //   action: "Pdf generat",
    //   date: new Date()
    // }];
    // this.offerData.files = [
    //   {
    //     name: `${res.pdfName.split("_")[0]}`,
    //     path: `public/${res.pdfName}`
    //   }
    // ];
  }

  async generateCommandsInternalDocumentsPdf() {
    this.spinner.show();
    this.offerData.summary = {
      totalPrice: this.totalPrice,
      totalWithOutDiscount: this.totalWithOutDiscount,
      discountValue: this.discountValue,
      discountPercent: this.discountPercent
    }
    let res: any = await this.offerService.generateCommandsInternalDocumentsPdf(this.offerData);
    this.spinner.hide();
    window.open(`${this.serviceUrl}/public/${res.pdfName}`);
  }

  async prefillClientData(offer) {
    if (!offer.offerData.selectedOfferType) {
      let contacts = JSON.parse(offer.contact)

      for (let contact of contacts) {
        if (!contact.phone) {
          break;
        }
        const data: any = await this.offerService.getClientData(contact.phone, "phone");
        if (data.rows.length > 0) {
          this.onClientSelected({ option: { value: data.rows[0] } })
          break;
        }
      }
    }
  }
  async prefillEditClientData(client) {
    console.log(client)
    let filteredCounties = this.counties.rows.filter(county => county.countyName == client.JUDET);
    this.selectedCounty = filteredCounties[0];
    let filteredLocalities;
    if (this.selectedCounty) {
      this.localities = await this.offerService.getLocalities(this.selectedCounty.countyId);
      this.filteredOptions = this.localities.rows;
      filteredLocalities = this.localities.rows.filter(locality => locality.localityName == client.DEN_LOCALIT);
    }



    /////////////////////////
    this.editClientData = {
      isPF: client.PERSOANAFIZICA == 1 ? true : false,
      clientId: client.CODPART,
      name: client.DEN_PART,
      agendaId: client.CODAGENDA,
      fiscalCode: client.CODFISCAL,
      tradeRegister: client.REGCOM,
      phone: client.TELEFON,
      county: this.selectedCounty,
      locality: filteredLocalities ? filteredLocalities[0] : "",
      street: client.ADRESA,
      scara: "",
      number: client.NUMBER,
      bloc: client.BLOC,
      etaj: client.ETAJ,
      apartament: client.APART
    }
    this.editClient = true;
  }


  async prefillSelectedClientData(client) {
    if (!client.CODFISCAL) {
      this.notify.warningMessage("Partenerul selectat nu are completat CUI/CNP !");
      return;
    }
    this.spinner.show();
    let rr: any = await this.offerService.getSoldDetail(client.CODPART);

    if (this.offerData.dealer && client.CODFISCAL != this.offerData.dealer.fiscalCode) {
      this.differentClient = true;
    }
    else {
      this.differentClient = false;
    }
    this.selectedClient = client;
    this.offerData.client = {
      creditValue: rr.creditValue,
      soldValue: rr.soldValue,
      characterizationName: rr.characterizationName,
      fiscalCode: client.CODFISCAL,
      name: client.DEN_PART,
      email: client.EMAIL,
      phone: client.TELEFON,
      internalId: client.CODPART,
      address: {
        address: client.ADRESA,
        city: client.DEN_LOCALIT,
        conunty: client.JUDET,
        headquarter: client.headquarter
        //todo add headquarter
      }
    }
    this.spinner.hide();
    this.showClientsModal = null;
  }

  async insertNewPartner() {

    if (!this.newClientData.locality.localityId) {
      this.notify.warningMessage("Selectati localitatea!");
      return 0;
    }
    this.spinner.show();
    this.newClientData.name = this.newClientData.name.toUpperCase();
    const insertResult: any = await this.offerService.insertPartner(this.newClientData);
    console.log(insertResult)
    if (insertResult.Error == "ok") {
      this.spinner.hide();
      this.notify.successMessage("Partener salvat in mentor!");
      this.addNewClient = false;
      this.clientFiscalCodeSearch = this.newClientData.fiscalCode;
      await this.onFiscalCodeChange();
      await this.prefillSelectedClientData(this.clients[0])
      this.showClientsModal = false;

    }
    else {
      this.spinner.hide();
      this.notify.errorMessage(insertResult.Error);
    }
  }

  async editPartner() {

    if (!this.editClientData.locality || !this.editClientData.locality.localityId) {
      this.notify.warningMessage("Selectati localitatea!");
      return 0;
    }
    console.log(this.editClientData)
    //filter client
    await this.offerService.editClient(this.editClientData);

    this.clientFiscalCodeSearch = this.editClientData.fiscalCode;
    this.onFiscalCodeChange();
    this.editClient = false;
  }

  async getDealers() {
    this.dealers = await this.offerService.getDealers();
  }

  async loadDealerAgents(event) {

    /////
    this.spinner.show();
    this.selectedDealer = event.value;
    //this.offerData.dealer = this.selectedDealer;

    /////prefill dealer data 
    const data: any = await this.offerService.getClientData(this.selectedDealer.fiscalCode, "cui");
    if (data.rows.length > 0) {
      this.onClientSelected({ option: { value: data.rows[0] } })
    }
    ////
    this.dealerAgents = await this.offerService.loadDealerAgents(this.selectedDealer.partnerId);
    this.spinner.hide();
  }

  async onSelectedDealerAgent(event) {
    this.selectedDealerAgent = event.value;
    this.offerData.client["address"] = {
      address: this.selectedDealerAgent.ADRESA,
      city: this.selectedDealerAgent.DEN_LOCALIT,
      conunty: this.selectedDealerAgent.JUDET,
      headquarter: this.selectedDealerAgent.headquarter
    }
    //this.offerData.dealerAgent = this.selectedDealerAgent;
  }

  loadAgentPrice(P) {
    for (let category of this.offerData.categories || []) {
      for (let article of category.articles) {
        for (let detail of article.data) {

          if (detail[P] > detail.agentMinimumPrice) {
            detail.agentPrice = detail[P];

          }
        }
      }
    }
    this.recalculate(null);
  }

  async getStoc(article) {
    console.log(article.CODOBIECT)
    //this.articleStockByLocation = await this.offerService.loadDealerAgents(this.selectedDealer.partnerId);
    let res: any = await this.offerService.getArticleManagementStock(article.CODOBIECT).toPromise();
    this.selectedArticle = article;
    this.stockDetail = res.rows;

    //if quantity saved on offer put in stockDetail object
    if (article.stockDetail) {
      console.log("HAS DETAIL")
      for (let det of this.stockDetail) {
        let offerDetails = article.stockDetail.filter(stockDetail => {
          return det.managementId == stockDetail.managementId;
        });
        if (offerDetails.length > 0) {
          det.conversionQuantity = offerDetails[0].conversionQuantity;
        }

      }
    }
  }

  commandTypeChanged(event) {
    if (event.value == "Client") {
      delete this.offerData.dealer;
      delete this.offerData.dealerAgent;
    }
  }

  compareDealers(o1, o2) {
    return o1.partnerId == o2.partnerId;
  }
  compareDealerAgents(o1, o2) {
    return o1.agentId == o2.agentId;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  showColorWarningDialog(data) {
    if (data.length == 1 && this.scannedItems.length == 0) { return; }
    if (data.length > 0) {
      let dialogRef = this.dialog.open(DecisionModalComponent, {
        data: {
          title: "dialog.title.warning",
          message: "offers.cart_color_warning",
          items: data,
          showClose: false
        }
      });
    }
  }

  round4Decimals(value) {
    return Math.round(value * 10000) / 10000;
  }



  delay(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  isTigla(articleName) {
    return articleName.includes("TIGLA METALICA");
  }


  async setCountyDelivery(event) {
    console.log(event.value.countyName)
    this.offerData.deliverAddress = event.value.countyName;

    this.spinner.show();
    this.localities = await this.offerService.getLocalities(event.value.countyId);
    this.filteredOptions = [...this.localities.rows];

    this.spinner.hide();
  }

  async setLocalityDelivery(event) {
    this.offerData.deliverAddress += " " + event.value.localityName
  }

  alternativeDeliveryAdress() {
    this.showAlternativeDelivery = false;
  }

  async onBasicUpload(event) {
    console.log(event)
    const formData = new FormData();
    formData.append("file", event.currentFiles[0]);

    //console.log('boundary:', formData._boundary);
    await this.offerService.saveFile(formData);

    console.log(event.currentFiles[0])
    this.offerData.files = [...this.offerData.files, {
      name: event.currentFiles[0].name,
      path: `public/agent1/${event.currentFiles[0].name}`,
      user: "agent1"
    }]
    this.fileUpload.clear();

    this.saveOffer(this.offerData.status, null);
  }

  onBasicUploadAuto(e) {
    console.log(e)
  }

  getCalculatedStock(selectedArticle, stockArt) {
    stockArt.conversionValue = stockArt.conversionValue ? stockArt.conversionValue : 1;
    let value = (selectedArticle && selectedArticle.MASA ? selectedArticle.MASA : "1") * stockArt.stock / stockArt.conversionValue;
    console.log(value)
    return Math.floor(value);
  }

  onSearchChange(searchValue: string): void {
    if (searchValue.length <= 2) {
      this.foundArticles = [];
      return;
    }
    else {
      this.foundArticles = [];
      for (let topCategory of this.offerData.topCategories) {
        for (let category of topCategory.categories) {
          for (let article of category.articles) {
            if (article.articleName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
              this.foundArticles.push(article)
            }
          }
        }
      }

      this.foundArticles.sort((a, b) => {
        if (a.articleName < b.articleName) { return -1; }
        if (a.articleName > b.articleName) { return 1; }
        return 0;
      })


    }

    console.log(this.foundArticles)
  }

  changeflag(f1, f2, f3) {
    this.f1 = f1;
    this.f2 = f2;
    this.f3 = f3;
  }
  saveQuantity(selectedArticle, stockItems) {
    let quantity = 0;
    let conversionQuantitySum = 0;
    for (let stockItem of stockItems) {
      if (stockItem.conversionQuantity && stockItem.conversionQuantity > 0) {
        quantity += stockItem.conversionQuantity;
        conversionQuantitySum += stockItem.conversionQuantity;
      }
    }
    for (let topCategory of this.offerData.topCategories) {
      for (let category of topCategory.categories) {
        for (let article of category.articles) {
          for (let detail of article.data) {

            if (detail.CODOBIECT == selectedArticle.CODOBIECT && detail.managementId == selectedArticle.managementId) {
              detail.quantity = quantity;
              detail.stockDetail = stockItems;
              detail.conversionQuantitySum = conversionQuantitySum;
            }
          }
        }
      }
    }
    this.stockDetail = null;
  }


  getStockOfLocation(event) {
    this.getOfferData(event.value ? event.value.locationId : null);

  }

}
