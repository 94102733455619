<div style="display:flex;min-height:100%;background-color: #797d82;" *ngIf=" offerData?.topCategories?.length>0">

    <div style="margin: 0 0 0 auto">

    </div>
    <div>
        <!---Start SEARCH AREA---->
        <div *ngIf="f2" class="searchArea">
            <div class="p-mt-3 p-mb-2">
                <span class="p-inputgroup">
                    <input placeholder="Cautati articole" inputId="street"
                        [style]="{'text-align':'left','width':'100%'}" type="text" pInputText [(ngModel)]="searchText"
                        (input)="onSearchChange($event.target.value)" />
                    <!-- <label for="street">Cautati articole</label> -->

                    <button (click)="searchText='';onSearchChange('')" type="button" style=" background: #2b50a3;"
                        pButton pRipple icon="pi pi-times" styleClass="p-button-danger"></button>
                </span>
            </div>


            <div class="result" *ngIf="isFoundSearch">
                <table id="customers" style="width:100%">
                    <tr style="font-size:14px;">
                        <th class="name">Nume</th>
                        <th class="unitMeasure">UM</th>
                        <th class="quantity">Cant.</th>
                        <th class="parity">Paritate</th>

                        <th class="price">Discount</th>
                        <th class="listPrice">Pret Lista</th>
                        <th class="listPrice">Subtotal</th>
                        <th class="quantity">Stoc.</th>
                        <th class="quantity">INFO</th>
                    </tr>
                    <ng-template ngFor let-topcategory [ngForOf]="offerData.topCategories">
                        <ng-template ngFor let-item [ngForOf]="topcategory.categories">
                            <tr *ngFor="let art of item.articles; let itemI = index">
                                <ng-container
                                    *ngIf="art.found && art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                                    <td [ngClass]="art.data[0].quantity ?'selectedItem name':'name'">
                                        {{art.articleName}}
                                    </td>
                                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                        {{art.data[0].UM}}
                                    </td>
                                    <td style="background-color: #ceedff;">
                                        <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                                            {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
                                        <input *ngIf="art.data[0].PRODUSPROPRIU==2"
                                            [disabled]="offerData.status=='COMANDA'" style="width: 100%; "
                                            (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity"
                                            type="number">
                                    </td>
                                    <td style="background-color: #ceedff;text-align: center;">
                                        {{art.data[0].parity}}
                                    </td>
                                    <td class="price">
                                        <!-- [disabled]="!differentClient" -->
                                        <input [disabled]="offerData.status=='COMANDA'"
                                            [ngClass]="art.invalidPrice?'alert':''" style="width: 100%; "
                                            (keyup)="recalculate($event)" [(ngModel)]="art.data[0].discount"
                                            type="number">
                                    </td>
                                    <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                                        {{round2Decimals(art.data[0].LISTA)}}
                                    </td>
                                    <td
                                        [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                        {{getSubtotal(art.data[0])}}
                                    </td>
                                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                        {{art.data[0].STOC}}
                                    </td>
                                    <td [ngClass]="!art.data[0].quantity || art.data[0].quantity<=art.data[0].STOC?'infoBtn':'infoBtnStock'"
                                        *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])">
                                        <div style="width:25px;height: 25px;    cursor: pointer;"
                                            [inlineSVG]="'/assets/images/info_int.svg'"></div>
                                    </td>
                                    <!-- <td>{{art.data[0].STOC}}</td> -->

                                </ng-container>

                                <ng-container *ngIf="art.data.length!=1 && art.found">
                                    <td *ngIf="art.data[0].PROMOTIE!=1"
                                        [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                                        {{art.articleName}}{{art.found}}
                                    </td>
                                    <td *ngIf="art.data[0].PROMOTIE==1"
                                        [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                                        {{art.articleName}}
                                    </td>
                                    <td style=" text-align: center;color: #536c79;font-weight: bold;">
                                        {{round2Decimals(getTotalQuantity(art))}}
                                    </td>
                                    <td class="unitMeasure">{{art.data[0].UM}}</td>
                                    <!-- [disabled]="differentClient" -->
                                    <td class="price"><input [disabled]="!differentClient"
                                            [ngClass]="art.invalidPrice?'alert':''"
                                            (keyup)="propagateQuantity(art.data);recalculate($event)"
                                            [(ngModel)]="art.data[0].discount" style="width: 100%;" type="number">
                                    </td>
                                    <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                                    <td
                                        [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                        {{getSubtotal(art.data[0])}}
                                    </td>
                                    <td class="quantity">INFO</td>
                                </ng-container>
                        </ng-template>
                    </ng-template>
                </table>
            </div>
        </div>
        <!---END Display Articles in search table---->

        <!---START Display Articles that are in shopping bag accordion---->
        <div *ngIf="f1" style="margin:5px; width:750px" class="orderedArticles">
            <mat-card>
                <div *ngIf="offerData.status!='COMANDA'" class="categoryTitle" style="text-align: center">Articole
                    selectate
                    pentru ofertare</div>
                <div *ngIf="offerData.status=='COMANDA'" class="categoryTitle" style="text-align: center">Articole din
                    comanda
                </div>

                <table id="customers" style="width:100%">
                    <tr style="font-size:14px;">
                        <th class="name">Nume</th>
                        <th class="unitMeasure">UM</th>
                        <th class="quantity">Cant.</th>
                        <th class="parity">Paritate</th>
                        <th class="price">Discount</th>
                        <th class="listPrice">Pret Lista</th>
                        <th class="listPrice">Subtotal</th>
                        <th class="quantity">Stoc.</th>
                        <th class="quantity">INFO</th>
                    </tr>
                    <ng-template ngFor let-topcategory [ngForOf]="offerData.topCategories">
                        <ng-template ngFor let-item [ngForOf]="topcategory.categories">
                            <tr *ngFor="let art of item.articles; let itemI = index">
                                <ng-container
                                    *ngIf="art.data[0].quantity && art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                                    <td [ngClass]="art.data[0].quantity ?'selectedItem name':'name'">
                                        {{art.articleName}}
                                    </td>
                                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                        {{art.data[0].UM}}
                                    </td>
                                    <td style="background-color: #ceedff;">
                                        <b *ngIf="art.data[0].PRODUSPROPRIU!=2">
                                            {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
                                        <input *ngIf="art.data[0].PRODUSPROPRIU==2"
                                            [disabled]="offerData.status=='COMANDA'" style="width: 100%; "
                                            (keyup)="recalculate($event)" [(ngModel)]="art.data[0].quantity"
                                            type="number">
                                    </td>
                                    <td style="background-color: #ceedff;text-align: center;">
                                        {{art.data[0].parity}}
                                    </td>
                                    <td class="price">
                                        <input [disabled]="offerData.status=='COMANDA'"
                                            [ngClass]="art.invalidPrice?'alert':''" style="width: 100%; "
                                            (keyup)="recalculate($event)" [(ngModel)]="art.data[0].discount"
                                            type="number">
                                    </td>
                                    <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                                        {{round2Decimals(art.data[0].LISTA)}}
                                    </td>
                                    <td
                                        [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                        {{getSubtotal(art.data[0])}}
                                    </td>
                                    <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                        {{art.data[0].STOC}}
                                    </td>
                                    <td *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])">
                                        <div [ngClass]="!art.data[0].quantity || art.data[0].quantity<=art.data[0].STOC?'infoBtn':'infoBtnStock'"
                                            style="width:25px;height: 25px;    cursor: pointer;"
                                            [inlineSVG]="'/assets/images/info_int.svg'"></div>
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="art.data.length!=1 && art.data[0].quantity">
                                    <td *ngIf="art.data[0].PROMOTIE!=1"
                                        [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                                        {{art.articleName}}
                                    </td>
                                    <td *ngIf="art.data[0].PROMOTIE==1"
                                        [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                                        {{art.articleName}}
                                    </td>
                                    <td style="    text-align: center;color: #536c79;font-weight: bold;">
                                        {{round2Decimals(getTotalQuantity(art))}} {{art.data[0].PRODUSPROPRIU}}
                                    </td>
                                    <td class="unitMeasure">{{art.data[0].UM}}</td>
                                    <!-- [disabled]="differentClient" -->
                                    <td class="price"><input [disabled]="!differentClient"
                                            [ngClass]="art.invalidPrice?'alert':''"
                                            (keyup)="propagateQuantity(art.data);recalculate($event)"
                                            [(ngModel)]="art.data[0].discount" style="width: 100%;" type="number">
                                    </td>
                                    <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                                    <td
                                        [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                        {{getSubtotal(art.data[0])}}
                                    </td>

                                </ng-container>
                            </tr>
                        </ng-template>
                    </ng-template>
                </table>
            </mat-card>



        </div>
        <!---END Display Articles that are in shopping bag accordion---->


        <!-- <mat-accordion *ngIf="f3 && !searchText">
            <mat-expansion-panel *ngFor="let topCategory of offerData.topCategories "
                (opened)="topCategory.panelOpenState = true" (closed)="topCategory.panelOpenState = false"
                style="margin:5px; width:750px;">

                <mat-expansion-panel-header style="padding:0 15px;" [collapsedHeight]="'30px'"
                    [expandedHeight]="'35px'">
                    <mat-panel-title>
                        <div class="categoryTitle" style="text-align: center">{{topCategory.topCategoryName}}</div>
                    </mat-panel-title>
                </mat-expansion-panel-header> -->
        <ng-container *ngIf="f3 && !searchText">

            <ng-container *ngFor="let topCategory of offerData.topCategories ">

                <mat-accordion *ngIf=" f3 && !searchText ">
                    <div *ngIf="topCategory.categories.length>1">
                        <div *ngFor="let item of topCategory.categories">
                            <mat-expansion-panel style="margin:1px;width: 760px;" id="{{item.category}}"
                                (opened)="item.panelOpenState = true" (closed)="item.panelOpenState = false">
                                <mat-expansion-panel-header style="padding:0 10px;" [collapsedHeight]="'30px'"
                                    [expandedHeight]="'35px'">
                                    <mat-panel-title>
                                        <div class="categoryTitle" style="text-align: center">{{item.category}}
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <table *ngIf="item.panelOpenState" id="customers" style="width:100%">
                                    <tr style="font-size:14px;">
                                        <th class="name">Nume</th>
                                        <th class="unitMeasure">UM</th>

                                        <th class="quantity">Cant.</th>
                                        <th class="parity">Paritate</th>

                                        <th class="price">Discount</th>
                                        <th class="listPrice">Pret</th>
                                        <th class="listPrice">Subtotal</th>
                                        <th class="quantity">Stoc.</th>
                                        <th class="quantity">INFO</th>
                                    </tr>
                                    <tr *ngFor="let art of item.articles; let itemI = index">
                                        <ng-container
                                            *ngIf="art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                                            <td [ngClass]="art.data[0].quantity ?'selectedItem name':'name'">
                                                {{art.articleName}}
                                            </td>
                                            <td
                                                [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                                {{art.data[0].UM}}
                                            </td>

                                            <td style="background-color: #ceedff;text-align: center;">
                                                <b>
                                                    {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}
                                                </b>
                                            </td>
                                            <td style="background-color: #ceedff;text-align: center;">
                                                <span *ngIf="art.data[0].boxSelected && art.data[0].boxSelected>0">
                                                    {{art.data[0].layerParity}}/strat
                                                    <!-- {{art.data[0].layerParity * math.floor(170/art.data[0].itemHeight)}}/palet -->
                                                </span>

                                                <span *ngIf="art.data[0].layerSelected&& art.data[0].layerSelected>0">
                                                    {{art.data[0].layerParity}} straturi
                                                    <!-- inaltime {{art.data[0].itemHeight}} -->
                                                </span>

                                            </td>

                                            <td class="price">
                                                <input [disabled]="offerData.status=='COMANDA'"
                                                    [ngClass]="art.invalidPrice?'alert':''" style="width: 100%; "
                                                    (keyup)="recalculate($event)" [(ngModel)]="art.data[0].discount"
                                                    type="number">
                                            </td>
                                            <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                                                {{round2Decimals(art.data[0].LISTA/(1+((art.data[0] &&
                                                art.data[0].discount?art.data[0].discount:0)/100)))}}
                                            </td>
                                            <td
                                                [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                                {{getSubtotal(art.data[0])}}
                                            </td>
                                            <td
                                                [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                                {{math.floor(art.data[0].STOC)}}
                                            </td>

                                            <td>
                                                <div [ngClass]="!art.data[0].quantity || art.data[0].quantity<=art.data[0].STOC?'infoBtn':'infoBtnStock'"
                                                    *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])"
                                                    style="width:25px;height: 25px;    cursor: pointer;    cursor: pointer;"
                                                    [inlineSVG]="'/assets/images/info_int.svg'"></div>
                                            </td>

                                        </ng-container>

                                        <ng-container *ngIf="art.data.length!=1 ">
                                            <td *ngIf="art.data[0].PROMOTIE!=1"
                                                [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                                                {{art.articleName}}
                                            </td>
                                            <td *ngIf="art.data[0].PROMOTIE==1"
                                                [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                                                {{art.articleName}}
                                            </td>
                                            <td style="    text-align: center;color: #536c79;font-weight: bold;">
                                                {{round2Decimals(getTotalQuantity(art))}}
                                            </td>
                                            <td class="unitMeasure">{{art.data[0].UM}}</td>
                                            <!-- [disabled]="differentClient" -->
                                            <td class="price"><input [ngClass]="art.invalidPrice?'alert':''"
                                                    (keyup)="propagateQuantity(art.data);recalculate($event)"
                                                    [(ngModel)]="art.data[0].discount" style="width: 100%;"
                                                    type="number"></td>
                                            <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                                            <td
                                                [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                                {{getSubtotal(art.data[0])}}
                                            </td>


                                        </ng-container>
                                    </tr>

                                </table>


                            </mat-expansion-panel>
                        </div>
                    </div>
                    <div *ngIf="topCategory.categories.length==1">
                        <div *ngFor="let item of topCategory.categories">

                            <table id="customers" style="width:100%">
                                <tr style="font-size:14px;">
                                    <th class="name">Nume</th>
                                    <th class="unitMeasure">UM</th>

                                    <th class="quantity">Cant.</th>
                                    <th class="parity">Paritate</th>

                                    <th class="price">Discount</th>
                                    <th class="listPrice">Pret Lista</th>
                                    <th class="listPrice">Subtotal</th>
                                    <th class="quantity">Stoc.</th>
                                    <th class="quantity">INFO</th>
                                </tr>
                                <tr *ngFor="let art of item.articles; let itemI = index">
                                    <ng-container
                                        *ngIf="art.data.length==1 && ((!showZeroQuantityArticles)|| (showZeroQuantityArticles && art.data[0].quantity>0))">
                                        <td [ngClass]="art.data[0].quantity ?'selectedItem name':'name'">
                                            {{art.articleName}}
                                        </td>
                                        <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                            {{art.data[0].UM}} mm
                                        </td>

                                        <td style="background-color: #ceedff;text-align: center;">
                                            <b>
                                                {{art.data[0].quantity?round2Decimals(art.data[0].quantity):""}}</b>
                                        </td>
                                        <td style="background-color: #ceedff;text-align: center;">
                                            {{art.data[0].parity}}
                                        </td>

                                        <td class="price">
                                            <input [disabled]="offerData.status=='COMANDA'"
                                                [ngClass]="art.invalidPrice?'alert':''" style="width: 100%; "
                                                (keyup)="recalculate($event)" [(ngModel)]="art.data[0].discount"
                                                type="number">
                                        </td>
                                        <td [ngClass]="art.data[0].LISTA==0?'alert listPrice':'listPrice'">
                                            {{round2Decimals(art.data[0].LISTA)}}
                                        </td>
                                        <td
                                            [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                            {{getSubtotal(art.data[0])}}
                                        </td>
                                        <td [ngClass]="art.data[0].quantity?'unitMeasure selectedItem':'unitMeasure'">
                                            {{art.data[0].STOC}}
                                        </td>

                                        <td>
                                            <div [ngClass]="!art.data[0].quantity || art.data[0].quantity<=art.data[0].STOC?'infoBtn':'infoBtnStock'"
                                                *ngIf="art.data[0].PRODUSPROPRIU!=2" (click)="getStoc(art.data[0])"
                                                style="width:25px;height: 25px;    cursor: pointer;"
                                                [inlineSVG]="'/assets/images/info_int.svg'"></div>
                                        </td>

                                        <!-- <td>{{art.data[0].STOC}}</td> -->

                                    </ng-container>

                                    <ng-container *ngIf="art.data.length!=1 ">
                                        <td *ngIf="art.data[0].PROMOTIE!=1"
                                            [ngClass]="art.totalQuantity?'name selectedItem':'name'">
                                            {{art.articleName}}
                                        </td>
                                        <td *ngIf="art.data[0].PROMOTIE==1"
                                            [ngClass]="art.totalQuantity?'name selectedPromotionItem':'name'">
                                            {{art.articleName}}
                                        </td>
                                        <td style="    text-align: center;color: #536c79;font-weight: bold;">
                                            {{round2Decimals(getTotalQuantity(art))}}
                                        </td>
                                        <td class="unitMeasure">{{art.data[0].UM}}</td>
                                        <!-- [disabled]="differentClient" -->
                                        <td class="price"><input [ngClass]="art.invalidPrice?'alert':''"
                                                (keyup)="propagateQuantity(art.data);recalculate($event)"
                                                [(ngModel)]="art.data[0].discount" style="width: 100%;" type="number">
                                        </td>
                                        <td>{{round2Decimals(art.data[0].LISTA)}}</td>
                                        <td
                                            [ngClass]="art.data[0].quantity?'listPrice selectedItem center':'listPrice center'">
                                            {{getSubtotal(art.data[0])}}
                                        </td>


                                    </ng-container>
                                </tr>

                            </table>

                        </div>
                    </div>
                </mat-accordion>

            </ng-container>

        </ng-container>

        <!-- </mat-expansion-panel>
        </mat-accordion> -->
        <!-- aaaaaaaaaaaaaaaaaaaaaaaaaa -->

    </div>



    <div class="noprint" style="width:300px;margin: 0 auto 0 0;">
        <div style="margin:5px;width:275px;overflow: auto;">



            <!-- <mat-card style=" background: #2f404a;">
          <div style="color:#fff;font-size:16px; margin-top:10px">
            <div style="color:#3ac47d;text-align: center;font-size:22px; margin-top:5px" *ngIf="offerData">
              Total: {{totalPrice}} lei
            </div>
          </div>
          <div style="display:flex">
            <div (click)="showAdaos=!showAdaos" class="actionSvg delete" style="margin:auto"
              [inlineSVG]="'/assets/images/cash_earned.svg'"></div>
          
          </div>
          <div *ngIf="showAdaos" style="color:#27A2DB;text-align: center;font-size:18px; margin-top:5px">
            {{adaosValue}} lei | {{adaosPercent}} %</div>
        </mat-card> -->
            <mat-card style="padding: 5px;">
                <div style="display:flex;">
                    <div style="width:50%">
                        <div style="color:black;text-align: right;font-size:18px; margin-top:5px;margin-right: 15px;"
                            *ngIf="offerData">
                            {{this.getTotalPrice()}} lei
                        </div>
                        <div style="display:flex;float: right;margin-right: 15px;">
                            <div style="color:black; margin-top: 7px;"> {{round2Decimals(totalOfferWeight)}}</div>
                            <!-- <div style="margin: auto auto auto 3px;" class="routeInfo kg" [inlineSVG]="'/assets/images/weight.svg'">
                </div> -->
                            <img style="width: 24px ;height: 24px ;" src="/assets/images/kg.png" alt="Logo" />
                        </div>
                    </div>
                    <div *ngIf="f2" style="width:45%">
                        <!-- *ngIf="offerData.status!='COMANDA'" -->
                        <div (click)="f1=true;f2=false;f3=false;"
                            style="text-align: center;padding:10px;color: white;font-size: 14px;background:#2b50a3">
                            <div><b>Vezi <br> COMANDA</b></div>
                        </div>
                    </div>

                    <div *ngIf="!f2" style="width:45%">
                        <!-- *ngIf="offerData.status!='COMANDA'" -->
                        <div (click)="f1=false;f2=true;f3=true;"
                            style="text-align: center;padding:10px;color: white;font-size: 14px;background:#2b50a3">
                            <div><b>Vezi <br> articole</b></div>
                        </div>
                    </div>

                    <!-- *ngIf="offerData.status!='COMANDA'" -->
                    <div *ngIf="offerData?.client?.name && offerData.client.soldValue+totalPrice<=offerData.client.creditValue"
                        class="actionSvg delete" style="width:45px;" [inlineSVG]="'/assets/images/tick.svg'"></div>
                    <div *ngIf="offerData?.client?.name && offerData.client.soldValue+totalPrice>offerData.client.creditValue"
                        class="actionSvg delete" style="width:65px;" [inlineSVG]="'/assets/images/error.svg'"></div>
                </div>
                <mat-divider></mat-divider>

                <div style="margin:5px;">
                    <div style="color: white;font-size: 16px;background: #2b50a3; padding: 5px;"
                        *ngFor="let pallet of nextData; index as ind;" (click)="selectedPackage=pallet">
                        <div>Palet {{ind+1}} {{pallet.height}} cm {{pallet.weight}} kg</div>

                    </div>
                </div>

                <p-divider *ngIf="(badPallet&& badPallet.layers.length>0 ) || incorrect">
                    <div class=" p-d-inline-flex p-ai-center" style="color:  #ff771a;">
                        <i class="pi pi-exclamation-triangle p-mr-2"></i>
                        <span>Paletare ineficienta!</span>
                    </div>
                </p-divider>
                <p-divider *ngIf="stockExceeded">
                    <div class=" p-d-inline-flex p-ai-center" style="color:  #ff771a;">
                        <i class="pi pi-exclamation-triangle p-mr-2"></i>
                        <span>Stoc depasit!</span>
                    </div>
                </p-divider>


                <button *ngIf="offerData.status!='COMANDA'" (click)="refapaletare()" pButton pRipple type="button"
                    label="Refa paletarea" class="p-button-success p-mt-2 customButton"></button>
            </mat-card>


            <mat-card style="margin:10px 0;">
                <div *ngIf="offerData.status=='COMANDA'">
                    <div
                        style="margin: 0 5px 10px 5px;font-size: 28px;font-weight: bold;text-align: center;color: #3ac47d;">
                        {{offerData.data && !offerData.returned?offerData.data.commandSeries:'RETUR '}}
                        {{offerData.data?offerData.data.commandNumber:''}}</div>
                    <div style="display:flex;justify-content: center;margin-bottom: 10px;">
                        <div (click)="cancelCommandFlag=true"
                            style="padding:10px;color: #fff;font-size: 16px;background:red">
                            Anuleaza comanda
                        </div>
                    </div>

                </div>
                <div style="color: #536c79; font-size: 20px;text-align: center;" *ngIf="offerData.client">
                    <b>Client</b>
                </div>

                <button *ngIf="offerData.status!='COMANDA'" (click)="showClientsModal=true" pButton pRipple
                    type="button" label="Lista Clienti" class="p-button-success p-mt-2 customButton"></button>

                <div style="color: #536c79; font-size: 20px;text-align: center; padding: 7px;" *ngIf="offerData.client">
                    {{offerData.client.name}}</div>





                <div *ngIf="offerData.client &&offerData.client.phone" class="p-mt-2">
                    <p-divider>
                        <div class="p-d-inline-flex p-ai-center grayDivider">
                            <i class="pi pi-phone p-mr-2"></i>
                            <span>Telefon</span>
                        </div>
                    </p-divider>
                    <div
                        style="color:#003048; font-size: 17px;text-align: center; padding: 3px 0;font-family: 'CarettaOffice';">
                        {{offerData.client.phone}}</div>
                </div>




                <p-divider *ngIf="offerData.client &&offerData.client.name">
                    <div class="p-d-inline-flex p-ai-center grayDivider">
                        <i class="pi pi-map-marker p-mr-2"></i>
                        <span>Adresa</span>
                    </div>
                </p-divider>

                <div *ngIf="offerData.client &&offerData.client.name"
                    style="color:#003048;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
                    {{offerData.client.address.address}} {{offerData.client.address.city}}
                    {{offerData.client.address.conunty}}</div>


                <div *ngIf="offerData?.client?.name" style="margin:5px;color: #536c79;">Sold:
                    {{round2Decimals(offerData.client.soldValue)}} </div>
                <div *ngIf="offerData?.client?.name" style="margin:5px;color: #536c79;">Limita de credit:
                    {{round2Decimals(offerData.client.creditValue)}}</div>
                <button *ngIf="clientSoldItems.length>0" (click)="showDocsModal=true" pButton pRipple type="button"
                    label="Documente" class="p-button-success p-mt-2 customButton"></button>



                <mat-divider></mat-divider>
                <!-- <div>
                    <div *ngIf="offerData.isAdvancePayment">Plata avans {{offerData.advancePaymentValue}}</div>
                    <div *ngIf="offerData.paymentType && offerData.paymentType!='undefined'">Modalitate de plata:
                        {{offerData.paymentType}}</div>
                    <div *ngIf="offerData.finishType && offerData.finishType!='undefined'">Finalizare:
                        {{offerData.finishType}}</div>
                    <div *ngIf="offerData.paymentTerm && offerData.paymentTerm!='undefined'">Termen plata:
                        {{offerData.paymentTerm}}</div>
                </div> -->
            </mat-card>

            <mat-card style="margin:10px 0;">
                <div style="color: #536c79; font-size: 20px;text-align: center;" *ngIf="offerData.client">
                    <b>Adresa de livrare si transport</b>
                </div>

                <button *ngIf="offerData&&offerData.status!='COMANDA'" (click)="showAlternativeDelivery=true" pButton
                    pRipple type="button" label="Seteaza adresa/transportul"
                    class="p-button-success p-mt-2 customButton"></button>

                <p-divider *ngIf="offerData.deliverAddress">
                    <div class="p-d-inline-flex p-ai-center grayDivider">
                        <i class="pi pi-map-marker p-mr-2"></i>
                        <span>Adresa livrare</span>
                    </div>
                </p-divider>

                <div *ngIf="offerData.deliverAddress"
                    style="color:#003048;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
                    {{offerData.deliverAddress}}
                </div>

                <p-divider *ngIf="offerData.deliverDate">
                    <div class="p-d-inline-flex p-ai-center grayDivider">
                        <i class="pi pi-calendar p-mr-2"></i>
                        <span>Termen livrare</span>
                    </div>
                </p-divider>

                <div *ngIf="offerData.deliverDate"
                    style="color:#003048;padding: 3px 0;text-align: center;font-family: 'CarettaOffice';">
                    {{offerData.deliverDate | date: 'dd/MM/yyyy' }}
                </div>

                <div class="p-mt-4">
                    <div class="p-float-label" [style]="{'width':'100%'}">
                        <textarea [disabled]="offerData.status=='COMANDA'?true:false" inputId="obs" [rows]="2"
                            [cols]="30" autoResize="autoResize" pInputTextarea
                            [(ngModel)]="offerData.observations"></textarea>
                        <label for="obs">Observatii</label>
                    </div>
                </div>
            </mat-card>




            <mat-card *ngIf="offerData.status!='COMANDA'" style="margin:10px 10px">
                <div style="color: #536c79; font-size: 20px;text-align: center;" *ngIf="offerData.client">
                    <b>FINALIZARE</b>
                </div>
                <button (click)="saveOffer('OFERTA',null)" pButton pRipple type="button" label="SALVEAZA OFERTA"
                    class="p-button-success finishAction"></button>

                <button (click)="finishCommand()" pButton pRipple type="button" label="PLASEAZA COMANDA"
                    class="p-button-success finishAction"></button>
            </mat-card>
            <div class="p-text-bold" style="text-align:center;color:white;font-family: CarettaOffice;font-size: 22px;">V
                {{version}}</div>
        </div>
    </div>
</div>


<p-dialog [closable]="false" [style]="{width: '900px',height:'85%'}" [modal]="true" [draggable]="false"
    [(visible)]="showClientsModal">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Clienti!</span>
        <div class="closeSvgRight" (click)="showClientsModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>
    <div style="display:flex">
    </div>

    <table id="customers" style="width:100%">
        <tr style="font-size:14px;">
            <th class="name">
                <mat-form-field style="width:100%" class="example-full-width noLabel">
                    <input [debounce]="500" [(ngModel)]="clientNameSearch" (onDebounce)="onNameInputChange()" matInput
                        placeholder="Nume">
                </mat-form-field>
            </th>
            <th class="quantity">
                <mat-form-field style="width:80px" class="example-full-width noLabel">
                    <input [debounce]="500" [(ngModel)]="clientPhoneSearch" (onDebounce)="onPhoneInputChange()" matInput
                        placeholder="Telefon">
                </mat-form-field>
            </th>
            <th class="unitMeasure">
                <mat-form-field style="width:80px" class="example-full-width noLabel">
                    <input [debounce]="500" [(ngModel)]="clientFiscalCodeSearch" (onDebounce)="onFiscalCodeChange()"
                        matInput placeholder="CNP/CUI">
                </mat-form-field>
            </th>
            <th class="unitMeasure">
                Reg. Com.
            </th>
            <th class="unitMeasure">
                Adresa
            </th>
            <!-- <th class="unitMeasure">
          Localitate
        </th> -->
            <!-- <th class="unitMeasure">
          Judet
        </th>
        <th class="unitMeasure">
          Localitate
        </th> -->
            <th class="unitMeasure">
                <div (click)="addNewClient=true" matTooltip="Adaugare client nou" class="editSvg"
                    [inlineSVG]="'/assets/images/add.svg'"></div>
            </th>
        </tr>
        <tr *ngFor="let client of clients">
            <td> {{client.DEN_PART}} </td>
            <td> {{client.TELEFON}} </td>
            <td> {{client.CODFISCAL}} </td>
            <td> {{client.REGCOM}} </td>
            <!-- <td> {{client.DEN_LOCALIT}} {{client.JUDET}} </td> -->
            <td *ngIf="client.PERSOANAFIZICA==0">{{client.JUDET}}
                {{client.DEN_LOCALIT&& client.JUDET
                &&client.DEN_LOCALIT.toLowerCase()!=client.JUDET.toLowerCase()?client.DEN_LOCALIT:""}}
                {{client.ADRESA}}</td>
            <td *ngIf="client.PERSOANAFIZICA==1">{{client.JUDET}}
                {{client.DEN_LOCALIT&& client.JUDET
                &&client.JUDET.toLowerCase()!=client.DEN_LOCALIT.toLowerCase()?client.DEN_LOCALIT:""}}
                {{client.ADRESA}}
            </td>
            <td>
                <div style="display:flex">
                    <!-- <div (click)="prefillEditClientData(client)" matTooltip="Adaugare locatie" class="actionSvg"
              [inlineSVG]="'/assets/images/addlocation.svg'"></div> -->
                    <div (click)="prefillEditClientData(client)" matTooltip="Editare locatie" class="actionSvg"
                        [inlineSVG]="'/assets/images/edit.svg'"></div>
                    <div (click)="prefillSelectedClientData(client)" matTooltip="Confirma" style="margin-left: 5px;"
                        class="actionSvg" [inlineSVG]="'/assets/images/confirm.svg'"></div>
                </div>
            </td>
            <!-- <td>{{art.data[0].STOC}}</td> -->
        </tr>

    </table>
</p-dialog>

<p-dialog [closable]="false" [style]="{width: '700px',height:'85%'}" [modal]="true" [draggable]="false"
    [(visible)]="showDocsModal">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Documente sold!</span>
        <div class="closeSvgRight" (click)="showDocsModal=null" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>
    <div style="display:flex">
    </div>

    <table id="customers" style="width:100%">
        <tr style="font-size:14px;">
            <th class="quantity">
                Tip Doc
            </th>
            <th class="quantity">
                Serie/Numar
            </th>
            <th class="unitMeasure">
                Data
            </th>
            <th class="unitMeasure">
                Termen
            </th>
            <th class="unitMeasure">
                Valoare
            </th>

            <th class="unitMeasure">
                Rest
            </th>
        </tr>
        <tr *ngFor="let soldItem of clientSoldItems">
            <td> {{soldItem.TipDocument}} </td>
            <td> {{soldItem.Numar}} </td>
            <td> {{soldItem.Data}} </td>
            <td> {{soldItem.Termen}} </td>
            <td>{{soldItem.Valoare}}</td>
            <td> {{soldItem.Rest}} </td>
        </tr>

    </table>
</p-dialog>



<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="addNewClient">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Adaugare client nou!</span>
        <div class="closeSvgRight" (click)="addNewClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>


    <div class="p-mt-4">
        <span class="p-float-label">
            <p-dropdown appendTo="body" [autoDisplayFirst]="false" inputId="clientType" name="value"
                [options]="clientTypes" [showClear]="true" [style]="{'width':'100%'}"
                [(ngModel)]="newClientData.clientType">
            </p-dropdown>
            <label for="clientType">Tip client</label>
        </span>
    </div>

    <div *ngIf="offerData && newClientData.clientType && !validFiscalCode && newClientData.clientType!='PF'"
        class=" p-mt-4" style="display:flex;">
        <span style="width: calc(100% - 100px);margin-right: 10px;" class="p-float-label">
            <input required inputId="CUI" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                [(ngModel)]="newClientData.fiscalCode" />
            <label for="CUI">CUI</label>
        </span>

        <button (click)="validateFiscalCode(newClientData.fiscalCode)" pButton type="button" label="Valideaza"
            class="p-button-outlined"></button>
    </div>




    <div *ngIf="newClientData.clientType=='PF' || validFiscalCode">

        <div class="p-mt-4">
            <span class="p-float-label">
                <input [disabled]="validFiscalCode" inputId="clientName" [style]="{'text-align':'left','width':'100%'}"
                    type="text" pInputText [(ngModel)]="newClientData.name" />
                <label for="clientName">Nume Client</label>
            </span>
        </div>

        <div class="p-mt-4">
            <span class="p-float-label">
                <input [disabled]="validFiscalCode" required inputId="typeClient"
                    [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                    [(ngModel)]="newClientData.fiscalCode" />
                <label for="typeClient">{{newClientData.clientType=='PF'?'CNP':'CUI'}}</label>
            </span>
        </div>
        <div *ngIf="offerData && newClientData.clientType!='PF'" class="p-mt-4">
            <span class="p-float-label">
                <input required inputId="reg" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                    [(ngModel)]="newClientData.tradeRegister" />
                <label for="reg">Reg Com</label>
            </span>
        </div>
        <div class="p-mt-4">
            <span class="p-float-label">
                <input required inputId="phone" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                    [(ngModel)]="newClientData.phone" />
                <label for="phone">Telefon</label>
            </span>
        </div>



        <!-- <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="newClientData.name">
    </mat-form-field>

    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput [placeholder]="newClientData.clientType=='PF'?'CNP':'CUI'"
        [(ngModel)]="newClientData.fiscalCode">
    </mat-form-field>
    <mat-form-field *ngIf="offerData && newClientData.clientType!='PF'" class="noLabel" style="width:100%">
      <input type="text" matInput placeholder="Reg Com" [(ngModel)]="newClientData.tradeRegister">
    </mat-form-field>
    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
      <input required type="text" matInput placeholder="Telefon" [(ngModel)]="newClientData.phone">
    </mat-form-field> -->


        <div class=" p-mt-4" *ngIf="counties">
            <span class="p-float-label">
                <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownCountyClient"
                    [options]="counties.rows" [filter]="true" [showClear]="true" [style]="{'width':'100%'}"
                    optionLabel="countyName" emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="countyName"
                    (onChange)="loadLocalities($event)">
                </p-dropdown>
                <label for="dropdownCountyClient">Judet</label>
            </span>
        </div>

        <div class=" p-mt-4" *ngIf="filteredOptions">
            <span class="p-float-label">
                <p-dropdown [autoDisplayFirst]="false" appendTo="body" inputId="dropdownLocality"
                    [(ngModel)]="newClientData.locality" [options]="filteredOptions" [filter]="true" [showClear]="true"
                    (onClick)="initLocalities()" [style]="{'width':'100%'}"
                    emptyFilterMessage="Nu au fost gasite rezultate!" optionLabel="localityName"
                    filterBy="localityName">
                    <ng-template let-loc pTemplate="item">
                        <div style="font-size:14px;margin-top:4px"> {{loc.localityName}} <span
                                *ngIf="loc && loc.localityName!=loc.village">({{loc.village }})</span></div>
                    </ng-template>
                </p-dropdown>
                <label for="dropdownLocality">Oraș/Localitate</label>
            </span>
        </div>



        <div style="display:flex">
            <mat-form-field style="width:100%" class="example-full-width noLabel">
                <input matInput placeholder="Strada" [(ngModel)]="newClientData.street">
            </mat-form-field>
            <mat-form-field style="width:50px" class="example-full-width noLabel">
                <input matInput placeholder="Nr." [(ngModel)]="newClientData.number">
            </mat-form-field>
            <mat-form-field style="width:50px" class="example-full-width noLabel">
                <input matInput placeholder="Scara" [(ngModel)]="newClientData.scara">
            </mat-form-field>
            <mat-form-field style="width:50px" class="example-full-width noLabel">
                <input matInput placeholder="Bloc" [(ngModel)]="newClientData.bloc">
            </mat-form-field>
            <mat-form-field style="width:50px" class="example-full-width noLabel">
                <input matInput placeholder="Etaj" [(ngModel)]="newClientData.etaj">
            </mat-form-field>
            <mat-form-field style="width:50px" class="example-full-width noLabel">
                <input matInput placeholder="Ap." [(ngModel)]="newClientData.apartament">
            </mat-form-field>

        </div>
        <div (click)="insertNewPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
        </div>

    </div>

</p-dialog>

<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="editClient">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Editare date client!</span>
        <div class="closeSvgRight" (click)="editClient=false" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>

    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Nume Client" [(ngModel)]="editClientData.name">
    </mat-form-field>
    <mat-form-field *ngIf="offerData && !editClientData.fiscalCode" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Cod fiscal" [(ngModel)]="editClientData.fiscalCode">
    </mat-form-field>
    <mat-form-field *ngIf="!editClientData.isPF" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Reg Com" [(ngModel)]="editClientData.tradeRegister">
    </mat-form-field>
    <mat-form-field *ngIf="offerData" class="noLabel" style="width:100%">
        <input required type="text" matInput placeholder="Telefon" [(ngModel)]="editClientData.phone">
    </mat-form-field>

    <mat-form-field *ngIf="counties" style="width:100%" class="example-full-width noLabel">
        <mat-label>Judet</mat-label>
        <mat-select [(value)]="selectedCounty" (selectionChange)='loadLocalities($event)'>
            <mat-option *ngFor="let county of counties.rows" [value]="county">
                {{county.countyName}}
            </mat-option>
        </mat-select>
    </mat-form-field>



    <mat-form-field style="width:100%" class="example-full-width noLabel">
        <input type="text" [(ngModel)]="editClientData.locality" placeholder="Oraș/Localitate" aria-label="Number"
            matInput [formControl]="myControl" [matAutocomplete]="matAutocompleteLocality">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="editClientData.locality=''">
            <mat-icon>close</mat-icon>
        </button>
        <!-- [displayWith]="displayLocalityName" -->
        <mat-autocomplete #matAutocompleteLocality="matAutocomplete">
            <mat-option *ngFor="let locality of filteredOptions" [value]="locality">
                {{locality.localityName }} <span *ngIf="locality.localityName!=locality.village">({{locality.village
                    }})</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div style="display:flex">
        <mat-form-field style="width:100%" class="example-full-width noLabel">
            <input matInput placeholder="Strada" [(ngModel)]="editClientData.street">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Nr." [(ngModel)]="editClientData.number">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Scara" [(ngModel)]="editClientData.scara">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Bloc" [(ngModel)]="editClientData.bloc">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Etaj" [(ngModel)]="editClientData.etaj">
        </mat-form-field>
        <mat-form-field style="width:50px" class="example-full-width noLabel">
            <input matInput placeholder="Ap." [(ngModel)]="editClientData.apartament">
        </mat-form-field>

    </div>
    <div (click)="editPartner()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

</p-dialog>

<p-dialog *ngIf="selectedArticle" [closable]="false" [style]="{'max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="stockDetail">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Alege cantitatea</span>
        <div class="closeSvgRight" (click)="stockDetail=null" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>

    <div style="color: #536c79;font-size: 20px;padding: 7px;">
        {{selectedArticle.DENUMIRE}}
    </div>

    <div style="color: #536c79;font-size: 20px;padding: 7px;">
        unitati pe strat {{selectedArticle.layerParity}}
        unitati pe palet {{selectedArticle.palletParity}}
    </div>

    <div style="color: #536c79;font-size: 20px;padding: 7px;">
        stoc disponibil in gestiunea proprie {{selectedArticle.STOC}}
    </div>
    <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="selectedArticle.useMinimum">unitati
        minime ofertare {{selectedArticle.offerMinimumQuantity}}
    </mat-checkbox>
    <table id="customers" style="width:100%">
        <tr style="font-size:14px;">
            <th style="width:200px;padding:5px;">Furnizor</th>
            <th style="width:150px;padding:5px;">Valabilitate</th>
            <th style="width:50px;padding:5px;">Stoc</th>
            <!-- <th style="width:50px;padding:5px;">Promo</th> -->
            <th style="width:50px;padding:5px;">Cantitate</th>
        </tr>
        <tr *ngFor="let art of stockDetail">
            <ng-container>
                <td>{{art.supplierName}}</td>
                <td>{{art.valability | date:'dd.MM.yyyy HH:mm'}}</td>
                <td> {{math.floor(art.stock)}} </td>

                <!-- <td>
                    <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="art.isPromo">
                    </mat-checkbox>
                </td> -->

                <td class="quantityModal">
                    <!-- <input [disabled]="offerData&&offerData.status=='COMANDA'" type="number" min="0" step="1"
                        [(ngModel)]="art.conversionQuantity" style="width: 65px;"> -->
                    <p-inputNumber [(ngModel)]="art.conversionQuantity" [useGrouping]="false" mode="decimal">
                    </p-inputNumber>
                </td>
            </ng-container>
        </tr>

    </table>

    <button *ngIf="offerData&&offerData.status!='COMANDA'" (click)="saveQuantity(selectedArticle,stockDetail)" pButton
        pRipple type="button" label="SALVEAZA DATELE" class="p-button-success p-mt-2 customButton"></button>
</p-dialog>

<!-- Anulare comanda -->
<p-dialog [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false" [modal]="true"
    [(visible)]="cancelCommandFlag">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Anulare comanda
            {{offerData&&offerData.data?offerData.data.commandNumber:''}}</span>
        <div class="closeSvgRight" (click)="cancelCommandFlag=false" [inlineSVG]="'/assets/images/close.svg'">
        </div>
    </p-header>
    <div>Doriti anularea comenzii?</div>
    <mat-form-field class="noLabel" style="width:100%">
        <textarea style="height: 90px;" [(ngModel)]="cancelObservations" matInput
            placeholder="Motiv anulare"></textarea>
    </mat-form-field>
    <div (click)="cancelCommand()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

</p-dialog>


<!-- Adresa livrare alternativa -->
<p-dialog *ngIf="offerData" [closable]="false" [style]="{width: '480px','max-height': '90%'}" [draggable]="false"
    [modal]="true" [(visible)]="showAlternativeDelivery">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Livrare si transport!</span>
        <div class="closeSvgRight" (click)="showAlternativeDelivery=false" [inlineSVG]="'/assets/images/close.svg'">
        </div>
    </p-header>

    <div class="p-fluid p-grid">
        <div class="p-field p-col-12">
            <span class="p-float-label">
                <p-dropdown appendTo="body" [autoDisplayFirst]="false" inputId="dropdownCounty"
                    [options]="counties.rows" [filter]="true" [showClear]="true" [style]="{'width':'100%'}"
                    optionLabel="countyName" emptyFilterMessage="Nu au fost gasite rezultate!" filterBy="countyName"
                    (onChange)="setCountyDelivery($event)">
                </p-dropdown>
                <label for="dropdownCounty">Judet</label>
            </span>
        </div>



        <div *ngIf="filteredOptions" class="p-field p-col-12">
            <span class="p-float-label">
                <p-dropdown appendTo="body" [autoDisplayFirst]="false" inputId="dropdownLocality"
                    [options]="filteredOptions" [filter]="true" [showClear]="true" (onClick)="initLocalities()"
                    [style]="{'width':'100%'}" emptyFilterMessage="Nu au fost gasite rezultate!"
                    optionLabel="localityName" filterBy="localityName" (onChange)="setLocalityDelivery($event)">
                    <ng-template let-loc pTemplate="item">
                        <div style="font-size:14px;margin-top:4px">{{loc.localityName}} <span
                                *ngIf="loc.localityName!=loc.village">({{loc.village }})</span></div>
                    </ng-template>
                </p-dropdown>
                <label for="dropdownLocality">Oraș/Localitate</label>
            </span>
        </div>
    </div>

    <div class=" p-mt-1">
        <span class="p-float-label">
            <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
                [(ngModel)]="newClientData.street" />
            <label for="street">Strada</label>
        </span>
    </div>

    <mat-form-field class="noLabel" style="width:100%">
        <input [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.deliverDate" matInput
            [min]="minDate" [matDatepicker]="picker"
            [placeholder]="offerData.returned?'Data estimativa de retur':'Termen livrare'">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <!-- <div class="p-field p-col-12 p-md-4">
      <label for="spanish">Spanish</label>
      <p-calendar  [locale]="es"  inputId="spanish"></p-calendar>
    </div> -->



    <div style="margin:10px;">
        <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.pickedByClient">Ridica
            Clientul
        </mat-checkbox>
    </div>
    <!-- Transport
    <div *ngIf="offerData.pickedByClient" class="p-mt-2">
      <span class="p-float-label">
        <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="offerData.driverName" />
        <label for="street">Sofer</label>
      </span>
    </div>
    <div *ngIf="offerData.pickedByClient" class="p-mt-2">
      <span class="p-float-label">
        <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="offerData.carNumber" />
        <label for="street">Numar Masina</label>
      </span>
    </div>
    <div *ngIf="offerData.pickedByClient" class="p-mt-2">
      <span class="p-float-label">
        <input inputId="street" [style]="{'text-align':'left','width':'100%'}" type="text" pInputText
          [(ngModel)]="offerData.trailerNumber" />
        <label for="street">Numar Remorca</label>
      </span>
    </div>
  
    <div style="margin:10px;">
      <mat-checkbox [disabled]="offerData.status=='COMANDA'?true:false" [(ngModel)]="offerData.coupling">
        Cuplaj
      </mat-checkbox>
    </div>
  
    <div class=" p-mt-1">
      <span class="p-float-label">
        <p-dropdown [autoDisplayFirst]="false" inputId="transportType" appendTo="body" styleClass="p-mb-2"
          [options]="transportTypes" name="value" [showClear]="true" [style]="{'width':'100%'}"
          [(ngModel)]="offerData.transportType">
        </p-dropdown>
        <label for="transportType">Tip transport</label>
      </span>
    </div> -->

    <div (click)="alternativeDeliveryAdress()" class="confirmSvg" [inlineSVG]="'/assets/images/checked.svg'">
    </div>

</p-dialog>


<p-dialog *ngIf="selectedPackage" [closable]="false" [style]="{'height': '100%','width': '100%',
'overflow-y': 'auto'}" (onShow)="showDialogMaximized($event,dialDetail)" #dialDetail [draggable]="false" [modal]="true"
    [(visible)]="selectedPackage">
    <p-header>
        <span class="dialog-title" style="width:80%;margin-top:0;">Detalii palet/i</span>
        <div class="closeSvgRight" (click)="selectedPackage=null" [inlineSVG]="'/assets/images/close.svg'"></div>
    </p-header>

    <div (click)="debug=!debug" [inlineSVG]="'/assets/images/gear.svg'" style="    width: 18px; height: 18px;"></div>
    <div style="display:flex;">

        <div style="margin:0 auto;" class="dropZonesContainer" cdkDropListGroup>
            <div style="width: 330px;" *ngFor="let pallet of nextData; index as ind;">
                <div style="text-align: center; font-size: 24px; color: #536c79;" >
                    Palet {{ind+1}}
                </div>
                <div style="text-align: center; font-size: 24px; color: #536c79;">
                    <span style="margin: 0 3px ;" [ngClass]="pallet.incorrectValue?'red':''"> {{pallet.value}}
                        lei</span>
                    <span style="margin: 0 3px ;" [ngClass]="pallet.incorrectHeight?'red':''">{{pallet.height}}
                        cm</span>
                    <span style="margin: 0 3px ;" [ngClass]="pallet.incorrectWeight?'red':''">{{pallet.weight}}
                        kg</span>
                </div>

                <div style="flex-direction: column-reverse;
                display: flex;    margin: 0px 10px;" cdkDropList [cdkDropListData]="pallet"
                    (cdkDropListDropped)="drop($event)">

                    <div [matBadge]="i+1" matBadgePosition="before" color="primary"
                        [ngClass]="item.partial?'layer incomplete':'layer'"
                        [style]="'border-right: 3px solid '+getColor(item.articles[0])+';'"
                        *ngFor="let item of pallet.layers; index as i;" (cdkDragStarted)="dragStarted($event)"
                        (cdkDragEnded)="dragEnded($event)" cdkDrag [cdkDragData]="item">

                        <div style="font-size: 14px;margin-left: 10px;">
                            <!-- {{getPriority(item)}} <div *ngIf="item.articles.length==1" style="color:#4a3aff"> {{item.articles[0].quantity}}
                                *{{item.articles[0].boxWeight}} / {{item.articles[0].itemHeight}} </div>
-->
                            <!-- [style]="'color:'+getColor(article)+';'" -->
                            <div *ngFor="let article of item.articles">
                                <div style="display:flex">
                                    <div *ngIf="item.complete" [inlineSVG]="'/assets/images/chain.svg'"
                                        style="width: 18px; height: 18px;"></div>
                                    <div>{{ article.quantity}} {{article.DENUMIRE}}</div>
                                </div>
                                <div style="color:green" *ngIf="debug">RANK <span
                                        style="color:red">{{getPriority(item)}}</span> Lung {{article.boxLenght}} Lat
                                    {{article.boxWidth}} Inal {{article.boxHeight}} kg {{article.boxWeight}} </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div [inlineSVG]="'/assets/images/pallet.svg'" style="width: 100%;padding: 0 5px;"></div>
            </div>


            <div *ngIf="badPallet&& badPallet.layers.length>0" style="min-width: 310px;">

                <div style="text-align: center; font-size: 24px; color: #536c79;">
                    Randuri nerepartizate
                </div>
                <div style="    margin: 0px 10px;" cdkDropList [cdkDropListData]="badPallet"
                    (cdkDropListDropped)="drop($event)">

                    <div [matBadge]="i+1" matBadgePosition="before" color="primary"
                        [ngClass]="item.partial?'layer incomplete':'layer'"
                        *ngFor="let item of badPallet.layers; index as i;" (cdkDragStarted)="dragStarted($event)"
                        (cdkDragEnded)="dragEnded($event)" cdkDrag [cdkDragData]="item">

                        <div style="font-size: 14px;margin-left: 10px;">
                            <!-- <span style="color:#4a3aff"> prioritate {{getPriority(item)}}</span> -->
                            <div *ngFor="let article of item.articles">
                                <div style="color:green;">
                                    {{ article.quantity}} x {{article.DENUMIRE}}</div>

                                <div *ngIf="debug">Lung {{ article.boxLenght}} Lat {{article.boxWidth}} Inal
                                    {{article.boxHeight}} kg {{article.boxWeight}} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [inlineSVG]="'/assets/images/pallet.svg'" class="errorPallet" style="width: 100%;padding: 0 5px;">
                </div>
            </div>

        </div>
    </div>
</p-dialog>

<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="large" color="#7fc3d6" type="ball-circus"></ngx-spinner>